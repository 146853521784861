import { useState } from 'react';
import {
  ConfirmContractExecution,
  ConfirmPublishTxModalContent,
  NamingJsonStore,
  SendExecuteStore,
  WalletStore,
} from '@zen/common-app-parts';
import { useUnmountedRef } from '@zen/common-react-hooks';
import { clipboard } from '@zen/common-utils-browser';

export default function ExecuteRawModalProcess({ runResult, onCancel }) {
  const unmounted = useUnmountedRef();
  const [status, setStatus] = useState('confirm-after-execution');
  const [signedTx, setSignedTx] = useState();
  const {
    state: {
      currentWalletInfo: {
        keys: { pkHash },
      },
    },
  } = WalletStore.useStore();
  const { executeStore } = SendExecuteStore.useStore();
  const { state: naming } = NamingJsonStore.useStore();

  const contractName = naming[executeStore.state.contractId]
    ? naming[executeStore.state.contractId].shortName
    : '';

  const handleConfirmAfterExecutionCancel = () => {
    onCancel();
  };

  const handleCopy = async () => {
    try {
      await clipboard.writeText(signedTx);
    } catch (error) {
      //ignore error
    }
  };

  const handleConfirmAfterExecutionContinue = () => {
    if (!unmounted.current) {
      setSignedTx(runResult.tx);
      setStatus('confirm-after-sign');
    }
  };

  const handleConfirmAfterSignBack = () => {
    setStatus('confirm-after-execution');
  };
  const handleConfirmAfterSignContinue = async () => {
    await handleCopy();
    onCancel();
  };

  if (status === 'confirm-after-execution') {
    if (!runResult) return null;

    return (
      <ConfirmContractExecution
        contractName={contractName}
        contractAddress={executeStore.state.address}
        command={executeStore.state.command}
        includeReturnAddress={executeStore.state.includeReturnAddress}
        includeSender={executeStore.state.includeSender}
        messageBody={executeStore.state.messageBody}
        pkHash={pkHash}
        spends={executeStore.state.spends}
        tx={runResult.tx}
        onCancel={handleConfirmAfterExecutionCancel}
        onConfirm={handleConfirmAfterExecutionContinue}
      />
    );
  }

  if (status === 'confirm-after-sign') {
    if (!signedTx) return null;

    return (
      <ConfirmPublishTxModalContent
        title="Copy your unsigned TX"
        confirmText="Copy"
        tx={signedTx}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
        progress={executeStore.state.progress}
      />
    );
  }

  return null;
}
