import { ApiService } from '@zen/common-app-parts';

/**
 * @param {Object} params
 * @param {Function} params.dispatch
 * @param {string} params.code
 * @param {number} params.limit
 * @param {number} params.numberOfBlocks
 * @param {import("@zen/common-app-parts/lib/stores/WalletStore/WalletStore").TWalletStore} params.wallet
 */
export async function activate({ dispatch, code, limit, numberOfBlocks, wallet }) {
  dispatch({ type: 'process-started' });
  try {
    const result = await wallet.actions.activateContract({ code, limit, numberOfBlocks });
    dispatch({ type: 'process-success' });
    return result;
  } catch (error) {
    dispatch({ type: 'process-failed' });
    throw error;
  }
}

/**
 * @param {Object} params
 * @param {Function} params.dispatch
 * @param {string} params.tx
 * @param {string} params.password
 * @param {import("@zen/common-app-parts/lib/stores/WalletStore/WalletStore").TWalletStore} params.wallet
 */
export async function sign({ dispatch, tx, password, wallet, passphrase, account }) {
  dispatch({ type: 'process-started' });
  try {
    const result = await wallet.actions.signTx({ tx, password, passphrase, account });
    dispatch({ type: 'process-success' });
    return result;
  } catch (error) {
    dispatch({ type: 'process-failed' });
    throw error;
  }
}

/**
 * @param {Object} params
 * @param {Function} params.dispatch
 * @param {string} params.tx
 * @param {string} params.nodeUrl
 */
export async function publish({ dispatch, nodeUrl, tx }) {
  dispatch({ type: 'process-started' });
  try {
    await ApiService.publishTx(nodeUrl, tx);
    dispatch({ type: 'process-success' });
  } catch (error) {
    dispatch({ type: 'process-failed' });
    throw error;
  }
}
