import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { color, compose, height, system, width } from 'styled-system';
import { getTransition } from '../../utils/styles/mixins';

const Switch = ({ className, ...props }) => (
  <div className={className}>
    <label
      className={props.disabled ? 'disabled switch' : 'switch'}
      htmlFor="switch-input"
      aria-label="Switch"
    >
      <input
        data-testid="Switch-input"
        className="switch-input"
        type="checkbox"
        id="switch-input"
        disabled={props.disabled}
        checked={props.checked}
        onChange={() => !props.disabled && props.onChange}
      />
      <div
        className="switch-label"
        onClick={() => !props.disabled && props.onChange(!props.checked)}
        onKeyDown={() => !props.disabled && props.onChange(!props.checked)}
        role="button"
        tabIndex={0}
      />
    </label>
  </div>
);

const StyledSwitch = styled(Switch)`
  .switch {
    justify-content: end;
    display: inline-flex;
    margin-bottom: 0;
    cursor: default;

    .switch-input {
      cursor: default;
      height: 0;
      width: 0;
      visibility: hidden;

      &:checked ~ .switch-label {
        ${system({
          checkedBgColor: {
            property: 'backgroundColor',
            scale: 'colors',
          },
        })}
        &:after {
          left: calc(95%);
          transform: translate(-105%, 20%);
        }
      }
    }

    .switch-label {
      cursor: pointer;
      text-indent: -9999px;
      ${compose(height, width)};

      display: block;
      border-radius: 23px;
      position: relative;
      margin: 0;
      ${color};

      &::after {
        content: '';
        position: absolute;
        transform: translate(4%, 21%);
        left: 4px;
        width: calc(${(props) => props.width} - 46px);
        height: calc(${(props) => props.width} - 46px);
        border-radius: 50%;
        ${getTransition()}
        ${system({
          handleBgColor: {
            property: 'backgroundColor',
            scale: 'colors',
          },
        })}
      }
    }

    &.disabled {
      cursor: default;

      .switch-label {
        cursor: default;
        ${system({
          disabledBg: {
            property: 'backgroundColor',
            scale: 'colors',
          },
        })};

        &::after {
          ${system({
            disabledHandleBgColor: {
              property: 'backgroundColor',
              scale: 'colors',
            },
          })};
          box-shadow: none !important;
        }
      }
    }
  }
`;

const SwitchRender = ({ ...props }) => {
  return <StyledSwitch checked={props.checked} onChange={props.onChange} {...props} />;
};

SwitchRender.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

SwitchRender.defaultProps = {
  width: '68px',
  height: '31px',
  handleBgColor: 'text',
  disabledHandleBgColor: 'textDisabled',
  backgroundColor: 'bg2',
  disabledBg: 'bg4',
  checkedBgColor: 'primary',
  checkedBorderColor: 'primary',
};

export default SwitchRender;
