import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Title from '../titles/Title';
import Box from '../Box';
import { ModalFooter, ModalHeader, ModalSection, ModalSeparator } from './ModalContent';

/**
 * Enum for the alert types.
 * @readonly
 * @enum {string}
 */
const TYPES = {
  DEFAULT: 'info',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

/**
 * An alert modal which contain a title and a text message
 *
 * @export
 * @param {Object} props
 * @param {Function} props.onDismiss - a callback to ball when dismissing the alert
 * @param {string} props.title
 * @param {string} props.dismissText - the text of the dismiss button
 */
export default function AlertModalContent({ onDismiss, title, dismissText = 'Close', children }) {
  return (
    <Box>
      <ModalHeader>
        <Title>{title}</Title>
      </ModalHeader>
      <ModalSection>{children}</ModalSection>
      <ModalSeparator />
      <ModalFooter>
        <Button onClick={onDismiss}>{dismissText}</Button>
      </ModalFooter>
    </Box>
  );
}

AlertModalContent.propTypes = {
  onDismiss: PropTypes.func,
  title: PropTypes.any,
  dismissText: PropTypes.string,
};

AlertModalContent.TYPE = TYPES;
