import { ExecuteContractStore, SettingsStore, WalletStore } from '@zen/common-app-parts';

export default function Provider(props) {
  const {
    state: {
      settings: { chain, nodeUrl },
    },
  } = SettingsStore.useStore();
  const wallet = WalletStore.useStore();

  return (
    <ExecuteContractStore.Provider chain={chain} nodeUrl={nodeUrl} wallet={wallet} {...props} />
  );
}
