import { useMemo, useState } from 'react';
import { SettingsStore, TitleContentLayout, WalletStore, ZenJsUtils } from '@zen/common-app-parts';
import {
  Box,
  Button,
  Code,
  Description,
  FaSvg,
  Form,
  FormFooter,
  FormGroup,
  Input,
  InputMessage,
  Label,
  LabelText,
  Modal,
  ModalContent,
  notification,
  Paragraph,
  themeUtils as tu,
  useModal,
} from '@zen-common/components-base';
import { styled } from 'styled-components';
import { PublicKey, Signature, YAML } from '@zen/zenjs';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { TopExternalLink, TopLinksContainer } from '../../components/voting/TopLinks';
import ConfirmPublishModalProcess from './components/ConfirmPublishModalProcess';
import ConfirmSignModalProcess from './components/ConfirmSignModalProcess';

function showNotificationType(type) {
  switch (type) {
    case 'Contract Activation':
      notification('Successfully published a the contract activation', {
        type: notification.TYPE.SUCCESS,
      });
      break;
    case 'Contract Execution':
      notification('Successfully published a contract execution', {
        type: notification.TYPE.SUCCESS,
      });
      break;
    case 'Send':
      notification('Successfully published a send transaction', {
        type: notification.TYPE.SUCCESS,
      });
      break;
    case 'Contract Extension':
      notification('Successfully published a extension of contract', {
        type: notification.TYPE.SUCCESS,
      });
      break;
    case 'Contract Destroy':
      notification('Successfully published a destroy transaction', {
        type: notification.TYPE.SUCCESS,
      });
      break;
    default:
      notification('Successfully published a transaction', {
        type: notification.TYPE.SUCCESS,
      });
      break;
  }
}

export default function Signer() {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();

  const {
    state: {
      currentWalletInfo: { isWatchMode },
    },
  } = WalletStore.useStore();

  const [unsignedTx, setUnsignedTx] = useState('');

  const onUnsignedTx = (e) => {
    setUnsignedTx(e.target.value.trim());
  };

  const txData = useMemo(
    () => ZenJsUtils.getTxData({ tx: unsignedTx, chain }),
    [unsignedTx, chain]
  );

  const { hide, show } = useModal();

  function cancelProcess() {
    hide();
  }

  const handleConfirmAfterSignContinue = () => {
    setUnsignedTx('');
    showNotificationType(txData.type);
    cancelProcess();
  };

  const handleSign = async () => {
    show(() => (
      <Modal onDismiss={cancelProcess}>
        <ModalContent onClose={cancelProcess}>
          <ConfirmSignModalProcess
            handleConfirmAfterSignContinue={handleConfirmAfterSignContinue}
            onCancel={cancelProcess}
            tx={unsignedTx}
          />
        </ModalContent>
      </Modal>
    ));
  };
  const handlePublish = async () => {
    show(() => (
      <Modal onDismiss={cancelProcess}>
        <ModalContent onClose={cancelProcess}>
          <ConfirmPublishModalProcess
            tx={unsignedTx}
            handleConfirmAfterSignContinue={() => handleConfirmAfterSignContinue()}
          />
        </ModalContent>
      </Modal>
    ));
  };
  return (
    <TitleContentLayout
      title="Signer"
      renderBelowTitle={
        <Box mt="2">
          <Description>
            <Paragraph>
              In watch mode you are able to create unsigned transactions, you can sign and publish
              the transaction
            </Paragraph>
          </Description>
          <TopLinksContainer>
            <TopExternalLink href="https://docs.zenprotocol.com/signer">
              <FaSvg icon={faInfoCircle} mr="2" />
              Learn More
            </TopExternalLink>
          </TopLinksContainer>
        </Box>
      }
    >
      <Box maxWidth="formsMaxWidth">
        <Form>
          <FormGroup>
            <Label>
              <LabelText> Paste your Unsigned Transaction</LabelText>
            </Label>
            <StyledInput value={unsignedTx} onChange={onUnsignedTx} inputType="expandable" />
            <InputMessage>{txData?.error && txData.error.message}</InputMessage>
          </FormGroup>
          {txData?.txHash && <TxHashForm hash={txData.txHash} type={txData.type} />}
          {txData?.outputs && <OutputsFrom outputs={txData.outputs} />}
          {txData?.contract && txData.contract.contract && (
            <ContractForm contract={txData.contract} />
          )}
          {txData?.contractWitness && txData.contractWitness.length !== 0 && (
            <ContractWitnessForm cws={txData.contractWitness} chain={chain} />
          )}
          <FormFooter>
            {!txData?.isSigned && !isWatchMode && (
              <StyledButton disabled={!!txData?.error || !unsignedTx} onClick={handleSign}>
                Sign
              </StyledButton>
            )}
            {txData?.isSigned && !isWatchMode && (
              <StyledButton disabled={!!txData?.error || !unsignedTx} onClick={handlePublish}>
                Publish
              </StyledButton>
            )}
          </FormFooter>
        </Form>
      </Box>
    </TitleContentLayout>
  );
}

const StyledInput = styled(Input)`
  width: 100%;
`;

function TxHashForm({ hash, type }) {
  return (
    <FormGroup>
      <Label>
        <LabelText>Tx Info</LabelText>
      </Label>
      <InputMessage />
      <Box bg="bg3" minHeight="100px" p="smd">
        <Label>
          <LabelText>Tx Hash</LabelText>
        </Label>
        <StyledInput value={hash} readOnly />
        <InputMessage />
        {type && (
          <>
            <Label>
              <LabelText>Tx Type</LabelText>
            </Label>
            <StyledInput value={type} readOnly />
            <InputMessage />
          </>
        )}
      </Box>
      <InputMessage />
    </FormGroup>
  );
}

function OutputsFrom({ outputs }) {
  return outputs.map((o, index) => {
    return (
      <FormGroup key={index}>
        <Label>
          <LabelText>Output {index + 1}</LabelText>
        </Label>
        <InputMessage />
        <Box bg="bg3" minHeight="100px" p="smd">
          {o.kind && (
            <>
              <Label>
                <LabelText>Lock Type</LabelText>
              </Label>
              <StyledInput value={o.kind} readOnly />
              <InputMessage />
            </>
          )}
          {o.lockedAddress && (
            <>
              <Label>
                <LabelText>Recipient Address</LabelText>
              </Label>
              <StyledInput value={o.lockedAddress} readOnly />
              <InputMessage />
            </>
          )}
          <Label>
            <LabelText>Asset</LabelText>
          </Label>
          <StyledInput value={o.asset} readOnly />
          <InputMessage />
          <Label>
            <LabelText>Amount</LabelText>
          </Label>
          <StyledInput value={o.amount.safeValue} readOnly />
          <InputMessage />
        </Box>
        <InputMessage />
      </FormGroup>
    );
  });
}

function ContractForm({ contract }) {
  return (
    <FormGroup>
      <Label>
        <LabelText>Contract Code</LabelText>
      </Label>
      <InputMessage />
      <CodeWrapper>
        <Code code={contract.contract.code} />
      </CodeWrapper>
      <InputMessage />
    </FormGroup>
  );
}

function ContractWitnessForm({ cws, chain }) {
  return (
    <FormGroup>
      <Label>
        <LabelText>Contract Information</LabelText>
      </Label>
      <InputMessage />
      <Box bg="bg3" minHeight="100px" p="smd">
        {cws.map((cw, index) => {
          return (
            <FormGroup key={index}>
              <LabelText>Contract Address</LabelText>
              <StyledInput
                value={ZenJsUtils.getContractAddress(chain, cw.contractId.toHex())}
                readOnly
              />
              <InputMessage />
              {cw.command && (
                <>
                  <LabelText>Command</LabelText>
                  <StyledInput value={cw.command} readOnly />
                  <InputMessage />
                </>
              )}
              {cw.signature && (
                <>
                  <LabelText>Public Key</LabelText>
                  <StyledInput
                    value={PublicKey.deserialize(cw.signature[0]).toAddress(chain)}
                    readOnly
                  />
                  <InputMessage />
                  <LabelText>Signature</LabelText>
                  <StyledInput
                    inputType="expandable"
                    value={Signature.deserialize(cw.signature[1]).toString()}
                    readOnly
                  />
                  <InputMessage />
                </>
              )}
              {cw.messageBody && (
                <>
                  <LabelText>Message Body</LabelText>
                  <CodeWrapper>
                    <Code lang="yaml" code={YAML.toYaml(chain, cw.messageBody, true)} />
                  </CodeWrapper>
                  <InputMessage />
                </>
              )}
            </FormGroup>
          );
        })}
      </Box>
    </FormGroup>
  );
}

const CodeWrapper = (props) => <Box bg="bg4" minHeight="100px" p="smd" {...props} />;

const StyledButton = styled(Button)`
  margin-top: ${tu.space('xxs')};
  min-width: 134px;
  ${tu.mq({
    width: [(p) => (p.growOnMobile ? '100%' : 'auto'), 'auto'],
  })}
`;
