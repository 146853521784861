import {
  AddAccountSetting,
  AutoLogoutSetting,
  BackupWalletSetting,
  ChangePasswordSetting,
  DeleteWalletSetting,
  EditLabelAccountSetting,
  LogoutSetting,
  ReportErrorsSetting,
  SettingsItem,
  TitleContentLayout,
  WalletStore,
} from '@zen/common-app-parts';
import {
  useAddAccountSetting,
  useAutoLogoutSetting,
  useBackupWalletSetting,
  useChangePasswordSetting,
  useDeleteWalletSetting,
  useEditLabelAccountSetting,
  useLogoutSetting,
  useReportErrorSetting,
} from './settingsHooks';

const AccountSettingsPage = () => {
  const {
    state: {
      currentWalletInfo: { isWatchMode, isLoggedIn },
    },
  } = WalletStore.useStore();
  return (
    <TitleContentLayout dark title="Account Settings">
      {isLoggedIn && (
        <>
          <SettingsItem>
            <EditLabelAccountSetting useSetting={useEditLabelAccountSetting} />
          </SettingsItem>
          <SettingsItem>
            <AutoLogoutSetting useSetting={useAutoLogoutSetting} />
          </SettingsItem>
          <SettingsItem>
            <ChangePasswordSetting useSetting={useChangePasswordSetting} />
          </SettingsItem>
        </>
      )}
      <SettingsItem>
        <AddAccountSetting useSetting={useAddAccountSetting} />
      </SettingsItem>
      {isLoggedIn && (
        <>
          {!isWatchMode && (
            <SettingsItem>
              <BackupWalletSetting useSetting={useBackupWalletSetting} />
            </SettingsItem>
          )}
          <SettingsItem>
            <DeleteWalletSetting useSetting={useDeleteWalletSetting} />
          </SettingsItem>
          <SettingsItem>
            <LogoutSetting useSetting={useLogoutSetting} />
          </SettingsItem>
          {process.env.REACT_APP_END_PLATFORM === 'desktop' && (
            <SettingsItem>
              <ReportErrorsSetting useSetting={useReportErrorSetting} />
            </SettingsItem>
          )}
        </>
      )}
    </TitleContentLayout>
  );
};

export default AccountSettingsPage;
