import { produce } from 'immer';
import { equals } from 'ramda';

export const ACTIONS = {
  WALLET_REMOVED: 'WALLET_REMOVED',
  CHAIN_CHANGED: 'CHAIN_CHANGED',
  CREATE: {
    REQUESTED: 'CREATE_REQUESTED',
    FAILED: 'CREATE_FAILED',
    SUCCEEDED: 'CREATE_SUCCEEDED',
  },
  LOGIN: {
    REQUESTED: 'LOGIN_REQUESTED',
    FAILED: 'LOGIN_FAILED',
    SUCCEEDED: 'LOGIN_SUCCEEDED',
  },
  WIPE: {
    REQUESTED: 'WIPE_REQUESTED',
  },
  CHANGE_PASSWORD: {
    REQUESTED: 'CHANGE_PASSWORD_REQUESTED',
    FAILED: 'CHANGE_PASSWORD_FAILED',
    SUCCEEDED: 'CHANGE_PASSWORD_SUCCEEDED',
  },
  GET_PRIVATE_KEYS: {
    REQUESTED: 'GET_PRIVATE_KEYS_REQUESTED',
    FAILED: 'GET_PRIVATE_KEYS_FAILED',
    SUCCEEDED: 'GET_PRIVATE_KEYS_SUCCEEDED',
  },
  FETCH_BALANCE: {
    REQUESTED: 'FETCH_BALANCE_REQUESTED',
    FAILED: 'FETCH_BALANCE_FAILED',
    SUCCEEDED: 'FETCH_BALANCE_SUCCEEDED',
  },
  SEND: {
    STARTED: 'SEND_STARTED',
    FINISHED: 'SEND_FINISHED',
  },
  EXECUTE: {
    STARTED: 'EXECUTE_STARTED',
    FINISHED: 'EXECUTE_FINISHED',
  },
  RESET_REQUESTED: 'RESET_REQUESTED',
  UPDATE_MAIN_ADDRESS: 'UPDATE_MAIN_ADDRESS',
  ACCOUNT_GENERATE: {
    REQUESTED: 'ACCOUNT_GENERATE_REQUESTED',
    FAILED: 'ACCOUNT_GENERATE_FAILED',
    SUCCEEDED: 'ACCOUNT_GENERATE_SUCCEEDED',
  },
  FULL_WIPE: {
    REQUESTED: 'FULL_WIPE_REQUESTED',
  },
  VIEW_ACCOUNT: 'VIEW_ACCOUNT',
  EDIT_LABEL: 'EDIT_LABEL',
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.WALLET_REMOVED:
      if (draft.wallets[draft.chain][draft.viewAccount].wallet) {
        draft.wallets[draft.chain][draft.viewAccount] = {
          label: draft.wallets[draft.chain][draft.viewAccount].label,
          wallet: null,
          balance: {},
          balances: [],
          assets: [],
          keys: {
            address: '',
            change: '',
            pkHash: '',
            pubKey: '',
            addresses: draft.wallets[draft.chain][draft.viewAccount].keys.addresses,
          },
          hasPassword: draft.wallets[draft.chain][draft.viewAccount].hasPassword,
          hasPassphrase: draft.wallets[draft.chain][draft.viewAccount].hasPassphrase,
          isSubAccount: draft.wallets[draft.chain][draft.viewAccount].isSubAccount,
          encryptedSeed: draft.wallets[draft.chain][draft.viewAccount].encryptedSeed,
          isLoggedIn: false,
          isWatchMode: draft.wallets[draft.chain][draft.viewAccount].isWatchMode,
          initialBalanceFetched: false,
        };
        draft.viewAccount = 0;
      }
      return;
    case ACTIONS.CHAIN_CHANGED:
      draft.wallets[draft.chain][draft.viewAccount].isLoggedIn = false;
      draft.wallets[draft.chain][draft.viewAccount].hasPassword =
        !!draft.wallets[draft.chain][draft.viewAccount].encryptedSeed;
      draft.chain = action.payload.chain;
      return;
    case ACTIONS.CHANGE_PASSWORD.REQUESTED:
      return;
    case ACTIONS.CHANGE_PASSWORD.SUCCEEDED:
      draft.chain = action.payload.chain;
      draft.wallets[draft.chain][draft.viewAccount].encryptedSeed = action.payload.encryptedSeed;
      return;
    case ACTIONS.CHANGE_PASSWORD.FAILED:
      return;

    case ACTIONS.CREATE.REQUESTED:
      return;
    case ACTIONS.CREATE.SUCCEEDED:
      draft.chain = action.payload.chain;
      draft.wallets[draft.chain][draft.viewAccount].isLoggedIn = !!action.payload.wallet;
      draft.wallets[draft.chain][draft.viewAccount].isWatchMode = action.payload.watchMode;
      draft.wallets[draft.chain][draft.viewAccount].encryptedSeed = action.payload.encryptedSeed;
      draft.wallets[draft.chain][draft.viewAccount].wallet = action.payload.wallet;
      draft.wallets[draft.chain][draft.viewAccount].hasPassword = !!action.payload.encryptedSeed;
      draft.wallets[draft.chain][draft.viewAccount].hasPassphrase = !!action.payload.hasPassphrase;
      draft.wallets[draft.chain][draft.viewAccount].isSubAccount = !!action.payload.isSubAccount;
      draft.wallets[draft.chain][draft.viewAccount].keys = getKeysFromWallet(
        action.payload.wallet,
        action.payload.chain,
        action.payload.watchMode
      );
      draft.wallets[draft.chain][draft.viewAccount].label = action.payload.label;
      return;
    case ACTIONS.CREATE.FAILED:
      return;

    case ACTIONS.GET_PRIVATE_KEYS.REQUESTED:
      return;
    case ACTIONS.GET_PRIVATE_KEYS.SUCCEEDED:
      return;
    case ACTIONS.GET_PRIVATE_KEYS.FAILED:
      return;
    case ACTIONS.UPDATE_MAIN_ADDRESS:
      if (
        draft.wallets[draft.chain][draft.viewAccount].keys.addresses.includes(
          action.payload.address
        )
      )
        draft.wallets[draft.chain][draft.viewAccount].keys.address = action.payload.address;
      return;
    case ACTIONS.LOGIN.REQUESTED:
      return;
    case ACTIONS.LOGIN.SUCCEEDED:
      draft.chain = action.payload.chain;
      draft.wallets[draft.chain][draft.viewAccount].isLoggedIn = !!action.payload.wallet;
      draft.wallets[draft.chain][draft.viewAccount].isWatchMode = action.payload.watchMode || false;
      draft.wallets[draft.chain][draft.viewAccount].wallet = action.payload.wallet;
      draft.wallets[draft.chain][draft.viewAccount].hasPassword =
        !!draft.wallets[draft.chain][draft.viewAccount].encryptedSeed;
      draft.wallets[draft.chain][draft.viewAccount].keys = getKeysFromWallet(
        action.payload.wallet,
        action.payload.chain,
        action.payload.watchMode
      );
      return;
    case ACTIONS.LOGIN.FAILED:
      return;

    case ACTIONS.WIPE.REQUESTED:
      draft.account =
        typeof action.payload.account !== 'undefined' ? action.payload.account : draft.viewAccount;
      draft.wallets[draft.chain].splice(draft.account, 1);
      draft.viewAccount = draft.wallets[draft.chain].length - 1;
      if (!draft.wallets[draft.chain].length) {
        draft.viewAccount = 0;
        draft.wallets[draft.chain][draft.viewAccount] = {
          label: '',
          wallet: null,
          balance: {},
          balances: [],
          assets: [],
          keys: {
            address: '',
            change: '',
            pkHash: '',
            pubKey: '',
            addresses: [],
          },
          hasPassword: false,
          hasPassphrase: false,
          isSubAccount: false,
          encryptedSeed: '',
          isLoggedIn: false,
          isWatchMode: false,
          initialBalanceFetched: false,
        };
      }
      draft.loading = {};
      return;

    case ACTIONS.FULL_WIPE.REQUESTED:
      draft.wallets[draft.chain] = [];
      draft.wallets[draft.chain].push({
        label: '',
        wallet: null,
        balance: {},
        balances: [],
        assets: [],
        keys: {
          address: '',
          change: '',
          pkHash: '',
          pubKey: '',
          addresses: [],
        },
        hasPassword: false,
        hasPassphrase: false,
        isSubAccount: false,
        encryptedSeed: '',
        isLoggedIn: false,
        isWatchMode: false,
      });
      draft.viewAccount = 0;
      return;

    case ACTIONS.FETCH_BALANCE.REQUESTED:
      draft.loading.fetchBalance = true;
      return;
    case ACTIONS.FETCH_BALANCE.SUCCEEDED:
      if (draft.wallets[draft.chain][draft.viewAccount].wallet) {
        if (!equals(draft.balance, action.payload.balance)) {
          draft.wallets[draft.chain][draft.viewAccount].balance = action.payload.balance;
          draft.wallets[draft.chain][draft.viewAccount].balances = getComputedBalances(
            action.payload.balance
          );
          draft.wallets[draft.chain][draft.viewAccount].assets = draft.wallets[draft.chain][
            draft.viewAccount
          ].balances.map((b) => b.asset);
        }
      }
      draft.initialBalanceFetched = true;
      draft.loading.fetchBalance = false;
      return;
    case ACTIONS.FETCH_BALANCE.FAILED:
      draft.loading.fetchBalance = false;
      return;

    case ACTIONS.SEND.STARTED:
      draft.loading.send = true;
      setExecuting(draft);
      return;
    case ACTIONS.SEND.FINISHED:
      draft.loading.send = false;
      setExecuting(draft);
      return;
    case ACTIONS.EXECUTE.STARTED:
      draft.loading.execute = true;
      setExecuting(draft);
      return;
    case ACTIONS.EXECUTE.FINISHED:
      draft.loading.execute = false;
      setExecuting(draft);
      return;
    case ACTIONS.RESET_REQUESTED:
      draft.initialBalanceFetched = false;
      draft.wallets[draft.chain][draft.viewAccount].balance = {};
      draft.wallets[draft.chain][draft.viewAccount].balances = [];
      draft.wallets[draft.chain][draft.viewAccount].assets = [];
      return;
    case ACTIONS.ACCOUNT_GENERATE.REQUESTED:
      return;
    case ACTIONS.ACCOUNT_GENERATE.SUCCEEDED:
      if (
        draft.wallets[draft.chain][draft.wallets[draft.chain].length - 1].keys.addresses?.length
      ) {
        draft.wallets[draft.chain].push({
          label: '',
          wallet: null,
          balance: {},
          balances: [],
          assets: [],
          keys: {
            address: '',
            change: '',
            pkHash: '',
            pubKey: '',
            addresses: [],
          },
          hasPassword: false,
          hasPassphrase: false,
          isSubAccount: false,
          encryptedSeed: '',
          isLoggedIn: false,
          isWatchMode: false,
        });
      }
      draft.viewAccount = draft.wallets[draft.chain].length - 1;
      return;
    case ACTIONS.ACCOUNT_GENERATE.FAILED:
      return;
    case ACTIONS.VIEW_ACCOUNT:
      draft.wallets[draft.chain].length > action.payload.account
        ? (draft.viewAccount = action.payload.account)
        : null;
      return;
    case ACTIONS.EDIT_LABEL:
      draft.wallets[draft.chain][draft.viewAccount].label = action.payload.label;
      return;
    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
});
export default reducer;

function setExecuting(draft) {
  draft.executing = !!draft.loading.send || !!draft.loading.execute;
}

/**
 * Convert balance to an array
 *
 * @param {Object} balance
 * @returns {[{asset: string, amount: string}]}
 */
function getComputedBalances(balance) {
  return balance
    ? Object.keys(balance)
        .sort()
        .map((key) => ({ asset: key, amount: balance[key] }))
    : [];
}

/**
 * @param {import('@zen/zenjs').Wallet} wallet
 * @param {string} chain
 * @param {boolean} watchMode
 */
function getKeysFromWallet(wallet, chain, watchMode) {
  const address = !watchMode
    ? wallet.getExternalPublicKey().toAddress(chain)
    : wallet.getExternalAddress(chain);
  const pubKey = !watchMode ? wallet.getExternalPublicKey().toString() : '';
  const pubKeyChange = !watchMode ? wallet.getChangePublicKey().toString() : '';
  //when there is no change public key then it will default the external one.
  return {
    address,
    change: address !== wallet.getChangeAddress(chain) ? wallet.getChangeAddress(chain) : '',
    pkHash: wallet.getExternalPublicKeyHash().hash,
    pubKey,
    pubKeyChange: pubKey === pubKeyChange ? null : pubKeyChange,
    addresses: wallet.getAddresses(),
  };
}
