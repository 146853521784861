import { styled } from 'styled-components';
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import Box from '../../Box';
import Button from '../../Button/Button';
import * as cssMixins from '../../../utils/styles/mixins';
import tu from '../../../utils/themeUtils';
import FaSvg from '../../helpers/FaSvg';
import IconButton from '../../Button/IconButton';

export const AddInputAligner = styled(Box)`
  display: flex;
  justify-content: ${(p) => (p.align === 'right' ? 'flex-end' : 'flex-start')};
`;

const StyledAddInputButton = styled(Button)`
  ${cssMixins.resetButton}
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  svg {
    transition: none;
  }

  &:not([disabled]) {
    color: ${tu.color('label')};

    &:hover {
      color: ${tu.color('text')};
    }
  }
`;

/**
 * Displays the add Input button with text and icon
 * @param {React.ButtonHTMLAttributes} props
 */
export const AddInputButton = (props) => {
  return (
    <StyledAddInputButton bg="bg2" disabledBg="bg2" {...props}>
      <FaSvg icon={faPlusCircle} fontSize="xxl" mr="sm" />
      Add
    </StyledAddInputButton>
  );
};

const InputAndRemoveWrapper = styled(Box)`
  display: flex;
  align-items: stretch;
  margin-top: ${(p) => (!p.noMarginTop ? 0 : p.isFirst ? 0 : tu.space('xl')(p))};
`;

const StyledRemoveInputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: ${tu.space('sm')};
`;

export const RemoveInputButton = styled(IconButton).attrs({
  icon: faTimesCircle,
  'aria-label': 'Remove Input',
})`
  width: auto;
  height: auto;
  padding: ${tu.space('sm')};
  font-size: ${tu.fontSize('xxl')};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }
`;

/**
 * Aligns the Input and the remove button
 */
export const InputAndRemoveAligner = ({
  input,
  button,
  showButton,
  noMarginTop,
  isFirst,
  ...props
}) => {
  return (
    <InputAndRemoveWrapper noMarginTop={noMarginTop} isFirst={isFirst} {...props}>
      <Box flexGrow="1">{input}</Box>
      {showButton && <StyledRemoveInputWrapper>{button}</StyledRemoveInputWrapper>}
    </InputAndRemoveWrapper>
  );
};
