import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useStorageState } from '@zen/common-react-hooks';
import * as ApiService from '../../utils/ApiService';
import { STORAGE_KEY } from './constants';

/**
 * @param {Object} params
 * @param {{main: string, test:string}} params.url - record for main and testnet urls
 * @param {main: string, test:string} params.chain - chain
 */
export default function useNamingAssetStore({ url, chain } = {}) {
  const [namingMap, setNamingMap] = useStorageState(STORAGE_KEY, {}, { storage: 'localStorage' });
  const [refetchInterval, setRefetchInterval] = useState(5000);
  const { data } = useQuery(`asset-naming-json-${chain}`, () => fetchNamingAssetJson(url, chain), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    forceFetchOnMount: true,
    refetchIntervalInBackground: true,
    refetchInterval,
    enabled: true,
  });

  useEffect(() => {
    setRefetchInterval(5000);
  }, [data, url, namingMap, chain]);
  useEffect(() => {
    if (data) {
      // convert to a hash table
      setNamingMap(
        data.items.reduce((hash, item) => {
          return Object.assign(hash, item);
        }, {})
      );
    } else {
      setNamingMap({});
    }
  }, [data, setNamingMap]);

  return {
    state: namingMap,
  };
}

function fetchNamingAssetJson(url, chain) {
  try {
    return ApiService.sendHttpRequest({
      url: url[chain],
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (e) {
    return { items: [] };
  }
}
