import { useEffect } from 'react';
import { Alert, Box, CopyableHash, notification, useModal } from '@zen-common/components-base';
import { assetUtils, numberUtils, textUtils } from '@zen/common-utils';
import WalletStore from '../../stores/WalletStore';
import NamingAssetStore from '../../stores/NamingAssetStore';
import * as TD from '../TechnicalData/TechnicalData';

export default function SendNotifications({ sendStore }) {
  const {
    state: { result },
  } = sendStore;
  const { show, hide } = useModal();
  const {
    state: { executing },
  } = WalletStore.useStore();
  const { state: naming } = NamingAssetStore.useStore();

  useEffect(() => {
    if (result.type === 'success') {
      const address = result.data.address;
      const spends = result.data.spends;

      // try to get ZP if exists
      const spend = spends.find((spend) => assetUtils.isZP(spend.asset)) || spends[0];
      const restCount = spends.length - 1;
      const restText =
        restCount > 0
          ? ` +${restCount} ${textUtils.getSingularOrPlural(restCount, 'spend', 'spends')}`
          : '';

      notification(
        `Successfully published a transaction with ${numberUtils.toDisplay(spend.amount)}
        ${
          naming && naming[spend.asset]
            ? naming[spend.asset].shortName
            : assetUtils.truncateOrNameAsset(spend.asset)
        }${restText} to ${textUtils.truncateHash(address)}`,
        { type: notification.TYPE.SUCCESS }
      );
    } else if (result.type === 'error-sign') {
      if (!executing) {
        show(() => (
          <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error Signing">
            Couldn&apos;t sign the TX.
            <br />
            Error message: {result.data.message}
          </Alert>
        ));
      }
    } else if (result.type === 'error-publish') {
      if (!executing) {
        show(() => (
          <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error Sending">
            Couldn&apos;t publish the transaction
            <br />
            Error message: {result.data.message}
            {result.data.signedTx ? (
              <Box>
                <TD.Label>Transaction:</TD.Label>
                <TD.Value>
                  <CopyableHash hash={result.data.signedTx} />
                </TD.Value>
              </Box>
            ) : null}
          </Alert>
        ));
      }
    }
  }, [
    executing,
    hide,
    result.data.address,
    result.data.message,
    result.data.signedTx,
    result.data.spends,
    result.type,
    show,
    naming,
  ]);

  return null;
}
