import { useMemo, useRef } from 'react';
import { styled } from 'styled-components';
import {
  AutoSuggest,
  Box,
  Button,
  Checkbox,
  ClearInput,
  FaSvg,
  Form,
  FormBody,
  FormFooter,
  FormGroup,
  HiddenSubmitButton,
  InfoBox,
  Input,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  themeUtils as tu,
  Tooltip,
  useTooltip,
} from '@zen-common/components-base';
import {
  NamingAssetStore,
  NamingJsonStore,
  PasteButton,
  SendExecuteStore,
  SpendsGroup,
  WalletStore,
} from '@zen/common-app-parts';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

/**
 * Combines send and execute functionality
 * currently uses both SendStore and ExecuteStore
 *
 * @param {Object} props
 * @param {boolean} props.disabled - disable the whole form
 * @param {() => void} props.onSend - to be called when clicking on the send button
 * @param {() => void} props.onExecute - to be called when clicking on the execute button
 */
export default function SendExecuteForm({ disabled, onSend, onExecute }) {
  /** @type {{
   * executeStore: import('@zen/common-app-parts/lib/stores/ExecuteContractStore/types').TExecuteContractStore,
   * sendStore: import('@zen/common-app-parts/lib/stores/SendStore/types').SendStore
   * isExecute: boolean,
   * resetIsExecute: () => {}
   * }} */

  const { state: naming } = NamingAssetStore.useStore();
  const { state: namingContract } = NamingJsonStore.useStore();
  const {
    state: {
      currentWalletInfo: { isWatchMode },
    },
  } = WalletStore.useStore();
  const { executeStore, sendStore, isExecute, resetIsExecute } = SendExecuteStore.useStore();
  const addressRef = useRef();
  const spendsRef = useRef();

  const progress = executeStore.state.progress || sendStore.state.progress;
  const sendDisabled =
    disabled ||
    !sendStore.state.valid ||
    progress ||
    (isExecute && !!executeStore.state.command) ||
    (isExecute && !!executeStore.state.messageBody);

  const executeDisabled =
    !executeStore.state.valid ||
    progress ||
    disabled ||
    (executeStore.state.includeReturnAddress &&
      executeStore.state.messageBody !== '' &&
      executeStore.state.messageBodyType !== 'CollectionDict');

  const addressValid = isExecute ? !executeStore.state.addressValid : !sendStore.state.addressValid;

  const valid = isExecute ? executeStore.state.address : sendStore.state.address;

  const isMobile = document.body.clientWidth < tu.breakpoints.xl.replace('px', '');

  const invalidMessage = isExecute ? 'Contract not active or invalid address' : 'Invalid address';

  const onAddressChange = (value) => {
    executeStore.actions.setAddress(value);
    sendStore.actions.setAddress(value);
  };
  const onAssetChange = ({ index, value }) => {
    executeStore.actions.setAsset({ index, value });
    sendStore.actions.setAsset({ index, value });
  };
  const onAmountChange = ({ index, value }) => {
    executeStore.actions.setAmount({ index, value });
    sendStore.actions.setAmount({ index, value });
  };
  const onRemoveSpend = (index) => {
    executeStore.actions.removeSpend(index);
    sendStore.actions.removeSpend(index);
    // make sure focus after the removed
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  };
  const onAddSpend = () => {
    executeStore.actions.addSpend();
    sendStore.actions.addSpend();
    // make sure focus after the removed
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  };

  const onReset = () => {
    executeStore.actions.reset();
    sendStore.actions.reset();
    resetIsExecute();
    setTimeout(() => {
      addressRef.current && addressRef.current.focus();
    }, 0);
  };

  /**
   * Handles form submit, will call the action only if 1 and only 1 action button is enabled
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!sendDisabled && executeDisabled) {
      onSend();
    } else if (sendDisabled && !executeDisabled) {
      onExecute();
    }
  };
  const contractName = useMemo(
    () =>
      `Contract address${
        !executeStore.state.contractId
          ? ''
          : ' - ' + namingContract[executeStore.state.contractId].name
      }`,
    [executeStore.state.contractId, namingContract]
  );
  const resetDisabled = useMemo(
    () =>
      !isExecute &&
      sendStore.state.address === '' &&
      executeStore.state.spends[0].asset === '' &&
      executeStore.state.spends[0].amount.value === '',
    [isExecute, executeStore.state.spends, sendStore.state.address]
  );
  return (
    <Form onSubmit={handleSubmit} disabled={disabled || progress}>
      <HiddenSubmitButton />
      <FormBody>
        <FormGroup>
          <Label htmlFor="send-execute-form-address" id="send-execute-form-address-label">
            <LabelText>{isExecute ? contractName : 'Destination address'}</LabelText>
          </Label>
          {isExecute ? (
            <AutoSuggest
              ref={addressRef}
              // use send value since we can also send from the full form, send is more permissive
              {...(executeStore.state.address && { valid: executeStore.state.addressValid })}
              hasClearButton
              items={(executeStore.state.activeContracts || []).map((contract) => ({
                value: contract.address,
                label:
                  !!namingContract && namingContract[contract.contractId]
                    ? `${namingContract[contract.contractId].name} - ${contract.address}`
                    : contract.address,
              }))}
              variant="block"
              getInputValue={(item) => item.value}
              value={executeStore.state.address}
              onChange={onAddressChange}
              disabled={disabled || progress}
              inputProps={{ id: 'send-execute-form-address' }}
              emptyText="No contracts found"
            />
          ) : (
            <Box display="flex">
              <Box flexGrow="1">
                <ClearInput
                  ref={addressRef}
                  id="send-execute-form-address"
                  {...(sendStore.state.address && { valid: sendStore.state.addressValid })}
                  variant="block"
                  width="100%"
                  value={sendStore.state.address}
                  onChange={(e) => onAddressChange(e.target.value.trim())}
                  onClear={() => onAddressChange('')}
                  inputType="expandable"
                  aria-labelledby="send-execute-form-address-label"
                  disabled={disabled || progress}
                />
              </Box>
              <PasteButton onPaste={onAddressChange} />
            </Box>
          )}
          <InputMessage>{!!valid && addressValid ? invalidMessage : ''}</InputMessage>
        </FormGroup>

        <SpendsGroup
          ref={spendsRef}
          disabled={disabled || progress}
          alignment="grid"
          noMarginTop
          spends={executeStore.state.spends}
          spendsValid={executeStore.state.spendsValid}
          assets={executeStore.state.assets}
          balance={executeStore.state.balance}
          naming={naming}
          addSpend={onAddSpend}
          removeSpend={onRemoveSpend}
          setAsset={onAssetChange}
          setAmount={onAmountChange}
        />

        {isExecute && (
          <>
            <FormGroup>
              <InfoBox
                info="String that the contract may use to know what to perform"
                isMobile={isMobile}
              >
                <Label htmlFor="ExecuteContractForm-command">
                  <LabelText>Choose command</LabelText>
                </Label>
              </InfoBox>
              <ClearInput
                id="ExecuteContractForm-command"
                disabled={disabled || progress}
                variant="block"
                value={executeStore.state.command}
                onChange={(e) => executeStore.actions.setCommand(e.target.value.trim())}
                onClear={() => executeStore.actions.setCommand('')}
              />
              <InputMessage />
            </FormGroup>

            <FormGroup>
              <InfoBox info="Add an extra message to the contract execution" isMobile={isMobile}>
                <Label htmlFor="ExecuteContractForm-message-body">
                  <LabelText>Message body</LabelText>
                </Label>
              </InfoBox>
              <Input
                id="ExecuteContractForm-message-body"
                disabled={disabled || progress}
                inputType="multiline"
                rows="5"
                value={executeStore.state.messageBody}
                variant="block"
                onChange={(e) => executeStore.actions.setMessageBody(e.target.value)}
              />
              <InputMessage>
                {executeStore.state.includeReturnAddress &&
                executeStore.state.messageBody !== '' &&
                executeStore.state.messageBodyType !== 'CollectionDict'
                  ? 'When including a return address, only a dictionary is allowed'
                  : !executeStore.state.messageBody || executeStore.state.messageBodyValid
                  ? ''
                  : 'Invalid message body'}
              </InputMessage>
            </FormGroup>

            <FormGroup>
              <CheckBoxWithTooltip
                tooltip="Add the receive address to the message body"
                disabled={disabled || progress}
                checked={executeStore.state.includeReturnAddress}
                label="Include return address"
                onChange={(e) => executeStore.actions.setIncludeReturnAddress(e.target.checked)}
                isMobile={isMobile}
              />
            </FormGroup>
            <FormGroup mb="smd">
              <CheckBoxWithTooltip
                tooltip={`Sign the contract execution ${
                  isWatchMode && 'N.B. not available in watch mode'
                }`}
                disabled={!isWatchMode && (disabled || progress)}
                checked={executeStore.state.includeSender}
                label="Include authentication"
                onChange={(e) => executeStore.actions.setIncludeSender(e.target.checked)}
                isMobile={isMobile}
              />
            </FormGroup>
          </>
        )}

        <FormFooter>
          <StyledButton
            growOnMobile={isExecute}
            disabled={resetDisabled || disabled || progress}
            type="button"
            bg="secondary"
            onClick={onReset}
          >
            Reset
          </StyledButton>

          {isExecute && (
            <StyledButton onClick={onExecute} disabled={executeDisabled} type="button">
              <LoadingOr loading={executeStore.state.progress} loadingContent="Executing...">
                {isWatchMode ? 'Generate Execute' : 'Execute'}
              </LoadingOr>
            </StyledButton>
          )}

          <StyledButton onClick={onSend} disabled={sendDisabled} type="button">
            <LoadingOr loading={sendStore.state.progress} loadingContent="Sending...">
              {isWatchMode ? 'Generate Send' : 'Send'}
            </LoadingOr>
          </StyledButton>
        </FormFooter>
      </FormBody>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-top: ${tu.space('xxs')};
  min-width: 134px;
  ${tu.mq({
    width: [(p) => (p.growOnMobile ? '100%' : 'auto'), 'auto'],
  })}
`;

const CheckBoxWithTooltip = ({ tooltip, disabled, checked, label, onChange, isMobile }) => {
  const { hide, ref, showAndHide, showAndUpdate, tooltipProps } = useTooltip({ placement: 'top' });
  const onMouseEnter = isMobile ? showAndHide : showAndUpdate;
  return (
    <Box display="inline-block">
      <Checkbox
        disabled={disabled}
        checked={checked}
        label={
          <Label>
            {label}
            <Box
              onClick={onMouseEnter}
              display="inline-block"
              ref={ref}
              onMouseEnter={onMouseEnter}
              onMouseLeave={hide}
            >
              <Tooltip {...tooltipProps}>{tooltip}</Tooltip>
              <FaSvgWrapper fontSize="sm" icon={faInfoCircle} />
            </Box>
          </Label>
        }
        onChange={onChange}
      />
    </Box>
  );
};

const FaSvgWrapper = styled(FaSvg)`
  position: relative;
  top: -2px;
  left: 3px;
  height: 100%;
  align-items: center;
  margin-left: 8px;
`;
