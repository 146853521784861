import PropTypes from 'prop-types';
import { Modal, ModalContent } from '@zen-common/components-base';
import SignerRequiredModalContentBase from './SignerRequiredModalContentBase';

/**
 * A presentational component for the signer required modal,
 * with the ability to add password, passphrase and account
 *
 * @param {Object} props
 * @param {Function} props.onSubmit
 * @param {Function} props.onDismiss
 * @param {boolean} props.progress
 * @param {string} props.error
 */
export default function SignerRequiredModal({ onSubmit, onDismiss, progress, error }) {
  return (
    <Modal onDismiss={onDismiss}>
      <ModalContent onClose={onDismiss}>
        <SignerRequiredModalContentBase error={error} onSubmit={onSubmit} progress={progress} />
      </ModalContent>
    </Modal>
  );
}

SignerRequiredModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  progress: PropTypes.bool,
  error: PropTypes.string,
};
