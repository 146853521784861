import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  Box,
  Checkbox,
  FaSvg,
  Form,
  FormBody,
  FormGroup,
  HiddenSubmitButton,
  InfoBox,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  PasswordInput,
  themeUtils as tu,
} from '@zen-common/components-base';
import { faClone, faEye, faLockAlt, faTrashRestore } from '@fortawesome/pro-solid-svg-icons';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import useSubmitWithLoading from '../hooks/useSubmitWithLoading';
import PaginationButton from '../blocks/PaginationButton';

export default function Passphrase({ onCancel, onBack, onContinue, onPassphraseAdded }) {
  const inputRef = useRef(null);
  const [passphrase, setPassphrase] = useState('');
  const [passphraseRepeat, setPassphraseRepeat] = useState('');
  // eslint-disable-next-line no-control-regex
  const passphraseValid = useMemo(() => /^[\x00-\x7F]{1,100}$/.test(passphrase), [passphrase]);
  const passphraseEqual = useMemo(
    () => passphrase === passphraseRepeat && passphrase.length === passphraseRepeat.length,
    [passphrase, passphraseRepeat]
  );
  const showContinue = useMemo(
    () => passphrase || passphraseRepeat,
    [passphrase, passphraseRepeat]
  );
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const [checked, setChecked] = useState(false);

  const submitDisabled = !checked || !passphraseEqual;
  const { handleSubmit, loading } = useSubmitWithLoading({
    validate: () => !submitDisabled,
    onSubmit: () => {
      showContinue && onPassphraseAdded({ passphrase, account: 0 });
      onContinue();
    },
  });
  const onSkip = () => {
    onPassphraseAdded({ passphrase: undefined, account: 0 });
    onContinue();
  };

  const onReset = () => {
    setPassphrase('');
    setPassphraseRepeat('');
    setChecked(false);
  };

  const onCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleOnPassphraseChange = (e) => {
    setPassphrase(e.target.value);
  };
  const handleOnPassphraseRepeatChange = (e) => {
    setPassphraseRepeat(e.target.value);
  };

  return (
    <Layout
      header={
        <Header
          title="Choose a Passphrase - Add an extra layer of security"
          subtitle={<Box mb="smd">This step is not required</Box>}
          text="Choose your  25th word to create another layer of security. Whenever you log in to your wallet you will be asked to enter both your password and your Passphrase."
          extraContent={
            <FullIconContainer>
              <IconAndText icon={faEye} text="Never share your passphrase with anyone" />
              <IconAndText
                icon={faLockAlt}
                text="Never keep your passphrase in a password manager"
              />
              <IconAndText icon={faClone} text="Keep a copy of your passphrase in a secure place" />
              <IconAndText
                icon={faTrashRestore}
                text="We can not recover or change your passphrase"
              />
            </FullIconContainer>
          }
        />
      }
      body={
        <Form disabled={loading} onSubmit={showContinue ? handleSubmit : onContinue}>
          <StyledFormBody>
            <FormGroup>
              <InfoBox
                info="A passphrase is the 25 word of the seed that can be any word, phrase, paragraph that
                generate a deterministic unique account"
              >
                <Label htmlFor="OnBoarding-SetPassphrase-Passphrase">
                  <LabelText>Passphrase</LabelText>
                </Label>
              </InfoBox>
              <StyledPassphraseInput
                id="OnBoarding-SetPassphrase-Passphrase"
                ref={inputRef}
                variant="block"
                value={passphrase}
                autoComplete="current-password"
                onChange={handleOnPassphraseChange}
                {...(passphrase && { valid: passphraseValid })}
                disabled={loading}
              />
              <InputMessage mb="smd" type="info"></InputMessage>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="OnBoarding-SetPassphrase-ConfirmPassphrase">
                <LabelText>Confirm passphrase</LabelText>
              </Label>
              <StyledPassphraseInput
                id="OnBoarding-SetPassphrase-ConfirmPassphrase"
                variant="block"
                value={passphraseRepeat}
                onChange={handleOnPassphraseRepeatChange}
                {...(passphraseRepeat && { valid: passphraseEqual })}
                disabled={loading}
              />
              <InputMessage>
                {passphraseRepeat && !passphraseEqual ? 'Passphrase does not match' : ''}
              </InputMessage>
            </FormGroup>
            <HiddenSubmitButton />
          </StyledFormBody>
        </Form>
      }
      footer={
        <Footer
          left={
            showContinue && (
              <label htmlFor="checkbox-passphrase-secure">
                <Checkbox
                  id="checkbox-passphrase-secure"
                  checked={checked}
                  onChange={onCheckboxChange}
                  label="I saved my passphrase and it’s secure."
                />
              </label>
            )
          }
          right={
            <>
              <PaginationButton onClick={onBack} mr="smd" bg="secondary">
                Back
              </PaginationButton>
              {showContinue && (
                <>
                  <PaginationButton onClick={onReset} mr="smd" bg="secondary">
                    Reset
                  </PaginationButton>
                  <PaginationButton disabled={submitDisabled} onClick={handleSubmit}>
                    <LoadingOr loading={loading}>Continue</LoadingOr>
                  </PaginationButton>
                </>
              )}
              {!showContinue && (
                <PaginationButton onClick={onSkip} mr="smd">
                  <LoadingOr loading={loading}>Skip</LoadingOr>
                </PaginationButton>
              )}
            </>
          }
        />
      }
      onClose={onCancel}
    />
  );
}

Passphrase.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onPassphraseAdded: PropTypes.func.isRequired,
};

function IconAndText({ icon, text }) {
  return (
    <IconAndTextContainer>
      <FaSvg icon={icon} mr="4" color="primary" fontSize="1rem" />
      <Box color="text">{text}</Box>
    </IconAndTextContainer>
  );
}

const FullIconContainer = styled(Box)`
  margin-top: ${tu.space(7)};
`;

const IconAndTextContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${tu.space(5)};
`;

const StyledFormBody = styled(FormBody)`
  ${tu.mq({
    width: ['100%', null, '40%'],
  })}
`;

const StyledPassphraseInput = styled(PasswordInput)`
  width: 100%;
`;
