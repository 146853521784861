import { Box } from '@zen-common/components-base';

export default function InputAndRest({ input, rest }) {
  return (
    <Box display="flex">
      <Box flexGrow="1">{input}</Box>
      {rest}
    </Box>
  );
}
