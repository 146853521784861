import { useEffect } from 'react';
import { styled } from 'styled-components';
import { Tooltip, useTooltip } from '@zen-common/components-base';

/**
 * Used to add the tooltip css to the page and prevent a flicker in the css of the first shown tooltip
 */
export default function TooltipFix() {
  const { tooltipProps, hide, show, ref } = useTooltip();

  useEffect(() => {
    show();
    setTimeout(hide, 500);
  }, [hide, show]);

  return (
    <HiddenTooltipCssWrapper>
      <div ref={ref} />
      <Tooltip {...tooltipProps}>Zen Protocol</Tooltip>;
    </HiddenTooltipCssWrapper>
  );
}

const HiddenTooltipCssWrapper = styled.div`
  position: fixed;
  top: 0;
  left: -1000px;
`;
