import { css, styled } from 'styled-components';
import { border, color, compose, layout, space, system, typography } from 'styled-system';
import { containerXPadding, getTransition } from '../utils/styles/mixins';
import tu from '../utils/themeUtils';
import IconButton from './Button/IconButton';
import Box from './Box';

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${color}
  ${layout}
`;

const Nav = (p) => <StyledNav {...p} />;

const StyledNavMenu = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
  ${compose(space, layout, border)}
`;

const NavMenu = (p) => <StyledNavMenu {...p} />;

const StyledNavItem = styled.li`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;

  ${border}
  svg {
    ${getTransition()}
  }

  a {
    width: 100%;
    text-align: center;
    text-decoration: none;
    position: relative;
    outline: none;
    user-select: none;
    cursor: pointer;
    align-self: stretch;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${typography}
    &.menu-item {
      white-space: nowrap;
      overflow: hidden;
      ${space}
    }

    ${getTransition()}
    ${system({
      linkColor: {
        property: 'color',
        scale: 'colors',
      },
    })}
    svg {
      color: ${tu.color('text')};
    }

    &.active {
      font-weight: 600;

      ${system({
        activeTextColor: {
          property: 'color',
          scale: 'colors',
        },
      })}
      svg {
        ${system({
          activeTextColor: {
            property: 'color',
            scale: 'colors',
          },
        })}
      }
    }
  }

  &:hover {
    a {
      ${system({
        activeTextColor: {
          property: 'color',
          scale: 'colors',
        },
      })}
    }
  }
`;
StyledNavItem.defaultProps = {
  px: 'smd',
  activeColor: 'primary',
  activeTextColor: 'text',
  linkColor: 'label',
  fontSize: 'md',
};

const StyledNavLink = styled.a`
  text-decoration: none;
  position: relative;
  outline: none;
  user-select: none;
  cursor: pointer;

  ${space}
  &.active {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      top: calc(100% - 1px);
      left: 0;
      ${color}
    }
  }

  ${compose(space, typography)}
`;

const StyledNavButton = styled(Box)``;
StyledNavButton.defaultProps = {
  fontSize: 'xl',
  color: 'label',
};

const NavButton = ({ icon, ...props }) => {
  return (
    <StyledNavButton {...props}>
      <IconButton icon={icon} />
    </StyledNavButton>
  );
};

const NavLink = (p) => <StyledNavLink {...p} />;

const SubNav = styled.nav`
  box-sizing: border-box;
  user-select: none;
  width: 100%;
  min-height: 70px;
  align-items: flex-end;
  padding: ${tu.space('smd')};
  padding-bottom: 0;
  ${containerXPadding}
  background-color: ${tu.color('bg2')};
  border-bottom: 1px solid ${tu.color('borderDark')};

  ${css(
    tu.mq({
      display: ['none', 'flex'],
    })
  )}
  a, ${StyledNavLink} {
    font-weight: 500;
    padding: ${tu.space('smd')} 0;
    margin: 0 ${tu.space('smd')};
    font-size: ${tu.fontSize('md')};
    text-transform: none;
    color: ${tu.color('label')};
    position: relative;

    &.active {
      color: ${tu.color('text')};

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        top: calc(100% - 1px);
        left: 0;
        background-color: ${tu.color('danger')};
      }
    }

    &:hover {
      color: ${tu.color('text')};
    }

    &:first-child {
      margin-left: 0;
    }
  }
`;

StyledNav.defaultProps = {
  height: '56px',
  backgroundColor: 'bg4',
  color: 'text',
};

export default Nav;
export { StyledNavItem as NavItem, NavButton, NavMenu, NavLink };
export { SubNav };
