import { numberUtils, textUtils } from '@zen/common-utils';
import TitleMessage from '../../../../components/TitleMessage';
import EstimatedTimeLeft from '../../../../components/EstimatedTimeLeft';

const { toDisplay } = numberUtils;
const { getSingularOrPlural } = textUtils;

export function BetweenMessage({ blocksToStart }) {
  return (
    <TitleMessage
      title={
        <>
          <span>
            Next interval begins in {toDisplay(blocksToStart)}{' '}
            {getSingularOrPlural(blocksToStart, 'block')},
          </span>{' '}
          <EstimatedTimeLeft blocksCount={blocksToStart} />
        </>
      }
      message="Your vote weight will consist of your total ZP at snapshot block"
    />
  );
}
