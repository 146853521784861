import { useState } from 'react';
import PasswordRequiredModalContentBase from '../../modals/PasswordRequiredModal/PasswordRequiredModalContentBase';
import BackupWalletModalContent from '../common/BackupWalletModalContent';
import SecurityWarningModalContent from '../common/SecurityWarningModalContent';
import { actions } from './actions';

export default function BackupWalletFormProcess({ onCancel, onDone, useSetting }) {
  const [status, setStatus] = useState('warning');
  const [data, setData] = useState();

  const { error, loading, onAction } = useSetting();

  function handleWarningsConfirm() {
    setStatus('password');
  }

  const handleSubmitPassword = async (password) => {
    const data = await onAction({
      type: actions.submit,
      value: { password: password },
    });
    if (data) {
      setData(data);
      setStatus('data');
    }
  };

  if (status === 'warning') {
    return <SecurityWarningModalContent onCancel={onCancel} onConfirm={handleWarningsConfirm} />;
  }

  if (status === 'password') {
    return (
      <PasswordRequiredModalContentBase
        error={error}
        progress={loading}
        onSubmit={handleSubmitPassword}
        onCancel={() => setStatus('warning')}
        cancelText="Back"
      />
    );
  }

  if (status === 'data') {
    return <BackupWalletModalContent {...data} onConfirm={onDone} confirmText="Done" />;
  }
}
