import { css, styled } from 'styled-components';
import { fontSize, border, color, system, space } from 'styled-system';
import tu from '../../utils/themeUtils';
import { getTransition } from '../../utils/styles/mixins';
import Box from '../Box';

const { color: c, space: s, mq } = tu;

// define border props
const createBorderColorProp = (name) =>
  system({ [name]: { property: 'borderColor', scale: 'colors' } });
const borderColor = system({ borderColor: true });
const borderColorFocus = createBorderColorProp('borderColorFocus');
const borderColorValid = createBorderColorProp('borderColorValid');
const borderColorInvalid = createBorderColorProp('borderColorInvalid');

const getBorderCss = (p) => {
  let selectedBorderColorProp = borderColor;
  let selectedBorderColorFocusProp = borderColorFocus;

  if (p.valid !== undefined) {
    selectedBorderColorProp = selectedBorderColorFocusProp = p.valid
      ? borderColorValid
      : borderColorInvalid;
  } else if (p.readOnly || p.disabled) {
    selectedBorderColorFocusProp = borderColor;
  }
  return p.focus ? css(selectedBorderColorFocusProp) : css(selectedBorderColorProp);
};

export const StyledWrapper = styled.div`
  box-sizing: border-box;
  display: ${(p) => (p.variant === 'block' ? 'flex' : 'inline-flex')};
  position: relative;
  background-color: ${(p) => (p.readOnly || p.disabled ? c('bg4') : c('bg1'))};
  border-style: solid;
  ${color}
  ${border}
  ${getBorderCss}
  ${getTransition()}
   ${css(
    mq({
      minWidth: [
        (p) => (p.variant === 'block' ? '100%' : p.width ? p.width : '100px'),
        (p) => (p.variant === 'block' ? '100%' : p.width ? p.width : '100px'),
        (p) => (p.width ? p.width : 'auto'),
        (p) => (p.width ? p.width : 'auto'),
      ],
    })
  )}
  &:hover {
    border-color: ${(p) =>
      p.valid !== undefined || p.readOnly || p.disabled ? 'borderDark' : c('textDisabled')};
    .clear-btn {
      color: ${(p) => (p.valid === undefined ? c('label') : 'none')};
    }
  }
`;
StyledWrapper.defaultProps = {
  borderWidth: 1,
  borderRadius: 1,
};

const basicCss = css`
  box-sizing: border-box;
  font-family: inherit;
  line-height: ${tu.lineHeight(5)};
  ${fontSize}
`;

const basicTextBoxCss = css`
  ${space};
  ${basicCss};
  display: block;
  width: 100%;
  flex-grow: 1;
  color: inherit;
  padding: ${s('xs')};
  border-style: none;
  border-width: 0;
  background-color: transparent;

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  /* custom input placeholder color */
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: c('textDisabled');
    opacity: 1;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: c('textDisabled');
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: c('textDisabled');
  }
`;

export const StyledInput = styled.input`
  ${basicTextBoxCss}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* remove clear button from chrome and IE */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
    width: 0;
    height: 0;
  }

  /* hide native browser arrows on number inputs  */
  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: none;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export const StyledTextArea = styled.textarea`
  ${basicTextBoxCss}

  resize: ${(p) => (p.resize ? 'auto' : 'none')};
`;

export const StyledExpandable = styled(Box).attrs((p) => ({
  contentEditable: p.readOnly || p.disabled ? false : true,
}))`
  ${basicTextBoxCss}

  overflow: auto;
  max-height: 400px; /** change and use props if needed to support no limit */
  display: inline-block;
  white-space: normal;
  word-break: break-all;
  line-height: ${tu.lineHeight(5)};
  resize: ${(p) => (p.resize ? 'auto' : 'none')};

  /** placeholder */
  &:empty:not(:focus):before {
    content: attr(placeholder);
    user-select: none;
    pointer-events: none;
    color: ${c('textDisabled')};
  }
`;

export const StyledAddon = styled.span`
  ${basicCss};
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${c('label')};
`;
