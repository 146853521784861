/* eslint-disable react/display-name */
import { useMemo } from 'react';
import { Decimal } from 'decimal.js';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, FaSvg } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import VoteResultsTable from '../../../../components/voting/VoteResultsTable';
import VoteTableButton from '../../../../components/voting/VoteTableButton';
import CopyableCommitId from '../CopyableCommitId';

export default function CommitsTable({
  onRowClick,
  canVote,
  disabled,
  thresholdZp,
  data,
  ...props
}) {
  const columns = useMemo(
    () => getColumns({ canVote, disabled, thresholdZp }),
    [canVote, disabled, thresholdZp]
  );

  return <VoteResultsTable columns={columns} data={data} onRowClick={onRowClick} {...props} />;
}

function getColumns({ canVote, disabled, thresholdZp } = {}) {
  return [
    {
      accessor: 'commitId',
      Header: 'Commit ID',
      minWidth: 120,
      Cell: ({ value }) => <CopyableCommitId commitId={value} />,
    },
    {
      accessor: 'zpAmount',
      Header: 'ZP Voted',
      minWidth: 150,
      Cell: ({ value }) => numberUtils.toDisplay(value, 2),
    },
    {
      // threshold indication
      id: 'threshold',
      accessor: 'zpAmount',
      Header: '',
      minWidth: 20,
      Cell: ({ value }) =>
        new Decimal(value || 0).gte(thresholdZp || Number.MAX_SAFE_INTEGER) ? (
          <Box title="passed threshold">
            <FaSvg icon={faCheckCircle} />
          </Box>
        ) : null,
    },
    {
      id: 'voteButton', // Since this isn't tied to a data property, we provide a unique id.
      className: 'force-hidden-mobile align-right',
      headerClassName: 'force-hidden-mobile align-right',
      Cell: () => {
        if (canVote) {
          return <VoteTableButton disabled={disabled} />;
        }
        return null;
      },
    },
  ];
}
