import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Only 1 modal per application
 */
export const ModalContext = createContext({
  show: () => {},
  hide: () => {},
  isOpen: false,
});

export function ModalRoot({ children }) {
  const [isOpen, setOpen] = useState(false);
  const [render, setRender] = useState(null);
  const show = useCallback((render = null) => {
    setRender(render);
    setOpen(true);
  }, []); // should not change
  const hide = useCallback(() => {
    setRender(null);
    setOpen(false);
  }, []); // should not change
  return (
    <ModalContext.Provider value={{ show, hide, isOpen }}>
      {render}
      {children}
    </ModalContext.Provider>
  );
}

ModalRoot.propTypes = {
  children: PropTypes.any,
};

/**
 * A modal toggler component, accept a toggle render prop
 */
export function ModalToggler({ toggle }) {
  const { show, hide, isOpen } = useContext(ModalContext);

  return <>{toggle({ show, hide, isOpen })}</>;
}

export function useModal() {
  return useContext(ModalContext);
}
