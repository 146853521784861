/* eslint-disable react/display-name */
import { memo } from 'react';
import { AutoSuggest, Label, LabelText } from '@zen-common/components-base';
import { tickers as DefaultTickers } from '../../utils/defaultTickers';

/**
 * Display a tickers dropdown
 *
 * @param {Object} props
 * @param {string} props.title - title
 * @param {string} props.ticker
 * @param {() => void} props.onTickerChanged
 * @param {bool} props.showTitle
 * @param {bool} props.tickerValid
 */
function TickerAutoSuggest({
  ticker,
  onTickerChanged,
  title = 'Search Asset',
  showTitle = false,
  tickerValid,
  ...props
}) {
  return (
    <>
      {showTitle && (
        <Label htmlFor="filter-by-ticker">
          <LabelText>{title}</LabelText>
        </Label>
      )}
      <AutoSuggest
        id="filter-by-ticker"
        inputType="input"
        hasClearButton
        items={DefaultTickers.map((s) => ({
          value: s,
          label: s,
        }))}
        variant="block"
        valid={ticker ? tickerValid : undefined}
        value={ticker}
        onChange={(value) => {
          onTickerChanged(value);
        }}
        emptyText=""
        {...props}
      />
    </>
  );
}

export default memo(TickerAutoSuggest); // do not change if balances stayed the same
