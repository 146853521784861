import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import {
  border,
  color as systemColor,
  compose,
  layout,
  space,
  system,
  typography,
} from 'styled-system';
import tu from '../../utils/themeUtils';
import { getTransition } from '../../utils/styles/mixins';

const { color } = tu;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.span`
  ${systemColor}
  ${typography}
`;

const Icon = styled.svg`
  fill: none;
  stroke-width: 2px;
  ${system({
    color: {
      property: 'stroke',
      scale: 'colors',
    },
  })}
`;
/* Hide checkbox visually but remain accessible to screen readers.
 Source: https://polished.js.org/docs/#hidevisually
 */
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:focus + div {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }
`;

const StyledCheckbox = styled.div`
  ${compose(layout, space, border)}
  display: inline-block;
  box-sizing: border-box;
  line-height: 1;
  background: ${(p) => (p.disabled ? color('bg4') : p.checked ? color(p.bgChecked) : color(p.bg))};
  border-color: ${(p) => (p.checked ? color(p.checkedBorderColor) : color(p.borderColor))};

  ${getTransition()}
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Checkbox = ({
  className,
  checked,
  onChange,
  label,
  labelColor,
  fontSize,
  disabled,
  ...props
}) => (
  // trick - activates the onChange. user can wrap the component in another label for text
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        data-testid="Checkbox-input"
      />
      <StyledCheckbox checked={checked} disabled={disabled} {...props}>
        <Icon viewBox="0 0 24 24" theme={props.theme} color={props.color}>
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      {label ? (
        <CheckboxLabel fontSize={fontSize} color={labelColor}>
          {label}
        </CheckboxLabel>
      ) : null}
    </CheckboxContainer>
  </label>
);
Checkbox.defaultProps = {
  minWidth: 3,
  width: 3,
  height: 3,
  borderRadius: 2,
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'bg5',
  checkedBorderColor: 'primary',
  bgChecked: 'primary',
  labelColor: 'text',
  bg: 'bg1',
  color: 'text',
  mr: 4,
  fontSize: ['xs', null, 'md'],
};

Checkbox.propTypes = {
  column: PropTypes.func,
};

export default Checkbox;
