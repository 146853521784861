import { useCallback, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Decimal } from 'decimal.js';
import {
  BlockchainInfoStore,
  SettingsStore,
  styles,
  TitleContentLayout,
  WalletStore,
  ZenJsUtils,
} from '@zen/common-app-parts';
import {
  Alert,
  Box,
  ConfirmationModalContent,
  cssMixins,
  Loading,
  Modal,
  ModalContent,
  notification,
  Title,
  useModal,
} from '@zen-common/components-base';
import { assetUtils, numberUtils } from '@zen/common-utils';
import { useUnmountedRef } from '@zen/common-react-hooks';
import FormAndTableLayout from '../../components/voting/FormAndTableLayout';
import usePreventAction from '../../utils/usePreventAction';
import TwoPhaseProgressBar from '../../components/voting/ProgressBar';
import FormContainerPadding from '../../components/voting/FormContainerPadding';
import MessageContainerPadding from '../../components/voting/MessageContainerPadding';
import SectionTitle from '../../components/voting/SectionTitle';
import SectionSubTitle from '../../components/voting/SectionSubTitle';
import TestnetWarning from '../Governance/components/TestnetWarning';
import TopParagraph from './components/TopParagraph';
import InfoBoxes from './components/InfoBoxes';
import PayoutForm from './components/PayoutForm';
import AllocationForm from './components/AllocationForm';
import BallotsTable from './components/BallotsTable';
import VotingModalProcess from './components/VotingModalProcess';
import { serializePayoutBallotId } from './store/modules/execute';
import {
  AllocationSuccessMessage,
  BetweenMessage,
  NominationOrPayoutSuccessMessage,
  NoZpAtSnapshotMessage,
} from './components/messages';

/**
 *
 * @param {Object} props
 * @param {import('./store/CgpStore').CgpStore} props.store
 */
export default function CGP({ store }) {
  const unmountedRef = useUnmountedRef();
  const { preventAction } = usePreventAction();
  const {
    state: {
      currentWalletInfo: { balance, isWatchMode },
    },
  } = WalletStore.useStore();
  const {
    state: { infos },
  } = BlockchainInfoStore.useStore();
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const { hide, show, isOpen: isModalOpen } = useModal();
  const [ballot, setBallot] = useState('');
  const [progress, setProgress] = useState({
    nomination: false,
    allocation: false,
    payout: false,
  });
  const resetPayoutFnRef = useRef(null); // has the reset fn from the payout form

  const {
    refetchVotes,
    setVotedAllocation,
    setVotedNomination,
    setVotedPayout,
    addressCgp,
    addressVote,
  } = store;

  const handleVoteCancel = useCallback(
    (type) => {
      setProgress((s) => ({ ...s, [type]: false }));
      hide();
    },
    [hide]
  );

  const handleVoteSuccess = useCallback(
    (type) => {
      if (!unmountedRef.current) {
        setBallot('');
        resetPayoutFnRef.current && resetPayoutFnRef.current();
        setProgress((s) => ({ ...s, [type]: false }));
        if (type === 'nomination') {
          setVotedNomination(true);
        } else if (type === 'allocation') {
          setVotedAllocation(true);
        } else if (type === 'payout') {
          setVotedPayout(true);
        }
      }
      refetchVotes();
      hide();
      notification('Vote was broadcasted successfully', {
        type: notification.TYPE.SUCCESS,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hide, refetchVotes, setVotedAllocation, setVotedNomination, unmountedRef]
  );

  const handleVoteError = useCallback(
    (type, error) => {
      if (!unmountedRef.current) {
        setProgress((s) => ({ ...s, [type]: false }));
      }
      show(() => (
        <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error">
          Couldn&apos;t broadcast the vote
          <br />
          Error message: {error.message}
        </Alert>
      ));
    },
    [hide, show, unmountedRef]
  );

  const onNominationVote = useCallback(
    async ({ address, spends }) => {
      if (preventAction()) return;

      const serializedBallot = serializePayoutBallotId({ chain, address, spends });
      setBallot(serializedBallot);

      show(() => (
        <Modal onDismiss={() => handleVoteCancel('nomination')}>
          <ModalContent onClose={() => handleVoteCancel('nomination')}>
            {!isWatchMode && (
              <VotingModalProcess
                type="nomination"
                address={address}
                spends={spends}
                addressVote={store.addressVote}
                ballot={serializedBallot}
                chain={chain}
                blockNumber={store.tallyBlock}
                currentInterval={store.currentInterval}
                snapshotZpBalance={store.snapshotZpBalance}
                setProgress={setProgress}
                onCancel={handleVoteCancel}
                onSuccess={handleVoteSuccess}
                onError={handleVoteError}
              />
            )}
            {isWatchMode && (
              <ConfirmationModalContent
                title={<Title>Not Available on watch only mode</Title>}
                subtitle="You will need to connect using your public keys in order to create a transaction that can be signed in a cold wallet"
                confirmText="Continue"
                onConfirm={() => hide()}
              />
            )}
          </ModalContent>
        </Modal>
      ));
    },
    [
      chain,
      handleVoteCancel,
      handleVoteError,
      handleVoteSuccess,
      preventAction,
      show,
      hide,
      isWatchMode,
      store.addressVote,
      store.tallyBlock,
      store.intervalDisplay,
      store.snapshotZpBalance,
    ]
  );

  const onAllocationVote = useCallback(
    async ({ allocation }) => {
      if (preventAction()) return;

      show(() => (
        <Modal onDismiss={() => handleVoteCancel('allocation')}>
          <ModalContent onClose={() => handleVoteCancel('allocation')}>
            {!isWatchMode && (
              <VotingModalProcess
                type="allocation"
                allocation={allocation}
                addressVote={store.addressVote}
                chain={chain}
                currentInterval={store.currentInterval}
                blockNumber={store.tallyBlock}
                snapshotZpBalance={store.snapshotZpBalance}
                setProgress={setProgress}
                onCancel={handleVoteCancel}
                onSuccess={handleVoteSuccess}
                onError={handleVoteError}
              />
            )}
            {isWatchMode && (
              <ConfirmationModalContent
                title={<Title>Not Available on watch only mode</Title>}
                subtitle="You will need to connect using your public keys in order to crete a transaction that can be signed in a cold wallet"
                confirmText="Continue"
                onConfirm={() => hide()}
              />
            )}
          </ModalContent>
        </Modal>
      ));
    },
    [
      chain,
      handleVoteCancel,
      handleVoteError,
      handleVoteSuccess,
      preventAction,
      show,
      hide,
      isWatchMode,
      store.addressVote,
      store.currentInterval,
      store.tallyBlock,
      store.snapshotZpBalance,
    ]
  );
  const onPayoutVote = useCallback(
    async ({ address, spends }) => {
      if (preventAction()) return;

      const serializedBallot = serializePayoutBallotId({ chain, address, spends });
      setBallot(serializedBallot);

      show(() => (
        <Modal onDismiss={() => handleVoteCancel('payout')}>
          <ModalContent onClose={() => handleVoteCancel('payout')}>
            {!isWatchMode && (
              <VotingModalProcess
                type="payout"
                address={address}
                spends={spends}
                addressVote={store.addressVote}
                ballot={serializedBallot}
                chain={chain}
                currentInterval={store.currentInterval}
                blockNumber={store.tallyBlock}
                snapshotZpBalance={store.snapshotZpBalance}
                setProgress={setProgress}
                onCancel={handleVoteCancel}
                onSuccess={handleVoteSuccess}
                onError={handleVoteError}
              />
            )}
            {isWatchMode && (
              <ConfirmationModalContent
                title={<Title>Not Available on watch only mode</Title>}
                subtitle="You will need to connect using your public keys in order to crete a transaction that can be signed in a cold wallet"
                confirmText="Continue"
                onConfirm={() => hide()}
              />
            )}
          </ModalContent>
        </Modal>
      ));
    },
    [
      chain,
      handleVoteCancel,
      handleVoteError,
      handleVoteSuccess,
      preventAction,
      show,
      hide,
      isWatchMode,
      store.addressVote,
      store.currentInterval,
      store.tallyBlock,
      store.snapshotZpBalance,
    ]
  );

  const onBallotsTableRowClick = useCallback(
    ({ data, vote }) => {
      const { ballot } = data;
      // prevent setting the ballot after vote
      const shouldSet =
        (store.voteStatus === 'nomination' && !store.votedNomination) ||
        (store.voteStatus === 'voting' && !store.votedPayout);
      if (shouldSet) {
        setBallot(ballot);
        if (vote && ZenJsUtils.validateBallot(ballot)) {
          const { address, spends } = ZenJsUtils.deserializePayoutBallotId(chain, ballot);
          store.voteStatus === 'nomination'
            ? onNominationVote({ address, spends })
            : onPayoutVote({ address, spends });
        }
      }
    },
    [
      chain,
      onNominationVote,
      onPayoutVote,
      store.voteStatus,
      store.votedNomination,
      store.votedPayout,
    ]
  );

  // either the balance at snapshot or the minimum amount of ZP calculated up to snapshot
  const cgpSnapshotBalanceOrPotential =
    store.voteStatus !== 'between'
      ? store.cgpSnapshotBalance
      : {
          ...store.cgpCurrentBalance,
          '00': numberUtils.toValue(
            new Decimal(assetUtils.getAssetBalance('00', store.cgpCurrentBalance) || 0).plus(
              new Decimal(store.cgpCurrentAllocation.zp || 0).times(
                store.snapshotBlock - infos.headers
              )
            )
          ),
        };

  return (
    <TitleContentLayout
      noPadding
      title="Common Goods Pool"
      renderBelowTitle={
        <Box mt="2">
          {chain === 'test' && <TestnetWarning />}
          <TopParagraph
            addressCgp={addressCgp}
            addressVote={addressVote}
            cgpBalance={cgpSnapshotBalanceOrPotential}
            interval={store.intervalDisplay}
            mt="2"
          />
        </Box>
      }
    >
      <ContentContainer>
        <BorderedContainer>
          <InfoBoxes store={store} currentBlock={infos.headers} balance={balance} />
        </BorderedContainer>
        {store.voteStatus !== 'between' && (
          <BorderedContainer>
            <TwoPhaseProgressBar
              currentBlock={infos.headers}
              phaseOneTitle="Nomination Phase"
              phaseOneSnapshotBlock={store.snapshotBlock}
              phaseOneTallyBlock={store.nominationBlock}
              phaseTwoTitle="Voting Phase"
              phaseTwoSnapshotBlock={store.nominationBlock} //The cgp has a single phase
              phaseTwoTallyBlock={store.tallyBlock}
            />
          </BorderedContainer>
        )}
        {store.voteStatus === 'between' ? (
          <BorderedContainer>
            <BetweenMessage blocksToStart={store.snapshotBlock - infos.headers} />
          </BorderedContainer>
        ) : (store.voteStatus === 'nomination' && !store.isReadyForNomination) ||
          (store.voteStatus === 'voting' && !store.isReadyForVoting) ? (
          <BorderedContainer>
            <Loading />
          </BorderedContainer>
        ) : store.snapshotZpBalance === '0' ? (
          <FormAndTableLayout
            formTabTitle={store.voteStatus === 'nomination' ? 'Propose Nominee' : 'Vote'}
            tableTabTitle={store.voteStatus === 'nomination' ? 'Nominees Board' : 'Finalists Board'}
            formTitle={
              <SectionTitle bigMargin>
                {store.voteStatus === 'nomination'
                  ? 'Propose a Payout Ballot'
                  : 'Vote for CGP Payout'}
              </SectionTitle>
            }
            form={
              <MessageContainerPadding>
                <NoZpAtSnapshotMessage
                  interval={store.intervalDisplay}
                  voteStatus={store.voteStatus}
                />
              </MessageContainerPadding>
            }
            tableTitle={
              <Box>
                <SectionTitle>
                  {store.voteStatus === 'nomination' ? 'Nominees Board' : 'Finalists Board'}
                </SectionTitle>
                {/* hack: add space as in nomination phase */}
                <SectionSubTitle>{'\u00a0'}</SectionSubTitle>
              </Box>
            }
            table={
              <BallotsTable
                title={`${store.voteStatus === 'nomination' ? 'Nominees' : 'Finalists'} Board`}
                onRowClick={() => null}
                data={store.voteStatus === 'nomination' ? store.nominees : store.candidates}
              />
            }
          />
        ) : store.voteStatus === 'nomination' ? (
          <FormAndTableLayout
            formTabTitle="Propose Nominee"
            tableTabTitle="Nominees Board"
            voteStatus={store.voteStatus}
            formTitle={<SectionTitle bigMargin>Propose a Payout Ballot</SectionTitle>}
            form={
              store.votedNomination ? (
                <MessageContainerPadding>
                  <NominationOrPayoutSuccessMessage
                    chain={chain}
                    store={store}
                    isNomination
                    interval={store.intervalDisplay}
                  />
                </MessageContainerPadding>
              ) : (
                <FormContainerPadding>
                  <PayoutForm
                    isNomination
                    cgpBalance={store.cgpSnapshotBalance}
                    onVote={onNominationVote}
                    ballot={ballot}
                    onBallotChange={setBallot}
                    progress={progress.nomination}
                    disabled={isModalOpen}
                    resetFnRef={resetPayoutFnRef}
                  />
                </FormContainerPadding>
              )
            }
            tableTitle={
              <Box>
                <SectionTitle>Nominees Board</SectionTitle>
                <SectionSubTitle>
                  {`Interval threshold: ${numberUtils.toDisplay(store.thresholdZp)} ZP`}
                </SectionSubTitle>
              </Box>
            }
            table={
              <BallotsTable
                canVote={!store.votedNomination}
                title="Nominees Board"
                onRowClick={onBallotsTableRowClick}
                data={store.nominees}
                progress={progress.nomination}
                thresholdZp={store.thresholdZp}
              />
            }
          />
        ) : (
          <FormAndTableLayout
            formTabTitle="Vote"
            tableTabTitle="Finalists Board"
            voteStatus={store.voteStatus}
            formTitle={<SectionTitle bigMargin>Vote for CGP Payout</SectionTitle>}
            form={
              <>
                <Box mb="xl">
                  {store.votedPayout ? (
                    <MessageContainerPadding>
                      <NominationOrPayoutSuccessMessage
                        chain={chain}
                        store={store}
                        isNomination={false}
                        interval={store.intervalDisplay}
                      />
                    </MessageContainerPadding>
                  ) : (
                    <FormContainerPadding>
                      <PayoutForm
                        onVote={onPayoutVote}
                        cgpBalance={store.cgpSnapshotBalance}
                        ballot={ballot}
                        onBallotChange={setBallot}
                        candidates={store.candidateBallots}
                        progress={progress.payout}
                        disabled={isModalOpen}
                        resetFnRef={resetPayoutFnRef}
                      />
                    </FormContainerPadding>
                  )}
                </Box>
                <Box>
                  <FormContainerPadding>
                    <SectionTitle bigMargin>Vote for CGP Allocation</SectionTitle>
                  </FormContainerPadding>
                  {store.votedAllocation ? (
                    <MessageContainerPadding>
                      <AllocationSuccessMessage
                        allocationVote={store.allocationVote}
                        interval={store.intervalDisplay}
                        snapshotZpBalance={store.snapshotZpBalance}
                      />
                    </MessageContainerPadding>
                  ) : (
                    <FormContainerPadding>
                      <AllocationForm
                        maxPercentage={store.allocationPercentageMax}
                        minPercentage={store.allocationPercentageMin}
                        prev={store.cgpCurrentAllocation.zp}
                        onVote={onAllocationVote}
                        progress={progress.allocation}
                        disabled={isModalOpen}
                        tallyBlock={store.tallyBlock}
                      />
                    </FormContainerPadding>
                  )}
                </Box>
              </>
            }
            tableTitle={
              <Box>
                <SectionTitle>Finalists Board</SectionTitle>
                {/* hack: add space as in nomination phase */}
                <SectionSubTitle>{'\u00a0'}</SectionSubTitle>
              </Box>
            }
            table={
              <BallotsTable
                canVote={!store.votedPayout}
                title="Finalists Board"
                onRowClick={onBallotsTableRowClick}
                data={store.candidates}
                progress={progress.payout}
              />
            }
          />
        )}
      </ContentContainer>
    </TitleContentLayout>
  );
}

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const BorderedContainer = styled(Box)`
  ${styles.borderBottomContainerCss}
  ${cssMixins.containerXPadding}
`;
