import { SubNav } from '@zen-common/components-base';
import { SubNavLink } from '@zen/common-app-parts';
import {
  CgpPhaseChangedUpdateIndicator,
  GovernancePhaseChangedUpdateIndicator,
} from '../update-indicators';
import PATHS from '../../paths';

export default function VotingNav() {
  return (
    <SubNav>
      <SubNavLink href={PATHS.VOTING_CGP} activeClassName="active">
        <CgpPhaseChangedUpdateIndicator>Common Goods Pool</CgpPhaseChangedUpdateIndicator>
      </SubNavLink>
      <SubNavLink href={PATHS.VOTING_GOVERNANCE} activeClassName="active">
        <GovernancePhaseChangedUpdateIndicator>Governance</GovernancePhaseChangedUpdateIndicator>
      </SubNavLink>
    </SubNav>
  );
}
