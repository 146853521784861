import { useCallback, useEffect, useState } from 'react';
import { BlockchainInfoStore, ConfigStore, SettingsStore } from '@zen/common-app-parts';
import { useStorageState } from '@zen/common-react-hooks';
import useFetchRelevantPhase from '../../pages/Governance/store/useFetchRelevantPhase';
import getVoteStatus from '../../pages/Governance/store/getVoteStatus';

const STORAGE_KEY = 'zen-protocol-wallet:governance-phase-changed';

/**
 * Sets a flag when a governance phase is open
 */
export default function useGovernancePhaseChanged() {
  const [state, setState] = useStorageState(STORAGE_KEY, {}, { storage: 'localStorage' });
  const [changed, setChanged] = useState(false);

  const {
    state: {
      infos: { headers },
    },
  } = BlockchainInfoStore.useStore();
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const config = ConfigStore.useStore();

  const { data: relevantPhase, refetch } = useFetchRelevantPhase({ chain, config });
  const voteStatus = getVoteStatus({ ...relevantPhase, headers });
  const { interval } = relevantPhase || {};
  const { interval: lastViewedInterval, voteStatus: lastViewedStatus } = state;

  // refetch interval
  const { endBlock } = relevantPhase || {};
  useEffect(() => {
    if (!endBlock) return;

    if (headers >= endBlock) {
      refetch();
    }
  }, [endBlock, headers, refetch]);

  // check changed
  useEffect(() => {
    if (!headers || !voteStatus) return;

    // exit if user already saw the update
    if (interval === lastViewedInterval && voteStatus === lastViewedStatus) return;

    if (['contestant', 'candidate'].includes(voteStatus)) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [headers, interval, lastViewedInterval, lastViewedStatus, voteStatus]);

  /** clears the flag and remember in storage  */
  const clear = useCallback(() => {
    setChanged(false);
    setState({ interval, voteStatus });
  }, [interval, setState, voteStatus]);
  const reset = useCallback(() => {
    setChanged(false);
  }, []);

  return {
    state: {
      changed,
    },
    actions: {
      clear,
      reset,
    },
  };
}
