import PropTypes from 'prop-types';
import { Box, Switch } from '@zen-common/components-base';
import SettingLayout from '../containers/SettingLayout';

const ReportErrorsSetting = ({ useSetting, ...props }) => {
  const { value, onAction } = useSetting();

  const handleChange = (value) => {
    typeof onAction === 'function' && onAction({ value });
  };

  return (
    <SettingLayout
      header="Report Errors"
      paragraph="Automatically sending diagnostic data anonymously"
      {...props}
    >
      <Box display="flex" justifyContent="center" width="100%">
        <Switch onChange={handleChange} checked={value} />
      </Box>
    </SettingLayout>
  );
};

ReportErrorsSetting.propTypes = {
  useSetting: PropTypes.func,
};

export default ReportErrorsSetting;
