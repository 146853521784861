import PropTypes from 'prop-types';
import { Decimal } from 'decimal.js';
import Box from '../Box';

/**
 * Display an amount with positive/negative indication
 * Adds a plus sign to a positive number
 */
export default function AmountDisplay({ amount, display }) {
  const dAmount = new Decimal(amount || 0);
  return (
    <Box color={dAmount.lt(0) ? 'danger' : 'success'}>
      {`${dAmount.gt(0) ? '+' : ''}${display}`}
    </Box>
  );
}

AmountDisplay.propTypes = {
  amount: PropTypes.string,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
