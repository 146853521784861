import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  Form,
  FormBody,
  FormGroup,
  HiddenSubmitButton,
  Input,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  themeUtils as tu,
} from '@zen-common/components-base';
import { useInput } from '@zen/common-react-hooks';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import PaginationButton from '../blocks/PaginationButton';

export default function SetName({ onBack, onCancel, onContinue, setName, error, loading = false }) {
  const inputRef = useRef(null);
  const { value: name, onChange: onNameChange } = useInput({ initialValue: '' });
  const valid = useMemo(() => name.length < 40, [name]);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const finishHandler = () => {
    onContinue();
    setName(name);
    inputRef.current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      finishHandler();
    }
  };
  return (
    <Layout
      header={
        <Header
          title="Name your account"
          text="Name your wallet to be easily discovered, especially when multiple accounts are connected. You can always rename it later"
        />
      }
      body={
        <Form
          disabled={loading}
          onSubmit={(e) => {
            e.preventDefault();
            finishHandler();
          }}
        >
          <StyledFormBody>
            <FormGroup>
              <Label htmlFor="OnBoarding-SetName-AccountName">
                <LabelText>Account Name</LabelText>
              </Label>
              <StyledAccountNameInput
                id="OnBoarding-SetName-AccountName"
                ref={inputRef}
                inputType="expandable"
                value={name}
                valid={name === '' ? undefined : valid}
                onChange={onNameChange}
                disabled={loading}
                onKeyDown={handleKeyDown}
              />
              <InputMessage />
            </FormGroup>
            <FormGroup>
              <InputMessage>{error}</InputMessage>
            </FormGroup>
            <HiddenSubmitButton />
          </StyledFormBody>
        </Form>
      }
      footer={
        <Footer
          left={null}
          right={
            <>
              <PaginationButton disabled={loading} onClick={onBack} mr="smd" bg="secondary">
                Back
              </PaginationButton>
              <PaginationButton disabled={!valid} onClick={finishHandler}>
                <LoadingOr loading={loading}>{name === '' ? 'Skip' : 'Continue'}</LoadingOr>
              </PaginationButton>
            </>
          }
        />
      }
      onClose={onCancel}
    />
  );
}
SetName.propTypes = {
  onBack: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

const StyledFormBody = styled(FormBody)`
  ${tu.mq({
    width: ['100%', null, '40%'],
  })}
`;

const StyledAccountNameInput = styled(Input)`
  width: 100%;
`;
