import { styled } from 'styled-components';
import { Box, Button, themeUtils as tu } from '@zen-common/components-base';

export default function VoteTableButton(props) {
  return (
    <Box display={['none', null, null, null, 'block']}>
      <StyledVoteButton data-vote="true" {...props}>
        Vote
      </StyledVoteButton>
    </Box>
  );
}

const StyledVoteButton = styled(Button)`
  font-size: ${tu.fontSize('xs')};
  padding: 1px ${tu.space('sm')};
  font-weight: 500;
`;
