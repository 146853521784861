import { useRef, useState } from 'react';
import { ConfirmationModalContent, Title } from '@zen-common/components-base';
import { ConfirmPublishTxModalContent, WalletStore } from '@zen/common-app-parts';
import { ValueDisplay } from '@zen/common-utils';
import { useUnmountedRef } from '@zen/common-react-hooks';
import { clipboard } from '@zen/common-utils-browser';
import usePreventAction from '../../../../utils/usePreventAction';
import ExtendStore from '../../../../stores/ExtendStore';
import * as actions from '../../../../stores/ExtendStore/actions';
import ExtendFormModalContent from '../ExtendContractModalProcess/ExtendFormModalContent';

export default function ExtendContractRawModalProcess({ contractId, onCancel }) {
  const store = ExtendStore.useStore();
  const unmounted = useUnmountedRef();
  const prevStatus = useRef();
  const [status, setStatus] = useState('form');
  /** @type {[numberOfBlocks: ValueDisplay]} */
  const [numberOfBlocks, setNumberOfBlocks] = useState(ValueDisplay.create());
  const [error, setError] = useState('');
  const unsignedTx = useRef();
  const { preventAction } = usePreventAction();
  const wallet = WalletStore.useStore();

  const setStatusAndPrev = (s) => {
    prevStatus.current = status;
    setStatus(s);
  };

  const handleNumberOfBlocksChange = (display) =>
    setNumberOfBlocks(numberOfBlocks.onDisplayChange(display));

  const handleFormCancel = () => {
    onCancel();
  };
  const handleFormContinue = async () => {
    if (preventAction()) return;

    try {
      unsignedTx.current = await actions.extend({
        dispatch: store.dispatch,
        contractId,
        numberOfBlocks: Number(numberOfBlocks.safeValue),
        wallet,
      });
      if (!unmounted.current) {
        setStatusAndPrev('confirm-after-sign');
      }
    } catch (error) {
      if (!unmounted.current) {
        setError(error.message);
        setStatusAndPrev('error');
      }
    }
  };

  const handleCopy = async () => {
    try {
      await clipboard.writeText(unsignedTx.current);
    } catch (error) {
      //ignore error
    }
  };

  const handleConfirmAfterSignBack = () => {
    unsignedTx.current = null;
    setStatusAndPrev('form');
  };
  const handleConfirmAfterSignContinue = async () => {
    if (preventAction()) return;
    await handleCopy();
    onCancel();
  };

  const handleErrorBack = () => {
    setStatus(prevStatus.current);
  };
  const handleErrorClose = () => {
    onCancel();
  };

  if (status === 'form') {
    return (
      <ExtendFormModalContent
        progress={store.state.progress}
        contractId={contractId}
        numberOfBlocks={numberOfBlocks}
        onNumberOfBlocksChange={handleNumberOfBlocksChange}
        onCancel={handleFormCancel}
        onContinue={handleFormContinue}
        title="Raw Extend"
      />
    );
  }
  if (status === 'confirm-after-sign') {
    return (
      <ConfirmPublishTxModalContent
        progress={store.state.progress}
        title="Copy your unsigned TX"
        confirmText="Copy"
        tx={unsignedTx.current}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
      />
    );
  }

  if (status === 'error') {
    return (
      <ConfirmationModalContent
        title={<Title>An error has occurred</Title>}
        cancelText="Back"
        confirmText="Close"
        onCancel={handleErrorBack}
        onConfirm={handleErrorClose}
      >
        {error}
      </ConfirmationModalContent>
    );
  }

  return null;
}
