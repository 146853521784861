import { styled } from 'styled-components';
import { Link, useCurrentRoute } from 'react-navi';
import { faCog, faWallet } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  Button,
  cssMixins,
  IconButton,
  Modal,
  ModalContent,
  Nav,
  NavButton,
  NavItem,
  NavMenu,
  StatusIndicator,
  themeUtils as tu,
  useModal,
} from '@zen-common/components-base';
import { SyncStatus } from '@zen/common-utils';
import { useOfflineStatus } from '@zen/common-react-hooks';
import {
  AccountsModalContent,
  AddressBox,
  BalanceBox,
  BlockchainInfoStore,
  SettingsStore,
  WalletStore,
} from '@zen/common-app-parts';
import PATHS from '../../paths';
import Logo from '../Logo/Logo';
import { TxCountChangeIndicator, VotingPhaseChangedUpdateIndicator } from '../update-indicators';
import MainSideNav from './MainSideNav';

const STATUS_OPTIONS = [
  { status: SyncStatus.Synced, label: 'SYNCED', color: 'success', progress: false },
  { status: SyncStatus.Syncing, label: 'SYNCING', color: 'success', progress: true },
  { status: SyncStatus.Error, label: 'NOT SYNCED', color: 'danger', progress: false },
];

const mobileOnlyCss = tu.mq({ display: ['flex', 'none'] });
const hideOnMobileCss = tu.mq({ display: ['none', 'flex'] });

export default function MainNav() {
  const {
    url: { href },
  } = useCurrentRoute();
  const { show, hide } = useModal();
  const { offline } = useOfflineStatus();
  const wallet = WalletStore.useStore();
  const {
    state: {
      currentWalletInfo: {
        isLoggedIn,
        balance,
        hasPassword,
        keys: { address },
      },
      wallets,
    },
  } = wallet;
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const {
    state: {
      error: nodeError,
      infos: { blocks, headers },
    },
  } = BlockchainInfoStore.useStore();
  const status =
    offline || nodeError
      ? SyncStatus.Error
      : blocks != headers // eslint-disable-line eqeqeq
      ? SyncStatus.Syncing
      : SyncStatus.Synced;

  const showConnectButton = !hasPassword && href.indexOf(PATHS.ONBOARDING) === -1;

  const showLoginButton = hasPassword && !isLoggedIn && href.indexOf(PATHS.UNLOCK) === -1;

  const onAction = () => {
    show(() => (
      <Modal>
        <ModalContent>
          <AccountsModalContent
            onCancel={hide}
            onAdd={wallet.actions.generateAccount}
            wallet={wallet}
          />
        </ModalContent>
      </Modal>
    ));
  };

  return (
    <MainNavContainer>
      <Nav>
        <LeftNavItem>
          <Link href={PATHS.DEFAULT}>
            <Box pr="smd">
              <Logo />
            </Box>
          </Link>
        </LeftNavItem>
        <NavMenu mr="auto" css={hideOnMobileCss}>
          {isLoggedIn && (
            <>
              <NavItem>
                <Link href={PATHS.WALLET} activeClassName="active" className="menu-item">
                  <TxCountChangeIndicator>My Wallet</TxCountChangeIndicator>
                </Link>
              </NavItem>
              <NavItem>
                <Link href={PATHS.CONTRACTS} activeClassName="active" className="menu-item">
                  Contracts
                </Link>
              </NavItem>
              <NavItem>
                <Link href={PATHS.VOTING} activeClassName="active" className="menu-item">
                  <VotingPhaseChangedUpdateIndicator>Voting</VotingPhaseChangedUpdateIndicator>
                </Link>
              </NavItem>
              {process.env.REACT_APP_END_PLATFORM === 'desktop' && (
                <NavItem>
                  <Link href={PATHS.SIGNER} activeClassName="active" className="menu-item">
                    Signer
                  </Link>
                </NavItem>
              )}
            </>
          )}
        </NavMenu>
      </Nav>
      <Nav>
        <NavMenu>
          {isLoggedIn && (
            <>
              <NavItemWithPadding css={mobileOnlyCss}>
                <StatusIndicator options={STATUS_OPTIONS} status={status} />
              </NavItemWithPadding>
              <NavItemWithPadding css={cssMixins.showOnDesktop('flex')}>
                <AddressBox address={address} />
              </NavItemWithPadding>
            </>
          )}
          {wallets[chain].filter((w) => w.keys.addresses?.length).length ? (
            <NavItemWithPadding css={cssMixins.showOnDesktop('flex')}>
              <IconButton mx="-11px" icon={faWallet} onClick={onAction} />
            </NavItemWithPadding>
          ) : null}
          {isLoggedIn && (
            <>
              <NavItemWithPadding css={cssMixins.showOnDesktop('flex')}>
                <BalanceBox balance={balance} />
              </NavItemWithPadding>
            </>
          )}
          {showConnectButton && (
            <NavItemWithPadding>
              <Link href={PATHS.ONBOARDING}>
                <Button sizeVariant="small" px="xs">
                  Connect a Wallet
                </Button>
              </Link>
            </NavItemWithPadding>
          )}
          {showLoginButton && (
            <NavItemWithPadding>
              <Link href={PATHS.UNLOCK}>
                <Button sizeVariant="small" px="xs">
                  Login
                </Button>
              </Link>
            </NavItemWithPadding>
          )}
        </NavMenu>
        <NavMenu css={hideOnMobileCss}>
          <RightNavItem>
            <Link href={PATHS.SETTINGS} activeClassName="active">
              <NavButton mx="-11px" icon={faCog} />
            </Link>
          </RightNavItem>
        </NavMenu>
        <NavMenu css={mobileOnlyCss}>
          <SideNavContainerItem>
            <MainSideNav />
          </SideNavContainerItem>
        </NavMenu>
      </Nav>
    </MainNavContainer>
  );
}

const MainNavContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${tu.color('bg4')};
`;

const LeftNavItem = styled(NavItem)`
  ${(p) => cssMixins.containerXPadding({ ...p, modifyRight: () => '0' })}
`;
const RightNavItem = styled(NavItem)`
  ${(p) => cssMixins.containerXPadding({ ...p, modifyLeft: (value) => `calc(${value} / 2)` })}
`;

const NavItemWithPadding = styled(NavItem)`
  ${(p) =>
    cssMixins.containerXPadding({
      ...p,
      modifyLeft: (value) => `calc(${value} / 2)`,
      modifyRight: (value) => `calc(${value} / 2)`,
    })}
`;

const SideNavContainerItem = styled(RightNavItem)`
  a {
    text-align: left;
  }
`;
