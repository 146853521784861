import PropTypes from 'prop-types';
import { css, styled } from 'styled-components';
import {
  Box,
  cssMixins,
  SubPageHeader,
  themeUtils as tu,
  Title,
  ViewAnimation,
} from '@zen-common/components-base';
import FullSizeBox from '../FullSizeBox';

/**
 * A layout with a title and content
 *
 * @param {Object} props
 * @param {*} props.title - the title to display
 * @param {boolean} props.noPadding - remove side padding
 * @param {boolean} props.dark - use a darker background
 * @param {boolean} props.renderBelowTitle - render content below the title
 */
export default function TitleContentLayout({
  title,
  noPadding,
  dark = false,
  renderBelowTitle,
  children,
  ...props
}) {
  return (
    <FullSizeBox>
      <SubPageHeader>
        <Box>
          <Title>{title}</Title>
          {renderBelowTitle}
        </Box>
      </SubPageHeader>
      <StyledViewAnimation noPadding={noPadding} dark={dark} {...props}>
        {children}
      </StyledViewAnimation>
    </FullSizeBox>
  );
}

TitleContentLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  noPadding: PropTypes.bool,
  dark: PropTypes.bool,
  renderBelowTitle: PropTypes.any,
};

const StyledViewAnimation = styled(ViewAnimation)`
  background-color: ${(p) => (p.dark ? tu.color('bg1')(p) : tu.color('bg2')(p))};

  ${(p) =>
    p.noPadding
      ? null
      : css`
          padding: ${tu.space('mlg')};
          ${cssMixins.containerXPadding}
        `}
`;
