import { css, styled } from 'styled-components';
import PropTypes from 'prop-types';
import Box from './Box';

const Centered = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) =>
    p.fullHeight
      ? css`
          height: 100%;
          flex-grow: 1;
        `
      : null}
`;

Centered.propTypes = {
  fullHeight: PropTypes.bool,
};

export default Centered;
