import axios from 'axios';
import { HttpError } from '@zen/common-utils';
import { Transaction } from '@zen/zenjs';

axios.defaults.timeout = 30000;

export function blockchainInfo(nodeUrl) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/blockchain/info'),
    method: 'get',
  }).then((data) => {
    if (process.env.REACT_APP_ENABLE_BACKDOOR === 'yes') {
      return {
        ...data,
        blocks: data.blocks + Number(sessionStorage.getItem('backdoor-add-headers') || 0),
        headers: data.headers + Number(sessionStorage.getItem('backdoor-add-headers') || 0),
      };
    }
    return data;
  });
}

export function activeContracts(nodeUrl) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/contract/active'),
    method: 'get',
  });
}

export function getConnections(nodeUrl) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/network/connections/count'),
    method: 'get',
  });
}

export function getHeaders(nodeUrl, { blockNumber, take }) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/blockchain/headers'),
    method: 'get',
    params: {
      blockNumber,
      take,
    },
  });
}

export function contractHistory(nodeUrl, { contractId, skip = 0, take = 100000000 } = {}) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/addressdb/contract/history'),
    method: 'post',
    data: {
      skip,
      take,
      contractId,
    },
    headers: { 'Content-Type': 'application/json' },
  });
}

/**
 * Get contract history for an contract ID by block number
 * from a start block to an end one
 *
 * @param {string} nodeUrl
 * @param {Object} params
 * @param {string} params.contractId
 * @param {number} params.start
 * @param {number} params.end
 */
export function contractHistoryByBlockNumber(nodeUrl, { contractId, start, end } = {}) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/addressdb/contract/history/filterByBlockNumber'),
    method: 'post',
    data: {
      start,
      end,
      contractId,
    },
    headers: { 'Content-Type': 'application/json' },
  });
}

/**
 * Get transaction for an address
 *
 * @param {string} nodeUrl
 * @param {Object} params
 * @param {[string]} params.addresses
 * @param {number} params.skip
 * @param {number} params.take
 */
export function getTransactions(nodeUrl, { addresses, skip = 0, take = 100000000 } = {}) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/addressdb/transactions'),
    method: 'post',
    data: {
      skip,
      take,
      addresses,
    },
    headers: { 'Content-Type': 'application/json' },
  });
}

/**
 * Get transaction for an address by block number
 * from a start block to an end one
 *
 * @param {string} nodeUrl
 * @param {Object} params
 * @param {[string]} params.addresses
 * @param {number} params.start
 * @param {number} params.end
 */
export function getTransactionsByBlockNumber(nodeUrl, { addresses, start, end } = {}) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/addressdb/transactions/filterByBlockNumber'),
    method: 'post',
    data: {
      start,
      end,
      addresses,
    },
    headers: { 'Content-Type': 'application/json' },
  });
}

export function getBalance(nodeUrl, { addresses, blockNumber }) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/addressdb/balance'),
    method: 'post',
    data: { addresses, blockNumber },
    headers: { 'Content-Type': 'application/json' },
  });
}

export const cgp = {
  getCurrent(nodeUrl) {
    return sendHttpRequest({
      url: getUrl(nodeUrl, '/blockchain/cgp'),
      method: 'get',
    });
  },
  getCandidates(nodeUrl, interval) {
    return sendHttpRequest({
      url: getUrl(nodeUrl, '/blockchain/candidates'),
      method: 'get',
      params: {
        interval,
      },
    });
  },
};

export function getContractMint(nodeUrl, asset) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/addressdb/contract/mint'),
    method: 'post',
    data: { asset },
    headers: { 'Content-Type': 'application/json' },
  });
}

/**
 * Publish a transaction
 * @param {string} nodeUrl
 * @param {string} tx - the transaction in hex
 */
export function publishTx(nodeUrl, tx) {
  return sendHttpRequest({
    url: getUrl(nodeUrl, '/blockchain/publishtransaction'),
    method: 'post',
    data: { tx },
    headers: { 'Content-Type': 'application/json' },
  });
}

/**
 * The base function to send an http request
 * @param {import("axios").AxiosRequestConfig} config
 */
export function sendHttpRequest(config) {
  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      // eslint-disable-next-line import/no-named-as-default-member
      if (axios.isCancel(error)) {
        throw error;
      } else {
        throw new HttpError(error);
      }
    });
}

export function getUrl(nodeUrl, path) {
  const url = nodeUrl.endsWith('/') ? nodeUrl.substring(0, nodeUrl.length - 1) : nodeUrl;
  return url + path;
}

export class RemoteNode {
  constructor(url) {
    this.url = url;
  }

  async getUnspentOutputs(addresses) {
    return sendHttpRequest({
      url: getUrl(this.url, '/addressdb/outputs'),
      method: 'post',
      data: { addresses, mode: 'unspentOnly' },
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async getTransactions(addresses, skip, take) {
    return getTransactions(this.url, { addresses, skip, take });
  }

  publish(tx) {
    return publishTx(this.url, tx.toHex());
  }

  executeContract(address, command, messageBody, tx, sender) {
    return sendHttpRequest({
      url: getUrl(this.url, '/blockchain/contract/execute'),
      method: 'post',
      data: {
        address: address,
        command: command,
        options: {
          sender: sender,
        },
        messageBody: messageBody,
        tx: tx.toHex(),
      },
      headers: { 'Content-Type': 'application/json' },
    }).then((hex) => Transaction.fromHex(hex, true));
  }

  blockChainInfo() {
    return blockchainInfo(this.url);
  }

  activeContracts() {
    return activeContracts(this.url);
  }

  activationOfContract(code, rlimit) {
    return sendHttpRequest({
      url: getUrl(this.url, '/addressdb/contract/info'),
      method: 'post',
      data: { code, rlimit: rlimit },
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getTransactionCount(addresses) {
    return sendHttpRequest({
      url: getUrl(this.url, '/addressdb/transactioncount'),
      method: 'post',
      data: { addresses },
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
