import { styled } from 'styled-components';
import { color, space, typography, system, border } from 'styled-system';
import tu from '../../utils/themeUtils';
import { getTransition } from '../../utils/styles/mixins';

export const StyledMenuWrapper = styled.div`
  position: relative;
`;

export const StyledMenu = styled.ul`
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  -webkit-overflow-scrolling: touch;
  ${space}
  ${border}
  ${system({
    backgroundColor: {
      property: 'backgroundColor',
      scale: 'colors',
    },
  })}
`;

export const StyledMenuItem = styled.li`
  list-style: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  ${color}
  ${space}
  ${typography}
  &:hover:not(.active) {
    ${system({
      hoverColor: {
        property: 'backgroundColor',
        scale: 'colors',
      },
    })}
  }
  font-weight: ${(p) => (p.isSelected ? '500' : 'normal')};
  background-color: ${(p) => (p.isSelected ? tu.color('primary') : 'initial')};
`;

export const StyledDropDown = styled.div`
  min-width: 100px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: none;
  cursor: pointer;
  outline: none;
  ${getTransition()}
  ${space}
  ${border}
  ${color}
  ${typography}
  &:hover {
    ${system({
      hoverColor: {
        property: 'backgroundColor',
        scale: 'colors',
      },
    })}
  }
  label {
    cursor: pointer;
    ${system({
      labelColor: {
        property: 'color',
        scale: 'colors',
      },
    })}
  }
`;

StyledMenu.defaultProps = {
  color: 'text',
  hoverColor: 'bg3',
  backgroundColor: 'bg4',
  borderRadius: 1,
  mt: 'xxs',
  py: 'xs',
};

StyledMenuItem.defaultProps = {
  fontSize: 'md',
  color: 'text',
  hoverColor: 'bg5',
  backgroundColor: 'bg4',
  px: 'smd',
  py: 'xs',
};

StyledDropDown.defaultProps = {
  fontSize: 'md',
  color: 'text',
  hoverColor: 'bg5',
  borderRadius: 1,
  backgroundColor: 'bg4',
  labelColor: 'label',
  px: 'sm',
  py: '8px',
};
