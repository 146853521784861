import { styled } from 'styled-components';
import tu from '../../utils/themeUtils';
import { containerXPadding } from '../../utils/styles/mixins';

const StyledTestnetBar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    user-select: none;
  }

  font-size: ${tu.fontSize('md')};
  height: 40px;
  background-color: ${tu.color('warning')};
  color: ${tu.color('text')};
  font-weight: 500;
  ${containerXPadding}
`;

const WatchModeBar = () => {
  return (
    <StyledTestnetBar>
      <span>Currently connected on watch mode</span>
    </StyledTestnetBar>
  );
};

WatchModeBar.propTypes = {};

export default WatchModeBar;
