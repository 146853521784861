import { useReducer } from 'react';
import { produce } from 'immer';

const initialState = {
  contractId: '',
  progress: false,
};

export default function useExtendStore() {
  /** @type {[state: initialState, dispatch: React.Dispatch<{type: string, payload: Object}>]} */
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    state,
    dispatch,
  };
}

const reducer = produce(
  /**
   * @param {initialState} draft
   * @param {{type: string}} action
   */
  (draft, { type, payload }) => {
    const handlers = {
      'set-contract-id': () => {
        draft.contractId = payload.contractId;
      },
      'process-started': () => {
        draft.progress = true;
      },
      'process-success': () => {
        draft.progress = false;
      },
      'process-failed': () => {
        draft.progress = false;
      },
    };

    if (typeof handlers[type] === 'function') {
      handlers[type]();
    } else {
      throw new Error('Unsupported action type');
    }
  }
);
