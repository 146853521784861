import { styled } from 'styled-components';
import { Box } from '@zen-common/components-base';

const FullSizeBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
`;

export default FullSizeBox;
