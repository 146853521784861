import { useCallback } from 'react';
import { Alert, useModal } from '@zen-common/components-base';
import BlockchainInfoStore from '../../stores/BlockchainInfoStore';

/**
 * Exposes a function to check if node is not synced
 *
 * @returns {preventAction} preventAction
 */
export default function useNodeNotSynced() {
  const {
    state: {
      infos: { blocks, headers },
    },
  } = BlockchainInfoStore.useStore();
  const { show, hide } = useModal();

  /**
   * Handles node not synced
   * @function
   * @typedef {Function} preventAction
   * @returns {boolean} true if node is not synced, otherwise false
   */
  const preventAction = useCallback(() => {
    if (blocks !== headers) {
      show(() => (
        <Alert title="Node is not synced" onDismiss={hide} type={Alert.TYPE.WARNING}>
          Operating is not possible while the node is syncing
          <br />
          Please try again later
        </Alert>
      ));
      return true;
    }
    return false;
  }, [blocks, headers, hide, show]);

  return preventAction;
}
