import { SubNav } from '@zen-common/components-base';
import { SubNavLink } from '@zen/common-app-parts';
import PATHS from '../../paths';

export default function SettingsNav() {
  return (
    <SubNav>
      <SubNavLink href={PATHS.SETTINGS_ACCOUNT} activeClassName="active">
        Account
      </SubNavLink>
      <SubNavLink href={PATHS.SETTINGS_CONNECTIVITY} activeClassName="active">
        Node Connectivity
      </SubNavLink>
    </SubNav>
  );
}
