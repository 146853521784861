import { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import FaSvg from '../helpers/FaSvg';
import {
  StyledSideNav,
  StyledSideNavContainer,
  StyledSideNavContent,
  StyledSideNavItem,
  StyledSideNavList,
  StyledSideNavMenu,
  StyledSideNavMenuTitleWrapper,
  StyledSideNavMenuToggler,
} from './SideNav.styled';

/**
 * Sidebar Navigation
 *
 * @export
 * @param {Object} props
 * @param {*} props.children
 * @param {string} props.width - sets the width of the sidenav (default is 300px)
 * @param {string} props.marginTop - sets a margin on top of the sidenav
 * @param {boolean} props.isOpen - opens/closes the menu
 * @param {Function} props.onClick - is called when clicking on an item
 */
export default function SideNav({ children, width, marginTop, isOpen, onClick, ...props }) {
  return (
    <StyledSideNavContainer {...props}>
      <StyledSideNav
        width={width}
        marginTop={marginTop}
        className={isOpen ? 'sidenav-is-open' : null}
      >
        <StyledSideNavList
          css={css`
            overflow: auto;
          `}
          onClick={(e) => {
            if (e.target.dataset.role !== 'sidenav-menu') {
              onClick && onClick();
            }
          }}
        >
          {children}
        </StyledSideNavList>
      </StyledSideNav>
    </StyledSideNavContainer>
  );
}

SideNav.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

function SideNavMenu({ title, active = false, children }) {
  const [isOpen, setIsOpen] = useState(active);

  useEffect(() => {
    if (active) {
      setIsOpen(true);
    }
  }, [active]);

  function toggleSideNavMenu() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <StyledSideNavMenu data-testid="SideNav-menu-wrapper">
      <StyledSideNavMenuTitleWrapper
        active={active}
        onClick={toggleSideNavMenu}
        data-role="sidenav-menu"
      >
        <StyledSideNavContent data-role="sidenav-menu">{title}</StyledSideNavContent>
        <StyledSideNavMenuToggler className={isOpen ? 'is-open' : ''}>
          <FaSvg icon={faAngleDown} />
        </StyledSideNavMenuToggler>
      </StyledSideNavMenuTitleWrapper>
      {isOpen && <StyledSideNavList>{children}</StyledSideNavList>}
    </StyledSideNavMenu>
  );
}

export { SideNavMenu, StyledSideNavItem as SideNavItem, StyledSideNavContent as SideNavContent };
