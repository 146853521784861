import { useEffect } from 'react';
import { queryCache } from 'react-query';
import {
  SettingsStore,
  BlockchainInfoStore,
  WalletStore,
  ExecuteContractStore,
  SendExecuteStore,
} from '@zen/common-app-parts';
import {
  CgpPhaseChangedStore,
  TxCountChangedStore,
  GovernancePhaseChangedStore,
} from '../../stores/ChangeNotificationsStore';

export default function StoreReset() {
  const {
    state: {
      settings: { chain, nodeUrl },
    },
  } = SettingsStore.useStore();

  const {
    actions: { reset: resetBlockchainInfoStore },
  } = BlockchainInfoStore.useStore();
  const {
    state: {
      currentWalletInfo: { isLoggedIn: isWalletLoggedIn },
    },
    actions: { reset: resetWallet },
  } = WalletStore.useStore();
  const {
    actions: { reset: resetExecuteContractStore },
  } = ExecuteContractStore.useStore();
  const {
    sendStore: {
      actions: { reset: resetSendStore },
    },
    executeStore: {
      actions: { reset: resetExecuteStore },
    },
  } = SendExecuteStore.useStore();
  const {
    actions: { reset: resetCgpPhaseChangedStore },
  } = CgpPhaseChangedStore.useStore();
  const {
    actions: { reset: resetGovernancePhaseChangedStore },
  } = GovernancePhaseChangedStore.useStore();
  const {
    actions: { reset: resetTxCountChangedStore },
  } = TxCountChangedStore.useStore();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('resetting all stores data');
    }
    resetBlockchainInfoStore();
    resetWallet();
    resetExecuteContractStore();
    resetSendStore();
    resetExecuteStore();
    resetCgpPhaseChangedStore();
    resetGovernancePhaseChangedStore();
    resetTxCountChangedStore();

    queryCache.getQueries().forEach((myQueryKey) => {
      queryCache.removeQueries(myQueryKey.queryKey);
      myQueryKey.refetch();
    });
  }, [
    chain,
    nodeUrl,
    isWalletLoggedIn,
    resetBlockchainInfoStore,
    resetWallet,
    resetExecuteContractStore,
    resetSendStore,
    resetExecuteStore,
    resetCgpPhaseChangedStore,
    resetTxCountChangedStore,
    resetGovernancePhaseChangedStore,
  ]);

  return null;
}
