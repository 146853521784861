import { useState } from 'react';
import { Address, PublicKey } from '@zen/zenjs';

/**
 * The inner logic of the watchOnly Address handling
 * @param {Object} params
 * @param {[{value:string}]} params.initialValue - the addresses
 * @param {'test'|'main'} params.chain - the addresses
 */
function useWatchAddresses({ initialValue = [], chain } = {}) {
  const [addresses, setAddresses] = useState(initialValue);
  /**
   * Clears the addresses, sets all words to empty strings
   */
  const clear = () => setAddresses([]);

  /**
   * Reset the addresses to the initial value
   */
  const reset = () => setAddresses(initialValue);

  const validAddress = (address) => {
    if (address && address.value === '') return false;
    if (
      (chain === 'test' && address.value.startsWith('tzn1')) ||
      (chain === 'main' && address.value.startsWith('zen1'))
    )
      return Address.validateAddress(chain, address.value);
    else
      try {
        return Address.validateAddress(chain, PublicKey.fromString(address.value).toAddress(chain));
      } catch (e) {
        return false;
      }
  };
  /**
   * Validate Addresses
   */
  const validAddresses = () => {
    return addresses.map(validAddress).every((ele) => ele === true);
  };
  /**
   * Set Addresses
   * @param {Object} params
   * @param {string} params.value - the value
   * @param {number} params.index - the index
   */
  const set = ({ index, value }) => {
    const _addresses = addresses.slice();
    _addresses[index] = { value: value, index: index };
    setAddresses(_addresses);
  };
  /**
   * Add Item to address list
   * @param {Object} params
   * @param {string} params.value - the value
   */
  const addItem = ({ value }) => {
    if (!validAddresses()) return;
    if (addresses.length >= 5) return;
    const _addresses = addresses.slice();
    _addresses.push({ value: value || '' });
    setAddresses(_addresses);
  };
  /**
   * Remove Item to address list
   * @param {number} index - the value
   */
  const removeItem = (index) => {
    const _addresses = addresses.slice();
    _addresses.splice(index, 1);
    setAddresses(_addresses);
  };
  return {
    removeItem,
    addItem,
    clear,
    reset,
    addresses,
    validAddress,
    validAddresses,
    setAddresses: set,
  };
}

export default useWatchAddresses;
