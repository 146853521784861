import { SettingsStore } from '@zen/common-app-parts';

/**
 * a wrapper around the SettingsStore.Provider with the needed settings
 */
export default function Provider({ config, storage, ...props }) {
  const { nodeUrl, chain, logoutMinutes } = config;
  return (
    <SettingsStore.Provider
      storage={storage}
      initialState={{
        logoutMinutes,
        nodeUrl,
        chain,
        errorReporting: {
          report: false,
          doNotAsk: false,
        },
      }}
      validation={{
        logoutMinutes: (value) => value > 0 && value <= 120,
      }}
      {...props}
    />
  );
}
