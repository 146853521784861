import { css, styled } from 'styled-components';
import Panel from '../Panel';
import tu from '../../utils/themeUtils';

/**
 * Apply this css to each table filter for nice positioning of the title
 */
export const tableFilterCss = css`
  display: flex;
  ${tu.mq({
    alignItems: ['flex-start', 'center'],
    flexDirection: ['column', 'row'],
  })}
`;

/**
 * Wraps table filters and position them at the title line on desktop and under the title on mobile
 */
export const TableFiltersContainer = styled(Panel)`
  display: flex;
  top: -50px;
  border-color: ${tu.color('borderDark')};
  border-style: solid;
  border-width: 0;
  ${css(
    tu.mq({
      position: ['static', 'absolute'],
      right: ['0', tu.space('sm')],
      padding: [tu.space('smd'), '0'],
      borderBottomWidth: ['1px', 0],
    })
  )}
`;
