import { styled } from 'styled-components';
import Box from '../Box';
import { fadeIntoView } from '../../utils/styles/mixins';

/**
 * Default animation for a View in a page
 */
const ViewAnimation = styled(Box)`
  ${fadeIntoView}

  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
`;

export default ViewAnimation;
