import { styled } from 'styled-components';
import tu from '../../../utils/themeUtils';
import Loading from '../../Loading';
import Box from '../../Box';

export default function LoadingInTable() {
  return (
    <Box>
      <Loading display="inline-block" fontSize="lg" />
      <StyledText>Loading...</StyledText>
    </Box>
  );
}

const StyledText = styled.span`
  padding-left: ${tu.space(1)};
  font-size: ${tu.fontSize('md')};
`;
