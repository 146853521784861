import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  cssMixins,
  InputGroup,
  LinkButton,
  LoadingOr,
  Paragraph,
} from '@zen-common/components-base';
import { useWatchAddresses } from '@zen/common-react-hooks';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import useSubmitWithLoading from '../hooks/useSubmitWithLoading';
import PaginationButton from '../blocks/PaginationButton';
import SettingsStore from '../../../../stores/SettingsStore';

export default function ImportWatchWallet({
  onCancel,
  onContinue,
  onCreateWallet,
  finalizeAddresses,
}) {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const {
    addresses,
    validAddresses,
    validAddress,
    setAddresses,
    addItem,
    removeItem,
    isInAddresses,
  } = useWatchAddresses({ initialValue: [{ value: '' }], chain });
  const spendsRef = useRef();

  const handleRemove = (...params) => {
    removeItem(...params);
  };
  const handleAdd = (...params) => {
    addItem(...params);
  };
  const finishHandler = ({ ...params } = {}) => {
    onContinue(params);
    finalizeAddresses(addresses.map((address) => address.value));
  };
  const { handleSubmit, loading } = useSubmitWithLoading({
    validate: () => validAddresses(),
    onSubmit: finishHandler,
  });
  return (
    <Layout
      header={
        <>
          <Header
            title="Import your public address or public key"
            text="Watch your wallet directly without private keys"
          />
        </>
      }
      body={
        <InputGroup
          inputs={addresses}
          inputsValid={() => validAddresses()}
          validAddress={validAddress}
          setAddresses={setAddresses}
          removeInput={handleRemove}
          addInput={handleAdd}
          isInAddresses={isInAddresses}
          addButtonAlignment="right"
          ref={spendsRef}
        />
      }
      footer={
        <Footer
          left={
            <Box
              css={cssMixins.showOnDesktop()}
              flexDirection="column"
              alignItems="flex-start"
              fontSize="md"
            >
              <Paragraph mb="0" color="label">
                {"Don't have a wallet?"}
              </Paragraph>
              <LinkButton onClick={onCreateWallet}>Create a wallet</LinkButton>
            </Box>
          }
          right={
            <>
              <PaginationButton disabled={loading} onClick={onCancel} mr="smd" bg="secondary">
                Cancel
              </PaginationButton>
              <PaginationButton disabled={!validAddresses() || loading} onClick={handleSubmit}>
                <LoadingOr loading={loading}>Continue</LoadingOr>
              </PaginationButton>
            </>
          }
        />
      }
      onClose={onCancel}
    />
  );
}

ImportWatchWallet.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};
