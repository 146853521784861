import { useState } from 'react';
import { SignerRequiredModalContent, WalletStore } from '@zen/common-app-parts';
import usePreventAction from '../../../utils/usePreventAction';
import { submitAllocationVote, submitNominationVote, submitPayoutVote } from '../store/modules/execute';
import VoteOnceModalContent from './VoteOnceModalContent';

/** @typedef {('nomination'|'allocation'|'payout')} TType */

/**
 * The voting modal process
 * @param {Object} props
 * @param {('test'|'main')} props.chain
 * @param {string} props.addressVote
 * @param {number} props.currentInterval
 * @param {TType} props.type
 * @param {string} props.ballot
 * @param {string} props.address
 * @param {Array} props.spends
 * @param {string} props.allocation
 * @param {string} props.snapshotZpBalance
 * @param {Function} props.setProgress
 * @param {(type: TType) => void} props.onSuccess
 * @param {(type: TType, error: Error) => void} props.onError
 * @param {(type: TType) => void} props.onCancel
 */
export default function VotingModalProcess({
  chain,
  addressVote,
  currentInterval,
  blockNumber,
  type,
  ballot,
  address,
  spends,
  allocation,
  snapshotZpBalance,
  setProgress,
  onSuccess,
  onError,
  onCancel,
}) {
  const [status, setStatus] = useState('vote-once');
  const { preventAction } = usePreventAction();
  const {
    actions: { submitCgpBallot },
  } = WalletStore.useStore();

  const handleVoteOnceCancel = () => {
    onCancel(type);
  };
  const handleVoteOnceContinue = () => {
    setStatus('password');
  };

  const handlePasswordBack = () => {
    setStatus('vote-once');
  };
  const handlePasswordContinue = async (password, passphrase, account) => {
    if (preventAction()) return;
    try {
      setProgress((s) => ({ ...s, [type]: true }));
      if (type === 'nomination') {
        await submitNominationVote({
          password,
          address,
          spends,
          chain,
          addressVote,
          currentInterval,
          submit: submitCgpBallot,
          passphrase,
          account,
        });
      } else if (type === 'allocation') {
        await submitAllocationVote({
          password,
          allocation,
          addressVote,
          currentInterval,
          blockNumber,
          submit: submitCgpBallot,
          passphrase,
          account,
        });
      } else if (type === 'payout') {
        await submitPayoutVote({
          password,
          address,
          spends,
          chain,
          addressVote,
          currentInterval,
          submit: submitCgpBallot,
          passphrase,
          account,
        });
      }

      onSuccess(type);
    } catch (error) {
      onError(type, error);
    }
  };

  if (status === 'vote-once') {
    return (
      <VoteOnceModalContent
        type={type}
        ballot={ballot}
        recipient={address}
        spends={spends}
        allocation={allocation}
        snapshotZpBalance={snapshotZpBalance}
        onCancel={handleVoteOnceCancel}
        onConfirm={handleVoteOnceContinue}
      />
    );
  }

  if (status === 'password') {
    return (
      <SignerRequiredModalContent
        cancelText="Back"
        submitText="Vote"
        onCancel={handlePasswordBack}
        onSuccess={handlePasswordContinue}
      />
    );
  }

  return null;
}
