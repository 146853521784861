import PropTypes from 'prop-types';
import { Modal, ModalContent, useModal } from '@zen-common/components-base';
import SettingLayout from '../containers/SettingLayout';
import SettingButton from '../common/SettingButton';
import EditLabelModalContent from './components/EditLabelModalContent';

const EditLabelAccountSetting = ({ useSetting, ...props }) => {
  const { show, hide } = useModal();
  const {
    onAction,
    wallet: {
      state: {
        currentWalletInfo: { label },
      },
    },
  } = useSetting();
  const showEditLabelAccountModal = () => {
    show(() => (
      <Modal>
        <ModalContent>
          <EditLabelModalContent onCancel={hide} onEdit={onAction} />
        </ModalContent>
      </Modal>
    ));
  };
  return (
    <SettingLayout
      header={label ? 'Account name: ' + label : 'Name your account'}
      paragraph="Name the account to easy discover them"
      {...props}
    >
      <SettingButton onClick={showEditLabelAccountModal}>{label ? 'Change' : 'Add'}</SettingButton>
    </SettingLayout>
  );
};

EditLabelAccountSetting.propTypes = {
  useSetting: PropTypes.func,
  viewAccount: PropTypes.number,
};

export default EditLabelAccountSetting;
