import { useCallback, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import {
  Alert,
  Box,
  cssMixins,
  Loading,
  Modal,
  ModalContent,
  notification,
  useModal,
} from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import { useStorageState, useUnmountedRef } from '@zen/common-react-hooks';
import { ExplorerLink, styles, TitleContentLayout } from '@zen/common-app-parts';
import usePreventAction from '../../utils/usePreventAction';
import TitleMessage from '../../components/TitleMessage';
import FormAndTableLayout from '../../components/voting/FormAndTableLayout';
import TwoPhaseProgressBar from '../../components/voting/ProgressBar';
import FormContainerPadding from '../../components/voting/FormContainerPadding';
import MessageContainerPadding from '../../components/voting/MessageContainerPadding';
import SectionTitle from '../../components/voting/SectionTitle';
import SectionSubTitle from '../../components/voting/SectionSubTitle';
import EstimatedTimeLeft from '../../components/EstimatedTimeLeft';
import {
  InfoBoxes,
  InfoBoxSemester,
  InfoBoxSnapshot,
  InfoBoxVoteWeight,
} from './components/InfoBoxes/InfoBoxes';
import TopParagraph from './components/TopParagraph';
import TestnetWarning from './components/TestnetWarning';
import CommitForm from './components/CommitForm';
import CommitsTable from './components/CommitsTable';
import NoZpMessage from './components/messages/NoZpMessage';
import VoteSuccessMessage from './components/messages/VoteSuccessMessage';
import VotingModalProcess from './components/VotingModalProcess';
import { getExplorerUrl, validateCommitId } from './modules/utils';

const STORAGE_KEY = 'zen-protocol-wallet:governance-commitId';
const FORM_TITLE_CONTESTANT = 'Propose a Commit ID';
const FORM_TITLE_CANDIDATE = 'Vote for a Commit ID';
const TABLE_TITLE_CONTESTANT = 'Contestants Board';
const TABLE_TITLE_CANDIDATE = 'Candidates Board';
const FORM_TAB_TITLE_CONTESTANT = 'Propose Commit';
const FORM_TAB_TITLE_CANDIDATE = 'Vote';
const TABLE_TAB_TITLE_CONTESTANT = 'Contestants';
const TABLE_TAB_TITLE_CANDIDATE = 'Candidates';

/**
 * @param {Object} props
 * @param {string} props.contractAddress
 * @param {Object} props.headers
 * @param {import('./types').Chain} props.chain
 * @param {string} props.zpBalance
 * @param {string} props.snapshotZpBalance
 * @param {import('./types').Phase} props.phaseOne - the contestant phase from explorer
 * @param {import('./types').Phase} props.phaseTwo - the candidate phase from explorer
 * @param {import('./types').Phase} props.currentPhase - a reference to one of phaseOne and phaseTwo
 * @param {string} props.thresholdZp
 * @param {import('./types').Candidates} props.contestants
 * @param {import('./types').Candidates} props.candidates
 * @param {string} props.contestantVote - the fetched vote
 * @param {string} props.candidateVote - the fetched vote
 * @param {import('./types').Voted} props.voted - the interval and phase when voted
 * @param {React.SetStateAction} props.setVoted - a function to set the voted state
 * @param {import('./types').Status} props.status
 * @param {boolean} props.executing - wallet store is executing
 * @param {Function} props.refetchVote - a function to refetch the vote
 */
export default function Governance({
  contractAddress,
  chain,
  headers,
  zpBalance,
  snapshotZpBalance,
  phaseOne,
  phaseTwo,
  currentPhase,
  thresholdZp,
  contestants,
  candidates,
  contestantVote,
  candidateVote,
  voted,
  setVoted,
  status,
  executing,
  refetchVote,
}) {
  const unmountedRef = useUnmountedRef();
  const { preventAction } = usePreventAction();
  const { hide: hideModal, show: showModal, isOpen: isModalOpen } = useModal();
  const [commitId, setCommitId, clearCommitIdStorage] = useStorageState(STORAGE_KEY, '');
  const [progress, setProgress] = useState(false);
  const [voteFromTable, setVoteFromTable] = useState(false);

  const voteWeightZp = ['contestant', 'candidate'].includes(status) ? snapshotZpBalance : zpBalance;
  const isZeroZp = numberUtils.isZero(voteWeightZp);
  const beginsInBlocks = currentPhase ? currentPhase.beginBlock - headers : 0;
  const contestantOptions = contestants.map((data) => data.commitId);
  const candidateOptions = candidates.map((data) => data.commitId);
  const commitIdValid =
    validateCommitId(commitId) &&
    (status === 'candidate' ? candidateOptions.includes(commitId) : true);
  const hasAlreadyVoted =
    currentPhase && voted.interval === currentPhase.interval && voted.phase === currentPhase.phase;
  const canVoteFromTable = currentPhase && !hasAlreadyVoted && !isZeroZp;

  useResetCommitIdOnStatusChange({ setCommitId, status });

  const { interval: currentPhaseInterval, phase: currentPhasePhase } = currentPhase || {};

  const handleVoteCancel = useCallback(() => {
    setProgress(false);
    hideModal();
  }, [hideModal]);

  const handleVoteError = useCallback(
    (error) => {
      if (!unmountedRef.current) {
        setProgress(false);
      }

      showModal(() => (
        <Alert type={Alert.TYPE.ERROR} onDismiss={hideModal} title="Error">
          Couldn&apos;t broadcast the vote
          <br />
          Error message: {error.message}
        </Alert>
      ));
    },
    [hideModal, showModal, unmountedRef]
  );

  const handleVoteSuccess = useCallback(() => {
    refetchVote();
    hideModal();
    notification('Vote was broadcasted successfully', {
      type: notification.TYPE.SUCCESS,
    });

    if (!unmountedRef.current) {
      setVoted({ interval: currentPhaseInterval, phase: currentPhasePhase });
      setProgress(false);
      setCommitId('');
    } else {
      clearCommitIdStorage();
    }
  }, [
    clearCommitIdStorage,
    currentPhaseInterval,
    currentPhasePhase,
    hideModal,
    refetchVote,
    setCommitId,
    setVoted,
    unmountedRef,
  ]);

  // Main vote handler
  const handleVote = useCallback(async () => {
    if (!commitIdValid || !currentPhase || executing || preventAction()) return;

    showModal(() => (
      <Modal onDismiss={handleVoteCancel}>
        <ModalContent onClose={handleVoteCancel}>
          <VotingModalProcess
            commitId={commitId}
            snapshotZpBalance={snapshotZpBalance}
            interval={currentPhaseInterval}
            phase={currentPhasePhase}
            votingContractAddress={contractAddress}
            setProgress={setProgress}
            onCancel={handleVoteCancel}
            onError={handleVoteError}
            onSuccess={handleVoteSuccess}
          />
        </ModalContent>
      </Modal>
    ));
  }, [
    commitId,
    commitIdValid,
    contractAddress,
    currentPhase,
    currentPhaseInterval,
    currentPhasePhase,
    executing,
    handleVoteCancel,
    handleVoteError,
    handleVoteSuccess,
    preventAction,
    showModal,
    snapshotZpBalance,
  ]);

  const onCommitsTableRowClick = useCallback(
    ({ data, vote }) => {
      const { commitId } = data;
      if (canVoteFromTable && !executing && !isModalOpen) {
        setCommitId(commitId);
      }
      if (vote && canVoteFromTable && validateCommitId(commitId)) {
        // vote on next render, commitId will be already set
        setVoteFromTable(true);
      }
    },
    [canVoteFromTable, executing, isModalOpen, setCommitId]
  );

  // vote from table
  useEffect(() => {
    if (voteFromTable) {
      setVoteFromTable(false);
      handleVote();
    }
  }, [handleVote, voteFromTable]);

  return (
    <TitleContentLayout
      noPadding
      title="Governance"
      renderBelowTitle={
        <Box mt="2">
          {chain === 'test' && <TestnetWarning />}
          <TopParagraph {...currentPhase} addressVote={contractAddress} />
        </Box>
      }
    >
      <ContentContainer>
        {currentPhase ? (
          <BorderedContainer>
            <InfoBoxes>
              <InfoBoxSemester interval={currentPhase.interval} />
              {['before', 'between'].includes(status) && (
                <InfoBoxSnapshot snapshot={currentPhase.beginBlock} />
              )}
              <InfoBoxVoteWeight
                isPotential={['after', 'before', 'between'].includes(status)}
                voteWeightZp={voteWeightZp}
              />
            </InfoBoxes>
          </BorderedContainer>
        ) : status === 'error' ? null : (
          <BorderedContainer>
            <Loading />
          </BorderedContainer>
        )}
        {status === 'before' && (
          <BorderedContainer>
            <TitleMessage
              title={
                <>
                  <span>Next semester begins in {beginsInBlocks} blocks,</span>{' '}
                  <EstimatedTimeLeft blocksCount={beginsInBlocks} />
                </>
              }
              message="Your vote weight will consist of your total ZP at snapshot block"
            />
          </BorderedContainer>
        )}
        {status === 'between' && (
          <BorderedContainer>
            <TitleMessage
              title={
                <>
                  <span>Next phase begins in {beginsInBlocks} blocks,</span>{' '}
                  <EstimatedTimeLeft blocksCount={beginsInBlocks} />
                </>
              }
              message="Your vote weight will consist of your total ZP at snapshot block"
            />
          </BorderedContainer>
        )}
        {status === 'after' && (
          <BorderedContainer>
            <TitleMessage
              title="Votes were already tallied"
              message={
                <>
                  Please visit the{' '}
                  <ExplorerLink path={getExplorerUrl(currentPhase)}>Block Explorer</ExplorerLink> to
                  see the winner
                </>
              }
            />
          </BorderedContainer>
        )}
        {status === 'loading' && (
          <BorderedContainer>
            <Loading />
          </BorderedContainer>
        )}
        {status === 'error' && (
          <BorderedContainer>
            <TitleMessage
              color="label"
              title="Could not fetch data from the server"
              message="Please try again in a few moments"
            />
          </BorderedContainer>
        )}
        {status === 'contestant' && (
          <>
            <BorderedContainer>
              {phaseOne && phaseTwo ? (
                <TwoPhaseProgressBar
                  currentBlock={headers}
                  phaseOneTitle="Contestant Phase"
                  phaseOneSnapshotBlock={phaseOne.beginBlock}
                  phaseOneTallyBlock={phaseOne.endBlock}
                  phaseTwoTitle="Candidate Phase"
                  phaseTwoSnapshotBlock={phaseTwo.beginBlock}
                  phaseTwoTallyBlock={phaseTwo.endBlock}
                />
              ) : (
                <Loading />
              )}
            </BorderedContainer>
            <FormAndTableLayout
              formTabTitle={FORM_TAB_TITLE_CONTESTANT}
              tableTabTitle={TABLE_TAB_TITLE_CONTESTANT}
              formTitle={<SectionTitle bigMargin>{FORM_TITLE_CONTESTANT}</SectionTitle>}
              form={
                hasAlreadyVoted ? (
                  contestantVote ? (
                    <MessageContainerPadding>
                      <VoteSuccessMessage
                        commitId={contestantVote}
                        interval={currentPhase.interval}
                        phase={currentPhase.phase}
                        voteWeightZp={voteWeightZp}
                      />
                    </MessageContainerPadding>
                  ) : (
                    <PaddingContainer>
                      <Loading />
                    </PaddingContainer>
                  )
                ) : isZeroZp ? (
                  <MessageContainerPadding>
                    <NoZpMessage interval={currentPhase.interval} phase={currentPhase.phase} />
                  </MessageContainerPadding>
                ) : (
                  <FormContainerPadding>
                    <CommitForm
                      commitId={commitId}
                      onCommitIdChange={setCommitId}
                      commitIdValid={commitIdValid}
                      options={contestantOptions}
                      progress={progress || executing}
                      disabled={isModalOpen || executing}
                      onSubmit={handleVote}
                    />
                  </FormContainerPadding>
                )
              }
              tableTitle={
                <Box>
                  <SectionTitle>{TABLE_TITLE_CONTESTANT}</SectionTitle>
                  <SectionSubTitle>
                    {`Interval threshold: ${numberUtils.toDisplay(thresholdZp)} ZP`}
                  </SectionSubTitle>
                </Box>
              }
              table={
                <CommitsTable
                  thresholdZp={thresholdZp}
                  data={contestants}
                  canVote={canVoteFromTable}
                  disabled={isModalOpen || executing}
                  onRowClick={onCommitsTableRowClick}
                />
              }
            />
          </>
        )}
        {status === 'candidate' && (
          <>
            <BorderedContainer>
              {phaseOne && phaseTwo ? (
                <TwoPhaseProgressBar
                  currentBlock={headers}
                  phaseOneTitle="Contestant Phase"
                  phaseOneSnapshotBlock={phaseOne.beginBlock}
                  phaseOneTallyBlock={phaseOne.endBlock}
                  phaseTwoTitle="Candidate Phase"
                  phaseTwoSnapshotBlock={phaseTwo.beginBlock}
                  phaseTwoTallyBlock={phaseTwo.endBlock}
                />
              ) : (
                <PaddingContainer>
                  <Loading />
                </PaddingContainer>
              )}
            </BorderedContainer>
            <FormAndTableLayout
              formTabTitle={FORM_TAB_TITLE_CANDIDATE}
              tableTabTitle={TABLE_TAB_TITLE_CANDIDATE}
              formTitle={<SectionTitle bigMargin>{FORM_TITLE_CANDIDATE}</SectionTitle>}
              form={
                hasAlreadyVoted ? (
                  candidateVote ? (
                    <MessageContainerPadding>
                      <VoteSuccessMessage
                        commitId={candidateVote}
                        interval={currentPhase.interval}
                        phase={currentPhase.phase}
                        voteWeightZp={voteWeightZp}
                      />
                    </MessageContainerPadding>
                  ) : (
                    <PaddingContainer>
                      <Loading />
                    </PaddingContainer>
                  )
                ) : isZeroZp ? (
                  <MessageContainerPadding>
                    <NoZpMessage interval={currentPhase.interval} phase={currentPhase.phase} />
                  </MessageContainerPadding>
                ) : (
                  <FormContainerPadding>
                    <CommitForm
                      commitId={commitId}
                      onCommitIdChange={setCommitId}
                      commitIdValid={commitIdValid}
                      options={candidateOptions}
                      progress={progress || executing}
                      disabled={isModalOpen || executing}
                      onSubmit={handleVote}
                    />
                  </FormContainerPadding>
                )
              }
              tableTitle={
                <Box>
                  <SectionTitle>{TABLE_TITLE_CANDIDATE}</SectionTitle>
                  {/* hack: add space as in nomination phase */}
                  <SectionSubTitle>{'\u00a0'}</SectionSubTitle>
                </Box>
              }
              table={
                <CommitsTable
                  data={candidates}
                  canVote={canVoteFromTable}
                  disabled={isModalOpen || executing}
                  onRowClick={onCommitsTableRowClick}
                />
              }
            />
          </>
        )}
      </ContentContainer>
    </TitleContentLayout>
  );
}

Governance.defaultProps = {
  headers: 0,
  zpBalance: '0',
  contestants: [],
  candidates: [],
};

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const BorderedContainer = styled(Box)`
  ${styles.borderBottomContainerCss}
  ${cssMixins.containerXPadding}
`;
const PaddingContainer = styled(Box)`
  ${cssMixins.containerXPadding}
`;

function useResetCommitIdOnStatusChange({ setCommitId, status }) {
  useEffect(() => {
    setCommitId('');
  }, [setCommitId, status]);
}
