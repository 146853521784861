import { ConfirmationModal, Title } from '@zen-common/components-base';

export const LogoutModal = ({ onCancel, onConfirm }) => {
  return (
    <ConfirmationModal
      dismissOnClickOutside
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={<Title>Confirm logout</Title>}
    >
      Are you sure you want to log out from this session?
    </ConfirmationModal>
  );
};
