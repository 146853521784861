import PropTypes from 'prop-types';
import { Modal, ModalContent } from '@zen-common/components-base';
import PasswordRequiredModalContentBase from './PasswordRequiredModalContentBase';

/**
 * A presentational component for the password required modal, contains no logic
 *
 * @param {Object} props
 * @param {Function} props.onSubmit
 * @param {Function} props.onDismiss
 * @param {boolean} props.progress
 * @param {string} props.error
 */
export default function PasswordRequiredModal({ onSubmit, onDismiss, progress, error }) {
  return (
    <Modal onDismiss={onDismiss}>
      <ModalContent onClose={onDismiss}>
        <PasswordRequiredModalContentBase error={error} onSubmit={onSubmit} progress={progress} />
      </ModalContent>
    </Modal>
  );
}

PasswordRequiredModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  progress: PropTypes.bool,
  error: PropTypes.string,
};
