import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledAddon,
  StyledExpandable,
  StyledInput,
  StyledTextArea,
  StyledWrapper,
} from './input.styled';

/**
 * @type {React.ForwardRefExoticComponent<InputProps & React.RefAttributes<any>}
 */
const Input = forwardRef(
  (
    {
      value,
      onChange,
      readOnly,
      disabled,
      variant,
      width,
      valid,
      renderRightAddon,
      renderLeftAddon,
      className,
      borderColor,
      borderColorFocus,
      borderColorValid,
      borderColorInvalid,
      color,
      inputType,
      onFocus,
      onBlur,
      ...props
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = (e) => {
      setIsFocus(true);
      typeof onFocus === 'function' && onFocus(e);
    };
    const handleBlur = (e) => {
      setIsFocus(false);
      typeof onBlur === 'function' && onBlur(e);
    };
    let inputToRender = null;
    switch (inputType) {
      case 'input':
        inputToRender = (
          <StyledInput
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            ref={ref}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
        );
        break;
      case 'multiline':
        inputToRender = (
          <StyledTextArea
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            ref={ref}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
        );
        break;
      case 'expandable':
        inputToRender = (
          <ContentEditable
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange}
            ref={ref}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
        );
        break;

      default:
        throw new Error(`Unhandled input type ${inputType}`);
    }

    return (
      <StyledWrapper
        variant={variant}
        width={width}
        valid={valid}
        readOnly={readOnly || disabled}
        focus={isFocus}
        theme={props.theme}
        className={className}
        {...{ borderColor, borderColorFocus, borderColorValid, borderColorInvalid }}
        {...{ color }}
      >
        {renderLeftAddon}
        {inputToRender}
        {renderRightAddon && <StyledAddon theme={props.theme}>{renderRightAddon}</StyledAddon>}
      </StyledWrapper>
    );
  }
);

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['block']),
  valid: PropTypes.bool,
  renderRightAddon: PropTypes.element,
  borderColor: PropTypes.string,
  borderColorFocus: PropTypes.string,
  borderColorValid: PropTypes.string,
  borderColorInvalid: PropTypes.string,
  color: PropTypes.string,
  inputType: PropTypes.oneOf(['input', 'multiline', 'expandable']),
};

Input.defaultProps = {
  fontSize: 2,
  borderColor: 'borderLight',
  borderColorFocus: 'textDisabled',
  borderColorValid: 'primary',
  borderColorInvalid: 'danger',
  color: 'text',
  inputType: 'input',
  spellCheck: 'false',
  autoComplete: 'off',
};

Input.displayName = 'Input';

export default Input;

const ContentEditable = forwardRef(({ value, onChange, onKeyDown, ...props }, ref) => {
  const initialVal = useRef(value + '<br />'); // using <br /> to keep height in firefox
  const _ref = useRef();
  const finalRef = ref || _ref; // give priority to external ref
  // value changed from the outside
  useEffect(() => {
    if (finalRef.current && value !== finalRef.current.textContent) {
      finalRef.current.innerHTML = value ? value : '<br />';
    }
  }, [finalRef, value]);

  const handleChange = useCallback(() => {
    const text = finalRef.current.textContent;
    if (text === '') {
      finalRef.current.innerHTML = '<br />';
    }
    if (onChange && text !== value && !/[<>]/.test(text)) {
      onChange({
        target: {
          value: text,
        },
      });
    }
  }, [finalRef, onChange, value]);

  // remove html from pasted text (IE not supported)
  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const plain = e.clipboardData.getData('text/plain');
    if (!/[<>]/.test(plain)) {
      document.execCommand('insertHTML', false, plain);
    }
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        e.preventDefault(); // no newlines
      }
      onKeyDown && onKeyDown(e);
    },
    [onKeyDown]
  );

  return (
    <StyledExpandable
      onPaste={handlePaste}
      onInput={handleChange}
      ref={finalRef}
      onKeyDown={handleKeyDown}
      {...props}
      dangerouslySetInnerHTML={{ __html: initialVal.current }}
    />
  );
});
ContentEditable.displayName = 'ContentEditable';
ContentEditable.defaultProps = {
  placeholder: '',
};

/**
 * @typedef {Object} InputExtraProps
 * @property {('input'|'multiline'|'expandable')} inputType
 * @property {('block')} variant
 * @property {boolean} valid
 * @property {React.ReactNode} renderRightAddon - render on the right
 * @property {React.ReactNode} renderLeftAddon - render on the left
 * @property {string} color
 * @property {string} borderColor
 * @property {string} borderColorFocus
 * @property {string} borderColorValid
 * @property {string} borderColorInvalid
 * @property {boolean} resize
 */

/**
 * @typedef { InputExtraProps &
 *  React.InputHTMLAttributes &
 *  import('styled-system').FontSizeProps &
 *  import('styled-system').SpaceProps
 * } InputProps
 */
