import { SubNav } from '@zen-common/components-base';
import { SubNavLink } from '@zen/common-app-parts';
import { TxCountChangeIndicator } from '../update-indicators';
import PATHS from '../../paths';

export default function WalletNav() {
  return (
    <SubNav>
      <SubNavLink href={PATHS.WALLET_BALANCES} activeClassName="active">
        Portfolio
      </SubNavLink>
      <SubNavLink href={PATHS.WALLET_RECEIVE} activeClassName="active">
        Receive
      </SubNavLink>
      <SubNavLink href={PATHS.WALLET_SEND} activeClassName="active">
        Send
      </SubNavLink>
      <SubNavLink href={PATHS.WALLET_TX_HISTORY} activeClassName="active">
        <TxCountChangeIndicator>Tx History</TxCountChangeIndicator>
      </SubNavLink>
    </SubNav>
  );
}
