import { Box, Loading } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import { TechnicalData as TD } from '@zen/common-app-parts';
import { convertPercentageToZP } from '../../store/modules/utils';
import MessageWithExplorerLink from '../MessageWithExplorerLink';

export function AllocationSuccessMessage({
  allocationVote,
  snapshotZpBalance,
  interval,
  tallyBlock,
}) {
  if (!allocationVote) return <Loading />; // can be fetching
  return (
    <MessageWithExplorerLink isOngoing type="success" interval={interval} phase="vote">
      <Box mb="4">Allocation vote was broadcasted successfully.</Box>
      <Box color="label">You voted for:</Box>
      <Box>
        <TD.Label>Allocation:</TD.Label>
        <TD.Value>{convertPercentageToZP(allocationVote, tallyBlock)} ZP</TD.Value>
      </Box>
      <Box>
        <TD.Label>Vote weight:</TD.Label>
        <TD.Value>{numberUtils.toDisplay(snapshotZpBalance)} ZP</TD.Value>
      </Box>
    </MessageWithExplorerLink>
  );
}
