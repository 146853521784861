import { useState } from 'react';
import { styled } from 'styled-components';
import {
  Box,
  Button,
  ConfirmationModalContent,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  RadioGroup,
  Title,
  ToggleVisibilityIcon,
} from '@zen-common/components-base';
import { assetUtils } from '@zen/common-utils';
import { useNavigation } from 'react-navi';
import SettingsStore from '../../../../stores/SettingsStore';
import { PasswordRequiredModalContent } from '../../../modals/PasswordRequiredModal';

const ONBOARDING_ROUTE = '/app/onboarding';
export default function AccountsModalContent({ onCancel, onAdd, wallet }) {
  const {
    state: {
      wallets,
      viewAccount: account,
      currentWalletInfo: { isLoggedIn },
    },
    actions: { viewAccount, wipeSeed },
  } = wallet;
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const { navigate } = useNavigation();
  const [index, setIndex] = useState(account);
  const [check, setCheck] = useState(isLoggedIn);
  const w = wallets[chain]
    .filter((w) => w.keys.addresses?.length)
    .map((w, index) => {
      return {
        label: `${index + 1}
        ${w.label ? '| ' + w.label : ''}
        ${w.isWatchMode ? '| Watch mode' : ''}
        ${w.hasPassphrase ? '| Passphrase' : ''}
        ${w.isSubAccount ? '| SubAccount' : ''}
        ${!w.isWatchMode && !w.hasPassphrase && !w.isSubAccount ? '| Standard' : ''}
        ${
          index === account
            ? `| Main ${check ? assetUtils.truncateOrNameAsset(w.keys.addresses[0]) : '******'}
        ${
          w.keys.addresses[1]
            ? `| Change ${check ? assetUtils.truncateOrNameAsset(w.keys.addresses[1]) : '******'}`
            : ''
        }`
            : ''
        }`,
        value: w.encryptedSeed,
      };
    });
  const [status, setStatus] = useState('unconfirmed');
  const [encryptValue, setEncryptValue] = useState();

  const handleConfirmChanged = () => {
    const index = w.findIndex((e) => e.value === encryptValue);
    viewAccount({ account: index });
    onCancel();
  };

  function goToWipe() {
    setStatus('remove');
  }

  const handleConfirmWipe = ({ index }) => {
    setIndex(index);
    goToWipe();
  };

  function handleChange(e) {
    setEncryptValue(e.target.value);
    setStatus('changed');
  }

  function handleFinish() {
    onAdd();
    navigate(ONBOARDING_ROUTE);
  }

  function handleCancel() {
    setStatus('unconfirmed');
  }

  function handleConfirmation() {
    setStatus('confirmed');
  }

  function wipe() {
    wipeSeed({ account: index });
    onCancel();
  }

  function handleWipe() {
    if (index === account) setStatus('password');
    else wipe();
  }

  if (status === 'password') {
    return <PasswordRequiredModalContent cancelText="Back" onCancel={goToWipe} onSuccess={wipe} />;
  }

  if (status === 'unconfirmed') {
    return (
      <Box>
        <ModalHeader>
          <Title>Connected Accounts</Title>
        </ModalHeader>
        <ModalSection>
          A new account can be added or removed here, once added you will be redirect to the
          onboarding page
        </ModalSection>
        {wallets[chain].filter((w) => w.keys.addresses?.length).length ? (
          <ModalSection>
            <Box color="label" fontSize="2">
              Hide Addresses
              <ToggleVisibilityIcon visible={check} onClick={() => setCheck(!check)} />
            </Box>
          </ModalSection>
        ) : null}
        <ModalSeparator />
        <ModalSection>
          <StyledRadioGroup
            checked={wallets[chain][account].encryptedSeed}
            onChange={handleChange}
            options={w}
            column
            hasSeparator
            hasRemove
            onRemove={handleConfirmWipe}
          />
        </ModalSection>
        <ModalFooter>
          <StyledButtonsWrapper>
            <StyledButton onClick={onCancel} bg="secondary" display={['none', 'block']}>
              Cancel
            </StyledButton>
            <StyledButton
              onClick={handleConfirmation}
              disabled={!wallets[chain][account].encryptedSeed}
            >
              Add an account
            </StyledButton>
          </StyledButtonsWrapper>
        </ModalFooter>
      </Box>
    );
  }
  if (status === 'confirmed') {
    return (
      <ConfirmationModalContent
        title={<Title> Add a new account</Title>}
        dismissOnClickOutside
        onCancel={handleCancel}
        cancelText="Back"
        confirmText="Add"
        onConfirm={handleFinish}
      >
        Adding a new account will log you out from your account and will redirect you to the
        on-boarding process
      </ConfirmationModalContent>
    );
  }
  if (status === 'changed') {
    return (
      <ConfirmationModalContent
        title={<Title>Confirm changing an account</Title>}
        dismissOnClickOutside
        onCancel={handleCancel}
        cancelText="Back"
        confirmText="Change"
        onConfirm={handleConfirmChanged}
      >
        You are about to switch to account {w.findIndex((e) => e.value === encryptValue) + 1}. After
        switching you will be redirected to login screen
      </ConfirmationModalContent>
    );
  }
  if (status === 'remove') {
    return (
      <ConfirmationModalContent
        title={<Title>Confirm wiping your wallet</Title>}
        dismissOnClickOutside
        onCancel={handleCancel}
        cancelText="Back"
        confirmText="Wipe"
        onConfirm={handleWipe}
      >
        You are about to wipe the account {index + 1} data. After wiping you will be redirected to
        login screen or to import wallet
      </ConfirmationModalContent>
    );
  }
}

const StyledRadioGroup = styled(RadioGroup)`
  margin-top: -15px;
`;

const StyledButtonsWrapper = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  flex-grow: 1;
`;
