import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalRoot } from '@zen-common/components-base';
import WalletStore from '../../stores/WalletStore';
import SettingsStore from '../../stores/SettingsStore';
import StartOnBoarding from './components/pages/StartOnBoarding';
import Terms from './components/pages/Terms';
import SecretPhrase from './components/pages/SecretPhrase';
import SecretPhraseQuiz from './components/pages/SecretPhraseQuiz';
import SetPassword from './components/pages/SetPassword';
import ImportWallet from './components/pages/ImportWallet';
import { getPaginationProps, PAGE_NAMES } from './onBoardingPagination';
import ImportWatchWallet from './components/pages/ImportWatchWallet';
import Passphrase from './components/pages/Passphrase';
import Confirm from './components/pages/Confirm';
import SetName from './components/pages/SetName';

/**
 * Main entry point for the on-boarding module
 *
 * @export
 * @param {Object} props
 * @param {string} props.appName - the app name to display in the start screen
 * @param {('create'|'import'|'watch')} props.type - start onboarding with this type, skips the start page
 */
export default function OnBoarding({ appName, type: givenType }) {
  const [mnemonic, setMnemonic] = useState('');
  const [passphrase, setPassphrase] = useState();
  const [password, setPassword] = useState();
  const [logoutMinutes, setLogoutMinutes] = useState();
  const [name, setName] = useState();
  const [account, setAccount] = useState();
  const [addresses, setAddresses] = useState([]);
  const [curPage, setCurPage] = useState(
    givenType === 'create' || givenType === 'import' || givenType === 'watch'
      ? PAGE_NAMES.Terms
      : PAGE_NAMES.StartOnBoarding
  );
  const [type, setType] = useState(givenType ? givenType : '');
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(false);
  const {
    actions: { setSetting },
  } = SettingsStore.useStore();
  const {
    actions: { create, createWatchOnly },
  } = WalletStore.useStore();

  const paginationProps = useMemo(() => getPaginationProps({ type, setType, setCurPage }), [type]);
  const onPasswordCreated = useCallback(async ({ password, logoutMinutes } = {}) => {
    setError('');
    setProgress(true);
    setPassword(password);
    setLogoutMinutes(logoutMinutes);
    setProgress(false);
  }, []);

  const onConfirm = useCallback(async () => {
    setError('');
    setProgress(true);
    setSetting({ name: 'logoutMinutes', value: logoutMinutes });
    try {
      if (type === 'watch') await createWatchOnly({ addresses, password, name });
      else await create({ mnemonic: mnemonic.join(' '), password, passphrase, account, name });
    } catch (error) {
      setProgress(false);
      setError(error.message);
    }
  }, [
    setSetting,
    logoutMinutes,
    type,
    createWatchOnly,
    addresses,
    password,
    name,
    create,
    mnemonic,
    passphrase,
    account,
  ]);
  const onPassphraseAdded = useCallback(
    async ({ passphrase, account } = {}) => {
      setPassphrase(passphrase);
      setAccount(account);
    },
    [setAccount, setPassphrase]
  );

  return (
    <ModalRoot>
      {curPage === PAGE_NAMES.StartOnBoarding && (
        <StartOnBoarding appName={appName} {...paginationProps[curPage]} />
      )}
      {curPage === PAGE_NAMES.ImportWatchWallet && (
        <ImportWatchWallet
          {...paginationProps[curPage]}
          error={error}
          loading={progress}
          finalizeAddresses={setAddresses}
        />
      )}
      {curPage === PAGE_NAMES.Terms && <Terms {...paginationProps[curPage]} />}
      {curPage === PAGE_NAMES.ImportWallet && (
        <ImportWallet {...paginationProps[curPage]} onValidPhraseEntered={setMnemonic} />
      )}
      {curPage === PAGE_NAMES.Passphrase && (
        <Passphrase {...paginationProps[curPage]} onPassphraseAdded={onPassphraseAdded} />
      )}
      {curPage === PAGE_NAMES.SecretPhrase && (
        <SecretPhrase {...paginationProps[curPage]} onPhraseGenerated={setMnemonic} />
      )}
      {curPage === PAGE_NAMES.SecretPhraseQuiz && (
        <SecretPhraseQuiz storedMnemonic={mnemonic} {...paginationProps[curPage]} />
      )}
      {curPage === PAGE_NAMES.SetPassword && (
        <SetPassword
          {...paginationProps[curPage]}
          onPasswordCreated={onPasswordCreated}
          error={error}
          loading={progress}
        />
      )}
      {curPage === PAGE_NAMES.Confirm && (
        <Confirm
          {...paginationProps[curPage]}
          onContinue={onConfirm}
          error={error}
          loading={progress}
          type={type}
          name={name}
          hasPassphrase={!!passphrase}
          logoutMinutes={logoutMinutes}
        />
      )}
      {curPage === PAGE_NAMES.SetName && (
        <SetName {...paginationProps[curPage]} error={error} loading={progress} setName={setName} />
      )}
    </ModalRoot>
  );
}
OnBoarding.propTypes = {
  appName: PropTypes.string,
};
