import { useCallback } from 'react';
import { OfflineModal, useModal } from '@zen-common/components-base';
import { useOfflineStatus } from '@zen/common-react-hooks';

/**
 * Exposes a function to check if offline and show a modal
 *
 * @returns {preventAction} preventAction - a function to show modal when offline
 */
export default function useOfflineModal() {
  const { offline } = useOfflineStatus();
  const { show } = useModal();

  /**
   * Shows the modal when offline
   * @function
   * @typedef {Function} preventAction
   * @returns {boolean} true if modal shown, otherwise false
   */
  const preventAction = useCallback(() => {
    if (offline) {
      show(() => <OfflineModal />);
      return true;
    }
    return false;
  }, [offline, show]);

  return preventAction;
}
