import { Box } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import { TechnicalData } from '@zen/common-app-parts';
import MessageWithExplorerLink from '../MessageWithExplorerLink';
import CopyableCommitId from '../CopyableCommitId';

export default function VoteSuccessMessage({ commitId, voteWeightZp, interval, phase }) {
  return (
    <MessageWithExplorerLink isOngoing type="success" interval={interval} phase={phase}>
      <Box mb="4">
        {phase === 'Contestant'
          ? 'Vote for a potential candidate was successfully broadcast'
          : 'Vote for a candidate was successfully broadcast'}
      </Box>
      <Box color="label">You voted for:</Box>
      <Box>
        <TechnicalData.Label>Commit ID:</TechnicalData.Label>
        <TechnicalData.Value>
          <CopyableCommitId commitId={commitId} />
        </TechnicalData.Value>
      </Box>
      <Box>
        <TechnicalData.Label>Vote weight:</TechnicalData.Label>
        <TechnicalData.Value>{numberUtils.toDisplay(voteWeightZp)} ZP</TechnicalData.Value>
      </Box>
    </MessageWithExplorerLink>
  );
}
