import { styled } from 'styled-components';
import tu from '../../utils/themeUtils';
import Box from '../Box';
import Dot from '../Dot';

export default function UpdateIndicator({ show, dotCss, children }) {
  return (
    <IndicatorContainer dotCss={dotCss}>
      {children}
      {show && <Dot size="6px" color="primary" />}
    </IndicatorContainer>
  );
}

const IndicatorContainer = styled(Box)`
  position: relative;
  width: max-content;

  ${Dot} {
    position: absolute;
    right: -${tu.space(6)};
    top: 50%;
    transform: translateY(-50%);
    ${(p) => p.dotCss}
  }
`;
