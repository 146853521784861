import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Form,
  InfoBox,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  Paragraph,
  PasswordInput,
  Title,
} from '@zen-common/components-base';
import { useInput, useResetError, useUnmountedRef } from '@zen/common-react-hooks';
import { numberUtils } from '@zen/common-utils';
import AccountIndex from '../../AccountIndex/AccountIndex';
import WalletStore from '../../../stores/WalletStore';

/**
 * A presentational component for the password required modal, contains no logic
 *
 * @param {Object} props
 * @param {Function} props.onSubmit
 * @param {Function} props.onCancel
 * @param {string} props.submitText
 * @param {string} props.cancelText
 * @param {boolean} props.progress
 * @param {string} props.error
 */
export default function SignerRequiredModalContentBase({
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  progress,
  error,
}) {
  const {
    state: {
      currentWalletInfo: { hasPassphrase, isSubAccount },
    },
  } = WalletStore.useStore();
  const unmountedRef = useUnmountedRef();
  const inputRef = useRef(null);
  const PassphraseInputRef = useRef(null);
  const { value: password, onChange: onPasswordChange } = useInput({ initialValue: '' });
  const { value: passphrase, onChange: onPassphraseChange } = useInput({ initialValue: '' });
  const {
    value: account,
    onChange: onAccountChange,
    setValue: setAccountChange,
  } = useInput({
    initialValue: 0,
    filter: (a) => {
      try {
        if (!a) return true;
        return numberUtils.parseAccount(a);
      } catch (e) {
        return false;
      }
    },
  });
  const accountValue = useMemo(() => numberUtils.clean(account, false), [account]);
  const submitError = useResetError({ externalError: error, resetDependencies: [password] });

  const showCancel = typeof onCancel === 'function';
  const submitDisabled = !password;
  const passwordInputValidState = submitError !== '' ? false : undefined;

  useEffect(() => {
    // fixes issue when form is in modal
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    !submitDisabled && (await onSubmit(password, passphrase, accountValue));
    !unmountedRef.current && inputRef.current && inputRef.current.focus();
  };

  return (
    <Box>
      <ModalHeader>
        <Title>Signer required</Title>
        <Paragraph>Please enter your info for signing</Paragraph>
      </ModalHeader>
      <Form onSubmit={handleSubmit} disabled={progress}>
        {hasPassphrase && (
          <>
            <ModalSection>
              <InfoBox
                info="A passphrase is the 25 word of the seed that can be any word, phrase, paragraph that
                generate a deterministic unique account"
              >
                <Label htmlFor="UnlockWallet-Password">
                  <LabelText>Passphrase</LabelText>
                </Label>
              </InfoBox>
              <PasswordInput
                id="UnlockWallet-Passphrase"
                ref={PassphraseInputRef}
                variant="block"
                onChange={onPassphraseChange}
                disabled={progress}
              />
              <InputMessage />
            </ModalSection>
            <ModalSeparator />
          </>
        )}
        {isSubAccount && (
          <>
            <ModalSection>
              <AccountIndex
                id="UnlockWallet-Account-Index"
                account={account}
                accountValue={accountValue}
                onAccountChange={onAccountChange}
                setAccountChange={setAccountChange}
                loading={progress}
              />
            </ModalSection>
            <ModalSeparator />
          </>
        )}
        <ModalSection>
          <Label htmlFor="SignerRequiredModal-Password">
            <LabelText>Password</LabelText>
          </Label>
          <PasswordInput
            id="SignerRequiredModal-Password"
            ref={inputRef}
            variant="block"
            valid={passwordInputValidState}
            value={password}
            onChange={onPasswordChange}
            autoComplete="current-password"
            disabled={progress}
          />
          <InputMessage>{submitError}</InputMessage>
        </ModalSection>
        <ModalSeparator />
        <ModalFooter>
          {showCancel && (
            <Button type="button" disabled={progress} onClick={onCancel} bg="secondary">
              {cancelText}
            </Button>
          )}
          <Button type="submit" disabled={submitDisabled || progress}>
            <LoadingOr loading={progress}>{submitText}</LoadingOr>
          </Button>
        </ModalFooter>
      </Form>
    </Box>
  );
}

SignerRequiredModalContentBase.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  progress: PropTypes.bool,
  error: PropTypes.string,
};
SignerRequiredModalContentBase.defaultProps = {
  submitText: 'Continue',
  cancelText: 'Cancel',
};
