import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import Box from '../../Box';
import FaSvg from '../../helpers/FaSvg';
import tu from '../../../utils/themeUtils';

/**
 * Display a small message under an input
 *
 * @param keepHeight
 * @param children
 * @param {Object} props
 * @param {('error'|'info'|'success')} props.type
 */
export default function InputMessage({ type = 'error', keepHeight, children, ...props }) {
  if (!keepHeight && !children) {
    return null;
  }

  const color = type === 'error' ? 'danger' : type === 'success' ? 'primary' : 'label';

  return (
    <StyledInputMessageContainer
      visibility={children ? 'visible' : 'hidden'}
      color={color}
      {...props}
    >
      {type === 'error' && (
        <FaSvg icon={faExclamationCircle} color="inherit" ml="1px" mr="xxs" fontSize="md" />
      )}
      {type === 'success' && (
        <FaSvg icon={faCheckCircle} color="inherit" ml="1px" mr="xxs" fontSize="md" />
      )}
      {children}
    </StyledInputMessageContainer>
  );
}

InputMessage.propTypes = {
  type: PropTypes.string,
};
InputMessage.defaultProps = {
  keepHeight: true,
};

const StyledInputMessageContainer = styled(Box)`
  display: flex;
  align-items: center;
  line-height: 1;
  visibility: ${(p) => p.visibility};
  font-size: ${tu.fontSize('xs')};
  margin-top: ${tu.space('xxs')};
  letter-spacing: 0.32px;
`;
