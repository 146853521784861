import { ExplorerLink } from '@zen/common-app-parts';
import GeneralMessageWithFooter from '../../../components/GeneralMessageWithFooter';

export default function MessageWithExplorerLink({
  type = 'warning',
  isOngoing = false,
  interval,
  phase,
  children,
}) {
  return (
    <GeneralMessageWithFooter
      footer={
        <>
          To see the {isOngoing ? 'ongoing' : 'past'} results please visit the{' '}
          <ExplorerLink path={`/cgp/${interval}/${phase}`}>Block Explorer</ExplorerLink>.
        </>
      }
      type={type}
    >
      {children}
    </GeneralMessageWithFooter>
  );
}
