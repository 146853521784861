import { useEffect } from 'react';
import { CgpPhaseChangedStore } from '../../stores/ChangeNotificationsStore';
import CGP from './CGP';
import { useCgpStore } from './store/CgpStore';

export default function CGPContainer() {
  const store = useCgpStore();
  const {
    state: { changed },
    actions: { clear: clearCgpPhaseChanged },
  } = CgpPhaseChangedStore.useStore();

  // clear on mount
  useEffect(() => {
    clearCgpPhaseChanged();
  }, [clearCgpPhaseChanged, changed]);

  return <CGP store={store} />;
}
