import { styled } from 'styled-components';
import {
  space,
  color,
  layout,
  flexbox,
  typography,
  border,
  position,
  background,
  compose,
} from 'styled-system';

const StyledBox = styled.div(
  {
    boxSizing: 'border-box',
  },
  compose(position, space, color, layout, border, flexbox, typography, background)
);

export default StyledBox;
