import {
  Address,
  Allocation,
  Asset,
  Ballot,
  ContractId,
  ContractWitness,
  DataFactory,
  DataType,
  ExtendedKey,
  LockIdentifiers,
  Payout,
  PublicKey,
  SecurePhrase,
  Spend,
  Transaction,
  Wallet,
  WitnessType,
  YAML,
} from '@zen/zenjs';
import { assetUtils, ValueDisplay } from '@zen/common-utils';
import { getSpend } from '../components/Spend/spendUtils';
import { RemoteNode } from './ApiService';

export function decryptPassword({ password, encryptedSeed }) {
  return SecurePhrase.decrypt(password, encryptedSeed).toString();
}

export function encryptPassword({ password, mnemonic }) {
  return SecurePhrase.encrypt(password, mnemonic);
}

export function walletFromMnemonic({ chain, mnemonic, account, passphrase, nodeUrl }) {
  return Wallet.fromMnemonic(mnemonic, new RemoteNode(nodeUrl), chain, account, passphrase);
}

export function walletFromAddresses({ chain, addresses = [], nodeUrl } = {}) {
  const valids = [];
  for (let addressOrPk of addresses) {
    if (validateAddress(chain, addressOrPk)) valids.push({ address: addressOrPk });
    else
      valids.push({
        pk: PublicKey.fromString(addressOrPk),
        address: PublicKey.fromString(addressOrPk).toAddress(chain),
      });
  }
  return new Wallet(valids, new RemoteNode(nodeUrl), chain);
}

export function checkWitnesses({ tx, addresses, chain }) {
  const witnesses = Transaction.fromHex(tx, true).witnesses.witnesses.filter(
    (a) => a.identifier === WitnessType.PKWitness
  );

  for (let witness of witnesses) {
    if (addresses.includes(PublicKey.fromString(witness.publicKey).toAddress(chain))) return true;
  }
  return false;
}

function privateKey({ mnemonic, passphrase, account = 0, change }) {
  return ExtendedKey.getZenPrivateKey(mnemonic, account, change, 0, passphrase);
}

export function privateKeys({ mnemonic, passphrase, account }) {
  return [
    privateKey({ mnemonic, passphrase, account, change: 0 }),
    privateKey({ mnemonic, passphrase, account, change: 1 }),
  ];
}

export function getPrivateKey({ mnemonic, passphrase, account = 0 }) {
  const extended = ExtendedKey.fromMnemonic(mnemonic, 'pair', account, passphrase);
  return extended.derivePath(`m/44'/258'/${account}'/0/0`).toBase58();
}

export function getXPub({ mnemonic, passphrase, account = 0 }) {
  const extended = ExtendedKey.fromMnemonic(mnemonic, 'pair', account, passphrase);
  return extended.derivePath(`m/44'/258'/${account}'`).neutered().toBase58();
}

/**
 * @param {Chain} chain
 * @param {string} yaml
 */
export function validateMessageBody(chain, yaml) {
  try {
    const data = YAML.fromYaml(chain, yaml);
    DataFactory.serialize(data);
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * @param {Chain} chain
 * @param {string} yaml
 */
export function getMessageBodyType(chain, yaml) {
  try {
    const data = YAML.fromYaml(chain, yaml);
    return DataType[data.identifier];
  } catch (err) {
    return '';
  }
}

/**
 * @param {Chain} chain
 * @param {string} address
 */
export function validateAddress(chain, address) {
  try {
    Address.decode(chain, address);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * @param {string} hex
 */
export function validateBallot(hex) {
  try {
    const payout = Ballot.fromHex(hex);
    return 2 * payout.getSize() === hex.length;
  } catch (e) {
    return false;
  }
}

/**
 * @param {string} value
 */
export function validateAsset(value) {
  try {
    new Asset(assetUtils.getAssetFullId(value));
    return true;
  } catch (error) {
    return false;
  }
}

export function getAddress(recipient) {
  if (!recipient) return '';
  switch (recipient.kind) {
    case 'PKRecipient':
      return recipient.hash.hash;
    case 'ContractRecipient':
      return recipient.contractId;
    default:
      return '';
  }
}

/**
 * @param {Chain} chain
 * @param {string} ballotId
 */
export function deserializePayoutBallotId(chain, ballotId) {
  const { data } = Ballot.fromHex(ballotId);
  const address = Address.getPublicKeyHashAddress(chain, getAddress(data.recipient));
  return {
    address,
    spends: data.spends.map((spend) => ({
      asset: spend.asset.asset,
      amount: assetUtils.fromKalapas(spend.amount),
    })),
  };
}

/**
 *
 * @param {Chain} chain
 * @param {string} recipient
 * @param {*} spends
 */
export function serializePayoutBallotId(chain, recipient, spends) {
  return new Ballot(toCgpPayout(chain, recipient, spends)).toHex();
}

/**
 * @param {string} ballotId
 */
export function deserializeAllocationBallotId(ballotId) {
  return Ballot.fromHex(ballotId).getData().allocation;
}

/**
 * @param {Chain} chain
 * @param {string} yaml
 */
export function getMessageBody(chain, yaml) {
  return yaml ? YAML.fromYaml(chain, yaml) : undefined;
}

/**
 * @param {Chain} chain
 * @param {string} contractId
 */
export function getContractAddress(chain, contractId) {
  return Address.getPublicKeyHashAddress(chain, ContractId.fromString(contractId));
}

/**
 * @param {string} assetId
 */
export function getContractIdFromAsset(assetId) {
  try {
    return new Asset(assetId).toContractId();
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

/**
 * @param {string} assetId
 */
export function getContractFromAsset(assetId) {
  try {
    return new Asset(assetId).toContractId().toHex();
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

/**
 * @param {Chain} chain
 * @param {string} assetId
 */
export function getContractAddressFromAsset(chain, assetId) {
  try {
    return Address.getPublicKeyHashAddress(chain, getContractIdFromAsset(assetId));
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

/**
 * @param {Chain} chain
 * @param {string} pk
 */
export function getPublicKeyAddress(chain, pk) {
  return PublicKey.fromString(pk).toAddress(chain);
}

/**
 * Sorts the spends by asset ASC and converts each to a Spend
 * @param {[AssetAmount]} spends
 */
export function toSpend(spends) {
  return spends
    .sort((a, b) => {
      if (a.asset > b.asset) {
        return 1;
      }
      if (a.asset < b.asset) {
        return -1;
      }
      return 0;
    })
    .map((spend) => {
      const { asset, amount } = spend;
      return new Spend(new Asset(asset), String(amount));
    });
}

/**
 * Creates a cgp payout, make sure the spends are sorted
 * @param {Chain} chain
 * @param {string} recipient
 * @param {[AssetAmount]} spends
 */
export function toCgpPayout(chain, recipient, spends) {
  const spendArr = toSpend(spends);
  const address = Address.decode(chain, recipient);
  if (address instanceof ContractId) {
    return new Payout(
      {
        kind: 'ContractRecipient',
        contractId: address,
      },
      spendArr
    );
  }
  return new Payout(
    {
      kind: 'PKRecipient',
      hash: address,
    },
    spendArr
  );
}

export function toCgpAllocation(percentage) {
  return new Allocation(percentage);
}

/** @typedef {('main'|'test')} Chain */

/** @typedef {{ asset: string, amount: string }} AssetAmount */

/**
 * Get a Zenjs Asset
 * @param {string} a
 */
export function getAsset(a) {
  try {
    return new Asset(a);
    // eslint-disable-next-line no-empty
  } catch {}
}

/**
 * Get a Zenjs ContractId String
 * @param {string} a
 */
export function getContractId(a) {
  try {
    return getAsset(a).toContractId().toHex();
    // eslint-disable-next-line no-empty
  } catch {}
}

export function getSigned(txObj) {
  const isSigned =
    txObj.inputs.inputs.length ===
    txObj.witnesses.witnesses.filter((a) => a.identifier === WitnessType.PKWitness).length;
  const contractWitness = txObj.witnesses.witnesses.filter(
    (a) => a.identifier === WitnessType.ContractWitness
  )[0]?.witness;
  const bi = contractWitness instanceof ContractWitness ? contractWitness.beginInputs : 0;
  const il = contractWitness instanceof ContractWitness ? contractWitness.inputsLength : 0;
  return il === 0 ? isSigned : isSigned && bi + il === txObj.inputs.inputs.length;
}

export function getTxData({ tx, chain }) {
  try {
    if (tx) {
      const txObj = Transaction.fromHex(tx, true);
      const txHash = txObj.hash().hash;
      let type = '';
      if (txObj.outputs.outputs.length === 0)
        return { error: new Error('Structurally invalid output') };
      const data = txObj.outputs.outputs.reduce(
        (data, output) => {
          const lockType = LockIdentifiers[output.lock.identifier];
          switch (lockType) {
            case 'ActivationSacrifice':
              type = 'Contract Activation';
              data.outputs.push({
                ...getSpend({
                  asset: output.spend.asset.getAsset(),
                  assetValid: true,
                  amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
                  amountValid: true,
                }),
                ...{
                  kind: lockType,
                },
              });
              break;
            case 'Fee':
              type = 'Contract Execution';
              data.outputs.push({
                ...getSpend({
                  asset: output.spend.asset.getAsset(),
                  assetValid: true,
                  amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
                  amountValid: true,
                }),
                ...{
                  kind: lockType,
                },
              });
              break;
            case 'PK':
              if (
                txObj.witnesses.witnesses.filter(
                  (a) => a.identifier === WitnessType.ContractWitness
                ).length !== 0
              ) {
                type = 'Contract Execution';
              } else type = 'Send';
              data.outputs.push({
                ...getSpend({
                  asset: output.spend.asset.getAsset(),
                  assetValid: true,
                  amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
                  amountValid: true,
                }),
                ...{
                  lockedAddress: Address.getPublicKeyHashAddress(chain, output.lock.pkHash.hash),
                },
              });
              break;
            case 'Contract':
              if (
                txObj.witnesses.witnesses.filter(
                  (a) => a.identifier === WitnessType.ContractWitness
                ).length !== 0
              ) {
                type = 'Execution';
              } else type = 'Send';
              data.outputs.push({
                ...getSpend({
                  asset: output.spend.asset.getAsset(),
                  assetValid: true,
                  amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
                  amountValid: true,
                }),
                ...{
                  lockedAddress: Address.getPublicKeyHashAddress(chain, output.lock.contractId),
                  kind: lockType,
                },
              });
              break;
            case 'ExtensionSacrifice':
              type = 'Contract Extension';
              data.outputs.push({
                ...getSpend({
                  asset: output.spend.asset.getAsset(),
                  assetValid: true,
                  amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
                  amountValid: true,
                }),
                ...{
                  lockedAddress: Address.getPublicKeyHashAddress(chain, output.lock.contractId),
                  kind: lockType,
                },
              });
              break;
            case 'Destroy':
              type = 'Destroy';
              data.outputs.push({
                ...getSpend({
                  asset: output.spend.asset.getAsset(),
                  assetValid: true,
                  amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
                  amountValid: true,
                }),
                ...{
                  kind: lockType,
                },
              });
              break;
          }
          // outputs for the pkHash only
          return data;
        },
        { outputs: [] }
      );
      if (txObj.contract.contract) type = 'Contract Activation';
      else if (data.outputs[0].kind === 'ExtensionSacrifice') type = 'Contract Extension';
      else if (data.outputs[0].kind === 'Destroy') type = 'Destroy';

      const isSigned = getSigned(txObj);

      return {
        txHash,
        ...data,
        contract: txObj.contract,
        contractWitness: txObj.witnesses.witnesses.filter(
          (a) => a.identifier === WitnessType.ContractWitness
        ),
        type,
        error: '',
        isSigned,
      };
    } else if (tx !== '') {
      return { error: new Error('Transaction cannot be parsed') };
    }
  } catch (e) {
    return { error: e };
  }
}
