import { styled } from 'styled-components';
import Box from '../../Box';

export const InputAddonText = styled(Box).attrs({
  fontSize: 'xs',
  px: 'xs',
})`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;
