import { styled } from 'styled-components';
import { Box, themeUtils as tu } from '@zen-common/components-base';

export default function InfoBox({ label, children, ...props }) {
  return (
    <InfoBoxContainer {...props}>
      <StyledLabel>{label}</StyledLabel>
      <StyledData>{children}</StyledData>
    </InfoBoxContainer>
  );
}

const InfoBoxContainer = styled(Box)`
  line-height: 0;
`;

const StyledLabel = styled(Box)`
  display: inline-block;
  color: ${tu.color('label')};
  font-size: ${tu.fontSize(1)};
  line-height: ${tu.lineHeight(1)};

  ${tu.mq({
    display: ['inline-block', null, 'block'],
    marginRight: [tu.space('sm'), null, '0'],
    marginBottom: ['0', null, tu.space('xxs')],
  })}
`;
const StyledData = styled(Box)`
  display: inline-block;
  font-size: ${tu.fontSize('xl')};
  ${tu.mq({
    display: ['inline-block', null, 'block'],
    fontSize: [tu.fontSize(1), null, tu.fontSize(4)],
    lineHeight: [tu.lineHeight(1), null, tu.lineHeight(4)],
  })}
`;
