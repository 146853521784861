import { styled } from 'styled-components';
import {
  Box,
  Button,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  Title,
} from '@zen-common/components-base';

export default function OptionsModalContent({
  hasMultiple,
  onBackupWallet,
  onWipe,
  onWipeAll,
  isWatch,
}) {
  return (
    <Box>
      <ModalHeader>
        <Title>Confirm wiping your wallet</Title>
      </ModalHeader>
      <ModalSection>
        By wiping your wallet you will delete your{' '}
        {isWatch ? 'addresses.' : 'encrypted seed. Make sure you saved your 24 mnemonic phrase. '}
        {hasMultiple &&
          'We notice you have added more then one account, choose between wiping all the account or just the current one'}
      </ModalSection>
      <ModalSeparator />
      <ModalFooter>
        <StyledButtonsWrapper>
          {!isWatch && <StyledButton onClick={onBackupWallet}>Backup Wallet</StyledButton>}
          <StyledButton onClick={onWipe}>Wipe {hasMultiple && ' Current'}</StyledButton>
          {hasMultiple && <StyledButton onClick={onWipeAll}>Wipe All</StyledButton>}
        </StyledButtonsWrapper>
      </ModalFooter>
    </Box>
  );
}

const StyledButtonsWrapper = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  flex-grow: 1;
`;
