import { Box, Header, Paragraph } from '@zen-common/components-base';

export default function AppHeader({ title, text, subtitle, extraContent }) {
  return (
    <Box>
      <Header fontWeight="400">{title}</Header>
      {subtitle}
      <Paragraph color="label" mb="0" fontSize={['md', null, 'lg']}>
        {text}
      </Paragraph>
      {extraContent}
    </Box>
  );
}
