import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import Button from '../Button/Button';
import tu from '../../utils/themeUtils';
import { containerXPadding } from '../../utils/styles/mixins';

const StyledTestnetBar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    user-select: none;
  }

  font-size: ${tu.fontSize('md')};
  height: 40px;
  background-color: ${tu.color('primary')};
  color: ${tu.color('text')};
  font-weight: 500;
  ${containerXPadding}
`;

const StyledSwitchButton = styled(Button)`
  border-radius: 6px;
  border: 1px solid ${tu.color('bg7')};
  padding: ${tu.space('xxs')} ${tu.space('xs')};
`;

const TestnetBar = ({ onSwitch }) => {
  return (
    <StyledTestnetBar>
      <span>Running on Testnet</span>
      <StyledSwitchButton ml="xs" onClick={onSwitch}>
        Switch to Mainnet
      </StyledSwitchButton>
    </StyledTestnetBar>
  );
};

TestnetBar.propTypes = {
  onSwitch: PropTypes.func.isRequired,
};

export default TestnetBar;
