import { useMemo } from 'react';
import { Router as NaviRouter } from 'react-navi';
import WalletStore from '../stores/WalletStore';

export default function Router({ routes, children }) {
  const {
    state: {
      currentWalletInfo: { isLoggedIn, hasPassword },
    },
    actions: { wipeSeed },
  } = WalletStore.useStore();

  const context = useMemo(
    () => ({ user: { hasPassword, isLoggedIn, wipeSeed } }),
    [hasPassword, isLoggedIn, wipeSeed]
  );

  return (
    <NaviRouter routes={routes} context={context}>
      {children}
    </NaviRouter>
  );
}
