import { forwardRef } from 'react';
import { css, styled } from 'styled-components';
import {
  border,
  color,
  compose,
  flexbox,
  fontSize,
  fontWeight,
  layout,
  lineHeight,
  size,
  space,
} from 'styled-system';
import PropTypes from 'prop-types';
import tu from '../../utils/themeUtils';
import { getTransition } from '../../utils/styles/mixins';
import Box from '../Box';
import getStateColors from './getStateColors';

const basicStyle = css`
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  text-decoration: none;

  ${getTransition()}
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }

  &:disabled {
    box-shadow: none;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  ${compose(border, color, space, size, fontSize, fontWeight, layout, flexbox, lineHeight)}

  ${(props) =>
    getStateColors({
      props,
      property: 'background-color',
      value: tu.color(props.bg || props.backgroundColor)(props),
    })}
`;

export const StyledInputButton = styled.button`
  ${basicStyle}
`;
export const StyledLinkButton = styled.a`
  ${basicStyle}
`;

const StyledButtonGroup = styled(Box)`
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  ${StyledInputButton}, > ${StyledLinkButton} {
    position: relative;
    flex: 0 1 auto;

    &:hover,
    &:focus,
    &:active {
      z-index: 1;
    }

    &:first-child {
      margin-left: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const SizeVariantProps = {
  small: { py: 2 },
  medium: { py: 4 },
  large: { py: 6 },
};

/**
 * @typedef {Object} ButtonInnerProps
 * @property {('small'|'medium'|'large')} sizeVariant
 * @property {string} href
 * @property {string} disabledBg - the background color for the disabled state
 * @property {string} disabledColor - the text color for the disabled state
 */

/**
 * @typedef { ButtonInnerProps &
 *  React.ButtonHTMLAttributes &
 *  import('styled-system').ColorProps &
 *  import('styled-system').BorderProps &
 *  import('styled-system').SpaceProps &
 *  import('styled-system').SizeProps &
 *  import('styled-system').FontSizeProps &
 *  import('styled-system').FontWeightProps &
 *  import('styled-system').LayoutProps &
 *  import('styled-system').FlexboxProps &
 *  import('styled-system').LineHeightProps
 * } ButtonProps
 */

/**
 * @type {React.ForwardRefExoticComponent<ButtonProps & React.RefAttributes<any>}
 */
const Button = forwardRef(({ disabledBg, disabledColor, ...props }, ref) => {
  const disabledOverrides = props.disabled ? { bg: disabledBg, color: disabledColor } : {};
  const sizeOverrides = props.sizeVariant
    ? SizeVariantProps[props.sizeVariant] || SizeVariantProps.medium
    : {};
  return props.href ? (
    <StyledLinkButton ref={ref} {...props} {...disabledOverrides} {...sizeOverrides} />
  ) : (
    <StyledInputButton ref={ref} {...props} {...disabledOverrides} {...sizeOverrides} />
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bg: PropTypes.string,
  sizeVariant: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
  disabledBg: PropTypes.string,
  disabledColor: PropTypes.string,
};
Button.defaultProps = {
  color: 'text',
  bg: 'primary',
  disabledBg: 'secondary',
  disabledColor: 'textDisabled',
  py: 4,
  px: 7,
  fontSize: 2,
  lineHeight: 5,
  fontWeight: 500,
  borderRadius: 1,
  type: 'button',
};

export default Button;
export { StyledButtonGroup as ButtonGroup };
