import { Box, ConfirmationModalContent, Title } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import { TechnicalData as TD } from '@zen/common-app-parts';
import CopyableCommitId from '../CopyableCommitId';

/**
 * @param {TProps} props
 */
export default function VoteOnceModalContent({ commitId, snapshotZpBalance, onCancel, onConfirm }) {
  return (
    <ConfirmationModalContent
      title={<Title>You can only vote once</Title>}
      subtitle="You can only vote once on each phase in an interval and only the first vote counts."
      confirmText="Continue"
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Box>
        <TD.Section>
          <Box>
            <TD.Title>Vote For</TD.Title>
          </Box>
          <Box>
            <TD.Label>Commit ID:</TD.Label>
            <TD.Value>
              <CopyableCommitId commitId={commitId} />
            </TD.Value>
          </Box>
        </TD.Section>
        <TD.Section>
          <TD.Title>Vote/TX Info</TD.Title>
          <Box>
            <TD.Label>Vote weight:</TD.Label>
            <TD.Value>{numberUtils.toDisplay(snapshotZpBalance)} ZP</TD.Value>
          </Box>
          <Box>
            <TD.Label>Tx fee:</TD.Label>
            <TD.Value>0.00000001 ZP</TD.Value>
          </Box>
        </TD.Section>
      </Box>
    </ConfirmationModalContent>
  );
}

/**
 * @typedef {Object} TProps
 * @property {string} params.commitId
 * @property {string} params.snapshotZpBalance
 * @property {Function} params.onCancel
 * @property {Function} params.onConfirm
 */
