import { styled } from 'styled-components';
import { color } from 'styled-system';
import Box from '../Box';

const SIZE = 8;
const HALF_SIZE = SIZE / 2;

export const StyledTooltipArrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: ${SIZE}px;
    height: ${SIZE}px;
    z-index: -1;
  }

  &::before {
    content: '';
    transform: rotate(45deg);
  }
`;

export const StyledTooltipText = styled(Box)`
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 10000;
  display: none;
  &[data-show='true'] {
    display: block;
  }

  &[data-popper-placement^='top'] > ${StyledTooltipArrow} {
    bottom: -${HALF_SIZE}px;
  }
  &[data-popper-placement^='bottom'] > ${StyledTooltipArrow} {
    top: -${HALF_SIZE}px;
  }
  &[data-popper-placement^='left'] > ${StyledTooltipArrow} {
    right: -${HALF_SIZE}px;
  }
  &[data-popper-placement^='right'] > ${StyledTooltipArrow} {
    left: -${HALF_SIZE}px;
  }

  > ${StyledTooltipArrow} ::before {
    ${color}
  }
`;
