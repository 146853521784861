import { styled } from 'styled-components';
import logo from './zen-logo.png';

const Logo = styled.img.attrs({
  alt: 'Zen Logo',
  src: logo,
})`
  height: 36px;
`;

export default Logo;
