const config = {
  appName: 'Zen Protocol Wallet',
  appNameShort: 'Wallet',
  logoutMinutes: 15,
  uri: process.env.REACT_APP_URI || 'wallet.zp.io',
  nodeUrl: 'https://mainnet-nodes.zp.io',
  nodes: [
    {
      label: 'Mainnet | Remote Node | mainnet-nodes.zp.io',
      value: 'main https://mainnet-nodes.zp.io',
    },
    {
      label: 'Testnet | Remote Node | testnet-nodes.zp.io',
      value: 'test https://testnet-nodes.zp.io',
    },
  ],
  chain: 'main',
  zenJsVersion: process.env.REACT_APP_ZENJS_VERSION || '0.0.0',
  buildVersion: process.env.REACT_APP_BUILD_VERSION || '0.0.0',
  contract: {
    cgp: {
      main: {
        fund: process.env.REACT_APP_CGP_FUND_CONTRACT_ID_MAIN,
        voting: process.env.REACT_APP_CGP_VOTING_CONTRACT_ID_MAIN,
      },
      test: {
        fund: process.env.REACT_APP_CGP_FUND_CONTRACT_ID_TEST,
        voting: process.env.REACT_APP_CGP_VOTING_CONTRACT_ID_TEST,
      },
    },
    governance: {
      main: process.env.REACT_APP_GOVERNANCE_CONTRACT_ID_MAIN,
      test: process.env.REACT_APP_GOVERNANCE_CONTRACT_ID_TEST,
    },
  },
  explorer: {
    main: process.env.REACT_APP_EXPLORER_MAIN || 'https://zp.io',
    test: process.env.REACT_APP_EXPLORER_TEST || 'https://testnet.zp.io',
  },
  genesis: {
    kalapas: {
      main: process.env.REACT_APP_GENESIS_TOTAL_KALAPAS_MAIN || '2000000000000000',
      test: process.env.REACT_APP_GENESIS_TOTAL_KALAPAS_TEST || '1',
    },
  },
  cgp: {
    thresholdPercentage: process.env.REACT_APP_CGP_NOMINATION_THRESHOLD_PERCENTAGE || '3',
  },
  contractNamingUrl: process.env.REACT_APP_CONTRACT_NAMING_JSON,
  contractAssetUrl: {
    main: process.env.REACT_APP_ASSET_NAMING_JSON,
    test: process.env.REACT_APP_ASSET_NAMING_TEST_JSON,
  },
  errorReporting: {
    endpoint: process.env.REACT_APP_CONFIG_REPORT_ERRORS_ENDPOINT,
    token: process.env.REACT_APP_CONFIG_REPORT_ERRORS_TOKEN,
    attributes: {
      processType: 'web',
      NODE_ENV: process.env.NODE_ENV,
      zenJsVersion: process.env.REACT_APP_ZENJS_VERSION || 'unknown',
      buildVersion: process.env.REACT_APP_BUILD_VERSION || 'unknown',
      // override for anonymity
      referer: '',
      hostname: '',
    },
  },
};

export default config;
