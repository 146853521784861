import PropTypes from 'prop-types';
import { css, styled } from 'styled-components';
import tu from '../utils/themeUtils';
import Button, { ButtonGroup } from './Button/Button';

const { color, space } = tu;

const activeCss = css`
  background-color: ${color('bg3')};

  color: ${color('text')};

  &:hover {
    color: ${color('text')};
  }

  &:hover,
  &:active {
    background-color: ${color('bg3')};
  }
`;

const StyledChainButton = styled(Button)`
  font-size: inherit;
  font-weight: 400;
  border: solid 1px ${color('borderDark')};
  background-color: ${color('bg2')};
  padding-left: ${space('xs')};
  padding-right: ${space('xs')};
  padding-top: ${space('xxs')};
  padding-bottom: ${space('xxs')};
  color: ${color('label')};

  &:hover {
    color: ${color('label')};
  }

  &:hover,
  &:active {
    background-color: ${color('bg2')};
  }

  &:last-child {
    border-left: none;
  }

  ${(p) => (p.active ? activeCss : undefined)}
`;

const ChainToggle = ({ onClick, chain, ...props }) => {
  const on = chain === 'test';
  return (
    <ButtonGroup onClick={onClick}>
      <StyledChainButton active={!on} {...props}>
        MAIN
      </StyledChainButton>
      <StyledChainButton active={on} {...props}>
        TESTNET
      </StyledChainButton>
    </ButtonGroup>
  );
};
ChainToggle.propTypes = {
  onClick: PropTypes.func,
  chain: PropTypes.oneOf(['main', 'test']),
  theme: PropTypes.object,
};

export default ChainToggle;
