import { styled } from 'styled-components';
import tu from '../utils/themeUtils';
import Box from './Box';

const Row = styled(Box)`
  display: flex;
  ${tu.mq({
    flexDirection: ['column', null, 'row'],
  })}
`;

const Col33 = styled(Box)`
  ${tu.mq({
    width: ['100%', null, '33.333333%'],
  })}

  ${tu.mq({
    marginLeft: [0, null, tu.space('xs')],
    marginRight: [0, null, tu.space('xs')],
    marginBottom: [tu.space('xs'), null, 0],
  })}
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const Col50 = styled(Box)`
  ${tu.mq({
    width: ['100%', null, '50%'],
  })}

  ${tu.mq({
    marginLeft: [0, null, tu.space('xs')],
    marginRight: [0, null, tu.space('xs')],
    marginBottom: [tu.space('xs'), null, 0],
  })}
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const Col100 = styled(Box)`
  ${tu.mq({
    width: ['100%', null, '100%'],
  })}

  ${tu.mq({
    marginLeft: [0, null, tu.space('xs')],
    marginRight: [0, null, tu.space('xs')],
    marginBottom: [tu.space('xs'), null, 0],
  })}
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export { Row, Col33, Col50, Col100 };
