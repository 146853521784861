import { useEffect } from 'react';
import { NotFoundBoundary, useCurrentRoute, useNavigation } from 'react-navi';
import NotFound from './components/NotFound';

/**
 * Handles not found paths - but checks first if the path has index.html in it, redirecting to root.
 * Defaults to a simple not found page
 *
 * @export
 * @param {Object} props
 * @param {*} props.FallbackComponent - the component to display when the route was not found
 */
export default function CustomNotFoundBoundary({ FallbackComponent = NotFound, ...props }) {
  const route = useCurrentRoute();
  const navigation = useNavigation();
  const routeHasIndexHtml = route.url.pathname.indexOf('index.html') >= 0;
  useEffect(() => {
    if (routeHasIndexHtml) {
      navigation.navigate('/');
    }
  });

  // show nothing in case of a redirect
  return routeHasIndexHtml ? null : <NotFoundBoundary render={FallbackComponent} {...props} />;
}
