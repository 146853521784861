import { forwardRef, useImperativeHandle, useRef } from 'react';
import { styled } from 'styled-components';
import { uniqueId } from '@zen/common-utils';
import { FormGroup, InputMessage, Label, LabelText } from '../../Form';
import Input from '../../inputs/Input';
import Box from '../../Box';
import tu from '../../../utils/themeUtils';

/**
 * @typedef {Object} SpendProps
 * @property {string} asset
 * @property {boolean} assetValid
 * @property {Array} assets
 * @property {Function} inputsValid
 * @property {string} amount
 * @property {boolean} amountValid
 * @property {Function} onAmountChange
 * @property {{asset: (string|number)}} balance - the zenjs balance object
 * @property {('default'|'inline'|'grid')} alignment - force alignment, by default change alignment between mobile and
 *   desktop
 * @property {('input'|'multiline'|'expandable')} inputType
 * @property {boolean} readOnly
 * @property {boolean} disabled
 */

/**
 * Show an asset/amount pair
 * @type {React.ForwardRefExoticComponent<SpendProps & React.RefAttributes<any>}
 */
const InputForm = forwardRef(
  (
    {
      value,
      inputValid,
      onChange,
      index,
      alignment = 'default',
      readOnly,
      disabled,
      assetReadOnly = false,
      invalidMessage = 'Invalid address',
    },
    ref
  ) => {
    const id = useRef(uniqueId()); // support multiple Spend components on the same page
    const inputRef = ref;

    // expose the refs
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current && inputRef.current.focus();
      },
      get input() {
        return inputRef.current;
      },
    }));

    return (
      <MainInputContainer alignment={alignment}>
        <InputFormGroup alignment={alignment} ref={inputRef}>
          <Label htmlFor={`amount-${id.current}`}>
            <LabelText>Address / Public Key</LabelText>
          </Label>
          {(readOnly || disabled) && !assetReadOnly ? (
            <Input
              id={`input-${id.current}`}
              value={value || '\u00a0'}
              onChange={() => {}}
              variant="block"
              inputType="expandable"
              readOnly={readOnly}
              disabled={disabled}
              ref={inputRef}
            />
          ) : (
            <StyledInput
              id={`input-${id.current}`}
              autoComplete="off"
              {...(value && { valid: inputValid() })}
              variant="block"
              value={value}
              type="text"
              onChange={(e) => onChange({ index, value: e.target.value.trim() })}
              ref={inputRef}
            />
          )}

          <InputMessage>{!value || inputValid() ? '' : invalidMessage}</InputMessage>
        </InputFormGroup>
      </MainInputContainer>
    );
  }
);
InputForm.displayName = 'InputForm';
export default InputForm;

const MainInputContainer = styled(Box)`
  display: flex;

  ${(p) =>
    tu.mq({
      flexDirection:
        p.alignment === 'default'
          ? ['column', null, 'row']
          : p.alignment === 'inline'
          ? 'row'
          : 'column',
    })}
`;

const InputFormGroup = styled(FormGroup)`
  ${(p) =>
    p.alignment !== 'grid' &&
    tu.mq({
      width: ['auto', null, '90%'],
      maxWidth: ['auto', null, '90%'],
    })}
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;
