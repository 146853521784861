import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Form,
  InputMessage,
  LoadingOr,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  Paragraph,
  PasswordInput,
  Title,
} from '@zen-common/components-base';
import { useResetError, useUnmountedRef } from '@zen/common-react-hooks';

/**
 * A presentational component for the password required modal, contains no logic
 *
 * @param {Object} props
 * @param {Function} props.onSubmit
 * @param {Function} props.onCancel
 * @param {string} props.submitText
 * @param {string} props.cancelText
 * @param {boolean} props.progress
 * @param {string} props.error
 */
export default function PasswordRequiredModalContentBase({
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  progress,
  error,
}) {
  const unmountedRef = useUnmountedRef();
  const inputRef = useRef(null);
  const [password, setPassword] = useState('');
  const submitError = useResetError({ externalError: error, resetDependencies: [password] });

  const showCancel = typeof onCancel === 'function';
  const submitDisabled = !password;
  const passwordInputValidState = submitError !== '' ? false : undefined;

  useEffect(() => {
    // fixes issue when form is in modal
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    !submitDisabled && (await onSubmit(password));
    !unmountedRef.current && inputRef.current && inputRef.current.focus();
  };

  const handleOnChange = (e) => {
    setPassword(e.target.value.trim());
  };

  return (
    <Box>
      <ModalHeader>
        <Title>Password required</Title>
        <Paragraph>Please enter your password</Paragraph>
      </ModalHeader>
      <Form onSubmit={handleSubmit} disabled={progress}>
        <ModalSection>
          <PasswordInput
            id="PasswordRequiredModal-password"
            ref={inputRef}
            variant="block"
            valid={passwordInputValidState}
            value={password}
            onChange={handleOnChange}
            autoComplete="current-password"
            disabled={progress}
          />
          <InputMessage>{submitError}</InputMessage>
        </ModalSection>
        <ModalSeparator />
        <ModalFooter>
          {showCancel && (
            <Button type="button" disabled={progress} onClick={onCancel} bg="secondary">
              {cancelText}
            </Button>
          )}
          <Button type="submit" disabled={submitDisabled || progress}>
            <LoadingOr loading={progress}>{submitText}</LoadingOr>
          </Button>
        </ModalFooter>
      </Form>
    </Box>
  );
}

PasswordRequiredModalContentBase.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  progress: PropTypes.bool,
  error: PropTypes.string,
};
PasswordRequiredModalContentBase.defaultProps = {
  submitText: 'Continue',
  cancelText: 'Cancel',
};
