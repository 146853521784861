import {
  BlockchainInfoStore,
  ConfigStore,
  ErrorReportingStore,
  NamingJsonStore,
  StorageUpdaterStore,
} from '@zen/common-app-parts';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import ActivateStore from '../../stores/ActivateStore';
import ExtendStore from '../../stores/ExtendStore';
import WalletProvider from './components/WalletProvider';
import SettingsStoreProvider from './components/SettingsStoreProvider';
import SendExecuteProvider from './components/SendExecuteProvider';
import ExecuteContractProvider from './components/ExecuteContractProvider';
import ChangesProvider from './components/ChangesProvider';
import NamingAssetStoreProvider from './components/NamingAssetStoreProvider';

export default function StoreProviders({ storage, config, children }) {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <NamingJsonStore.Provider url={config.contractNamingUrl}>
        <ConfigStore.Provider config={config}>
          <StorageUpdaterStore.Provider storage={storage}>
            <ErrorReportingStore.Provider config={config}>
              <SettingsStoreProvider storage={storage} config={config}>
                <NamingAssetStoreProvider config={config}>
                  <BlockchainInfoStore.Provider>
                    <WalletProvider storage={storage}>
                      <ChangesProvider>
                        <ExecuteContractProvider>
                          <SendExecuteProvider>
                            <ActivateStore.Provider>
                              <ExtendStore.Provider>{children}</ExtendStore.Provider>
                            </ActivateStore.Provider>
                          </SendExecuteProvider>
                        </ExecuteContractProvider>
                      </ChangesProvider>
                    </WalletProvider>
                  </BlockchainInfoStore.Provider>
                </NamingAssetStoreProvider>
              </SettingsStoreProvider>
            </ErrorReportingStore.Provider>
          </StorageUpdaterStore.Provider>
        </ConfigStore.Provider>
      </NamingJsonStore.Provider>
    </StyleSheetManager>
  );
}

function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
