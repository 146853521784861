/*
 *  This submit button is used in order to be able to submit
 *  a form by pressing enter where there's no need for the button
 *  to be visually visible or in cases where the actual submit or
 *  action button isn't nested inside the form.
 */

export default function HiddenSubmitButton() {
  const styles = {
    position: 'absolute',
    left: '-9999px',
    width: '1px',
    height: '1px',
    tabindex: '-1',
  };

  return <button type="submit" style={styles} />;
}
