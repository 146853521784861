import axios from 'axios';
import { pathOr } from 'ramda';
import { HttpError } from '@zen/common-utils';

axios.defaults.timeout = 30000;

/**
 * The explorer API service
 *
 * @param {Object} params
 * @param {('test'|'main')} params.chain - the current chain
 * @param {Object} params.config - the config object
 */
export function ExplorerService({ chain, config } = {}) {
  const explorer = formatUrl(
    chain === 'test'
      ? pathOr('https://testnet.zp.io', ['explorer', 'test'], config)
      : pathOr('https://zp.io', ['explorer', 'main'], config)
  );

  return {
    cgp: {
      getNominees(interval) {
        return sendHttpRequest({
          url: `${explorer}/api/cgp/ballots/nomination?interval=${interval}`,
          method: 'get',
        });
      },
      /**
       *
       * @param {number} interval
       * @param {('nomination'|'allocation'|'payout')} type
       */
      getResults({ interval, type, page, pageSize } = {}) {
        return sendHttpRequest({
          url: `${explorer}/api/cgp/results/${type}`,
          method: 'get',
          params: {
            interval,
            page,
            pageSize,
          },
        });
      },
      getPayoutBallots(interval) {
        return sendHttpRequest({
          url: `${explorer}/api/cgp/ballots/payout?interval=${interval}`,
          method: 'get',
        });
      },
    },
    governance: {
      getCurrentNextOrPrevInterval() {
        return sendHttpRequest({
          url: `${explorer}/api/votes/current-next-or-prev`,
          method: 'get',
        });
      },
      /**
       * Get a specific phase
       * @param {Object} params
       * @param {number} params.interval
       * @param {TGovernancePhase} params.phase
       */
      getPhase({ interval, phase } = {}) {
        return sendHttpRequest({
          url: `${explorer}/api/votes/interval`,
          method: 'get',
          params: {
            interval,
            phase,
          },
        });
      },
      /**
       * Get vote results
       * @param {Object} params
       * @param {number} params.interval
       * @param {TGovernancePhase} params.phase
       * @param {number} params.page
       * @param {number} params.pageSize
       */
      getResults({ interval, phase, page, pageSize } = {}) {
        return sendHttpRequest({
          url: `${explorer}/api/votes/results`,
          method: 'get',
          params: {
            interval,
            phase,
            page,
            pageSize,
          },
        });
      },
      /**
       * Get the winning contestants
       * @param {Object} params
       * @param {number} params.interval
       */
      getCandidates({ interval } = {}) {
        return sendHttpRequest({
          url: `${explorer}/api/votes/candidates`,
          method: 'get',
          params: {
            interval,
          },
        });
      },
    },
  };
}

function sendHttpRequest(config) {
  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      // eslint-disable-next-line import/no-named-as-default-member
      if (axios.isCancel(error)) {
        throw error;
      } else {
        throw new HttpError(error);
      }
    });
}

function formatUrl(url) {
  return url.endsWith('/') ? url.substring(0, url.length - 1) : url;
}

/**
 * @typedef {('Contestant'|'Candidate')} TGovernancePhase
 */
