import { css, styled } from 'styled-components';
import { space } from 'styled-system';
import tu from '../utils/themeUtils';

const listCss = css`
  line-height: ${tu.lineHeight(6)};
`;

export const OrderedList = styled.ol`
  ${listCss}
  ${space}
`;
export const UnorderedList = styled.ul`
  ${listCss}
  ${space}
`;
export const ListItem = styled.li`
  ${space}
`;
