import { useEffect } from 'react';
import {
  InfoBox,
  Input,
  InputAddonNumberControls,
  InputMessage,
  Label,
  LabelText,
  useUpDownNumberControl,
} from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';

/**
 * Display zp balance with a title
 * @param {Object} props
 * @param {string} props.account - the account number
 * @param {string} props.accountValue - the clean version of this number
 * @param {Function} props.onAccountChange - function to change the input event target
 * @param {Function} props.setAccountChange - function to change the account directly
 * @param {boolean} props.loading - loading value
 * @param {string} props.id - id for styled component
 */
export default function AccountIndex({
  account,
  accountValue,
  onAccountChange,
  setAccountChange,
  loading,
  id,
}) {
  useEffect(() => {
    if (account === '') setAccountChange(0);
  }, [account, setAccountChange]);

  const { onKeyDown: onAmountKeyDown } = useUpDownNumberControl({
    min: 0,
    max: numberUtils.getMaxAccount(),
    value: accountValue,
    onChange: setAccountChange,
  });
  return (
    <>
      <InfoBox
        info="
                    This level splits the key space into independent user identities, so the wallet
                    never mixes the coins across different accounts. Users can use these accounts to
                    organize the funds in the same fashion as bank accounts"
      >
        <Label htmlFor={`${id}-Account-Index`}>
          <LabelText>Choose Account Index</LabelText>
        </Label>
      </InfoBox>
      <Input
        id={id}
        variant="block"
        value={accountValue}
        type="text"
        onChange={onAccountChange}
        disabled={loading}
        onKeyDown={onAmountKeyDown}
        renderRightAddon={
          <>
            <InputAddonNumberControls
              onChange={setAccountChange}
              value={accountValue}
              max={numberUtils.getMaxAccount()}
              min="0"
            />
          </>
        }
      />
      <InputMessage></InputMessage>
    </>
  );
}
