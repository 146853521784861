/* eslint-disable  */
import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import hljs from 'highlight.js/lib/core';
import fsharp from 'highlight.js/lib/languages/fsharp';
import yaml from 'highlight.js/lib/languages/yaml';
import tu from '../../utils/themeUtils';
import { getTransition } from '../../utils/styles/mixins';
import Loading from '../Loading';
import Box from '../Box';

hljs.registerLanguage('fsharp', fsharp);
hljs.registerLanguage('yaml', yaml);

/**
 * Highlights and formats code
 * @param {Object} props
 * @param {string} props.lang - the code language
 * @param {string} props.code - the code to highlight
 */
export default function Code({ lang = 'fsharp', code = '' }) {
  const [loaded, setLoaded] = useState(false);
  const elRef = useRef();

  useEffect(() => {
    // setTimeout: remove this processing from the current render so it will not affect performance
    setTimeout(() => {
      if (elRef.current) {
        elRef.current.querySelectorAll('pre code').forEach((block) => hljs.highlightBlock(block));
        setLoaded(true);
      }
    }, 0);
  }, [code]);

  return (
    <Box position="relative">
      {!loaded && <StyledLoading />}
      <StyledPre ref={elRef} loaded={loaded}>
        <code className={lang}>{code.trim()}</code>
      </StyledPre>
    </Box>
  );
}

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;

/*
 Monokai Sublime style. Derived from Monokai by noformnocontent http://nn.mit-license.org/
 */
const StyledPre = styled.pre`
  ${getTransition()}
  opacity: ${(p) => (p.loaded ? '1' : '0')};
  display: block;
  white-space: pre-wrap;
  line-height: ${tu.lineHeight(3)};
  margin: 0;

  code {
    font-family: 'Montserrat', monospace;
  }

  .hljs {
    display: block;
    background: transparent;
    color: #cccccc;
    word-break: break-all;
  }

  .hljs-tag,
  .hljs-subst {
    color: #cccccc;
  }

  .hljs-strong,
  .hljs-emphasis {
    color: #a8a8a2;
  }

  .hljs-bullet,
  .hljs-quote,
  .hljs-number,
  .hljs-regexp,
  .hljs-literal,
  .hljs-link {
    color: ${tu.color('primary')};
  }

  .hljs-code,
  .hljs-title,
  .hljs-section,
  .hljs-selector-class {
    color: #a6e22e;
  }

  .hljs-strong {
    font-weight: 600;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-name,
  .hljs-attr {
    color: #e83e8c;
  }

  .hljs-symbol,
  .hljs-attribute {
    color: #66d9ef;
  }

  .hljs-params,
  .hljs-class .hljs-title {
    color: #cccccc;
  }

  .hljs-string,
  .hljs-type,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-selector-id,
  .hljs-selector-attr,
  .hljs-selector-pseudo,
  .hljs-addition,
  .hljs-variable,
  .hljs-template-variable {
    color: #ffc107;
  }

  .hljs-comment,
  .hljs-deletion,
  .hljs-meta {
    color: #75715e;
  }
`;
