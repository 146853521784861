import { useRef, useState } from 'react';
import { ConfirmationModalContent, Title, useFileDropZone } from '@zen-common/components-base';
import { ConfirmPublishTxModalContent, WalletStore } from '@zen/common-app-parts';
import { ValueDisplay } from '@zen/common-utils';
import { useUnmountedRef } from '@zen/common-react-hooks';
import { clipboard } from '@zen/common-utils-browser';
import usePreventAction from '../../../../utils/usePreventAction';
import ActivateStore from '../../../../stores/ActivateStore';
import * as actions from '../../../../stores/ActivateStore/actions';
import ActivateFormModalContent from '../ActivateContractModalProcess/ActivateFormModalContent';

export default function ActivateContractModalProcess({ onCancel }) {
  const store = ActivateStore.useStore();
  const unmounted = useUnmountedRef();
  const prevStatus = useRef();
  const [status, setStatus] = useState('form');
  /** @type {[numberOfBlocks: ValueDisplay]} */
  const [numberOfBlocks, setNumberOfBlocks] = useState(ValueDisplay.create());
  /** @type {[rLimit: ValueDisplay]} */
  const [rLimit, setRLimit] = useState(ValueDisplay.create('2723280'));
  const [error, setError] = useState('');
  const unsignedTx = useRef();
  const { preventAction } = usePreventAction();
  const wallet = WalletStore.useStore();

  const fileDropZoneProps = useFileDropZone({ accept: '.fst' });

  const setStatusAndPrev = (s) => {
    prevStatus.current = status;
    setStatus(s);
  };

  const handleNumberOfBlocksChange = (display) =>
    setNumberOfBlocks(numberOfBlocks.onDisplayChange(display));
  const handleRLimitChange = (display) => setRLimit(rLimit.onDisplayChange(display));

  const handleFormCancel = () => {
    onCancel();
  };
  const handleFormContinue = () => {
    if (!fileDropZoneProps.acceptedFiles.length) return;
    if (preventAction()) return;

    const reader = new FileReader();
    reader.onerror = () => {
      setError('There was an error reading the file content');
      setStatusAndPrev('error');
    };
    reader.onload = async () => {
      const code = reader.result;
      try {
        unsignedTx.current = await actions.activate({
          dispatch: store.dispatch,
          code,
          limit: Number(rLimit.safeValue),
          numberOfBlocks: Number(numberOfBlocks.safeValue),
          wallet,
        });
        if (!unmounted.current) {
          setStatusAndPrev('confirm-after-sign');
        }
      } catch (error) {
        if (!unmounted.current) {
          setError(error.message);
          setStatusAndPrev('error');
        }
      }
    };
    reader.readAsText(fileDropZoneProps.acceptedFiles[0]);
  };

  const handleCopy = async () => {
    try {
      await clipboard.writeText(unsignedTx.current);
    } catch (error) {
      //ignore error
    }
  };

  const handleConfirmAfterSignBack = () => {
    unsignedTx.current = null;
    setStatusAndPrev('form');
  };
  const handleConfirmAfterSignContinue = async () => {
    if (preventAction()) return;
    await handleCopy();
    onCancel();
  };

  const handleErrorBack = () => {
    setStatus(prevStatus.current);
  };
  const handleErrorClose = () => {
    onCancel();
  };

  if (status === 'form') {
    return (
      <ActivateFormModalContent
        progress={store.state.progress}
        fileDropZoneProps={fileDropZoneProps}
        numberOfBlocks={numberOfBlocks}
        onNumberOfBlocksChange={handleNumberOfBlocksChange}
        rLimit={rLimit}
        onRLimitChange={handleRLimitChange}
        onCancel={handleFormCancel}
        onContinue={handleFormContinue}
        title="Raw Activate"
      />
    );
  }

  if (status === 'confirm-after-sign') {
    return (
      <ConfirmPublishTxModalContent
        progress={store.state.progress}
        title="Copy your unsigned TX"
        confirmText="Copy"
        tx={unsignedTx.current}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
      />
    );
  }

  if (status === 'error') {
    return (
      <ConfirmationModalContent
        title={<Title>An error has occurred</Title>}
        cancelText="Back"
        confirmText="Close"
        onCancel={handleErrorBack}
        onConfirm={handleErrorClose}
      >
        {error}
      </ConfirmationModalContent>
    );
  }

  return null;
}
