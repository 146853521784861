import PropTypes from 'prop-types';
import { assetUtils } from '@zen/common-utils';
import Copyable from '../Copy/Copyable';
import Asset from './Asset';

/**
 * Show an asset and a copy button
 *
 * @param {Object} props
 * @param {string} props.hash - the hash to show
 * @param {boolean} props.truncate - whether to truncate the hash
 * @param {boolean} props.copy - whether to display a copy button
 * @param {('all'|'touch'|'none')} props.forceShowCopy - force showing the copy button on different states
 */
export default function CopyableAsset({
  hash,
  copy = true,
  forceShowCopy,
  truncate = true,
  naming,
  ...props
}) {
  const displayName =
    naming && naming[hash]
      ? naming[hash].shortName
      : truncate
      ? assetUtils.truncateOrNameAsset(hash)
      : hash;
  const assetComponent = <Asset hash={hash} displayName={displayName} {...props} />;
  return copy ? (
    <Copyable value={hash} forceShowCopy={forceShowCopy}>
      {assetComponent}
    </Copyable>
  ) : (
    assetComponent
  );
}

CopyableAsset.propTypes = {
  hash: PropTypes.string.isRequired,
  copy: PropTypes.bool,
  truncate: PropTypes.bool,
  forceShowCopy: PropTypes.string,
};
