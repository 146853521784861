import { css, styled } from 'styled-components';
import { Box, themeUtils as tu } from '@zen-common/components-base';

/**
 * Use this to apply style for text blocks, pass the text as children
 */
export default function TextContainer(props) {
  return <Wrapper {...props} />;
}

const Wrapper = styled(Box)`
  color: ${tu.color('text')};
  text-align: justify;
  ${css(
    tu.mq({
      fontSize: ['11px', null, '14px'],
      lineHeight: ['18px', null, '20px'],
      letterSpacing: ['0.27px', null, '0.32px'],
    })
  )}
`;
