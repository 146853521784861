import { styled } from 'styled-components';
import { useCurrentRoute } from 'react-navi';
import { Box, cssMixins, OfflineBar, WatchModeBar } from '@zen-common/components-base';
import { useOfflineStatus } from '@zen/common-react-hooks';
import { SettingsStore, WalletStore } from '@zen/common-app-parts';
import PATHS from '../../paths';
import MainNav from '../navs/MainNav';
import StatusBar from '../StatusBar/StatusBar';
import TestnetBar from '../TestnetBar/TestnetBar';

export default function MainLayout({ children }) {
  return (
    <PageAlignment>
      <PageItem>
        <OfflineContainer />
      </PageItem>
      <PageItem>
        <MainNav />
      </PageItem>
      <PageItem css={cssMixins.showOnMobile()}>
        <TestnetBarContainer />
        <WatchModeBarContainer />
      </PageItem>
      <MainContentWrapper>
        {children}
        <MobileStatusBarDisplay>
          <PageItem css={cssMixins.showOnMobile()}>
            <StatusBarContainer />
          </PageItem>
        </MobileStatusBarDisplay>
      </MainContentWrapper>
      <PageItem css={cssMixins.showOnDesktop()}>
        <TestnetBarContainer />
        <WatchModeBarContainer />
      </PageItem>
      <PageItem css={cssMixins.showOnDesktop()}>
        <StatusBarContainer />
      </PageItem>
    </PageAlignment>
  );
}

const PageAlignment = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PageItem = styled(Box)`
  flex-grow: 0;
`;

const MainContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  height: 0px; /* Safari fix for onboarding, container must have a height set */
`;

const OfflineContainer = () => {
  const { offline } = useOfflineStatus();

  return offline ? <OfflineBar /> : null;
};

const TestnetBarContainer = () => {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();

  return chain === 'test' ? <TestnetBar /> : null;
};

const WatchModeBarContainer = () => {
  const {
    state: {
      currentWalletInfo: { isWatchMode },
    },
  } = WalletStore.useStore();

  return isWatchMode ? <WatchModeBar /> : null;
};

const StatusBarContainer = () => {
  const {
    url: { pathname },
  } = useCurrentRoute();
  const isOnBoarding = pathname === PATHS.ONBOARDING;

  return !isOnBoarding ? <StatusBar /> : null;
};

const MobileStatusBarDisplay = ({ children }) => {
  const {
    url: { pathname },
  } = useCurrentRoute();
  if (pathname === PATHS.SETTINGS_CONNECTIVITY) {
    return children;
  }

  return null;
};
