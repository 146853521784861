import { StyledTooltipArrow, StyledTooltipText } from './Tooltip.styled';

/**
 * A Tooltip component to be used with the useTooltip hook
 */
const Tooltip = ({
  setPopperElement,
  setArrowElement,
  styles,
  attributes,
  pinned,
  visible,
  children,
  ...props
}) => {
  return (
    <StyledTooltipText
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      data-show={pinned || visible}
      {...props}
    >
      {children}
      <StyledTooltipArrow className="arrow" ref={setArrowElement} style={styles.arrow} />
    </StyledTooltipText>
  );
};

StyledTooltipText.defaultProps = {
  p: 4,
  bg: 'bg4',
  fontSize: 'sm',
  fontWeight: 300,
  color: 'text',
};

export default Tooltip;
