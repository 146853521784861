import { useEffect } from 'react';
import { Alert, Box, CopyableHash, notification, useModal } from '@zen-common/components-base';
import WalletStore from '../../stores/WalletStore';
import * as TD from '../TechnicalData/TechnicalData';

/**
 * Show result of a contract execution
 * @param {Object} params
 * @param {import('../../stores/ExecuteContractStore/types').TExecuteContractStore} params.executeStore
 */
export default function ExecuteContractNotifications({ executeStore }) {
  const {
    state: { result },
    actions: { runStarted },
  } = executeStore;
  const { show, hide } = useModal();
  const {
    state: { executing },
  } = WalletStore.useStore();
  useEffect(() => {
    if (result.type === 'success') {
      notification('Contract was executed successfully', { type: notification.TYPE.SUCCESS });
    } else if (result.type === 'error-execute') {
      show(() => (
        <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error Executing">
          Couldn&apos;t execute the contract with the parameters you entered.
          <br />
          Error message: {result.message}
        </Alert>
      ));
      runStarted();
    } else if (result.type === 'error-sign') {
      if (!executing) {
        show(() => (
          <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error Signing">
            Couldn&apos;t sign the TX.
            <br />
            Error message: {result.message}
          </Alert>
        ));
      }
    } else if (result.type === 'error-publish') {
      if (!executing) {
        show(() => (
          <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error Publishing">
            Couldn&apos;t publish the transaction
            <br />
            Error message: {result.message}
            {result.data.signedTx ? (
              <Box>
                <TD.Label>Transaction:</TD.Label>
                <TD.Value>
                  <CopyableHash hash={result.data.signedTx} />
                </TD.Value>
              </Box>
            ) : null}
          </Alert>
        ));
      }
    }
  }, [executing, hide, result.data.signedTx, result.message, result.type, show, runStarted]);

  return null;
}
