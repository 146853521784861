import { styled } from 'styled-components';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import Box from '../../Box';
import FaSvg from '../../helpers/FaSvg';
import tu from '../../../utils/themeUtils';

const StyledBox = styled(Box)`
  opacity: 1;
  position: absolute;
  top: ${tu.space(1)};
  right: ${tu.space(1)};

  color: ${tu.color('label')};

  &:hover {
    color: ${tu.color('text')};
  }
`;

const CloseButton = ({ closeToast }) => {
  return (
    <StyledBox px="xxs">
      <FaSvg icon={faTimes} size="1em" color="inherit" onClick={closeToast} />
    </StyledBox>
  );
};

export default CloseButton;
