import { styled } from 'styled-components';
import { Box, themeUtils as tu } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';

/**
 * Display zp balance with a title
 * @param {Object} props
 * @param {Object} props.balance - the zenjs balance object
 */
export default function BalanceBox({ balance, ...props }) {
  return (
    <BalanceBoxContainer {...props}>
      <TitleWrapper>Total ZP Balance</TitleWrapper>
      <BalanceWrapper>{numberUtils.toDisplay(balance['00'] || 0)}</BalanceWrapper>
    </BalanceBoxContainer>
  );
}

const BalanceBoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-transform: initial;
`;
const TitleWrapper = styled(Box)`
  color: ${tu.color('label')};
  margin-bottom: ${tu.space('xxs')};
  font-size: ${tu.fontSize('xs')};
`;
const BalanceWrapper = styled(Box)`
  color: ${tu.color('text')};
  font-size: ${tu.fontSize('md')};
`;
