import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { color, compose, layout, space, typography } from 'styled-system';
import { forwardRef } from 'react';
import { getTransition } from '../../utils/styles/mixins';
import tu from '../../utils/themeUtils';
import FaSvg from '../helpers/FaSvg';
import resetButton from '../../utils/styles/mixins/resetButton';
import getStateColors from './getStateColors';

const StyledButton = styled.button`
  ${resetButton}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: ${tu.space('xs')};
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${getTransition()} @media(hover: hover) {
    &:hover {
      background-color: ${tu.color('bg5')};

      svg {
        color: ${tu.color('text')};
      }
    }
  }
  @media (hover: none) {
    &:active {
      background-color: ${tu.color('bg5')};

      svg {
        color: ${tu.color('text')};
      }
    }
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  ${compose(color, space, typography)}
  ${(props) =>
    !props.static &&
    getStateColors({ property: 'color', value: tu.color(props.color)(props), props })}
  svg {
    ${layout}
  }
`;

StyledButton.defaultProps = {
  color: 'label',
  static: false,
  type: 'button',
};

/**
 * @type {React.ForwardRefExoticComponent<IconButtonExtraProps & React.ButtonHTMLAttributes & React.RefAttributes<any>}
 */
const IconButton = forwardRef(({ icon, ...props }, ref) => (
  <StyledButton ref={ref} {...props}>
    <FaSvg icon={icon} />
  </StyledButton>
));
IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  icon: PropTypes.object.isRequired,
  static: PropTypes.bool,
};

export default IconButton;

/**
 * @type {Object} IconButtonExtraProps
 * @property {string} icon
 * @property {bool} [static] - prevent changing colors on different states
 */
