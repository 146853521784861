/* eslint-disable react/display-name */
import { memo, useMemo } from 'react';
import { assetUtils } from '@zen/common-utils';
import {
  Copyable,
  CopyableAsset,
  Hash,
  PaginationSize,
  Table,
  tableFilterCss,
  TableFiltersContainer,
  themeUtils,
  usePagination,
} from '@zen-common/components-base';
import * as ZenJsUtils from '../../utils/zenJsUtils';
import ExplorerLink from '../ExplorerLink';
import StorageUpdaterStore from '../../stores/StorageUpdaterStore';

const STORAGE_KEY = 'balances-table-page-size';

/**
 * Display a balance table
 *
 * @param {Object} props
 * @param {Array} props.balances - the wallet balances array
 * @param {Array} props.chain - the chain
 */
function Balance({ balances, chain }) {
  const storage = StorageUpdaterStore.useStorage();
  const isMobile = document.body.clientWidth < themeUtils.breakpoints.xl.replace('px', '');
  const columns = useMemo(() => getColumns(chain, isMobile), [chain, isMobile]);

  const pagination = usePagination({
    manual: true,
    itemsCount: balances.length,
    initialPageSize: storage.get(STORAGE_KEY) || undefined, // prevent passing null
  });

  const paginatedData = useMemo(() => {
    const start = pagination.page * pagination.pageSize;
    return balances.slice(start, start + pagination.pageSize);
  }, [balances, pagination.page, pagination.pageSize]);

  // remember selected page size
  StorageUpdaterStore.useUpdater([
    {
      key: STORAGE_KEY,
      value: pagination.pageSize,
    },
  ]);

  return (
    <>
      <TableFiltersContainer>
        <PaginationSize {...pagination} css={tableFilterCss} width={['100%', 'auto']} />
      </TableFiltersContainer>
      <Table
        data={paginatedData}
        columns={columns}
        resizable={false}
        sortable={false}
        minRows={0}
        {...pagination}
      />
    </>
  );
}

export default memo(Balance); // do not change if balances stayed the same

const getColumns = (chain, isMobile) => [
  {
    Header: 'Asset',
    accessor: '',
    minWidth: 300,
    maxWidth: 350,
    Cell: ({ value }) => <CopyableAsset hash={value.asset} displayName={value.metadata.name} />,
  },
  {
    Header: 'By Contract',
    accessor: '',
    minWidth: 100,
    maxWidth: 250,
    Cell: ({ value }) =>
      !assetUtils.isZP(value.asset) ? (
        <Copyable value={ZenJsUtils.getContractAddressFromAsset(chain, value.asset)}>
          <ExplorerLink
            path={`/contracts/${ZenJsUtils.getContractAddressFromAsset(chain, value.asset)}`}
          >
            <Hash hash={value.metadataContract.name} display={value.metadataContract.shortName} />
          </ExplorerLink>
        </Copyable>
      ) : null,
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    minWidth: 160,
    className: isMobile ? 'align-right' : '',
    headerClassName: isMobile ? 'align-right' : '',
    Cell: (data) => assetUtils.getAmountDisplay(data.original.asset, data.value),
  },
];
