import { NodeConnectivitySetting, TitleContentLayout } from '@zen/common-app-parts';
import { useNodeConnectivitySetting } from './settingsHooks';

const ConnectivitySettingsPage = () => {
  return (
    <TitleContentLayout dark title="Node Connectivity Settings">
      <NodeConnectivitySetting useSetting={useNodeConnectivitySetting} showWallet />
    </TitleContentLayout>
  );
};

export default ConnectivitySettingsPage;
