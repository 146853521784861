import { usePaginatedQuery } from 'react-query';
import {
  AmountDisplay,
  Copyable,
  Hash,
  PaginationSize,
  Pending,
  Table,
  tableFilterCss,
  TableFiltersContainer,
  usePagination,
} from '@zen-common/components-base';
import { assetUtils, numberUtils } from '@zen/common-utils';
import {
  ApiQueries,
  BlockchainInfoStore,
  ExplorerLink,
  NamingAssetStore,
  StorageUpdaterStore,
  TitleContentLayout,
  WalletStore,
} from '@zen/common-app-parts';
import { useLoadingOnUserGesture } from '@zen/common-react-hooks';
import { getDateStringFromTimestamp } from '@zen/common-utils/lib/utils/textUtils';

const STORAGE_KEY = 'tx-history-table-page-size';
const POLL_TIME = 5000;

export default function TxHistory() {
  const storage = StorageUpdaterStore.useStorage();
  const {
    state: {
      infos: { headers },
    },
  } = BlockchainInfoStore.useStore();
  const {
    actions: { fetchTransactions },
  } = WalletStore.useStore();

  const txCountResult = ApiQueries.useFetchTxCount({ enabled: true, refetchInterval: POLL_TIME });

  const pagination = usePagination({
    manual: true,
    itemsCount: txCountResult.data || 0,
    initialPageSize: storage.get(STORAGE_KEY) || undefined, // prevent passing null
  });

  const { resolvedData, latestData, isFetching } = usePaginatedQuery(
    [
      'tx-history',
      { skip: pagination.page * pagination.pageSize, take: pagination.pageSize },
      txCountResult.data, // refetch if count is changing
      headers, // refetch when blocks are added in case of pending txs
    ],
    (_, params) => fetchTransactions(params),
    { enabled: txCountResult.data }
  );

  const { state: naming } = NamingAssetStore.useStore();

  const txData = (resolvedData || []).map((data) => ({
    ...data,
    metadata: naming[data.asset] ? naming[data.asset] : { name: '', shortName: '' },
  }));

  const loading = useLoadingOnUserGesture({
    isFetching,
    latestData,
    page: pagination.page,
    pageSize: pagination.pageSize,
  });

  // remember selected page size
  StorageUpdaterStore.useUpdater([
    {
      key: STORAGE_KEY,
      value: pagination.pageSize,
    },
  ]);

  return (
    <TitleContentLayout noPadding dark position="relative" title="Tx History">
      <TableFiltersContainer>
        <PaginationSize {...pagination} css={tableFilterCss} width={['100%', 'auto']} />
      </TableFiltersContainer>
      <Table
        data={txData}
        loading={loading}
        columns={tableColumns}
        resizable={false}
        sortable={false}
        minRows={0}
        {...pagination}
      />
    </TitleContentLayout>
  );
}

const BlockByConfirmations = ({ confirmations }) => {
  const {
    state: {
      infos: { headers },
    },
  } = BlockchainInfoStore.useStore();

  return numberUtils.toDisplay((headers || 0) - confirmations + 1);
};

const tableColumns = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    minWidth: 200,
    Cell: ({ value }) => getDateStringFromTimestamp(value),
  },
  {
    Header: 'Block',
    accessor: 'confirmations',
    minWidth: 80,
    Cell: ({ value }) =>
      Number(value) === 0 ? <Pending /> : <BlockByConfirmations confirmations={value} />,
  },
  {
    Header: 'Asset',
    accessor: '',
    minWidth: 200,
    Cell: ({ value }) => (
      <Copyable value={value.asset}>
        <ExplorerLink path={`/assets/${value.asset}`}>
          <Hash
            hash={value.asset}
            display={value.metadata.shortName || assetUtils.truncateOrNameAsset(value.asset)}
          />
        </ExplorerLink>
      </Copyable>
    ),
  },
  {
    Header: 'Tx Hash',
    accessor: 'txHash',
    minWidth: 150,
    Cell: ({ value }) => (
      <Copyable value={value}>
        <ExplorerLink path={`/tx/${value}`}>
          <Hash hash={value} />
        </ExplorerLink>
      </Copyable>
    ),
  },
  {
    Header: 'Confirmations',
    accessor: 'confirmations',
    minWidth: 120,
    Cell: ({ value }) => (Number(value) === 0 ? <Pending /> : numberUtils.toDisplay(value)),
  },
  {
    Header: 'Amount',
    accessor: '',
    minWidth: 140,
    className: 'align-right',
    headerClassName: 'align-right',
    Cell: ({ original }) => (
      <AmountDisplay
        amount={original.amount}
        display={assetUtils.getAmountDisplay(original.asset, original.amount)}
      />
    ),
  },
];
