import { styled } from 'styled-components';
import { Box, Copyable, Hash, themeUtils as tu } from '@zen-common/components-base';
import { textUtils } from '@zen/common-utils';
import { Link } from 'react-navi';

/**
 * Display zp balance with a title
 * @param {Object} props
 * @param {string} props.address - the zenjs balance object
 * @param {string} props.path - the zenjs balance object
 */
export default function AddressBox({ address = '', path = '/app/wallet/receive', ...props }) {
  const value = textUtils.truncateHash(address);
  return (
    <AddressBoxContainer {...props}>
      <TitleWrapper>Address</TitleWrapper>
      <Copyable value={address} forceShowCopy="all">
        <Link href={path}>
          <StyledLabelText hash={value} />
        </Link>
      </Copyable>
    </AddressBoxContainer>
  );
}

const AddressBoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-transform: initial;
`;
const TitleWrapper = styled(Box)`
  color: ${tu.color('label')};
  margin-bottom: ${tu.space('xxs')};
  font-size: ${tu.fontSize('xs')};
`;
const StyledLabelText = styled(Hash)`
  color: ${tu.color('text')};
  font-size: ${tu.fontSize('md')};
`;
