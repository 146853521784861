import { Box, ConfirmationModalContent, CopyableHash, Title } from '@zen-common/components-base';
import * as TD from '../TechnicalData/TechnicalData';
import { SpendsTechnicalData, useSpendsTechnicalData } from '../Spend';

/**
 * A modal content for the send process, to be used in a <Modal></Modal>
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.address
 * @param {[import('./spendUtils').Spend]} props.spends
 * @param {() => void} props.onCancel
 * @param {() => void} props.onConfirm
 */
export default function ConfirmSendModalContent({ title, address, spends, onCancel, onConfirm }) {
  return (
    <ConfirmationModalContent
      title={<Title>{title}</Title>}
      confirmText="Continue"
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <SendSection address={address} spends={spends} />
    </ConfirmationModalContent>
  );
}

function SendSection({ address, spends }) {
  const spendsTD = useSpendsTechnicalData({ spends });
  const showMore =
    spends.length > 3 ? (
      <TD.ShowMoreLink isMore={spendsTD.isMore} onClick={spendsTD.toggleMore} />
    ) : null;
  return (
    <TD.Section>
      <Box>
        <TD.Title>Send</TD.Title>
        {showMore}
      </Box>
      <Box>
        <TD.Label>Destination address:</TD.Label>
        <TD.Value>
          <CopyableHash hash={address} />
        </TD.Value>
      </Box>
      <SpendsTechnicalData spends={spendsTD.spends} />
    </TD.Section>
  );
}
