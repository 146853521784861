import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { textUtils } from '@zen/common-utils';
import tu from '../../utils/themeUtils';
import useTooltip from '../Tooltip/useTooltip';
import Tooltip from '../Tooltip/Tooltip';
import Box from '../Box';

/**
 * Hash - show a hash and truncate it
 *
 * @export
 * @param {Object} props
 * @param {string} props.hash - the hash to show
 * @param {boolean} props.truncate - whether to truncate, true by default
 * @param {string} props.display - a display string to show instead of the hash
 */
export default function Hash({ hash, truncate = true, display, ...props }) {
  const { hide, showAndUpdate, ref, tooltipProps, visible } = useTooltip({ placement: 'top' });
  const truncated = textUtils.truncateHash(hash);
  const title = (truncate && truncated !== hash) || display ? hash : null;

  return (
    <Box {...props}>
      <HashWrapper ref={ref} onMouseEnter={showAndUpdate} onMouseLeave={hide}>
        {display ? display : truncate ? truncated : hash}
      </HashWrapper>
      {title && visible && <StyledTooltip {...tooltipProps}>{title}</StyledTooltip>}
    </Box>
  );
}

Hash.propTypes = {
  hash: PropTypes.string.isRequired,
  truncate: PropTypes.bool,
  display: PropTypes.string,
};

const StyledTooltip = styled(Tooltip)`
  word-wrap: break-word;
  white-space: normal;
  max-width: 360px;
  line-height: ${tu.lineHeight(2)};
`;

const HashWrapper = styled.span`
  display: inline-block;
`;
