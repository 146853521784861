import PropTypes from 'prop-types';
import { useModal } from '@zen-common/components-base';
import { useNavigation } from 'react-navi';
import SettingLayout from '../containers/SettingLayout';
import SettingButton from '../common/SettingButton';
import { LogoutModal } from './components';

export const actions = {
  logout: 'LOGOUT',
};
const UNLOCK_ROUTE = '/unlock';
const LogoutSetting = ({ useSetting, ...props }) => {
  const { show, hide } = useModal();
  const { onAction } = useSetting();
  const { navigate } = useNavigation();

  const handleLogoutConfirm = () => {
    if (typeof onAction === 'function') {
      onAction({ type: actions.logout });
      navigate(UNLOCK_ROUTE);
    }
  };

  const handleLogoutClick = () => {
    show(<LogoutModal onCancel={hide} onConfirm={handleLogoutConfirm} />);
  };

  return (
    <SettingLayout
      header="Logout"
      paragraph="Logout from this session"
      data-testid="LogoutSetting-container"
      {...props}
    >
      <SettingButton onClick={handleLogoutClick} data-testid="LogoutSetting-button-logout">
        Logout
      </SettingButton>
    </SettingLayout>
  );
};

LogoutSetting.propTypes = {
  onAction: PropTypes.func,
};

export default LogoutSetting;
