import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  AutoLogoutInput,
  Form,
  FormBody,
  FormGroup,
  HiddenSubmitButton,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  PasswordInput,
  themeUtils as tu,
} from '@zen-common/components-base';
import { useInput } from '@zen/common-react-hooks';
import { VALIDATE_PASSWORD_MESSAGES, validatePassword } from '@zen/common-utils';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import PaginationButton from '../blocks/PaginationButton';

export default function SetPassword({
  onBack,
  onContinue,
  onCancel,
  onPasswordCreated,
  error,
  loading,
}) {
  const inputRef = useRef(null);
  const { value: password, onChange: onPasswordChange } = useInput({ initialValue: '' });
  const { value: passwordRepeat, onChange: onPasswordRepeatChange } = useInput({
    initialValue: '',
  });
  const [logoutMinutes, setLogoutMinutes] = useState('15');

  const passwordValid = useMemo(() => validatePassword(password), [password]);
  const passwordsEqual = useMemo(() => password === passwordRepeat, [password, passwordRepeat]);
  const valid = useMemo(() => passwordValid && passwordsEqual, [passwordValid, passwordsEqual]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const finishHandler = () => {
    if (valid) {
      onPasswordCreated({ password, logoutMinutes: Number(logoutMinutes) });
      onContinue();
    }
    inputRef.current.focus();
  };
  return (
    <Layout
      header={
        <Header
          title="Choose a password"
          text="If you forget your password you can easily import your wallet again, using your 24 words mnemonic phrase."
        />
      }
      body={
        <Form
          disabled={loading}
          onSubmit={(e) => {
            e.preventDefault();
            finishHandler();
          }}
        >
          <StyledFormBody>
            <FormGroup>
              <Label htmlFor="OnBoarding-SetPassword-Password">
                <LabelText>Password</LabelText>
              </Label>
              <StyledPasswordInput
                id="OnBoarding-SetPassword-Password"
                ref={inputRef}
                variant="block"
                value={password}
                onChange={onPasswordChange}
                {...(password && { valid: passwordValid })}
                autoComplete="new-password"
                disabled={loading}
              />
              <InputMessage mb="smd" type="info">
                {VALIDATE_PASSWORD_MESSAGES.NOT_VALID}
              </InputMessage>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="OnBoarding-SetPassword-ConfirmPassword">
                <LabelText>Confirm password</LabelText>
              </Label>
              <StyledPasswordInput
                id="OnBoarding-SetPassword-ConfirmPassword"
                variant="block"
                value={passwordRepeat}
                onChange={onPasswordRepeatChange}
                {...(passwordRepeat && { valid: passwordsEqual })}
                autoComplete="new-password"
                disabled={loading}
              />
              <InputMessage>
                {passwordRepeat && !passwordsEqual ? 'Password does not match' : ''}
              </InputMessage>
            </FormGroup>
            <FormGroup mt={['md', null, 'xl']} mb="0">
              <Label htmlFor="OnBoarding-SetPassword-AutoLogoutDuration">
                <LabelText>Auto logout duration</LabelText>
              </Label>
              <StyledAutoLogoutInput
                id="OnBoarding-SetPassword-AutoLogoutDuration"
                value={logoutMinutes}
                onChange={setLogoutMinutes}
                variant="block"
                disabled={loading}
              />
            </FormGroup>
            <FormGroup>
              <InputMessage>{error}</InputMessage>
            </FormGroup>
            <HiddenSubmitButton />
          </StyledFormBody>
        </Form>
      }
      footer={
        <Footer
          left={null}
          right={
            <>
              <PaginationButton disabled={loading} onClick={onBack} mr="smd" bg="secondary">
                Back
              </PaginationButton>
              <PaginationButton disabled={!valid || loading} onClick={finishHandler}>
                <LoadingOr loading={loading}>Continue</LoadingOr>
              </PaginationButton>
            </>
          }
        />
      }
      onClose={onCancel}
    />
  );
}
SetPassword.propTypes = {
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

const StyledFormBody = styled(FormBody)`
  ${tu.mq({
    width: ['100%', null, '40%'],
  })}
`;

const StyledPasswordInput = styled(PasswordInput)`
  width: 100%;
`;
const StyledAutoLogoutInput = styled(AutoLogoutInput)`
  min-width: 150px;
  width: 100%;
`;
