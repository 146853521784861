import { createStore } from '@zen/common-app-parts';
import useExtendStore from './useExtendStore';

const { Provider, useStore } = createStore({
  useStoreHook: useExtendStore,
  contextDisplayName: 'ExtendStoreContext',
});

const store = {
  Provider,
  /**
   * @type {TUseExtendStore}
   */
  useStore,
};

export default store;

/**
 * @typedef {() => import('./types').TExtendStore} TUseExtendStore
 */
