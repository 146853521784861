import { styled } from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import tu from '../../../utils/themeUtils';
import resetButton from '../../../utils/styles/mixins/resetButton';
import getTransition from '../../../utils/styles/mixins/getTransition';
import Box from '../../Box';
import FaSvg from '../../helpers/FaSvg';
import useUpDown from './modules/useUpDown';

/**
 * An input addon with up and down arrows to control number increase/decrease
 * @param {Object} props
 * @param {string} props.value
 * @param {(value: string) => void} props.onChange
 * @param {(number|string)} props.min
 * @param {(number|string)} props.max
 */
export const InputAddonNumberControls = ({ value, onChange, min, max }) => {
  const { onDown, onUp } = useUpDown({ min, max, value, onChange });

  return (
    <NumberControlsContainer>
      <StyledButton onClick={onUp} tabIndex="-1">
        <FaSvg icon={faAngleUp} />
      </StyledButton>
      <StyledButton onClick={onDown} tabIndex="-1">
        <FaSvg icon={faAngleDown} />
      </StyledButton>
    </NumberControlsContainer>
  );
};
InputAddonNumberControls.defaultProps = {
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
};

const NumberControlsContainer = styled(Box)`
  width: 26px;
  height: 100%;
  border-left: 1px solid ${tu.color('borderLight')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledButton = styled.button.attrs({ type: 'button' })`
  ${resetButton}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;

  ${getTransition()}
  svg {
    color: ${tu.color('label')};
    font-size: 1rem;
  }

  &:hover {
    background-color: ${tu.color('bg2')};

    svg {
      color: ${tu.color('text')};
    }
  }

  &:active {
    background-color: ${tu.color('bg3')};

    svg {
      color: ${tu.color('text')};
    }
  }
`;
