import { css, styled } from 'styled-components';
import { Box, themeUtils as tu } from '@zen-common/components-base';
import PhaseBar from './PhaseBar';

/**
 * Renders a progress bar for 2 voting phases
 *
 * @param {Object} props
 * @param {number} props.currentBlock
 * @param {string} props.phaseOneTitle
 * @param {number} props.phaseOneSnapshotBlock
 * @param {number} props.phaseOneTallyBlock
 * @param {string} props.phaseTwoTitle
 * @param {number} props.phaseTwoSnapshotBlock
 * @param {number} props.phaseTwoTallyBlock
 */
export default function TwoPhaseProgressBar({
  currentBlock,
  phaseOneTitle,
  phaseOneSnapshotBlock,
  phaseOneTallyBlock,
  phaseTwoTitle,
  phaseTwoSnapshotBlock,
  phaseTwoTallyBlock,
}) {
  const currentPhase =
    currentBlock >= phaseOneSnapshotBlock && currentBlock < phaseOneTallyBlock
      ? 'first'
      : currentBlock >= phaseTwoSnapshotBlock && currentBlock < phaseTwoTallyBlock
      ? 'second'
      : '';
  return (
    <ProgressBarContainer>
      <PhaseOneWrapper showMobile={currentPhase !== 'first'}>
        <PhaseBar
          title={phaseOneTitle}
          currentBlock={currentBlock}
          start={phaseOneSnapshotBlock}
          end={phaseOneTallyBlock}
        />
      </PhaseOneWrapper>
      <PhaseTwoWrapper showMobile={currentPhase !== 'second'}>
        <PhaseBar
          title={phaseTwoTitle}
          currentBlock={currentBlock}
          start={phaseTwoSnapshotBlock}
          end={phaseTwoTallyBlock}
        />
      </PhaseTwoWrapper>
    </ProgressBarContainer>
  );
}

const phaseWrapperCss = css`
  flex-grow: 1;
  flex-shrink: 0;
  ${tu.mq({
    // width: ['100%', null, '50%'],
    marginRight: ['0', null, tu.space('xxl')],
  })}
`;

const PhaseOneWrapper = styled(Box)`
  ${phaseWrapperCss}

  ${(p) =>
    tu.mq({
      display: [p.showMobile ? 'none' : 'block', null, 'block'],
    })}
`;
const PhaseTwoWrapper = styled(Box)`
  ${phaseWrapperCss}

  ${(p) =>
    tu.mq({
      display: [p.showMobile ? 'none' : 'block', null, 'block'],
    })}
`;
const ProgressBarContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
`;
