import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SeedNumber, StyledInput, StyledList, StyledListItem } from './Mnemonic.styled';

/**
 * A Mnemonic presentational component
 * Pass a function as the children to control the rendering of the list items
 */
export default function Mnemonic({
  isEdit,
  isVerify,
  isPopupModal,
  wordsList,
  wordChangeHandler,
  columns,
  children,
  ...props
}) {
  return (
    <StyledList
      columns={columns}
      theme={props.theme}
      isEdit={isEdit}
      isVerify={isVerify}
      isPopupModal={isPopupModal}
      className={isEdit ? 'edit' : 'view'}
    >
      {wordsList.map((item, index) => {
        if (children) return children({ item, index });
        return isEdit ? (
          <MnemonicListItem key={index}>
            <MnemonicInput
              index={index}
              word={item.word}
              validity={item.validity}
              onChange={wordChangeHandler}
            />
          </MnemonicListItem>
        ) : (
          <MnemonicListItem key={index}>
            <span>{item.word}</span>
          </MnemonicListItem>
        );
      })}
    </StyledList>
  );
}
Mnemonic.defaultProps = {
  renderItemProps: () => ({}),
};
Mnemonic.propTypes = {
  isEdit: PropTypes.bool,
  wordChangeHandler: PropTypes.func,
  wordsList: PropTypes.arrayOf(
    PropTypes.shape({ word: PropTypes.string, validity: PropTypes.object })
  ),
  renderItemProps: PropTypes.func,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
};

export function MnemonicListItem(props) {
  return <StyledListItem {...props} />;
}

export const MnemonicInput = forwardRef(
  ({ word, validity, index, onChange, visible, ...props }, ref) => {
    const [_visible, setVisible] = useState(false);

    const toggleVisibilityHandler = () => setVisible(!_visible);

    function appendZero(number) {
      /*this function appends a zero to single digit numbers*/
      return ('0' + number).slice(-2);
    }

    /*override visible from the outside*/
    useEffect(() => {
      typeof visible === 'boolean' && setVisible(visible);
    }, [visible]);

    return (
      <StyledInput
        ref={ref}
        value={word}
        {...validity}
        onChange={(e) => onChange(index, e.target.value.trim())}
        visible={_visible}
        onToggleVisibility={toggleVisibilityHandler}
        renderLeftAddon={
          <SeedNumber
            px="sm"
            bg="bg2"
            fontSize="sm"
            borderRight="1px solid"
            borderColor="borderLight"
            borderRadius="3px 0 0 3px"
          >
            {appendZero(index + 1)}
          </SeedNumber>
        }
        {...props}
      />
    );
  }
);

MnemonicInput.displayName = 'MnemonicInput';
