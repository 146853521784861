import PropTypes from 'prop-types';
import { assetUtils } from '@zen/common-utils';
import Hash from '../Hash/Hash';

/**
 * Display an asset id, show it's name if available, truncate
 *
 * @param {Object} props
 * @param {string} props.hash - the hash to show
 * @param {boolean} props.truncate - whether to truncate, true by default
 * @param {boolean} props.displayName - the label to show
 */
export default function Asset({ hash, truncate = true, displayName, ...props }) {
  const name = assetUtils.getAssetName(hash);
  const display = name !== hash ? name : displayName;

  return <Hash hash={hash} truncate={truncate} display={display} {...props} />;
}

Asset.propTypes = {
  hash: PropTypes.string.isRequired,
  truncate: PropTypes.bool,
};
