import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { css, styled } from 'styled-components';
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import FaSvg from '../helpers/FaSvg';
import { containerXPadding, getTransition } from '../../utils/styles/mixins';
import tu from '../../utils/themeUtils';
import PaginationControls from './components/PaginationControls';
import Loading from './components/Loading';

const Table = (props) => {
  return <ReactTable {...props} />;
};
const minCellHeight = '50px';
const StyledTable = styled(Table)`
  &.ReactTable {
    border: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100px;

    * {
      box-sizing: border-box;
    }

    .rt-table {
      flex: auto 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      border-collapse: collapse;
      overflow-x: auto;
      overflow-y: hidden;
      font-weight: 500;
      background-color: ${tu.color('bg1')};
    }

    .rt-thead {
      color: ${tu.color('label')};
      background-color: ${tu.color('bg2')};
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      user-select: none;

      .rt-tr {
        text-align: center;
      }

      .rt-th {
        line-height: normal;
        position: relative;
        outline: 0;
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: inset 0 0 0 0 transparent;

        &:last-child {
          border-right: 0;
        }

        &:focus {
          outline-width: 0;
        }
      }

      .rt-td {
        line-height: normal;
        position: relative;
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: inset 0 0 0 0 transparent;

        &:last-child {
          border-right: 0;
        }
      }

      .rt-th.-sort-asc {
        box-shadow: none !important;
      }

      .rt-td.-sort-asc {
        box-shadow: none !important;
      }

      .rt-th.-sort-desc {
        box-shadow: none !important;
      }

      .rt-td.-sort-desc {
        box-shadow: none !important;
      }

      .rt-th.-cursor-pointer {
        cursor: pointer;
      }

      .rt-td.-cursor-pointer {
        cursor: pointer;
      }

      .rt-resizable-header {
        overflow: visible;

        &:last-child {
          overflow: hidden;
        }
      }

      .rt-resizable-header-content {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .rt-header-pivot {
        border-right-color: #f7f7f7;

        &:after {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #fff;
          border-width: 8px;
          margin-top: -8px;
        }

        &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(102, 102, 102, 0);
          border-left-color: #f7f7f7;
          border-width: 10px;
          margin-top: -10px;
        }
      }

      &.-headerGroups {
        background: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }

      &.-filters {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        input {
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 5px 7px;
          font-size: inherit;
          border-radius: 3px;
          font-weight: normal;
          outline-width: 0;
        }

        select {
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 5px 7px;
          font-size: inherit;
          border-radius: 3px;
          font-weight: normal;
          outline-width: 0;
        }

        .rt-th {
          border-right: 1px solid rgba(0, 0, 0, 0.02);
        }
      }

      &.-header {
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      }
    }

    .rt-tbody {
      overflow-x: hidden;
      overflow-y: auto;
      color: ${tu.color('text')};
      font-size: ${tu.fontSize('md')};
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;

      .rt-tr-group {
        border-bottom: solid 1px ${tu.color('borderDark')};
        background-color: ${tu.color('bg1')};

        &:hover {
          background-color: ${tu.color('bg3')};
        }
      }

      .rt-td {
        &:last-child {
          border-right: 0;
        }

        position: relative;
      }

      .rt-expandable {
        cursor: pointer;
        text-overflow: clip;
      }
    }

    .rt-tr-group {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .rt-tr {
      flex: 1 0 auto;
      display: inline-flex;
      height: ${minCellHeight};
    }

    .rt-th,
    .rt-td {
      display: flex;
      align-items: center;
      flex: 1 0 0;
      overflow: visible;
      transition: 0.3s ease;
      ${cellPadding}
      transition-property: width, min-width, padding, opacity;

      &.align-right {
        text-align: right;
        justify-content: flex-end;
      }
    }

    .rt-th {
      text-transform: uppercase;
      font-size: ${tu.fontSize('sm')};
      border-bottom: 1px solid ${tu.color('borderDark')};
      text-align: left;
      border-right-color: transparent;
      white-space: nowrap;
    }

    .rt-td {
      white-space: nowrap;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .rt-th.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    }

    .rt-td.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    }

    .rt-expander {
      display: inline-block;
      position: relative;
      color: transparent;
      margin: 0 10px;

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        border-left: 5.04px solid transparent;
        border-right: 5.04px solid transparent;
        border-top: 7px solid rgba(0, 0, 0, 0.8);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        cursor: pointer;
      }
    }

    .rt-expander.-open {
      &:after {
        transform: translate(-50%, -50%) rotate(0);
      }
    }

    .rt-resizer {
      display: inline-block;
      position: absolute;
      width: 36px;
      top: 0;
      bottom: 0;
      right: -18px;
      cursor: col-resize;
      z-index: 10;
    }

    .rt-tfoot {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

      .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.05);

        &:last-child {
          border-right: 0;
        }
      }
    }

    .-pagination {
      background-color: ${tu.color('bg2')};
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border-top: 2px solid rgba(0, 0, 0, 0.1);
      padding: ${tu.space('xxs')};
      height: ${minCellHeight};

      .pagination {
        border-left: 1px solid ${tu.color('bg2')};
        border-right: 1px solid ${tu.color('bg2')};
        margin: 0;
        list-style: none;
        display: flex;
        padding-left: 0;
        border-radius: 0.15rem;

        .page-item {
          &.page-number {
            display: none;
          }

          .page-link {
            background-color: ${tu.color('bg4')};
            border: 0 solid ${tu.color('bg2')};
            border-right-width: 1px;
            user-select: none;
            outline: none;
            color: ${tu.color('label')};
            font-size: ${tu.fontSize('xs')};
            position: relative;
            min-width: 33px;
            width: auto;
            height: 33px;
            cursor: pointer;
            ${getTransition()} @media(hover: hover) {
              &:hover {
                color: ${tu.color('text')};
                background-color: ${tu.color('bg5')};

                svg {
                  color: ${tu.color('text')};
                }
              }
            }

            @media (hover: none) {
              &:active {
                color: ${tu.color('text')};
                background-color: ${tu.color('bg5')};

                svg {
                  color: ${tu.color('text')};
                }
              }
            }

            svg {
              color: ${tu.color('label')};
              ${getTransition()}
            }

            &[disabled] {
              pointer-events: none;

              svg {
                color: ${tu.color('bg5')};
              }
            }

            &.back {
              border-top-left-radius: ${tu.radius(1)};
              border-bottom-left-radius: ${tu.radius(1)};
            }

            &.next {
              border-top-right-radius: ${tu.radius(1)};
              border-bottom-right-radius: ${tu.radius(1)};
            }
          }

          &.active {
            &.page-number {
              display: block;
            }

            .page-link {
              color: ${tu.color('text')};
              background-color: ${tu.color('bg5')};
              font-weight: 500;

              &:hover {
                color: white;
              }
            }
          }
        }
      }
    }

    @media (min-width: ${tu.breakpoints.md}) {
      .-pagination {
        .pagination {
          .page-item {
            &.page-number {
              display: block;
            }

            &.page-skip-first,
            &.page-skip-last {
              display: none;
            }
          }
        }
      }
    }

    .rt-noData {
      display: block;
      position: absolute;
      left: 50%;
      top: calc(50% + (${minCellHeight} / 2));
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;
      z-index: 1;
      pointer-events: none;
      font-size: ${tu.fontSize('sm')};
      color: ${tu.color('text')};
    }

    .-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      top: ${minCellHeight};
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      transition: all 0.3s ease;
      z-index: -1;
      opacity: 0;
      pointer-events: none;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: ${minCellHeight};
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }

      &.-active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;
      }
    }

    .rt-resizing {
      .rt-th,
      .rt-td {
        transition: none !important;
        cursor: col-resize;
        user-select: none;
      }
    }
  }
`;

StyledTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      //... any other expected properties for column
    })
  ).isRequired,
  loading: PropTypes.bool,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  resizable: PropTypes.bool,
  sortable: PropTypes.bool,
  showPagination: PropTypes.bool,
  multiSort: PropTypes.bool,
  defaultSorted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  defaultSortDesc: PropTypes.bool,
  onFetchData: PropTypes.func,
  defaultPageSize: PropTypes.number,
  pages: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  SubComponent: PropTypes.elementType,
  pivotBy: PropTypes.arrayOf(PropTypes.string),
  expanded: PropTypes.objectOf(PropTypes.bool),
  getTrProps: PropTypes.func,
};

StyledTable.defaultProps = {
  loading: false,
  pageSizes: [10, 25, 50],
  resizable: true,
  sortable: true,
  showPagination: true,
  multiSort: false,
  defaultSortDesc: false,
  defaultPageSize: 10,
  noDataText: 'No data',
  previousText: <FaSvg icon={faChevronLeft} />,
  nextText: <FaSvg icon={faChevronRight} />,
  firstText: <FaSvg icon={faChevronDoubleLeft} />,
  lastText: <FaSvg icon={faChevronDoubleRight} />,
  PaginationComponent: PaginationControls,
  loadingText: <Loading />,
};

export default StyledTable;

function cellPadding(p) {
  const xs = tu.space('xs')(p);
  return css`
    padding-top: ${xs};
    padding-bottom: ${xs};
    ${containerXPadding(p)}
  `;
}
