import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { styled } from 'styled-components';
import {
  AutoSuggest,
  Box,
  Button,
  ClearInput,
  Form,
  FormBody,
  FormFooter,
  FormGroup,
  Input,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  themeUtils as tu,
} from '@zen-common/components-base';
import {
  NamingAssetStore,
  PasteButton,
  SettingsStore,
  SpendsGroup,
  SpendsReducer,
  useRevalidateSpendsOnBalanceChange,
} from '@zen/common-app-parts';
import { useUnmountedRef } from '@zen/common-react-hooks';
import InputAndRest from '../InputAndRest';
import { initialState, reducer } from './modules/reducer';

export default function PayoutForm({
  isNomination,
  candidates,
  cgpBalance,
  ballot,
  onBallotChange,
  onVote,
  progress,
  resetFnRef,
  disabled,
}) {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const { state: naming } = NamingAssetStore.useStore();
  const addressRef = useRef();
  const spendsRef = useRef();

  const formDisabled = disabled || progress;

  const assets = useMemo(() => {
    return Object.keys(cgpBalance);
  }, [cgpBalance]);

  const unmountedRef = useUnmountedRef();
  const [_state, dispatch] = useReducer(reducer, initialState);
  /** @type {initialState} */
  const state = _state;

  const onAddressChange = useCallback(
    (value) => {
      onBallotChange('');
      dispatch({ type: 'address-changed', payload: { value, chain } });
    },
    [chain, onBallotChange]
  );
  const onAssetChange = useCallback(
    ({ value, index }) => {
      onBallotChange('');
      dispatch({
        type: SpendsReducer.actions.ASSET_CHANGED,
        payload: { value, index, balance: cgpBalance },
      });
    },
    [cgpBalance, onBallotChange]
  );
  const onAmountChange = useCallback(
    ({ value, index }) => {
      onBallotChange('');
      dispatch({
        type: SpendsReducer.actions.AMOUNT_CHANGED,
        payload: { value, index, balance: cgpBalance },
      });
    },
    [cgpBalance, onBallotChange]
  );
  const onSpendAdd = useCallback(() => {
    onBallotChange('');
    dispatch({ type: SpendsReducer.actions.ADD_SPEND });
    // make sure focus after the removed
    setTimeout(() => {
      spendsRef.current && spendsRef.current.focus();
    }, 0);
  }, [onBallotChange]);
  const onSpendRemove = useCallback(
    (index) => {
      onBallotChange('');
      dispatch({
        type: SpendsReducer.actions.REMOVE_SPEND,
        payload: { index, balance: cgpBalance },
      });
      // make sure focus after the removed
      setTimeout(() => {
        spendsRef.current && spendsRef.current.focus();
      }, 0);
    },
    [cgpBalance, onBallotChange]
  );
  const onReset = useCallback(() => {
    if (!unmountedRef.current) {
      onBallotChange('');
      dispatch({ type: 'clear' });
      addressRef.current && addressRef.current.focus();
    }
  }, [onBallotChange, unmountedRef]);

  // send the reset function to the parent so that it can reset the form from the outside
  // TODO: consider lifting the whole payout form state to a store
  useEffect(() => {
    if (resetFnRef) {
      resetFnRef.current = onReset;
    }
  }, [onReset, resetFnRef]);

  useRevalidateSpendsOnBalanceChange({ dispatch, balance: cgpBalance });

  // handle ballot prop changes
  useEffect(() => {
    // in nomination phase do not dispatch if ballot is empty
    if (!isNomination || ballot) {
      dispatch({ type: 'ballot-changed', payload: { value: ballot, chain, balance: cgpBalance } });
    }
  }, [cgpBalance, ballot, chain, isNomination]);

  const ballotIdValidAllPhases = useMemo(
    () => state.ballotIdValid && (isNomination || candidates.includes(ballot)),
    [candidates, isNomination, ballot, state.ballotIdValid]
  );

  const formValid = useMemo(() => {
    return isNomination
      ? state.addressValid && state.spendsValid
      : (candidates || []).includes(ballot);
  }, [candidates, isNomination, state.addressValid, ballot, state.spendsValid]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formValid && !progress) {
      onVote({
        address: state.address,
        spends: state.spends.map((spend) => ({
          asset: spend.asset,
          amount: spend.amount.safeValue,
        })),
      });
    }
  };

  const resetDisabled = useMemo(
    () =>
      ballot === '' &&
      state.address === '' &&
      state.spends[0].asset === '' &&
      state.spends[0].amount.value === '',
    [ballot, state.address, state.spends]
  );

  return (
    <Form onSubmit={onSubmit} disabled={formDisabled}>
      <FormBody>
        <FormGroup>
          <Label htmlFor="ballot-id">
            <LabelText>Ballot ID</LabelText>
          </Label>

          <InputAndRest
            input={
              isNomination ? (
                <ClearInput
                  id="ballot-id"
                  inputType="expandable"
                  valid={!ballot ? undefined : ballotIdValidAllPhases}
                  variant="block"
                  width="100%"
                  value={ballot}
                  disabled={formDisabled}
                  onChange={(e) => onBallotChange(e.target.value.trim())}
                  onClear={() => onBallotChange('')}
                />
              ) : (
                <AutoSuggest
                  placeholder="Select one of the candidates payout ballot ID"
                  valid={!ballot ? undefined : ballotIdValidAllPhases}
                  hasClearButton
                  items={candidates.map((ballot) => ({ value: ballot, label: ballot }))}
                  variant="block"
                  value={ballot}
                  disabled={formDisabled}
                  onChange={onBallotChange}
                  inputProps={{ id: 'ballot-id' }}
                />
              )
            }
            rest={<PasteButton onPaste={onBallotChange} />}
          />
          <InputMessage>{!ballot || ballotIdValidAllPhases ? '' : 'Invalid ballot'}</InputMessage>
        </FormGroup>
        <FormGroup>
          <TextSeparator>{isNomination ? 'OR' : 'AS'}</TextSeparator>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="recipient-address">
            <LabelText>Recipient Address</LabelText>
          </Label>
          {isNomination ? (
            <InputAndRest
              input={
                <ClearInput
                  ref={addressRef}
                  id="recipient-address"
                  inputType="expandable"
                  valid={!state.address ? undefined : state.addressValid}
                  variant="block"
                  width="100%"
                  value={state.address}
                  disabled={formDisabled}
                  onChange={(e) => onAddressChange(e.target.value.trim())}
                  onClear={() => onAddressChange('')}
                />
              }
              rest={isNomination && <PasteButton onPaste={onAddressChange} />}
            />
          ) : (
            <Input
              id="recipient-address"
              value={state.address || '\u00a0'}
              variant="block"
              inputType="expandable"
              readOnly
              disabled={formDisabled}
            />
          )}
          <InputMessage>
            {!state.address || state.addressValid ? '' : 'Invalid address'}
          </InputMessage>
        </FormGroup>

        <SpendsGroup
          ref={isNomination ? spendsRef : null}
          alignment={isNomination ? 'grid' : 'default'}
          disabled={!isNomination || formDisabled}
          noMarginTop={isNomination}
          spends={state.spends}
          spendsValid={state.spendsValid}
          assets={assets}
          balance={cgpBalance}
          addSpend={onSpendAdd}
          removeSpend={onSpendRemove}
          setAsset={onAssetChange}
          setAmount={onAmountChange}
          naming={naming}
        />

        <FormFooter>
          {isNomination && (
            <Button bg="secondary" onClick={onReset} disabled={resetDisabled}>
              Reset
            </Button>
          )}
          <Button type="submit" disabled={disabled || !formValid || progress}>
            <LoadingOr loading={progress} loadingContent="Voting...">
              Vote
            </LoadingOr>
          </Button>
        </FormFooter>
      </FormBody>
    </Form>
  );
}
PayoutForm.defaultProps = {
  cgpBalance: {},
  candidates: [],
};

function TextSeparator({ children }) {
  return (
    <Box display="flex" alignItems="center">
      <Divider />
      <Box fontSize="sm" color="label">
        {children}
      </Box>
      <Divider />
    </Box>
  );
}

const Divider = styled(Box)`
  height: 0;
  flex-grow: 1;
  flex-shrink: 0;
  border-bottom: 1px solid ${tu.color('borderDark')};
`;
