import { useEffect } from 'react';
import { ApiQueries, ExecuteContractStore } from '@zen/common-app-parts';
import ActiveContracts from './ActiveContracts';

export default function ActiveContractsWithFetch() {
  const { status, data } = ApiQueries.useFetchActiveContracts(5000);
  const {
    actions: { setActiveContracts },
  } = ExecuteContractStore.useStore();

  // set active contracts in store
  useEffect(() => {
    setActiveContracts(data);
  }, [data, setActiveContracts]);

  return <ActiveContracts fetching={status === 'loading'} />;
}
