import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  ConfirmationModal,
  RadioGroup,
  themeUtils as tu,
  Title,
  useModal,
} from '@zen-common/components-base';
import { truncateOrNameAsset } from '@zen/common-utils/lib/utils/assetUtils';
import { getContractAddress } from '../../../utils/zenJsUtils';

export const actions = {
  change: 'CHANGE',
};

const ContractVersionSetting = ({ useContract }) => {
  const { value, items, naming, onAction, chain } = useContract();
  const { show, hide } = useModal();

  const onChange = (e) => {
    if (typeof onAction === 'function') {
      const selectedValue = e.target.value;
      const selectedValueMessage =
        !!naming && naming[selectedValue]
          ? `${naming[selectedValue].shortName} - ${truncateOrNameAsset(
              getContractAddress(chain, selectedValue)
            )}`
          : truncateOrNameAsset(getContractAddress(chain, selectedValue));
      const valueMessage =
        !!naming && naming[value]
          ? `${naming[value].shortName} - ${truncateOrNameAsset(getContractAddress(chain, value))}`
          : truncateOrNameAsset(getContractAddress(chain, value));
      show(() => (
        <ConfirmationModal
          dismissOnClickOutside={true}
          showClose={false}
          onCancel={hide}
          onConfirm={() => {
            hide();
            onAction({ type: actions.change, value: selectedValue });
          }}
          title={<Title>Confirm switching contract id</Title>}
        >
          You are about to switch from {valueMessage} to {selectedValueMessage}.
        </ConfirmationModal>
      ));
    }
  };
  return (
    <StyledRadioGroup checked={value} onChange={onChange} options={items} column hasSeparator />
  );
};

ContractVersionSetting.propTypes = {
  useSetting: PropTypes.func,
};

export default ContractVersionSetting;

const StyledRadioGroup = styled(RadioGroup)`
  li {
    padding: ${tu.space('sm')} 0;
  }
`;
