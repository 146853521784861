import { css, styled } from 'styled-components';
import { Box, IconButton, themeUtils as tu } from '@zen-common/components-base';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const PADDINGS = ['md', null, 'xl'];

export default function Layout({ header, footer, body, onClose, ...props }) {
  const showClose = typeof onClose === 'function';
  return (
    <MainContainer {...props}>
      <TopButtonsContainer>
        {showClose && <IconButton icon={faTimes} onClick={onClose} />}
      </TopButtonsContainer>
      <HeaderWrapper showClose={showClose}>{header}</HeaderWrapper>
      <ScrollWrapper>
        <BodyWrapper>{body}</BodyWrapper>
      </ScrollWrapper>
      <FooterWrapper>{footer}</FooterWrapper>
    </MainContainer>
  );
}

const MainContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 760px;
  width: 100%;
  background-color: ${tu.color('bg2')};

  ${css(
    tu.mq({
      justifyContent: ['start', null, 'center'],
      margin: ['0', null, 'auto'],
      height: ['100%', null, 'auto'],
      maxHeight: ['100%', null, '90%', '80%'],
      border: [null, null, '1px solid'],
      borderColor: [null, null, tu.color('borderLight')],
      borderRadius: [null, null, tu.radius(1)],
    })
  )}
`;

const HeaderWrapper = styled(Box)`
  ${(p) => {
    const padding = tu.space(PADDINGS)(p);
    return css(
      tu.mq({
        paddingLeft: padding,
        paddingRight: padding,
        paddingBottom: padding,
        paddingTop: p.showClose ? '0px' : padding,
      })
    );
  }}
`;

const ScrollWrapper = styled(Box)`
  overflow: auto;
  background-color: ${tu.color('bg2')};
  flex-grow: 1;
  ${css(
    tu.mq({
      minHeight: ['150px', null, '100px'],
    })
  )}
`;

const BodyWrapper = styled(Box)`
  ${(p) => {
    const padding = tu.space(PADDINGS)(p);
    return css(
      tu.mq({
        paddingLeft: padding,
        paddingRight: padding,
      })
    );
  }}
`;

const FooterWrapper = styled(Box)`
  background-color: ${tu.color('bg3')};
  border-top: 1px solid ${tu.color('borderDark')};
  bottom: 0;
  width: 100%;

  ${(p) => {
    const pMd = tu.space('md')(p);
    const pMlg = tu.space('mlg')(p);
    const pXl = tu.space('xl')(p);
    return css(
      tu.mq({
        padding: [pMd, `${pMd} ${pXl}`, `${pMlg} ${pXl}`],
      })
    );
  }}
`;
const TopButtonsContainer = styled(Box)`
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: flex-end;

  ${css(
    tu.mq({
      paddingTop: [tu.space('xxs'), null, tu.space('sm')],
      paddingRight: [tu.space('xxs'), null, tu.space('sm')],
    })
  )}
`;
