import { useState } from 'react';
import {
  ConfirmPublishTxModalContent,
  ConfirmSendModalContent,
  SendExecuteStore,
} from '@zen/common-app-parts';
import { useUnmountedRef } from '@zen/common-react-hooks';
import { clipboard } from '@zen/common-utils-browser';

export default function ConfirmSendRawModalProcess({ onCancel }) {
  const unmounted = useUnmountedRef();
  const [status, setStatus] = useState('confirm-send');
  const [signedTx, setSignedTx] = useState();
  const { sendStore } = SendExecuteStore.useStore();

  const handleCancel = () => {
    onCancel();
  };

  const handleCopy = async () => {
    try {
      await clipboard.writeText(signedTx);
    } catch (error) {
      //ignore error
    }
  };

  const handleConfirmSendContinue = async () => {
    const tx = await sendStore.actions.send();
    if (!unmounted.current) {
      setSignedTx(tx);
      setStatus('confirm-after-sign');
    }
  };

  const handleConfirmAfterSignBack = () => {
    setStatus('confirm-send');
  };
  const handleConfirmAfterSignContinue = async () => {
    await handleCopy();
    onCancel();
  };

  if (status === 'confirm-send') {
    return (
      <ConfirmSendModalContent
        title="Send Raw Transaction Summary"
        address={sendStore.state.address}
        spends={sendStore.state.spends}
        onCancel={handleCancel}
        onConfirm={handleConfirmSendContinue}
      />
    );
  }

  if (status === 'confirm-after-sign') {
    if (!signedTx) return null;

    return (
      <ConfirmPublishTxModalContent
        title="Copy your unsigned TX"
        confirmText="Copy"
        tx={signedTx}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
        progress={sendStore.state.progress}
      />
    );
  }

  return null;
}
