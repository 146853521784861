import { useEffect } from 'react';
import { Alert, notification, useModal } from '@zen-common/components-base';
import SettingLayout from '../containers/SettingLayout';
import SettingButton from '../common/SettingButton';
import ConfirmDeletingStorageModal from './components/ConfirmDeletingStorageModal';
import LoadingModal from './components/LoadingModal';

const DeleteStorageSetting = ({ useSetting, ...props }) => {
  const { show, hide } = useModal();
  const { error, success, onAction } = useSetting();

  function showConfirmDeletingStorageModal() {
    show(() => <ConfirmDeletingStorageModal hide={hide} onConfirm={handleConfirm} />);
  }

  function handleConfirm() {
    show(() => <LoadingModal />);
    onAction();
  }

  useEffect(() => {
    if (success) {
      notification('Storage deleted successfully', { type: notification.TYPE.SUCCESS });
      hide();
    }
  }, [hide, success]);

  useEffect(() => {
    if (error) {
      show(() => (
        <Alert type={Alert.TYPE.ERROR} onDismiss={hide} title="Error">
          {error}
        </Alert>
      ));
    }
  }, [error, hide, show]);

  return (
    <SettingLayout header="Delete Storage" paragraph="Delete Zendex database" {...props}>
      <SettingButton onClick={showConfirmDeletingStorageModal}>Delete</SettingButton>
    </SettingLayout>
  );
};

export default DeleteStorageSetting;
