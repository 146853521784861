/* eslint-disable react/display-name */
import { useEffect } from 'react';
import { TxCountChangedStore } from '../../stores/ChangeNotificationsStore';
import TxHistory from './TxHistory';

export default function TxHistoryContainer() {
  const {
    state: { changed: txCountChanged },
    actions: { clear: clearTxCountChanged },
  } = TxCountChangedStore.useStore();

  // clear on mount
  useEffect(() => {
    clearTxCountChanged();
  }, [clearTxCountChanged, txCountChanged]);

  return <TxHistory />;
}
