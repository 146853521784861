import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useStorageState } from '@zen/common-react-hooks';
import * as ApiService from '../../utils/ApiService';
import { STORAGE_KEY } from './constants';

/**
 * @param {Object} params
 * @param {string} params.url - the URL to the naming json
 * @param {{get: (key: string) => *, set: (key: string, value: *) => void}} params.storage
 */
export default function useNamingJsonStore({ url } = {}) {
  const [namingMap, setNamingMap] = useStorageState(STORAGE_KEY, {}, { storage: 'localStorage' });

  const { data } = useQuery('contract-naming-json', () => fetchNamingJson(url), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    forceFetchOnMount: true,
  });

  useEffect(() => {
    if (data) {
      // convert to a hash table
      setNamingMap(
        data.items.reduce((hash, item) => {
          return Object.assign(hash, item);
        }, {})
      );
    }
  }, [data, setNamingMap]);

  return {
    state: namingMap,
  };
}

function fetchNamingJson(url) {
  return ApiService.sendHttpRequest({
    url,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}
