import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import tu from '../../utils/themeUtils';
import Box from '../Box';

/**
 * A bootstrap style container
 */
const Container = styled(Box)`
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media (min-width: ${tu.breakpoints.xl}) {
    max-width: ${(p) => (p.fluid ? 'auto' : tu.breakpoints.xl)};
  }
`;

Container.propTypes = {
  fluid: PropTypes.bool,
};

export default Container;
