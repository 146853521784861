import { useState } from 'react';
import { css, styled } from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { Box, FaSvg, themeUtils as tu } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import InfoBox from '../../../../components/InfoBox';

export function InfoBoxes({ children }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen((s) => !s);

  return (
    <InfoBoxesContainer isOpen={isOpen} onClick={toggle}>
      <MobileToggler icon={!isOpen ? faAngleDown : faAngleUp} color="label" />
      {children}
    </InfoBoxesContainer>
  );
}

export function InfoBoxSemester({ interval }) {
  return <StyledInfoBox label="Semester">{numberUtils.toDisplay(interval)}</StyledInfoBox>;
}
export function InfoBoxSnapshot({ snapshot }) {
  return <StyledInfoBox label="Snapshot Block">{numberUtils.toDisplay(snapshot)}</StyledInfoBox>;
}
export function InfoBoxVoteWeight({ isPotential, voteWeightZp }) {
  return (
    <StyledInfoBox label={isPotential ? 'Potential Vote Weight' : 'Vote Weight'}>
      {numberUtils.toDisplay(voteWeightZp)} ZP
    </StyledInfoBox>
  );
}

const InfoBoxesContainer = styled(Box)`
  position: relative;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  ${(p) =>
    css(
      tu.mq({
        flexDirection: ['column', null, 'row'],
        height: [p.isOpen ? 'auto' : tu.space('sm'), null, 'auto'],
        marginBottom: [0, null, `calc(-1 * ${tu.space('smd')(p)})`],
        paddingBottom: ['2px', null, '0'], // for cut out text hidden by the overflow
        cursor: ['pointer', null, 'default'],
      })
    )}
`;

const MobileToggler = styled(FaSvg)`
  font-size: 24px;
  position: absolute;
  right: 0;
  top: -6px;
  ${tu.mq({ display: ['block', null, 'none'] })}
`;

const StyledInfoBox = styled(InfoBox)`
  position: relative;
  ${css(
    tu.mq({
      width: ['100%', null, 'auto'],
      marginRight: ['0', null, tu.space(11)],
      marginBottom: [tu.space('sm'), null, tu.space('smd')],
    })
  )}
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
`;
