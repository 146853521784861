import { styled } from 'styled-components';
import { color } from 'styled-system';
import tu from '../../utils/themeUtils';
import Box from '../Box';
import FaSvg from '../helpers/FaSvg';
import { getErrorColor } from './helpers/getErrorColor';
import { getErrorIcon } from './helpers/getErrorIcon';

const GeneralMessage = ({ type, children, ...props }) => {
  return (
    <MessageWrapper {...props}>
      <BorderTop bg={getErrorColor(type)} />
      <Box display="flex" flexDirection="row" alignItems="baseline">
        <Box mr="sm" display={['none', null, 'block']}>
          <FaSvg fontSize="xl" icon={getErrorIcon(type)} color={getErrorColor(type)} />
        </Box>
        <Box fontSize="md">{children}</Box>
      </Box>
    </MessageWrapper>
  );
};

export default GeneralMessage;

const MessageWrapper = styled(Box)`
  position: relative;
  border: 1px solid ${tu.color('borderDark')};
  padding: ${tu.space('smd')};
`;

const BorderTop = styled(Box)`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: 3px;
  ${color}
`;
