import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import {
  Box,
  Button,
  Input,
  InputMessage,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  Title,
} from '@zen-common/components-base';
import WalletStore from '../../../../stores/WalletStore';

export default function EditLabelModalContent({ onCancel, onEdit }) {
  const {
    state: {
      currentWalletInfo: { label },
    },
  } = WalletStore.useStore();

  const currentInputRef = useRef(null);

  const [labelInput, setLabelInput] = useState('');

  useEffect(() => {
    currentInputRef.current.focus();
  }, []);

  function handleConfirmation() {
    onEdit({ value: labelInput });
    onCancel();
  }

  const handleOnChange = (e) => {
    setLabelInput(e.target.value);
  };

  return (
    <Box>
      <ModalHeader>
        <Title>{label ? 'Edit your account name' : 'Name your account'} </Title>
      </ModalHeader>
      <ModalSection>{label ? `Current Name: ${label}` : ''}</ModalSection>
      <ModalSeparator />
      <ModalSection>
        <Input
          ref={currentInputRef}
          onChange={handleOnChange}
          type="text"
          value={labelInput}
          variant="block"
        />
        <InputMessage />
      </ModalSection>
      <ModalFooter>
        <StyledButtonsWrapper>
          <StyledButton onClick={onCancel} bg="secondary" display={['none', 'block']}>
            Cancel
          </StyledButton>
          <StyledButton onClick={handleConfirmation}>Confirm</StyledButton>
        </StyledButtonsWrapper>
      </ModalFooter>
    </Box>
  );
}

const StyledButtonsWrapper = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const StyledButton = styled(Button)`
  max-width: 100%;
  flex-grow: 1;
`;
