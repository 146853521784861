import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { produce } from 'immer';
import { assetUtils, ValueDisplay } from '@zen/common-utils';
import * as ZenJsUtils from '../../utils/zenJsUtils';
import {
  SpendsReducer,
  SpendUtils,
  useRevalidateSpendsOnBalanceChange,
} from '../../components/Spend';
import * as ApiService from '../../utils/ApiService';

const initialState = {
  activeContracts: [],
  isWatch: false,
  contractId: '',
  address: '',
  addressValid: false,
  addressReadOnly: false,
  allReadOnly: false,
  command: '',
  messageBody: '',
  messageBodyValid: true,
  messageBodyType: '',
  spends: [SpendUtils.getSpend()],
  spendsValid: false,
  includeReturnAddress: false,
  includeSender: false,
  progress: false,
  result: {
    type: '',
    message: '',
    data: {},
  },
};

/**
 * @param {Object} params
 * @param {import('../WalletStore/WalletStore').WalletStore} params.wallet
 * @param {string} params.chain
 */
export default function useExecuteContractStore({ wallet, chain, nodeUrl } = {}) {
  const [_state, dispatch] = useReducer(reducer, initialState);
  /** @type {initialState} */
  const state = _state;

  const valid =
    state.addressValid &&
    state.messageBodyValid &&
    (state.spends.length > 1
      ? state.spendsValid
      : state.spends[0].asset
      ? state.spends[0].assetValid && state.spends[0].amountValid
      : true);

  // add running flag
  const activeContracts = useMemo(
    () =>
      (state.activeContracts || []).map((c) => ({
        ...c,
        running: state.progress && c.address === state.address,
      })),
    [state.activeContracts, state.address, state.progress]
  );

  useRevalidateSpendsOnBalanceChange({ dispatch, balance: wallet.state.currentWalletInfo.balance });

  // revalidate address on active contracts change
  useEffect(() => {
    dispatch({
      type: 'address-revalidate',
      payload: { chain },
    });
  }, [activeContracts, chain]);

  // set internal watch mode
  useEffect(() => {
    dispatch({
      type: 'set-watch-mode',
      payload: { isWatch: wallet.state.currentWalletInfo.isWatchMode },
    });
  }, [chain, state, wallet.state.currentWalletInfo.isWatchMode]);
  // actions --
  const setActiveContracts = useCallback(
    (value) =>
      dispatch({
        type: 'active-contracts-changed',
        payload: { value },
      }),
    []
  );
  const setAddress = useCallback(
    (value) =>
      dispatch({
        type: 'address-changed',
        payload: { value, chain, balance: wallet.state.currentWalletInfo.balance },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const setAddressReadOnly = useCallback(
    (value) =>
      dispatch({
        type: 'address-readonly-changed',
        payload: { value },
      }),
    []
  );
  const setAllReadOnly = useCallback(
    (value) =>
      dispatch({
        type: 'all-readonly-changed',
        payload: { value },
      }),
    []
  );
  const setCommand = useCallback(
    (value) =>
      dispatch({
        type: 'command-changed',
        payload: { value, chain, balance: wallet.state.currentWalletInfo.balance },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const setMessageBody = useCallback(
    (value) =>
      dispatch({
        type: 'body-changed',
        payload: { value, chain, balance: wallet.state.currentWalletInfo.balance },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const addSpend = useCallback(() => dispatch({ type: SpendsReducer.actions.ADD_SPEND }), []);
  const removeSpend = useCallback(
    (index) =>
      dispatch({
        type: SpendsReducer.actions.REMOVE_SPEND,
        payload: { index, balance: wallet.state.currentWalletInfo.balance },
      }),
    [wallet.state.currentWalletInfo.balance]
  );
  const setAsset = useCallback(
    ({ index, value }) =>
      dispatch({
        type: SpendsReducer.actions.ASSET_CHANGED,
        payload: { index, value, chain, balance: wallet.state.currentWalletInfo.balance },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const setAmount = useCallback(
    ({ index, value }) =>
      dispatch({
        type: SpendsReducer.actions.AMOUNT_CHANGED,
        payload: { index, value, chain, balance: wallet.state.currentWalletInfo.balance },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const setIncludeReturnAddress = useCallback(
    (value) =>
      dispatch({
        type: 'include-return-address-changed',
        payload: { value },
      }),
    []
  );
  const setIncludeSender = useCallback(
    (value) =>
      dispatch({
        type: 'include-sender-changed',
        payload: { value },
      }),
    []
  );

  const walletExecute = wallet.actions.execute;
  const walletAddresses = wallet.state.currentWalletInfo.keys.addresses;

  /**
   * Execute the contract and return the Tx object
   */
  const run = async () => {
    try {
      if (!valid || wallet.state.executing) return;

      dispatch({
        type: 'run-started',
      });
      const spends = state.spends
        .filter((spend) => spend.amountValid && spend.assetValid)
        .map((spend) => ({
          asset: spend.asset,
          amount: assetUtils.toKalapas(spend.amount.safeValue),
        }));
      const result = await walletExecute({
        address: state.address,
        command: state.command,
        includeReturnAddress: state.includeReturnAddress,
        messageBody: state.messageBody,
        spends,
        path: state.includeSender ? '0/0' : '',
      });
      dispatch({
        type: 'run-success',
      });
      return result;
    } catch (error) {
      dispatch({
        type: 'run-failed',
        payload: { error },
      });
    }
  };

  const sign = async ({ tx, sign, password, passphrase, account }) => {
    try {
      if (!tx || wallet.state.executing) return false;

      dispatch({
        type: 'sign-started',
      });
      const result = await wallet.actions.signContractExecution({
        tx,
        sign,
        password,
        passphrase,
        account,
      });
      if (ZenJsUtils.checkWitnesses({ tx: result, addresses: walletAddresses, chain })) {
        dispatch({
          type: 'sign-success',
        });
        return result;
      } else throw new Error('Signature do not match address');
    } catch (error) {
      dispatch({
        type: 'sign-failed',
        payload: { error },
      });
      return false;
    }
  };

  const publish = async (signedTx) => {
    try {
      if (!signedTx || wallet.state.executing) return false;

      dispatch({
        type: 'publish-started',
      });
      const result = await ApiService.publishTx(nodeUrl, signedTx);

      dispatch({
        type: 'publish-success',
        payload: { txHash: result },
      });
      return true;
    } catch (error) {
      dispatch({
        type: 'publish-failed',
        payload: { error, signedTx },
      });
      return false;
    }
  };

  const reset = useCallback(() => dispatch({ type: 'reset' }), []);

  const runStarted = useCallback(() => dispatch({ type: 'pre-run' }), []);
  // -- actions

  return {
    state: {
      ...state,
      valid,
      assets: wallet.state.currentWalletInfo.assets,
      activeContracts,
      balance: wallet.state.currentWalletInfo.balance,
    },
    actions: {
      setActiveContracts,
      setAddress,
      setAddressReadOnly,
      setAllReadOnly,
      setCommand,
      setMessageBody,
      addSpend,
      removeSpend,
      setAsset,
      setAmount,
      setIncludeReturnAddress,
      setIncludeSender,
      run,
      sign,
      publish,
      reset,
      runStarted,
    },
  };
}

function validateAddress({ chain, activeContracts, address } = {}) {
  return (
    ZenJsUtils.validateAddress(chain, address) &&
    (activeContracts || []).some((c) => c.address === address)
  );
}

const executeReducer = produce((draft, { type, payload }) => {
  const handlers = {
    'progress-changed': () => {
      draft.progress = payload.value;
    },
    'active-contracts-changed': () => {
      draft.activeContracts = payload.value;
    },
    'address-changed': () => {
      preventChangeOnProgress(draft, () => {
        if (!draft.addressReadOnly) {
          draft.address = payload.value;
          draft.contractId = (
            draft.activeContracts?.find((c) => c.address === payload.value) || { contractId: '' }
          ).contractId;
          draft.addressValid = validateAddress({
            chain: payload.chain,
            activeContracts: draft.activeContracts,
            address: payload.value,
          });
        }
      });
    },
    'address-revalidate': () => {
      preventChangeOnProgress(draft, () => {
        draft.addressValid = validateAddress({
          address: draft.address,
          chain: payload.chain,
          activeContracts: draft.activeContracts,
        });
      });
    },
    'address-readonly-changed': () => {
      draft.addressReadOnly = payload.value;
    },
    'all-readonly-changed': () => {
      draft.allReadOnly = payload.value;
    },
    'command-changed': () => {
      preventChangeOnProgress(draft, () => {
        draft.command = payload.value;
      });
    },
    'body-changed': () => {
      preventChangeOnProgress(draft, () => {
        draft.messageBody = payload.value;
        draft.messageBodyValid = ZenJsUtils.validateMessageBody(payload.chain, payload.value);
        draft.messageBodyType = ZenJsUtils.getMessageBodyType(payload.chain, payload.value);
      });
    },
    'include-return-address-changed': () => {
      preventChangeOnProgress(draft, () => {
        draft.includeReturnAddress = payload.value;
      });
    },
    'include-sender-changed': () => {
      preventChangeOnProgress(draft, () => {
        draft.includeSender = !draft.isWatch && payload.value;
      });
    },
    reset: () => {
      preventChangeOnProgress(draft, () => _reset(draft));
    },
    'pre-run': () => {
      draft.progress = false;
      draft.result.type = '';
      draft.result.message = '';
      draft.result.data = {};
    },
    'run-started': () => {
      draft.progress = true;
      draft.result.type = '';
      draft.result.message = '';
      draft.result.data = {};
    },
    'run-success': () => {
      draft.progress = false;
      draft.result.type = 'execute-success';
      draft.result.message = '';
      draft.result.data = {};
    },
    'run-failed': () => {
      draft.progress = false;
      draft.result.type = 'error-execute';
      draft.result.message = (payload.error || {}).message;
      draft.result.data = {};
    },
    'sign-started': () => {
      draft.progress = true;
      draft.result.type = '';
      draft.result.message = '';
      draft.result.data = {};
    },
    'sign-success': () => {
      draft.progress = false;
    },
    'sign-failed': () => {
      draft.progress = false;
      draft.result.type = 'error-sign';
      draft.result.message = (payload.error || {}).message;
    },
    'publish-started': () => {
      draft.progress = true;
      draft.result.type = '';
      draft.result.message = '';
      draft.result.data = {};
    },
    'publish-success': () => {
      draft.progress = false;
      draft.result.type = 'success';
      draft.result.message = '';
      draft.result.data = {
        txHash: payload.txHash,
      };
      _reset(draft);
    },
    'publish-failed': () => {
      draft.progress = false;
      draft.result.type = 'error-publish';
      draft.result.message = (payload.error || {}).message;
      draft.result.data = {
        signedTx: payload.signedTx,
      };
    },
    'set-watch-mode': () => {
      draft.isWatch = payload.isWatch;
    },
  };

  if (typeof handlers[type] === 'function') {
    handlers[type]();
  }
});

function reducer(state, action) {
  // pass payload value through the func, if not, pass as normal
  return tryParseFieldsJson({
    state,
    action,
    callback: () => executeReducer(SpendsReducer.reducer(state, action), action),
  });
}

/**
 * Try to parse value as a fields json, if can't, calls the callback to further process
 */
function tryParseFieldsJson({ state, action, callback }) {
  try {
    const json = JSON.parse(action.payload.value);
    if (Object.keys(json).length === 0) throw new Error('Not a fields json or no fields available');
    return Object.keys(json).reduce((draft, field) => {
      const fieldValue =
        state.address !== json['address'] && state.addressReadOnly ? '' : json[field];
      switch (field) {
        case 'address':
          return executeReducer(draft, {
            type: 'address-changed',
            payload: { ...action.payload, value: fieldValue },
          });
        case 'spends':
          return SpendsReducer.reducer(draft, {
            type: SpendsReducer.actions.SET_SPENDS,
            payload: {
              ...action.payload,
              value: fieldValue.map((spend) =>
                SpendUtils.getSpend({
                  asset: spend.asset,
                  amount: ValueDisplay.create(spend.amount),
                })
              ),
            },
          });
        case 'command':
          return executeReducer(draft, {
            type: 'command-changed',
            payload: { ...action.payload, value: fieldValue },
          });
        case 'messageBody':
          return executeReducer(draft, {
            type: 'body-changed',
            payload: { ...action.payload, value: fieldValue },
          });
        case 'includeAuthentication':
          return executeReducer(draft, {
            type: 'include-sender-changed',
            payload: { ...action.payload, value: fieldValue },
          });
        case 'includeReturnAddress':
          return executeReducer(draft, {
            type: 'include-return-address-changed',
            payload: { ...action.payload, value: fieldValue },
          });
        default:
          return draft;
      }
    }, state);
  } catch (error) {
    return callback();
  }
}

/**
 * Prevent changing the state if progress is true
 *
 * @param {*} draft
 * @param {Function} change
 */
function preventChangeOnProgress(draft, change) {
  if (draft.progress) return;
  change();
}

function _reset(draft) {
  if (!draft.addressReadOnly) {
    draft.address = '';
    draft.addressValid = false;
  }
  draft.command = '';
  draft.messageBody = '';
  draft.contractId = '';
  draft.messageBodyValid = true;
  draft.includeReturnAddress = false;
  draft.includeSender = false;

  const res = SpendsReducer.reducer(draft, { type: SpendsReducer.actions.CLEAR });
  draft.spends = res.spends;
  draft.spendsValid = res.spendsValid;
}
