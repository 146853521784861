import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  Box,
  Button,
  CopiedMessage,
  FaSvg,
  Input,
  LabelText,
  Modal,
  ModalContent,
  ModalHeader,
  QRGenerator,
  RadioGroup,
  themeUtils as tu,
  useModal,
  useTheme,
} from '@zen-common/components-base';
import { useCopy } from '@zen/common-react-hooks';
import { faQrcode } from '@fortawesome/pro-light-svg-icons';
import { textUtils } from '@zen/common-utils';
import * as styles from '../../styles';

function ReceiveForm({ address, addresses, config, title = 'Your Address', updateAddress }) {
  const [addressCopied, errorCopying, onAddressCopy] = useCopy({ value: address });
  const isMobile = document.body.clientWidth < tu.breakpoints.xl.replace('px', '');

  const handleUpdate = useCallback(
    (e) => {
      updateAddress({ address: e.target.value.trim() });
    },
    [updateAddress]
  );
  const handlePkFocus = useCallback(() => {
    onAddressCopy();
  }, [onAddressCopy]);

  return (
    <>
      <BorderedContainer>
        <Box display="flex" flexDirection={['column', null, 'row']}>
          <Box flexGrow="1" width={['100%', 'auto', null]}>
            <LabelText>{`${title} ${addresses[0] === address ? '(main)' : '(change)'}`}</LabelText>
            <Input
              onClick={handlePkFocus}
              readOnly
              inputType="expandable"
              value={address}
              variant="block"
            />
            <CopiedMessage
              keepHeight
              copied={addressCopied}
              error={errorCopying}
              what="Public address"
            />
          </Box>
          <ActionButton
            bg="secondary"
            onClick={onAddressCopy}
            alignSelf={['start', null, 'center']}
            mt={['smd', null, '0']}
          >
            Copy
          </ActionButton>

          <QrDisplay address={address} config={config} />
        </Box>
      </BorderedContainer>
      <StyledRadioGroup
        checked={address}
        onChange={handleUpdate}
        options={addresses.map((address) => ({
          value: address,
          label: `${addresses[0] === address ? 'Main' : 'Change'} | ${
            isMobile ? textUtils.truncateHash(address) : address
          }`,
        }))}
        column
        hasSeparator
      />
    </>
  );
}

ReceiveForm.propTypes = {
  address: PropTypes.string,
};

export default ReceiveForm;

function QrDisplay({ address, config }) {
  const theme = useTheme();
  const { show, hide } = useModal();
  const convertAddress = (address) =>
    config
      ? `https://${config.uri}/app/wallet/${
          config.appNameShort === 'Wallet' ? 'send' : 'withdraw'
        }?fields={"address":"${address}"}`
      : address;

  function openModal() {
    show(() => (
      <Modal>
        <ModalContent large>
          <ModalHeader>
            <StyledTitle>Address: {textUtils.truncateHash(address)}</StyledTitle>
          </ModalHeader>
          <BigQrWrapper onClick={hide}>
            <BigQr
              value={convertAddress(address)}
              includeMargin
              renderAs="svg"
              bgColor={tu.color('text')({ theme })}
            />
          </BigQrWrapper>
        </ModalContent>
      </Modal>
    ));
  }

  return (
    <ActionButton
      bg="secondary"
      onClick={() => openModal()}
      alignSelf={['start', null, 'center']}
      mt={['smd', null, '0']}
    >
      <FaSvg icon={faQrcode} />
    </ActionButton>
  );
}

const ActionButton = styled(Button)`
  margin-left: 10px;
  padding: ${tu.space('xs')};
`;

const StyledTitle = styled.span`
  ${tu.mq({
    color: 'white',
    fontSize: 'sm',
    mb: 2,
    display: 'block',
    letterSpacing: '0.32px',
    padding: '10px',
  })}
`;

const BigQrWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BigQr = styled(QRGenerator)`
  width: 80vw;
  height: 80vh;
  max-width: 500px;
  max-height: 500px;
`;

const BorderedContainer = styled(Box)`
  ${styles.borderBottomContainerCss}
`;

const StyledRadioGroup = styled(RadioGroup)`
  li {
    padding: ${tu.space('sm')} 0;
  }
`;
