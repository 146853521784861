import { styled } from 'styled-components';
import { Box, LinkButton, themeUtils as tu } from '@zen-common/components-base';

// Use this when presenting technical data (order details, vote details, vote success message)

export const Section = styled(Box)`
  line-height: ${tu.lineHeight(3)};
  margin-bottom: ${tu.space('smd')};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled(Box)`
  color: ${tu.color('text')};
  display: inline-block;
  line-height: ${tu.lineHeight(5)};
`;

export const Label = styled(Box)`
  font-size: ${tu.fontSize('sm')};
  line-height: ${tu.lineHeight(3)};
  display: inline-block;
  color: ${tu.color('label')};
`;
Label.defaultProps = {
  mr: 1,
};
export const Value = styled(Label)`
  color: ${tu.color('text')};
`;

const StyledLinkButton = styled(LinkButton)`
  padding: 0;
  margin-left: 15px;
  font-size: ${tu.fontSize('sm')};
`;

/**
 * Renders a show more link
 * @param {Object} params
 * @param {boolean} params.isMore - true if showing more items, false if not
 * @param {() => void} params.onClick
 */
export const ShowMoreLink = ({ isMore, onClick }) => {
  return <StyledLinkButton onClick={onClick}>show {isMore ? 'less' : 'more'}</StyledLinkButton>;
};
