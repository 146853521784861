import { useState } from 'react';
import { Box, ModalHeader, ModalSection, Title } from '@zen-common/components-base';
import { useUnmountedRef } from '@zen/common-react-hooks';
import { clipboard } from '@zen/common-utils-browser';
import WalletStore from '../../../stores/WalletStore';
import ExecuteContractStore from '../../../stores/ExecuteContractStore';
import ConfirmPublishTxModalContent from '../../modals/ConfirmPublishTxModalContent';
import ConfirmContractExecution from '../ConfirmContractExecution';
import ExecuteContractForm from '../ExecuteContractModalProcess/ExecuteContractForm';

export default function ExecuteContractModalProcess({ contractId, onFinish, naming }) {
  const unmounted = useUnmountedRef();
  const [status, setStatus] = useState('execute-form');
  const [runResult, setRunResult] = useState();
  const {
    state: {
      currentWalletInfo: {
        keys: { pkHash },
      },
    },
  } = WalletStore.useStore();
  const executeStore = ExecuteContractStore.useStore();
  const contractName = naming[contractId] ? naming[contractId].shortName : '';

  const handleExecuteFormSubmit = async () => {
    const runResult = await executeStore.actions.run();
    if (!unmounted.current) {
      setRunResult(runResult);
      setStatus('confirm-after-execution');
    }
  };

  const handleConfirmAfterExecutionBack = () => {
    setStatus('execute-form');
  };
  const handleConfirmAfterExecutionContinue = () => {
    setStatus('confirm-after-sign');
  };

  const handleConfirmAfterSignBack = () => {
    setStatus('confirm-after-execution');
  };
  const handleCopy = async () => {
    try {
      await clipboard.writeText(runResult.tx);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
  const handleConfirmAfterSignContinue = async () => {
    await handleCopy();
    onFinish();
  };

  if (status === 'execute-form') {
    return (
      <Box>
        <ModalHeader>
          <Title>Execute Raw {contractName ? contractName : 'a Contract'}</Title>
        </ModalHeader>
        <ModalSection>
          <ExecuteContractForm onSubmit={handleExecuteFormSubmit} />
        </ModalSection>
      </Box>
    );
  }

  if (status === 'confirm-after-execution') {
    if (!runResult) return null;

    return (
      <ConfirmContractExecution
        contractName={contractName}
        contractAddress={executeStore.state.address}
        command={executeStore.state.command}
        includeReturnAddress={executeStore.state.includeReturnAddress}
        includeSender={executeStore.state.includeSender}
        messageBody={executeStore.state.messageBody}
        pkHash={pkHash}
        spends={executeStore.state.spends}
        tx={runResult.tx}
        cancelText="Back"
        onCancel={handleConfirmAfterExecutionBack}
        onConfirm={handleConfirmAfterExecutionContinue}
      />
    );
  }

  if (status === 'confirm-after-sign') {
    if (!runResult) return null;

    return (
      <ConfirmPublishTxModalContent
        title="Copy your unsigned TX"
        confirmText="Copy"
        tx={runResult.tx}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
      />
    );
  }

  return null;
}
