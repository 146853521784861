import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  Box,
  cssMixins,
  Form,
  HiddenSubmitButton,
  InputMessage,
  LinkButton,
  LoadingOr,
  Mnemonic,
  MnemonicInput,
  MnemonicListItem,
} from '@zen-common/components-base';
import { SeedPhrase } from '@zen/common-utils-seed';
import { useMnemonic } from '@zen/common-react-hooks';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import ResetShowAllSection from '../blocks/ResetShowAllSection';
import useSubmitWithLoading from '../hooks/useSubmitWithLoading';
import PaginationButton from '../blocks/PaginationButton';
import getMarginBottom from '../modules/getMarginBottom';
import { INVALID_SEED_MSG } from '../modules/texts';

export default function SecretPhraseQuiz({
  amount = 3,
  columns = [1, null, 3],
  storedMnemonic,
  onCreateWallet,
  onContinue,
  onCancel,
}) {
  // useMemo for not changing on each render
  const chosenIndexes = useMemo(() => {
    return chooseRandomIndexes(amount).sort((a, b) => a - b);
  }, [amount]);

  const [focusIndex, setFocusIndex] = useState(-1);
  const inputRefs = useRef([]); // contain all refs of all inputs
  const [showAll, setShowAll] = useState(false);
  const [valid, setValid] = useState(false);
  const { handleSubmit, loading } = useSubmitWithLoading({
    validate: () => valid,
    onSubmit: onContinue,
  });

  // set the chosen indexes to empty strings which the user should fill out
  const { allWordsValid, wordChangeHandler, wordsList, mnemonic, reset } = useMnemonic({
    initialValue: storedMnemonic.map((word, index) => (chosenIndexes.includes(index) ? '' : word)),
    generate: false,
  });

  useEffect(() => {
    const hasUnMatchedWord = storedMnemonic.find((word, i) => word !== mnemonic[i]) !== undefined;
    setValid(!hasUnMatchedWord);
  }, [storedMnemonic, mnemonic]);

  // a custom word list with a hidden attribute
  const wordsListWithHiddenAttribute = wordsList.map((item, index) => ({
    ...item,
    isHidden: !chosenIndexes.includes(index),
  }));

  const toggleShowAll = () => {
    setShowAll((showAll) => !showAll);
  };

  const handleReset = () => {
    reset();
    /**
     * trick: this will trigger the effect in the mnemonic input since the value is changing,
     * but it will not cause a flicker since the value is not a boolean
     */
    setShowAll('trick');
    setTimeout(() => setShowAll(false), 0);
  };

  const handleWordChange = (index, value) => {
    setFocusIndex(index);
    // disallow paste a mnemonic, just get the first word
    const _value = SeedPhrase.getPhraseFromClipboardContents(value)[0] || value;
    wordChangeHandler(index, _value);
  };

  // focus next input on final valid word
  useEffect(() => {
    if (focusIndex >= 0 && focusIndex < chosenIndexes[2] && wordsList[focusIndex].validity.final) {
      const nextIndex = chosenIndexes.findIndex((item) => item === focusIndex) + 1;
      inputRefs.current[chosenIndexes[nextIndex]].focus();
    }
  }, [chosenIndexes, focusIndex, wordsList]);

  return (
    <Layout
      header={
        <Header
          title="Verify your mnemonic phrase"
          text="Please enter the specific words below to make sure you saved it correctly."
        />
      }
      body={
        <MainContainer>
          <ResetShowAllSection
            disabled={loading}
            css={cssMixins.showOnMobile()}
            mb="mlg"
            onReset={handleReset}
            onShowAll={toggleShowAll}
            showingAll={showAll === true}
          />
          <Form onSubmit={handleSubmit} disabled={loading}>
            <HiddenSubmitButton />
            <Mnemonic
              wordChangeHandler={handleWordChange}
              wordsList={wordsListWithHiddenAttribute}
              isEdit={true}
              isVerify={true}
              columns={columns}
            >
              {({ item, index }) => (
                <HidableMnemonicListItem key={index} className={item.isHidden ? 'hidden' : ''}>
                  <MnemonicInput
                    ref={(input) => {
                      inputRefs.current[index] = input;
                    }}
                    onBlur={() => setFocusIndex(-1)}
                    index={index}
                    word={item.word}
                    validity={item.validity}
                    onChange={handleWordChange}
                    visible={showAll}
                    disabled={loading}
                  />
                </HidableMnemonicListItem>
              )}
            </Mnemonic>
          </Form>
          <ResetShowAllSection
            disabled={loading}
            mt="smd"
            css={cssMixins.showOnDesktop()}
            onReset={handleReset}
            onShowAll={toggleShowAll}
            showingAll={showAll === true}
          />
          <InputMessage mt={[0, 'smd']}>
            {allWordsValid && !valid ? INVALID_SEED_MSG : ''}
          </InputMessage>
        </MainContainer>
      }
      footer={
        <Footer
          left={
            <Box
              css={cssMixins.showOnDesktop('flex')}
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box>I did not write my recovery phrase</Box>
              <LinkButton onClick={onCreateWallet} fontSize="md">
                Create a new wallet
              </LinkButton>
            </Box>
          }
          right={
            <>
              <PaginationButton disabled={loading} onClick={onCancel} mr="smd" bg="secondary">
                Cancel
              </PaginationButton>
              <PaginationButton disabled={!valid || loading} onClick={handleSubmit}>
                <LoadingOr loading={loading}>Continue</LoadingOr>
              </PaginationButton>
            </>
          }
        />
      }
      onClose={onCancel}
    />
  );
}

SecretPhraseQuiz.propTypes = {
  amount: PropTypes.number,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  storedMnemonic: PropTypes.array.isRequired,
  onCreateWallet: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const HidableMnemonicListItem = styled(MnemonicListItem)`
  &.hidden {
    position: absolute;
    visibility: hidden;
  }
`;

/**
 * Get random indexes - no duplicates
 * @param {number} amount the amount of indexes to choose
 */
function chooseRandomIndexes(amount = 3) {
  const availableIndexes = Array(24)
    .fill(0)
    .map((val, index) => index);
  const _chosenIndexes = [];
  for (let i = 0; i < amount; i++) {
    const chosenIndex = Math.floor(Math.random() * Math.floor(availableIndexes.length));
    _chosenIndexes.push(availableIndexes[chosenIndex]);
    availableIndexes.splice(chosenIndex, 1);
  }
  return _chosenIndexes;
}

const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${getMarginBottom}
`;
