import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { clipboard } from '@zen/common-utils-browser';
import tu from '../../utils/themeUtils';
import resetButton from '../../utils/styles/mixins/resetButton';
import useTooltip from '../Tooltip/useTooltip';
import Tooltip from '../Tooltip/Tooltip';
import IconButton from '../Button/IconButton';

/**
 * CopyButton
 *
 * @export
 * @param errorText
 * @param {Object} props
 * @param {string} props.value - the value to copy
 * @param {string} props.copyText - the text to show on hover
 * @param {string} props.copiedText - the text to show after copy
 */
export default function CopyButton({
  value,
  copyText = 'Copy',
  copiedText = 'Copied to clipboard',
  errorText = 'Could not copy',
  ...props
}) {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);
  const { hide, showAndUpdate, ref, tooltipProps, visible } = useTooltip({ placement: 'top' });

  const handleCopy = async () => {
    try {
      await clipboard.writeText(value);
      setCopied(true);
    } catch (error) {
      setError(true);
    }
    showAndUpdate();
  };

  const handleDismiss = () => {
    hide();
    setCopied(false);
    setError(false);
  };

  return (
    <>
      <StyledIconButton
        icon={faCopy}
        onClick={handleCopy}
        onMouseEnter={showAndUpdate}
        onMouseLeave={handleDismiss}
        onBlur={handleDismiss}
        ref={ref}
        {...props}
      />
      {/* render only when needed, much better performance on large tables */}
      {visible && (
        <StyledTooltip {...tooltipProps}>
          {copied ? copiedText : error ? errorText : copyText}
        </StyledTooltip>
      )}
    </>
  );
}

CopyButton.propTypes = {
  value: PropTypes.string.isRequired,
  copyText: PropTypes.string,
  copiedText: PropTypes.string,
  errorText: PropTypes.string,
};

const StyledIconButton = styled(IconButton)`
  ${resetButton}
  border-radius: ${tu.radius(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: ${tu.space(2)};
`;

const StyledTooltip = styled(Tooltip)`
  white-space: nowrap;
`;
