import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, useModal } from '@zen-common/components-base';
import { Link } from 'react-navi';
import SettingLayout from '../containers/SettingLayout';
import SettingButton from '../common/SettingButton';
import SettingsStore from '../../../stores/SettingsStore/SettingsStore';
import AccountsModalContent from './components/AccountsModalContent';

const AddAccountSetting = ({ useSetting, ...props }) => {
  const { show, hide } = useModal();
  const { onAction, wallet } = useSetting();
  const {
    state: { wallets },
  } = wallet;
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const showAddAccountModal = () => {
    show(() => (
      <Modal>
        <ModalContent>
          <AccountsModalContent onCancel={hide} onAdd={onAction} wallet={wallet} />
        </ModalContent>
      </Modal>
    ));
  };
  return (
    <SettingLayout
      header="View your Accounts"
      paragraph={`${
        wallets[chain].filter((w) => w.keys.addresses?.length).length
      } accounts connected`}
      {...props}
    >
      {!wallets[chain].filter((w) => w.keys.addresses?.length).length ? (
        <Link href="/app/onboarding">
          <Button sizeVariant="small" px="xs">
            Connect a Wallet
          </Button>
        </Link>
      ) : (
        <SettingButton onClick={showAddAccountModal}>View</SettingButton>
      )}
    </SettingLayout>
  );
};

AddAccountSetting.propTypes = {
  useSetting: PropTypes.func,
  viewAccount: PropTypes.number,
};

export default AddAccountSetting;
