import { styled } from 'styled-components';
import { faCube, faFileContract, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  Description,
  FaSvg,
  Paragraph,
  Tooltip,
  useTooltip,
} from '@zen-common/components-base';
import { getExplorerUrl } from '../modules/utils';
import {
  TopExplorerLink,
  TopExternalLink,
  TopLinksContainer,
} from '../../../components/voting/TopLinks';

export default function TopParagraph({ interval, phase, addressVote }) {
  return (
    <Box>
      <Description>
        <Paragraph>
          Vote on your preferred commit ID to influence protocol upgrades. The community vote is
          split into two phases, each phase has its own snapshot.
        </Paragraph>
      </Description>
      <TopLinksContainer>
        <LearnMoreLink />
        <TopExplorerLink path={getExplorerUrl({ interval, phase })}>
          <FaSvg icon={faCube} mr="2" />
          Block Explorer
        </TopExplorerLink>
        <TopExplorerLink path={`/contracts/${addressVote}`}>
          <FaSvg icon={faFileContract} mr="2" />
          Voting Contract
        </TopExplorerLink>
      </TopLinksContainer>
    </Box>
  );
}

const LearnMoreLink = () => {
  const { hide, ref, tooltipProps, showAndUpdate } = useTooltip({ placement: 'auto-end' });

  return (
    <>
      <TopExternalLink href="https://gitlab.com/zenprotocol/zenprotocol/-/blob/master/AUTHORIZED_PROTOCOL.pdf">
        <Box ref={ref} onMouseEnter={showAndUpdate} onMouseLeave={hide} position="relative">
          <FaSvg icon={faInfoCircle} mr="2" />
          Learn More
          <StyledTooltip {...tooltipProps}>See software license</StyledTooltip>
        </Box>
      </TopExternalLink>
    </>
  );
};

const StyledTooltip = styled(Tooltip)`
  white-space: nowrap;
`;
