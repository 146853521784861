import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { ExplorerService } from '@zen/common-app-parts';

/**
 * Fetch a phase by interval and phase
 * @param {Object} params
 * @param {import('../types').Chain} params.chain
 * @param {Object} params.config
 * @param {import('../types').Phase} params.relevantPhase
 * @returns {import('react-query').QueryResult<import('../types').Phase>}
 */
export default function useFetchOtherPhase({ chain, config, relevantPhase = {} } = {}) {
  const enabled = !!relevantPhase.interval; // current phase exist
  const { interval, phase } = relevantPhase;
  return useQuery(
    ['governance-phase', { interval, phase }],
    useCallback(async () => {
      const service = ExplorerService({ chain, config });
      const result = await service.governance.getPhase({
        interval,
        phase: phase === 'Candidate' ? 'Contestant' : 'Candidate',
      });
      return result.data;
    }, [chain, config, interval, phase]),
    { enabled, refetchOnWindowFocus: false }
  );
}
