import { styled } from 'styled-components';
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  Button,
  cssMixins,
  FaSvg,
  IconButton,
  themeUtils as tu,
} from '@zen-common/components-base';

export const AddSpendAligner = styled(Box)`
  display: flex;
  justify-content: ${(p) => (p.align === 'right' ? 'flex-end' : 'flex-start')};
`;

const StyledAddSpendButton = styled(Button)`
  ${cssMixins.resetButton}
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
  svg {
    transition: none;
  }
  &:not([disabled]) {
    color: ${tu.color('label')};

    &:hover {
      color: ${tu.color('text')};
    }
  }
`;

/**
 * Displays the add spend button with text and icon
 * @param {React.ButtonHTMLAttributes} props
 */
export const AddSpendButton = (props) => {
  return (
    <StyledAddSpendButton bg="bg2" disabledBg="bg2" {...props}>
      <FaSvg icon={faPlusCircle} fontSize="xxl" mr="sm" />
      Add Asset & Amount
    </StyledAddSpendButton>
  );
};

const SpendAndRemoveWrapper = styled(Box)`
  display: flex;
  align-items: stretch;
  margin-top: ${(p) => (!p.noMarginTop ? 0 : p.isFirst ? 0 : tu.space('xl')(p))};
`;

const StyledRemoveSpendWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: ${tu.space('sm')};
`;

export const RemoveSpendButton = styled(IconButton).attrs({
  icon: faTimesCircle,
  'aria-label': 'Remove spend',
})`
  width: auto;
  height: auto;
  padding: ${tu.space('sm')};
  font-size: ${tu.fontSize('xxl')};
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }
`;

/**
 * Aligns the spend and the remove button
 */
export const SpendAndRemoveAligner = ({
  spend,
  button,
  showButton,
  noMarginTop,
  isFirst,
  ...props
}) => {
  return (
    <SpendAndRemoveWrapper noMarginTop={noMarginTop} isFirst={isFirst} {...props}>
      <Box flexGrow="1">{spend}</Box>
      {showButton && <StyledRemoveSpendWrapper>{button}</StyledRemoveSpendWrapper>}
    </SpendAndRemoveWrapper>
  );
};
