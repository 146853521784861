import { notification } from '@zen-common/components-base';
import WalletStore from '../../../stores/WalletStore';
import SettingsStore from '../../../stores/SettingsStore';
import useWalletStateTransitions from './useWalletStateTransitions';

export default function WalletNotifications() {
  const { state } = WalletStore.useStore();
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  useWalletStateTransitions({
    onAction,
    isLoggedIn: state.currentWalletInfo.isLoggedIn,
    encryptedSeed: state.currentWalletInfo.encryptedSeed,
    chain,
  });

  return null;
}

function onAction(type) {
  switch (type) {
    case 'create':
      notification('Wallet successfully connected', { type: notification.TYPE.SUCCESS });
      break;
    case 'wipe':
      notification('Wallet successfully removed', { type: notification.TYPE.SUCCESS });
      break;
    case 'login':
      break;
    case 'logout':
      break;

    default:
      throw new Error('Unhandled type');
  }
}
