import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import getTransition from '../../utils/styles/mixins/getTransition';
import Box from '../Box';
import CopyButton from './CopyButton';

/**
 * Copyable - show a copy button next to an element
 *
 * @export
 * @param {Object} props
 * @param {string} props.value - the value to copy
 * @param {('all'|'touch'|'none')} props.forceShowCopy - force showing the copy button on different states
 * @param {*} props.children - any element to display
 */
export default function Copyable({ value, forceShowCopy, children }) {
  return (
    <Container>
      <Box pr="lg">{children}</Box>
      <CopyButtonPlacement forceShowCopy={forceShowCopy}>
        <CopyButton value={value} />
      </CopyButtonPlacement>
    </Container>
  );
}

Copyable.propTypes = {
  value: PropTypes.string.isRequired,
  forceShowCopy: PropTypes.string,
};
Copyable.defaultProps = {
  forceShowCopy: 'touch',
};

const Container = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CopyButtonPlacement = styled(Box)`
  position: absolute;
  right: 0;
  opacity: ${(p) => (p.forceShowCopy === 'all' ? '1' : '0')};
  @media (hover: none) {
    opacity: ${(p) => (p.forceShowCopy === 'touch' ? '1' : '0')};
  }

  ${Container}:hover & {
    opacity: 1;
  }

  ${getTransition()}
`;
