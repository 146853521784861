import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { Container, themeUtils as tu } from '@zen-common/components-base';
import FullSizeBox from '../FullSizeBox';

/**
 * A layout with a bordered container and an optional nav on top
 *
 * @param {Object} props
 * @param {*} props.nav - an optional nav component
 */
export default function PageLayout({ nav, children }) {
  return (
    <StyledContainer>
      {nav}
      <FullSizeBox>{children}</FullSizeBox>
    </StyledContainer>
  );
}

PageLayout.propTypes = {
  nav: PropTypes.any,
  children: PropTypes.any,
};

const StyledContainer = styled(Container)`
  flex-grow: 1;
  flex-shrink: 0;
  border-style: solid;
  border-color: ${tu.color('borderDark')};
  border-width: 0 1px;
  display: flex;
  flex-direction: column;
`;
