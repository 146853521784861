import { forwardRef, useRef } from 'react';
import { styled } from 'styled-components';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import Box from '../Box';
import FaSvg, { StyledSvg } from '../helpers/FaSvg';
import Input from './Input';

/**
 * @type {React.ForwardRefExoticComponent<import('@zen-common/components-base').InputProps & React.RefAttributes<any>}
 */
const ClearInput = forwardRef(({ readOnly, value, onClear, valid, ...props }, ref) => {
  const inputRef = useRef(null);
  const finalRef = ref || inputRef;
  const onClearClick = () => {
    onClear();
    finalRef.current.focus();
  };
  const enableClear = !readOnly;
  const clearButtonStyles = {
    cursor: 'pointer',
  };
  const clearBtnHideProps = !value
    ? {
        style: { pointerEvents: 'none' },
        color: 'transparent',
      }
    : {};
  return (
    <StyledInput
      ref={finalRef}
      readOnly={readOnly}
      value={value}
      valid={valid}
      renderRightAddon={
        enableClear ? (
          <StyledBox show={!!value}>
            <FaSvg
              className="clear-btn"
              style={clearButtonStyles}
              icon={faTimesCircle}
              color="label"
              onClick={onClearClick}
              {...clearBtnHideProps}
            />
          </StyledBox>
        ) : null
      }
      {...props}
    />
  );
});
ClearInput.displayName = 'ClearInput';
export default ClearInput;

const StyledBox = styled(Box).attrs({
  width: '34px',
  px: 'xxs',
})`
  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    ${StyledSvg} {
      filter: brightness(1.25);
    }
  }
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;
