import PropTypes from 'prop-types';
import { css, styled } from 'styled-components';
import Box from '../Box';
import { StyledInputButton } from '../Button/Button';
import tu from '../../utils/themeUtils';

const DEFAULT_MARGIN_BOTTOM = 3;

/**
 * A <form/> wrapper
 *
 * @export
 * @param {Object} props
 * @param {boolean} props.disabled
 */
export default function Form({ disabled, children, ...props }) {
  return (
    <form {...props}>
      <StyledFieldSet disabled={disabled}>{children}</StyledFieldSet>
    </form>
  );
}
Form.propTypes = {
  disabled: PropTypes.bool,
};

/** Reset the fieldset and prevent pointer when disabled */
const StyledFieldSet = styled.fieldset`
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
`;

export const FormBody = styled(Box)`
  margin-bottom: -${tu.space(DEFAULT_MARGIN_BOTTOM)};

  ${(p) => {
    if (p.inline) {
      return css`
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      `;
    }
  }}
`;

export const FormGroup = styled(Box)`
  position: relative;
`;
FormGroup.defaultProps = {
  mb: DEFAULT_MARGIN_BOTTOM,
};

export const FormFooter = styled(FormGroup)`
  display: flex;
  flex-wrap: nowrap;
  margin-top: -${tu.space('smd')};
  margin-left: -${tu.space('smd')};

  ${tu.mq({
    justifyContent: ['space-evenly', 'flex-end'],
    flexWrap: ['wrap', 'no-wrap'],
  })}
  ${StyledInputButton} {
    min-width: 134px;
    margin-top: ${tu.space('smd')};
    margin-left: ${tu.space('smd')};
    ${css(
      tu.mq({
        flexGrow: ['1', '0'],
      })
    )}
  }
`;
