import MessageWithExplorerLink from '../MessageWithExplorerLink';

export function NoZpAtSnapshotMessage({ interval, voteStatus }) {
  return (
    <MessageWithExplorerLink
      isOngoing
      interval={interval}
      phase={voteStatus === 'nomination' ? 'nomination' : 'vote'}
    >
      You did not have any ZP at snapshot block.
      <br />
      Please make sure you have ZP in your wallet prior to the next snapshot.
    </MessageWithExplorerLink>
  );
}
