import PropTypes from 'prop-types';
import Hash from '../Hash/Hash';
import Copyable from '../Copy/Copyable';

/**
 * CopyableHash - show a hash and a copy button
 *
 * @param {Object} props
 * @param {string} props.hash - the hash to show
 * @param {boolean} props.truncate - whether to truncate the hash
 * @param {boolean} props.copy - whether to display a copy button
 * @param {('all'|'touch'|'none')} props.forceShowCopy - force showing the copy button on different states
 * @param {boolean} props.display - a display string to show instead of the hash
 */
export default function CopyableHash({
  hash,
  copy = true,
  forceShowCopy,
  truncate = true,
  display,
  ...props
}) {
  const hashComponent = <Hash hash={hash} truncate={truncate} display={display} {...props} />;
  return copy ? (
    <Copyable value={hash} forceShowCopy={forceShowCopy}>
      {hashComponent}
    </Copyable>
  ) : (
    hashComponent
  );
}

CopyableHash.propTypes = {
  hash: PropTypes.string.isRequired,
  copy: PropTypes.bool,
  forceShowCopy: PropTypes.string,
  truncate: PropTypes.bool,
  display: PropTypes.string,
};
