import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Box, FormGroup } from '@zen-common/components-base';
import Spend from '../Spend';
import { canAddAssetAmount, filterOutAlreadyUsedAssets } from '../spendUtils';
import {
  AddSpendAligner,
  AddSpendButton,
  RemoveSpendButton,
  SpendAndRemoveAligner,
} from './components/SpendsGroupBlocks';

/**
 * Renders a group of Spends with the ability to add or remove spends
 * @type {React.ForwardRefExoticComponent<TSpendsGroupProps & React.RefAttributes<any>}
 */
const SpendsGroup = forwardRef(
  (
    {
      spends,
      spendsValid,
      assets,
      balance,
      setAsset,
      setAmount,
      removeSpend,
      addSpend,
      noMarginTop,
      alignment,
      addButtonAlignment,
      readOnly,
      disabled,
      naming,
      assetReadOnly,
    },
    ref
  ) => {
    const lastSpendAssetRef = useRef(null);
    const addAssetAmountDisabled = !canAddAssetAmount({
      spends,
      spendsValid,
      assetsCount: assets.length,
    });

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (lastSpendAssetRef.current) {
          lastSpendAssetRef.current.focus();
        }
      },
    }));

    return (
      <Box>
        {spends.map((item, index) => (
          <FormGroup key={item.id}>
            <SpendAndRemoveAligner
              isFirst={index === 0}
              noMarginTop={noMarginTop}
              spend={
                <Spend
                  readOnly={readOnly}
                  disabled={disabled}
                  alignment={alignment}
                  amount={item.amount.display}
                  amountValid={item.amountValid}
                  asset={item.asset}
                  assetValid={item.assetValid}
                  assets={filterOutAlreadyUsedAssets({
                    assets,
                    spends,
                    index,
                  })}
                  balance={balance}
                  onAssetChange={(value) => setAsset({ value, index })}
                  onAmountChange={(value) => setAmount({ value, index })}
                  ref={index === spends.length - 1 ? lastSpendAssetRef : null}
                  naming={naming}
                  assetReadOnly={assetReadOnly}
                />
              }
              button={<RemoveSpendButton onClick={() => removeSpend(index)} />}
              showButton={!readOnly && !disabled && spends.length > 1}
            />
          </FormGroup>
        ))}
        {!readOnly && !disabled && (
          <FormGroup mb={6}>
            <AddSpendAligner align={addButtonAlignment}>
              <AddSpendButton disabled={addAssetAmountDisabled} onClick={addSpend} />
            </AddSpendAligner>
          </FormGroup>
        )}
      </Box>
    );
  }
);
SpendsGroup.displayName = 'SpendsGroup';
export default SpendsGroup;

/**
 * @typedef {Object} TSpendsGroupProps
 * @property {[import('../types').Spend]} spends
 * @property {boolean} spendsValid
 * @property {[string]} assets
 * @property {{[asset: string]: string}} balance
 * @property {({ value: string, index: number }) => void} setAsset
 * @property {({ value: string, index: number }) => void} setAmount
 * @property {(index: number) => void} removeSpend
 * @property {() => void} addSpend
 * @property {boolean} noMarginTop - no margin above each spend
 * @property {('default'|'inline'|'grid')} alignment - the spend alignment
 * @property {('left'|'right')} addButtonAlignment
 * @property {boolean} readOnly - make the spends readonly
 * @property {boolean} disabled - disable the spends completely (no events)
 */
