import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { color, layout, typography } from 'styled-system';
import Box from '../../Box';

/**
 * Display an offline bar
 *
 * @export
 * @param {Object} props
 * @param {string} props.text - the text to display
 */
export default function OfflineBar({ text = 'Offline', ...props }) {
  return (
    <StyledBar {...props}>
      <span>{text}</span>
    </StyledBar>
  );
}
OfflineBar.propTypes = {
  text: PropTypes.string,
};

const StyledBar = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    user-select: none;
  }

  ${color}
  ${layout}
  ${typography}
`;

StyledBar.defaultProps = {
  fontSize: 'md',
  height: 6,
  backgroundColor: 'danger',
  fontWeight: '500',
};
