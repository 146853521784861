import { useCallback, useMemo, useReducer } from 'react';
import { produce } from 'immer';
import { assetUtils, ValueDisplay } from '@zen/common-utils';
import { publishTx } from '../../utils/ApiService';
import * as ZenJsUtils from '../../utils/zenJsUtils';
import {
  SpendsReducer,
  SpendUtils,
  useRevalidateSpendsOnBalanceChange,
} from '../../components/Spend';

/**
 * @param {Object} params
 * @param {import("../WalletStore/WalletStore").WalletStore} params.wallet
 */
export default function useSendStore({ wallet, chain, nodeUrl } = {}) {
  const [_state, dispatch] = useReducer(reducer, initialState);
  /** @type {initialState} */
  const state = _state;

  const valid = useMemo(
    () => state.addressValid && state.spendsValid,
    [state.addressValid, state.spendsValid]
  );

  useRevalidateSpendsOnBalanceChange({
    dispatch,
    balance: wallet.state.currentWalletInfo.balance,
  });

  const walletSign = wallet.actions.signSendTx;
  const walletSendRaw = wallet.actions.send;
  const walletAddresses = wallet.state.currentWalletInfo.keys.addresses;

  // ACTIONS ---
  const reset = useCallback(() => {
    dispatch({ type: ACTIONS.RESET_REQUESTED });
  }, []);

  const send = useCallback(async () => {
    try {
      if (!valid || wallet.state.executing) return false;

      dispatch({ type: ACTIONS.SIGN.REQUESTED });
      const result = await walletSendRaw({
        address: state.address,
        spends: state.spends
          .filter((spend) => spend.amountValid && spend.assetValid)
          .map((spend) => ({
            asset: spend.asset,
            amount: assetUtils.toKalapas(spend.amount.safeValue),
          })),
      });
      dispatch({ type: ACTIONS.SIGN.SUCCEEDED });
      return result;
    } catch (error) {
      dispatch({ type: ACTIONS.SIGN.FAILED, payload: { error } });
      return false;
    }
  }, [state.address, state.spends, valid, wallet.state.executing, walletSendRaw]);
  const sign = useCallback(
    async (password, passphrase, account) => {
      try {
        if (!valid || wallet.state.executing) return false;

        dispatch({ type: ACTIONS.SIGN.REQUESTED });
        const result = await walletSign({
          address: state.address,
          spends: state.spends
            .filter((spend) => spend.amountValid && spend.assetValid)
            .map((spend) => ({
              asset: spend.asset,
              amount: assetUtils.toKalapas(spend.amount.safeValue),
            })),
          password,
          passphrase,
          account,
        });
        if (ZenJsUtils.checkWitnesses({ tx: result, addresses: walletAddresses, chain })) {
          dispatch({ type: ACTIONS.SIGN.SUCCEEDED });
          return result;
        } else throw new Error('Failed to verify signature with the provided address');
      } catch (error) {
        dispatch({ type: ACTIONS.SIGN.FAILED, payload: { error } });
        return false;
      }
    },
    [state.address, state.spends, valid, wallet.state.executing, walletAddresses, chain, walletSign]
  );
  const publish = useCallback(
    async (signedTx) => {
      try {
        if (!signedTx || wallet.state.executing) return false;

        dispatch({ type: ACTIONS.PUBLISH.REQUESTED });
        await publishTx(nodeUrl, signedTx);

        dispatch({ type: ACTIONS.PUBLISH.SUCCEEDED });
        return true;
      } catch (error) {
        dispatch({ type: ACTIONS.PUBLISH.FAILED, payload: { error, signedTx } });
        return false;
      }
    },
    [nodeUrl, wallet.state.executing]
  );
  const setAddress = useCallback(
    (address) =>
      !/[<>]/.test(address) &&
      dispatch({
        type: ACTIONS.ADDRESS_CHANGED,
        payload: {
          value: address,
          chain,
          balance: wallet.state.currentWalletInfo.balance,
        },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const setAsset = useCallback(
    ({ index, value }) =>
      dispatch({
        type: SpendsReducer.actions.ASSET_CHANGED,
        payload: {
          index,
          value,
          chain,
          balance: wallet.state.currentWalletInfo.balance,
        },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const setAmount = useCallback(
    ({ index, value }) =>
      dispatch({
        type: SpendsReducer.actions.AMOUNT_CHANGED,
        payload: {
          index,
          value,
          chain,
          balance: wallet.state.currentWalletInfo.balance,
        },
      }),
    [chain, wallet.state.currentWalletInfo.balance]
  );
  const addSpend = useCallback(() => dispatch({ type: SpendsReducer.actions.ADD_SPEND }), []);
  const removeSpend = useCallback(
    (index) =>
      dispatch({
        type: SpendsReducer.actions.REMOVE_SPEND,
        payload: { index, balance: wallet.state.currentWalletInfo.balance },
      }),
    [wallet.state.currentWalletInfo.balance]
  );
  // --- ACTIONS

  return {
    state: {
      ...state,
      assets: wallet.state.currentWalletInfo.assets,
      balance: wallet.state.currentWalletInfo.balance,
      valid,
      progress: wallet.state.loading.send || state.progress,
    },
    actions: {
      setAddress,
      setAsset,
      setAmount,
      addSpend,
      removeSpend,
      send,
      sign,
      publish,
      reset,
    },
  };
}

const initialState = {
  address: '',
  addressValid: false,
  spends: [SpendUtils.getSpend()],
  spendsValid: false,
  progress: false,
  result: {
    type: '',
    data: {
      address: '',
      spends: [],
      message: '',
    },
  },
};

const ACTIONS = {
  RESET_REQUESTED: 'RESET_REQUESTED',
  ADDRESS_CHANGED: 'ADDRESS_CHANGED',
  SIGN: {
    REQUESTED: 'SIGN_REQUESTED',
    SUCCEEDED: 'SIGN_SUCCEEDED',
    FAILED: 'SIGN_FAILED',
  },
  PUBLISH: {
    REQUESTED: 'SEND_REQUESTED',
    SUCCEEDED: 'SEND_SUCCEEDED',
    FAILED: 'SEND_FAILED',
  },
};

const sendReducer = produce(
  /**
   * @param {initialState} draft
   * @param {{type: string, payload: Object}} action
   */
  (draft, action) => {
    const actions = {
      [ACTIONS.ADDRESS_CHANGED]: () => {
        const { value, chain } = action.payload;
        draft.address = value;
        draft.addressValid = ZenJsUtils.validateAddress(chain, value);
      },
      [ACTIONS.RESET_REQUESTED]: () => {
        _reset({ result: true, draft });
      },
      [ACTIONS.SIGN.REQUESTED]: () => {
        draft.progress = true;
        draft.result = initialState.result;
      },
      [ACTIONS.SIGN.SUCCEEDED]: () => {
        draft.progress = false;
      },
      [ACTIONS.SIGN.FAILED]: () => {
        draft.progress = false;
        draft.result.type = 'error-sign';
        draft.result.data = {
          message: action.payload.error.message,
        };
      },
      [ACTIONS.PUBLISH.REQUESTED]: () => {
        draft.progress = true;
        draft.result = initialState.result;
      },
      [ACTIONS.PUBLISH.SUCCEEDED]: () => {
        draft.progress = false;
        draft.result.type = 'success';
        draft.result.data.address = draft.address;
        draft.result.data.spends = draft.spends.map((spend) => ({
          asset: spend.asset,
          amount: spend.amount.safeValue,
        }));
        draft.result.data.message = '';

        // reset form data
        _reset({ result: false, draft });
      },
      [ACTIONS.PUBLISH.FAILED]: () => {
        draft.progress = false;
        draft.result.type = 'error-publish';
        draft.result.data = {
          message: action.payload.error.message,
          signedTx: action.payload.signedTx,
          address: draft.address,
          spends: draft.spends.map((spend) => ({
            asset: spend.asset,
            amount: spend.amount.safeValue,
          })),
        };
      },
    };
    if (actions[action.type]) {
      actions[action.type]();
    }
  }
);

function reducer(state, action) {
  // pass payload value through the func, if not, pass as normal
  return tryParseFieldsJson({
    state,
    action,
    callback: () => sendReducer(SpendsReducer.reducer(state, action), action),
  });
}

/**
 * Try to parse value as a fields json, if can't, calls the callback to further process
 */
function tryParseFieldsJson({ state, action, callback }) {
  try {
    const json = JSON.parse(action.payload.value);
    if (Object.keys(json).length === 0) throw new Error('Not a fields json or no fields available');
    return Object.keys(json).reduce((draft, field) => {
      const fieldValue = json[field];
      switch (field) {
        case 'address':
          return sendReducer(draft, {
            type: ACTIONS.ADDRESS_CHANGED,
            payload: { ...action.payload, value: fieldValue },
          });
        case 'spends':
          return SpendsReducer.reducer(draft, {
            type: SpendsReducer.actions.SET_SPENDS,
            payload: {
              ...action.payload,
              value: fieldValue.map((spend) =>
                SpendUtils.getSpend({
                  asset: spend.asset,
                  amount: ValueDisplay.create(spend.amount),
                })
              ),
            },
          });
        default:
          return draft;
      }
    }, state);
  } catch (error) {
    return callback();
  }
}

function _reset({ result = false, draft } = {}) {
  draft.address = initialState.address;
  draft.addressValid = initialState.addressValid;
  if (result) {
    draft.result = initialState.result;
  }

  const res = SpendsReducer.reducer(draft, { type: SpendsReducer.actions.CLEAR });
  draft.spends = res.spends;
  draft.spendsValid = res.spendsValid;
}
