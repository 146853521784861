import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  FormBody,
  FormGroup,
  InputMessage,
  Label,
  LabelText,
  LoadingOr,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ModalSeparator,
  notification,
  Paragraph,
  PasswordInput,
  Title,
} from '@zen-common/components-base';
import { useForm, useResetError } from '@zen/common-react-hooks';
import actions from '../../ChangePasswordSettingActions';
import { formSchema } from './formSchema';

const ChangePasswordModal = ({ hide, appNameShort, useSetting }) => {
  const { values, errors, isDirty, handleOnChange, handleOnSubmit, isSubmitted, isDisabled } =
    useForm(formSchema, handleSave);
  const { currentPassword, newPassword, verifyPassword } = values;
  const currentPasswordInputRef = useRef(null);
  const [changeFinished, setChangeFinished] = useState(false);
  const setting = useSetting();
  const submitError = useResetError({
    externalError: setting.error,
    resetDependencies: [values],
  });

  const currentPasswordValidState = isSubmitted && submitError !== '' ? false : undefined;

  useEffect(() => {
    currentPasswordInputRef.current.focus();
  }, []);

  async function handleSave() {
    if (!setting.loading && typeof setting.onAction === 'function') {
      setChangeFinished(false);
      await setting.onAction({
        type: actions.submit,
        value: { currentPassword: currentPassword, newPassword: newPassword },
      });
      setChangeFinished(true);
      currentPasswordInputRef.current.focus();
    } else {
      throw new Error('onAction is not a function');
    }
  }

  // show success notification
  useEffect(() => {
    if (changeFinished && setting.success) {
      notification('Changed password successfully', { type: notification.TYPE.SUCCESS });
      hide();
    }
  }, [changeFinished, hide, setting.success]);

  return (
    <Modal>
      <ModalContent>
        <ModalHeader>
          <Title>Change login password</Title>
          <Paragraph>Set up your login password to unlock the {appNameShort}</Paragraph>
        </ModalHeader>
        <ModalSeparator />
        <Form onSubmit={handleOnSubmit} disabled={setting.loading}>
          <ModalSection>
            <FormBody>
              <FormGroup mb="xs">
                <Label htmlFor="ChangePasswordModal-CurrentPassword">
                  <LabelText>Current Password</LabelText>
                </Label>
                <PasswordInput
                  id="ChangePasswordModal-CurrentPassword"
                  ref={currentPasswordInputRef}
                  variant="block"
                  name="currentPassword"
                  valid={currentPasswordValidState}
                  value={currentPassword}
                  onChange={handleOnChange}
                  autoComplete="current-password"
                  disabled={setting.loading}
                />
                <InputMessage>{submitError}</InputMessage>
              </FormGroup>
              <FormGroup mb="xs">
                <Label htmlFor="ChangePasswordModal-NewPassword">
                  <LabelText>New Password</LabelText>
                </Label>
                <PasswordInput
                  id="ChangePasswordModal-NewPassword"
                  variant="block"
                  name="newPassword"
                  valid={isDirty.newPassword && !errors.newPassword}
                  value={newPassword}
                  onChange={handleOnChange}
                  autoComplete="new-password"
                  disabled={setting.loading}
                />
                <InputMessage>{errors.newPassword}</InputMessage>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="ChangePasswordModal-VerifyNewPassword">
                  <LabelText>Verify New Password</LabelText>
                </Label>
                <PasswordInput
                  id="ChangePasswordModal-VerifyNewPassword"
                  variant="block"
                  name="verifyPassword"
                  valid={isDirty.verifyPassword && !errors.verifyPassword}
                  value={verifyPassword}
                  onChange={handleOnChange}
                  autoComplete="new-password"
                  disabled={setting.loading}
                />
                <InputMessage>{errors.verifyPassword}</InputMessage>
              </FormGroup>
            </FormBody>
          </ModalSection>
          <ModalSeparator />
          <ModalFooter>
            <Button type="submit" disabled={isDisabled || setting.loading}>
              <LoadingOr loading={setting.loading}>Save Changes</LoadingOr>
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
