import { Modal, useModal } from '@zen-common/components-base';
import BallotIdGeneratorModalContent from './BallotIdGeneratorModalContent';

export default function BallotIdGeneratorModal({ cgpBalance }) {
  const { hide } = useModal();

  return (
    <Modal>
      <BallotIdGeneratorModalContent cgpBalance={cgpBalance} onClose={hide} />
    </Modal>
  );
}
