import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { faCompress, faExpand, faTimes } from '@fortawesome/pro-light-svg-icons';
import { showOnDesktop } from '../../utils/styles/mixins';
import IconButton from '../Button/IconButton';
import { useModal } from './ModalRoot';
import {
  StyledModalContainer,
  StyledModalContent,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalPanel,
  StyledModalSection,
  StyledModalSeparator,
  TopButtonsContainer,
} from './ModalContent.styled';

/**
 * The modal container, including the backdrop and a placeholder for the content
 */
export const Modal = ({ dismissOnClickOutside, onDismiss, children }) => {
  const { isOpen, hide } = useModal();
  const [className, setClassName] = useState('');
  const containerRef = useRef();

  // trigger animation by replacing css class
  useLayoutEffect(() => {
    containerRef.current.focus();
    setClassName(isOpen ? 'is-open' : '');
  }, [isOpen]);

  const dismiss = () => {
    typeof onDismiss === 'function' && onDismiss();
    hide();
  };

  const onKeyDown = (event) => {
    if (dismissOnClickOutside && event.keyCode === 27) {
      dismiss();
    }
  };

  const onMouseDown = (e) => {
    if (e.target === e.currentTarget && dismissOnClickOutside) {
      dismiss();
    }
  };

  return (
    <StyledModalContainer
      tabIndex={dismissOnClickOutside ? '0' : '-1'}
      ref={containerRef}
      className={className}
      onKeyDown={onKeyDown}
      onMouseDown={onMouseDown}
    >
      <StyledModalContent className={className}>{children}</StyledModalContent>
    </StyledModalContainer>
  );
};
Modal.propTypes = {
  dismissOnClickOutside: PropTypes.bool,
  onDismiss: PropTypes.func,
};
Modal.defaultProps = {
  dismissOnClickOutside: true,
};

/**
 * The modal content to have the same look across modals
 * @param {ModalContentProps} props
 */
const ModalContent = (props) => {
  const { onClose, showClose, showMaximize, children, ...rest } = props;
  const { hide } = useModal();
  const [expanded, setExpanded] = useState(false);

  const close = useCallback(() => {
    typeof onClose === 'function' && onClose();
    hide();
  }, [hide, onClose]);

  const toggleExpanded = () => setExpanded((m) => !m);

  return (
    <StyledModalPanel {...rest} expanded={expanded}>
      <TopButtonsContainer>
        {showMaximize && (
          <IconButton
            css={showOnDesktop()}
            icon={expanded ? faCompress : faExpand}
            onClick={toggleExpanded}
          />
        )}
        {showClose && <IconButton icon={faTimes} onClick={close} />}
      </TopButtonsContainer>
      {children}
    </StyledModalPanel>
  );
};
ModalContent.propTypes = {
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  showMaximize: PropTypes.bool,
  large: PropTypes.bool,
  expanded: PropTypes.bool,
};
ModalContent.defaultProps = {
  showClose: true,
};

export { ModalContent };
export const ModalHeader = StyledModalHeader;
export const ModalSection = StyledModalSection;
export const ModalSeparator = StyledModalSeparator;
export const ModalFooter = StyledModalFooter;

/**
 * @typedef {Object} ModalContentInnerProps
 * @property {Function} onClose
 * @property {boolean} showClose
 * @property {boolean} showMaximize
 * @property {boolean} expanded
 * @property {boolean} large
 */
/**
 * @typedef {ModalContentInnerProps &
 * import('styled-system').ColorProps &
 * import('styled-system').SpaceProps &
 * import('styled-system').TypographyProps
 * } ModalContentProps
 */
