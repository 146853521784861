export const PAGE_NAMES = {
  StartOnBoarding: 'StartOnBoarding',
  Terms: 'Terms',
  ImportWallet: 'ImportWallet',
  ImportWatchWallet: 'ImportWatchWallet',
  SecretPhrase: 'SecretPhrase',
  Passphrase: 'Passphrase',
  SecretPhraseQuiz: 'SecretPhraseQuiz',
  SetPassword: 'SetPassword',
  SetName: 'SetName',
  Confirm: 'Confirm',
};

export const STEPS = {
  import: [
    PAGE_NAMES.Terms,
    PAGE_NAMES.ImportWallet,
    PAGE_NAMES.SetPassword,
    PAGE_NAMES.Passphrase,
    PAGE_NAMES.SetName,
    PAGE_NAMES.Confirm,
  ],
  create: [
    PAGE_NAMES.Terms,
    PAGE_NAMES.SecretPhrase,
    PAGE_NAMES.SecretPhraseQuiz,
    PAGE_NAMES.SetPassword,
    PAGE_NAMES.Passphrase,
    PAGE_NAMES.SetName,
    PAGE_NAMES.Confirm,
  ],
  watch: [
    PAGE_NAMES.Terms,
    PAGE_NAMES.ImportWatchWallet,
    PAGE_NAMES.SetPassword,
    PAGE_NAMES.SetName,
    PAGE_NAMES.Confirm,
  ],
};

/**
 * Get the pagination props for each onboarding page
 */
export const getPaginationProps = ({ type, setType, setCurPage } = {}) => {
  const gotoStart = () => {
    setType('');
    setCurPage(PAGE_NAMES.StartOnBoarding);
  };
  const gotoNext = (page) => {
    const index = findStepIndex(page, type); // if not found, returns -1, then we go to step 0
    if (STEPS[type].length > index + 1) {
      setCurPage(STEPS[type][index + 1]);
    }
  };
  const gotoPrev = (page) => {
    const index = findStepIndex(page, type);
    if (index > 0) {
      setCurPage(STEPS[type][index - 1]);
    } else {
      gotoStart();
    }
  };

  return {
    [PAGE_NAMES.StartOnBoarding]: {
      onCreate() {
        setType('create');
        setCurPage(PAGE_NAMES.Terms);
      },
      onImport() {
        setType('import');
        setCurPage(PAGE_NAMES.Terms);
      },
      onWatch() {
        setType('watch');
        setCurPage(PAGE_NAMES.Terms);
      },
    },
    [PAGE_NAMES.ImportWallet]: {
      onCancel() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.ImportWallet);
      },
      onCreateWallet() {
        setType('create');
        setCurPage(STEPS.create[0]);
      },
    },
    [PAGE_NAMES.ImportWatchWallet]: {
      onCancel() {
        gotoStart();
      },
      onContinue() {
        setType('watch');
        gotoNext(PAGE_NAMES.ImportWatchWallet);
      },
      onCreateWallet() {
        setType('create');
        setCurPage(STEPS.create[0]);
      },
    },
    [PAGE_NAMES.Terms]: {
      onCancel() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.Terms);
      },
    },
    [PAGE_NAMES.Passphrase]: {
      onCancel() {
        gotoStart();
      },
      onBack() {
        gotoPrev(PAGE_NAMES.Passphrase);
      },
      onContinue() {
        gotoNext(PAGE_NAMES.Passphrase);
      },
    },
    [PAGE_NAMES.SecretPhrase]: {
      onCancel() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.SecretPhrase);
      },
    },
    [PAGE_NAMES.SecretPhraseQuiz]: {
      onCancel() {
        gotoStart();
      },
      onCreateWallet() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.SecretPhraseQuiz);
      },
    },
    [PAGE_NAMES.SetPassword]: {
      onBack() {
        gotoPrev(PAGE_NAMES.SetPassword);
      },
      onCancel() {
        gotoStart();
      },
      onContinue() {
        gotoNext(PAGE_NAMES.SetPassword);
      },
    },
    [PAGE_NAMES.SetName]: {
      onBack() {
        gotoPrev(PAGE_NAMES.SetName);
      },
      onContinue() {
        gotoNext(PAGE_NAMES.SetName);
      },
      onCancel() {
        gotoStart();
      },
    },
    [PAGE_NAMES.Confirm]: {
      onBack() {
        gotoPrev(PAGE_NAMES.Confirm);
      },
      onCancel() {
        gotoStart();
      },
    },
  };
};

const findStepIndex = (page, type) => STEPS[type].findIndex((step) => step === page);
