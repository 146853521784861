import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Handles state which is saved and retrieved from local/session storage
 * @param {string} key
 * @param {*} defaultValue
 * @param {Object} options
 * @param {('sessionStorage'|'localStorage')} [options.storage='sessionStorage'] - chooses the storage object, defaults
 *   to sessionStorage
 * @param {(value: *) => string} options.serialize
 * @param {(value: string) => *} options.deserialize
 *
 * @returns {[state: *, setState: React.SetStateAction, clear: () => void]}
 */
export default function useStorageState(
  key,
  defaultValue = '',
  {
    storage: storageMechanism = 'sessionStorage',
    serialize = JSON.stringify,
    deserialize = JSON.parse,
  } = {}
) {
  const storage = storageMechanism === 'localStorage' ? window.localStorage : window.sessionStorage;
  const [state, setState] = useState(() => {
    const valueInStorage = storage.getItem(key);
    if (valueInStorage) {
      return deserialize(valueInStorage);
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  const clear = useCallback(() => {
    storage.removeItem(key);
  }, [key, storage]);

  const prevKeyRef = useRef(key);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      storage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
    storage.setItem(key, serialize(state));
  }, [key, state, serialize, storage]);

  return [state, setState, clear];
}
