import { ConfirmationModal, TestnetBar, Title, useModal } from '@zen-common/components-base';
import { useNodeConnectivitySetting } from '../../pages/Settings/settingsHooks';

export default function TestnetBarContainer() {
  const { onAction, items } = useNodeConnectivitySetting();
  const { show, hide } = useModal();

  const chainValue = items[0]['value'];

  const handleSwitchChain = () => {
    if (typeof onAction === 'function') {
      show(() => (
        <ConfirmationModal
          dismissOnClickOutside={true}
          showClose={false}
          onCancel={hide}
          onConfirm={() => {
            hide();
            onAction({ type: 'CHANGE', value: chainValue });
          }}
          title={<Title>Confirm switching chain</Title>}
        >
          You are about to switch from Testnet to Mainnet. After switching you will be redirected to
          import wallet or login screen.
        </ConfirmationModal>
      ));
    }
  };
  return <TestnetBar onSwitch={handleSwitchChain} />;
}
