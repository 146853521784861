import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const PAGES_TO_SHOW = 4;
const MIN_PAGE_SKIP = 10;
const PAGE_SKIP_MULTIPLIER = 2;

const PaginationControls = (props) => {
  const [state, setState] = useState({
    skipUp: MIN_PAGE_SKIP,
    skipDown: MIN_PAGE_SKIP,
  });

  const getSafePage = (page) => {
    if (Number.isNaN(page)) {
      page = props.page;
    }
    return Math.min(Math.max(page, 0), props.pages - 1);
  };

  const changePage = (page) => {
    page = getSafePage(page);
    if (props.page !== page) {
      props.onPageChange(page);
    }
    calculateNextPageSkips(page);
  };

  const calculateNextPageSkips = (page) => {
    if ([0, props.pages - 1].includes(page) || Math.abs(page - props.page) < MIN_PAGE_SKIP) {
      // reset
      setState({
        skipUp: MIN_PAGE_SKIP,
        skipDown: MIN_PAGE_SKIP,
      });
    } else {
      // multiply
      if (page > props.page) {
        setState((state) => ({
          skipUp: state.skipUp * PAGE_SKIP_MULTIPLIER,
          skipDown: MIN_PAGE_SKIP,
        }));
      } else {
        setState((state) => ({
          skipUp: MIN_PAGE_SKIP,
          skipDown: state.skipDown * PAGE_SKIP_MULTIPLIER,
        }));
      }
    }
  };

  const { pages, page, canPrevious, canNext, className } = props;

  if (pages < 2) {
    return null;
  }

  const getPageButtons = (page, pages) => {
    const pageButtonsNumbers = [];

    for (let i = 0; i < pages; i++) {
      if (
        i === 0 ||
        (i < PAGES_TO_SHOW && page < 2) ||
        (Math.abs(page - i) < PAGES_TO_SHOW - 2 && page >= 2) ||
        (i >= pages - PAGES_TO_SHOW && page >= pages - 2) ||
        i === pages - 1
      ) {
        pageButtonsNumbers.push(i);
      }
    }
    const pageButtons = [];
    for (let i = 0; i < pageButtonsNumbers.length; i++) {
      const curPageNumber = pageButtonsNumbers[i];
      pageButtons.push(
        getPageButton({
          page: curPageNumber,
          active: curPageNumber === page,
          classNames: 'page-number',
        })
      );
      if (i < pageButtonsNumbers.length - 1) {
        const nextPageNumber = pageButtonsNumbers[i + 1];
        if (nextPageNumber - curPageNumber > 1) {
          const skipHalf = Math.floor((nextPageNumber + curPageNumber) / 2);
          const skipToPage =
            page < curPageNumber
              ? Math.min(curPageNumber + state.skipUp, skipHalf)
              : Math.max(nextPageNumber - state.skipDown, skipHalf);

          pageButtons.push(
            getPageButton({
              page: skipToPage,
              active: false,
              text: '...',
              key: curPageNumber + pages,
              classNames: 'page-skip',
            })
          );
        }
      }
    }

    return pageButtons;
  };

  const getPageButton = ({ page, active, text, key, classNames } = {}) => {
    return (
      <li key={key || page} className={cx('page-item', { active }, classNames)}>
        <button
          onClick={() => {
            changePage(page);
          }}
          className="page-link"
        >
          {text || page + 1}
        </button>
      </li>
    );
  };

  return (
    <div className={cx(className, '-pagination')} style={props.style}>
      <nav aria-label="Page navigation">
        <ul className="pagination pagination-sm">
          <li className="page-item page-skip-first">
            <button
              className="page-link"
              onClick={() => {
                changePage(0);
              }}
              disabled={!canPrevious}
            >
              {props.firstText}
            </button>
          </li>
          <li className="page-item">
            <button
              className="page-link back"
              onClick={() => {
                if (!canPrevious) return;
                changePage(page - 1);
              }}
              disabled={!canPrevious}
            >
              {props.previousText}
            </button>
          </li>
          {getPageButtons(page, pages)}
          <li className="page-item">
            <button
              className="page-link next"
              onClick={() => {
                if (!canNext) return;
                changePage(page + 1);
              }}
              disabled={!canNext}
            >
              {props.nextText}
            </button>
          </li>
          <li className="page-item page-skip-last">
            <button
              className="page-link"
              onClick={() => {
                changePage(pages - 1);
              }}
              disabled={!canNext}
            >
              {props.lastText}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

PaginationControls.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  canPrevious: PropTypes.bool,
  canNext: PropTypes.bool,
  className: PropTypes.string,
  firstText: PropTypes.any,
  lastText: PropTypes.any,
  nextText: PropTypes.any,
  previousText: PropTypes.any,
  style: PropTypes.any,
};

export default PaginationControls;
