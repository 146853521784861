import { useCallback, useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ZenJsUtils, ApiService, WalletStore } from '@zen/common-app-parts';
import { useUpdateEffect } from '@zen/common-react-hooks';
import { findVotes } from '../../utils';
import { REFETCH_SHORT } from './utils/constants';

/**
 * Fetches the user's votes
 */
export default function useVotes({
  voteStatus,
  contractIdVote,
  nodeUrl,
  snapshotBlock,
  tallyBlock,
  currentInterval,
} = {}) {
  const [votedNomination, setVotedNomination] = useState(false);
  const [votedAllocation, setVotedAllocation] = useState(false);
  const [votedPayout, setVotedPayout] = useState(false);
  const [nominationVote, setNominationVote] = useState(null);
  const [allocationVote, setAllocationVote] = useState(null);
  const [payoutVote, setPayoutVote] = useState(null);
  const {
    state: {
      currentWalletInfo: {
        keys: { addresses },
      },
    },
  } = WalletStore.useStore();
  const shouldFetch =
    voteStatus !== 'between' &&
    snapshotBlock &&
    tallyBlock &&
    ((voteStatus === 'nomination' && nominationVote === null) ||
      (voteStatus === 'voting' && (allocationVote === null || payoutVote === null)));
  const refetchInterval = REFETCH_SHORT;

  const {
    data: userTxsWithHeaders,
    refetch: refetchTxs,
    status: statusTxs,
  } = useQuery(
    ['cgp-user-vote-transactions', { addresses, snapshotBlock, tallyBlock, nodeUrl }],
    fetchUserTxsWithHeaders,
    { enabled: shouldFetch, refetchInterval }
  );

  const {
    data: contractHistory,
    refetch: refetchHistory,
    status: statusHistory,
  } = useQuery(
    ['cgp-contract-vote-transaction', { nodeUrl, contractIdVote, snapshotBlock, tallyBlock }],
    fetchContractHistory,
    { enabled: shouldFetch, refetchInterval }
  );

  const initialVotesFetchDone = useInitialVoteFetchDone({
    currentInterval,
    statusHistory,
    statusTxs,
  });

  const refetchVotes = useCallback(() => {
    refetchTxs({ force: true });
    refetchHistory({ force: true });
  }, [refetchHistory, refetchTxs]);

  const votes = useMemo(() => {
    return findVotes({
      contractHistory,
      transactions: userTxsWithHeaders ? userTxsWithHeaders[1] : [],
      currentBlock: userTxsWithHeaders ? userTxsWithHeaders[0] : 0,
      snapshotBlock,
      tallyBlock,
    });
  }, [contractHistory, snapshotBlock, tallyBlock, userTxsWithHeaders]);

  // set deserialized votes as soon as they are found and only once
  useEffect(() => {
    if (votes.nomination && nominationVote === null) {
      setNominationVote(getBallotId(votes.nomination, 'Nomination'));
    }
  }, [nominationVote, votes.nomination]);
  useEffect(() => {
    if (votes.allocation !== null && allocationVote === null) {
      setAllocationVote(
        ZenJsUtils.deserializeAllocationBallotId(getBallotId(votes.allocation, 'Allocation'))
      );
    }
  }, [allocationVote, votes.allocation]);
  useEffect(() => {
    if (votes.payout && payoutVote === null) {
      setPayoutVote(getBallotId(votes.payout, 'Payout'));
    }
  }, [payoutVote, votes.payout]);

  // set voted as soon as votes were found
  // allocation
  useEffect(() => {
    if (allocationVote !== null) {
      setVotedAllocation(true);
    }
  }, [allocationVote]);

  // nomination
  useEffect(() => {
    if (nominationVote) {
      setVotedNomination(true);
    }
  }, [nominationVote]);

  // payout
  useEffect(() => {
    if (payoutVote) {
      setVotedPayout(true);
    }
  }, [payoutVote]);

  // reset on interval change, should not reset on mount as there could be data in cache
  useUpdateEffect(() => {
    setNominationVote(null);
    setAllocationVote(null);
    setPayoutVote(null);
    setVotedNomination(false);
    setVotedAllocation(false);
    setVotedPayout(false);
  }, [currentInterval]);

  return {
    initialVotesFetchDone,
    nominationVote,
    allocationVote,
    payoutVote,
    refetchVotes,
    votedAllocation,
    votedNomination,
    votedPayout,
    setVotedNomination,
    setVotedAllocation,
    setVotedPayout,
  };
}

/**
 * Fetch the user txs in the voting range with the current headers
 * (some calculations need to know the block number from the confirmations)
 */
function fetchUserTxsWithHeaders(key, { nodeUrl, addresses, snapshotBlock, tallyBlock }) {
  return Promise.all([
    ApiService.blockchainInfo(nodeUrl).then((data) => data.headers),
    ApiService.getTransactionsByBlockNumber(nodeUrl, {
      addresses,
      start: snapshotBlock + 1,
      end: tallyBlock + 1,
    }),
  ]);
}
/**
 * Fetch the contract history in the voting range
 */
function fetchContractHistory(key, { nodeUrl, contractIdVote, snapshotBlock, tallyBlock }) {
  return ApiService.contractHistoryByBlockNumber(nodeUrl, {
    contractId: contractIdVote,
    start: snapshotBlock + 1,
    end: tallyBlock + 1,
  });
}

function useInitialVoteFetchDone({ currentInterval, statusHistory, statusTxs } = {}) {
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  // reset on interval change
  useEffect(() => {
    setInitialFetchDone(false);
  }, [currentInterval]);
  // set to true once
  useEffect(() => {
    if (statusHistory === 'success' && statusTxs === 'success') {
      setInitialFetchDone(true);
    }
  }, [statusHistory, statusTxs]);

  return initialFetchDone;
}

function getBallotId(vote, command) {
  return vote.messageBody.dict.find((txs) => txs[0] === command)[1].string;
}
