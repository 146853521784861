import { useCallback, useEffect, useReducer, useState } from 'react';
import { defaultTo, mapObjIndexed } from 'ramda';
import { useQuery } from 'react-query';
import { assetUtils, errorUtils, toDetachedPromise } from '@zen/common-utils';
import {
  decryptPassword,
  encryptPassword,
  getPrivateKey,
  getXPub,
  privateKeys,
  walletFromAddresses,
  walletFromMnemonic,
} from '../../../utils/zenJsUtils';
import { publishTx } from '../../../utils/ApiService';
import reducer, { ACTIONS } from './walletReducer';
import * as walletActions from './walletActions';

const { captureError } = errorUtils;
const PASSWORD_ERROR_MSG = 'Wrong password.';
const NO_WALLET_MSG = 'Wallet is not yet instantiated';

export const LS_PASS_PHRASE_MAIN = 'pass-phrase-main';
export const LS_PASS_PHRASE_TEST = 'pass-phrase-test';
export const LS_WATCH_MAIN = 'is-watch-main';
export const LS_WATCH_TEST = 'is-watch-test';
export const LS_WATCH_ADDRESSES_MAIN = 'watch-addresses-main';
export const LS_WATCH_ADDRESSES_TEST = 'watch-addresses-test';
export const LS_TOTAL_WALLET_MAIN = 'total-wallet-main';
export const LS_TOTAL_WALLET_TEST = 'total-wallet-test';
export const LS_HAS_PASSPHRASE_MAIN = 'has-passphrase-main';
export const LS_HAS_PASSPHRASE_TEST = 'has-passphrase-test';
export const LS_IS_SUB_ACCOUNT_MAIN = 'is-sub-account-main';
export const LS_IS_SUB_ACCOUNT_TEST = 'is-sub-account-test';
export const LS_ACCOUNT_LABEL_MAIN = 'account-label-main';
export const LS_ACCOUNT_LABEL_TEST = 'account-label-test';
export const LS_VIEW_ACCOUNT_MAIN = 'view-account-main';
export const LS_VIEW_ACCOUNT_TEST = 'view-account-test';

function _initWalletState(chain, storage) {
  return [
    ...Array(
      defaultTo(1, storage.get(chain === 'test' ? LS_TOTAL_WALLET_TEST : LS_TOTAL_WALLET_MAIN))
    ).keys(),
  ].map((index) => {
    return {
      label: defaultTo(
        '',
        storage.get(
          chain === 'test'
            ? _append(LS_ACCOUNT_LABEL_TEST, index)
            : _append(LS_ACCOUNT_LABEL_MAIN, index)
        )
      ),
      wallet: null,
      balance: {},
      balances: [],
      assets: [],
      keys: {
        address: '',
        change: '',
        pkHash: '',
        pubKey: '',
        pubKeyChange: null,
        addresses: defaultTo(
          [],
          storage.get(
            chain === 'test'
              ? _append(LS_WATCH_ADDRESSES_TEST, index)
              : _append(LS_WATCH_ADDRESSES_MAIN, index)
          )
        ),
      },
      hasPassword: !!storage.get(
        chain === 'test' ? _append(LS_PASS_PHRASE_TEST, index) : _append(LS_PASS_PHRASE_MAIN, index)
      ),
      encryptedSeed: defaultTo(
        '',
        storage.get(
          chain === 'test'
            ? _append(LS_PASS_PHRASE_TEST, index)
            : _append(LS_PASS_PHRASE_MAIN, index)
        )
      ),
      isLoggedIn: false,
      isWatchMode: defaultTo(
        false,
        storage.get(
          chain === 'test' ? _append(LS_WATCH_TEST, index) : _append(LS_WATCH_MAIN, index)
        )
      ),
      hasPassphrase: defaultTo(
        false,
        storage.get(
          chain === 'test'
            ? _append(LS_HAS_PASSPHRASE_TEST, index)
            : _append(LS_HAS_PASSPHRASE_MAIN, index)
        )
      ),
      isSubAccount: defaultTo(
        false,
        storage.get(
          chain === 'test'
            ? _append(LS_IS_SUB_ACCOUNT_TEST, index)
            : _append(LS_IS_SUB_ACCOUNT_MAIN, index)
        )
      ),
    };
  });
}

export default function useWalletState({
  chain = 'main',
  nodeUrl = '',
  storage = {
    get() {},
    set() {},
  },
} = {}) {
  const [fetchingBalance, setFetchingBalance] = useState(false);
  /** @type {[state: import('../WalletStore').TWalletState, dispatch: React.Dispatch]} */
  const [state, dispatch] = useReducer(reducer, { chain, storage }, ({ chain, storage }) => ({
    initialBalanceFetched: false,
    wallets: { test: _initWalletState('test', storage), main: _initWalletState('main', storage) },
    loading: {}, // address: true
    executing: false, // true when performing actions like send or execute contract
    viewAccount: defaultTo(
      0,
      storage.get(chain === 'test' ? LS_VIEW_ACCOUNT_TEST : LS_VIEW_ACCOUNT_MAIN)
    ),
    chain,
  }));
  // fetch balance
  const { data: balance, refetch: refetchBalance } = useQuery(
    ['wallet-store-balance'],
    useCallback(async () => {
      try {
        dispatch({ type: ACTIONS.FETCH_BALANCE.REQUESTED });
        return await _fetchBalance({
          wallet: state.wallets[chain][state.viewAccount].wallet,
        });
      } catch (error) {
        dispatch({ type: ACTIONS.FETCH_BALANCE.FAILED });
      }
    }, [state.viewAccount, state.wallets, chain]),
    {
      enabled: fetchingBalance && state.wallets[state.chain][state.viewAccount].wallet,
      refetchInterval: 5000,
    }
  );

  // set balance on change
  useEffect(() => {
    if (balance) {
      dispatch({
        type: ACTIONS.FETCH_BALANCE.SUCCEEDED,
        payload: { balance },
      });
    }
  }, [balance]);

  // destroy wallet when props change
  useEffect(() => {
    dispatch({ type: ACTIONS.WALLET_REMOVED });
  }, [chain, nodeUrl]);

  // chain changed
  useEffect(() => {
    dispatch({ type: ACTIONS.CHAIN_CHANGED, payload: { chain } });
  }, [chain]);
  // store pass phrase on change
  useEffect(() => {
    storage.set(
      chain === 'test'
        ? _append(LS_PASS_PHRASE_TEST, state.viewAccount)
        : _append(LS_PASS_PHRASE_MAIN, state.viewAccount),
      state.wallets[chain][state.viewAccount].encryptedSeed
    );
  }, [state.wallets, state.viewAccount, chain, storage]);

  // store total wallets on change
  useEffect(() => {
    storage.set(
      LS_TOTAL_WALLET_TEST,
      state.wallets.test.filter((w) => w.keys.addresses?.length).length || 1
    );
    storage.set(
      LS_TOTAL_WALLET_MAIN,
      state.wallets.main.filter((w) => w.keys.addresses?.length).length || 1
    );
  }, [state.wallets, state.viewAccount, chain, storage]);
  // store watch mode on change
  useEffect(() => {
    storage.set(
      chain === 'test'
        ? _append(LS_WATCH_TEST, state.viewAccount)
        : _append(LS_WATCH_MAIN, state.viewAccount),
      state.wallets[chain][state.viewAccount].isWatchMode
    );
  }, [state.wallets, chain, storage, state.viewAccount]);
  // store passphrase change on change
  useEffect(() => {
    storage.set(
      chain === 'test'
        ? _append(LS_HAS_PASSPHRASE_TEST, state.viewAccount)
        : _append(LS_HAS_PASSPHRASE_MAIN, state.viewAccount),
      !!state.wallets[chain][state.viewAccount].hasPassphrase
    );
  }, [state.wallets, chain, storage, state.viewAccount]);
  // store if is subAccount change on change
  useEffect(() => {
    storage.set(
      chain === 'test'
        ? _append(LS_IS_SUB_ACCOUNT_TEST, state.viewAccount)
        : _append(LS_IS_SUB_ACCOUNT_MAIN, state.viewAccount),
      !!state.wallets[chain][state.viewAccount].isSubAccount
    );
  }, [state.wallets, chain, storage, state.viewAccount]);
  // store label on view account change
  useEffect(() => {
    storage.set(
      chain === 'test'
        ? _append(LS_ACCOUNT_LABEL_TEST, state.viewAccount)
        : _append(LS_ACCOUNT_LABEL_MAIN, state.viewAccount),
      state.wallets[chain][state.viewAccount].label
    );
  }, [state.wallets, chain, storage, state.viewAccount]);
  // store view account value
  useEffect(() => {
    storage.set(
      chain === 'test' ? LS_VIEW_ACCOUNT_TEST : LS_VIEW_ACCOUNT_MAIN,
      state.wallets[chain][state.viewAccount].encryptedSeed
        ? state.viewAccount
        : state.viewAccount !== 0
        ? state.viewAccount - 1
        : state.viewAccount
    );
  }, [state.wallets, chain, storage, state.viewAccount]);
  // store watch address on change in change mode is on
  useEffect(() => {
    switch (chain) {
      case 'main':
        storage.set(
          _append(LS_WATCH_ADDRESSES_MAIN, state.viewAccount),
          state.wallets[chain][state.viewAccount].keys.addresses
        );
        break;
      case 'test':
        storage.set(
          _append(LS_WATCH_ADDRESSES_TEST, state.viewAccount),
          state.wallets[chain][state.viewAccount].keys.addresses
        );
        break;
    }
  }, [chain, state.viewAccount, state.wallets, storage]);

  // parameters to change when wallet state changes
  useEffect(() => {
    if (state.wallets[chain][state.viewAccount].wallet) {
      setFetchingBalance(true);
    } else {
      setFetchingBalance(false);
      dispatch({ type: ACTIONS.WALLET_REMOVED });
    }
  }, [chain, state.viewAccount, state.wallets]);

  return Object.freeze({
    state: {
      currentWalletInfo: state.wallets[chain][state.viewAccount],
      ...state,
    },
    actions: {
      create: useCallback(
        ({ mnemonic, password, passphrase, account, name } = {}) => {
          return _createWallet({
            chain,
            dispatch,
            mnemonic,
            password,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            nodeUrl,
            watchMode: false,
            passphrase,
            account,
            name,
          });
        },
        [chain, state.wallets, state.viewAccount, nodeUrl]
      ),
      createWatchOnly: useCallback(
        ({ addresses, password, name } = {}) => {
          return _createWallet({
            chain,
            dispatch,
            addresses,
            password,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            nodeUrl,
            watchMode: true,
            name,
          });
        },
        [chain, state.wallets, state.viewAccount, nodeUrl]
      ),
      wipeSeed: useCallback(
        ({ account = state.viewAccount } = {}) => {
          dispatch({ type: ACTIONS.WIPE.REQUESTED, payload: { account } });
        },
        [state.viewAccount]
      ),
      wipeAllSeed: useCallback(() => {
        dispatch({ type: ACTIONS.FULL_WIPE.REQUESTED });
      }, []),
      login: useCallback(
        (password, passphrase, account) => {
          return _login({
            password,
            chain,
            nodeUrl,
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            watchMode: state.wallets[chain][state.viewAccount].isWatchMode,
            addresses: state.wallets[chain][state.viewAccount].keys.addresses,
            passphrase,
            account,
          });
        },
        [chain, nodeUrl, state.wallets, state.viewAccount]
      ),
      logout: useCallback(() => {
        dispatch({ type: ACTIONS.WALLET_REMOVED });
      }, []),
      changePassword: useCallback(
        ({ currentPassword, newPassword } = {}) => {
          return _changePassword({
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            currentPassword,
            newPassword,
            chain,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      getPrivateKeys: useCallback(
        (password, passphrase, account) => {
          return _getPrivateKeys({
            dispatch,
            password,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            passphrase,
            account,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      getXPubKey: useCallback(
        (password, passphrase, account) => {
          return _getPrivateKeys({
            dispatch,
            password,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            passphrase,
            account,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      checkPassword: useCallback(
        (password) =>
          _checkPassword({
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
          }),
        [chain, state.viewAccount, state.wallets]
      ),
      fetchBalance: refetchBalance,
      fetchTransactions: useCallback(
        ({ skip, take } = {}) => {
          return _fetchTransactions({
            wallet: state.wallets[chain][state.viewAccount].wallet,
            skip,
            take,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      fetchTransactionsCount: useCallback(() => {
        return _fetchTransactionsCount({
          wallet: state.wallets[chain][state.viewAccount].wallet,
        });
      }, [chain, state.viewAccount, state.wallets]),
      send: useCallback(
        ({ address, spends, password, raw, passphrase, account } = {}) => {
          return _send({
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            address,
            spends,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
            raw,
            passphrase,
            account,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      signSendTx: useCallback(
        ({ address, spends, password, passphrase, account } = {}) => {
          return _signSendTx({
            address,
            dispatch,
            spends,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
            passphrase,
            account,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      signTx: useCallback(
        ({ tx, password, passphrase, account } = {}) => {
          return _signTx({
            wallet: state.wallets[chain][state.viewAccount].wallet,
            tx,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
            passphrase,
            account,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      execute: useCallback(
        ({ address, command, includeReturnAddress, messageBody, path, spends } = {}) => {
          return _execute({
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            chain,
            address,
            command,
            includeReturnAddress,
            messageBody,
            path,
            spends,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      executeAndPublish: useCallback(
        ({
          address,
          command,
          includeReturnAddress,
          messageBody,
          path,
          spends,
          password,
          passphrase,
          account,
        } = {}) => {
          return _executeAndPublish({
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            chain,
            address,
            command,
            includeReturnAddress,
            messageBody,
            path,
            spends,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
            passphrase,
            account,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      signContractExecution: useCallback(
        ({ tx, sign, password, passphrase, account } = {}) => {
          return _signContractExecution({
            wallet: state.wallets[chain][state.viewAccount].wallet,
            tx,
            sign,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
            passphrase,
            account,
          });
        },
        [chain, state.viewAccount, state.wallets]
      ),
      submitCgpBallot: useCallback(
        ({ command, ballotData, interval, votingContractAddress, password, passphrase, account }) =>
          _submitCgpBallot({
            ballotData,
            command,
            dispatch,
            interval,
            votingContractAddress,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
            passphrase,
            account,
          }),
        [chain, state.viewAccount, state.wallets]
      ),
      submitRepoVote: useCallback(
        ({ commitId, phase, interval, votingContractAddress, password, passphrase, account }) =>
          _submitRepoVote({
            commitId,
            phase,
            dispatch,
            interval,
            votingContractAddress,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            encryptedSeed: state.wallets[chain][state.viewAccount].encryptedSeed,
            password,
            passphrase,
            account,
          }),
        [chain, state.viewAccount, state.wallets]
      ),
      activateContract: useCallback(
        ({ code, limit, numberOfBlocks }) =>
          _activateContract({
            code,
            limit,
            numberOfBlocks,
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
          }),
        [chain, state.viewAccount, state.wallets]
      ),
      extendContract: useCallback(
        ({ contractId, numberOfBlocks }) =>
          _extendContract({
            contractId,
            numberOfBlocks,
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
          }),
        [chain, state.viewAccount, state.wallets]
      ),
      destroy: useCallback(
        ({ spend } = {}) =>
          _destroy({
            dispatch,
            wallet: state.wallets[chain][state.viewAccount].wallet,
            spend,
          }),
        [chain, state.viewAccount, state.wallets]
      ),
      reset: useCallback(() => dispatch({ type: ACTIONS.RESET_REQUESTED }), []),
      updateAddress: useCallback(({ address }) => {
        dispatch({ type: ACTIONS.UPDATE_MAIN_ADDRESS, payload: { address } });
      }, []),
      publish: useCallback(
        async (signedTx) => {
          try {
            if (!signedTx || state.executing) return false;

            dispatch({ type: ACTIONS.EXECUTE.STARTED });
            await publishTx(nodeUrl, signedTx);

            dispatch({ type: ACTIONS.EXECUTE.FINISHED });
            return true;
          } catch (error) {
            dispatch({ type: ACTIONS.EXECUTE.FINISHED });
            throw error;
          }
        },
        [nodeUrl, state.executing]
      ),
      generateAccount: useCallback(() => {
        dispatch({ type: ACTIONS.WALLET_REMOVED });
        dispatch({ type: ACTIONS.ACCOUNT_GENERATE.SUCCEEDED });
      }, []),
      viewAccount: useCallback(({ account }) => {
        dispatch({ type: ACTIONS.WALLET_REMOVED });
        dispatch({ type: ACTIONS.VIEW_ACCOUNT, payload: { account } });
      }, []),
      editLabel: useCallback(({ label }) => {
        dispatch({ type: ACTIONS.EDIT_LABEL, payload: { label } });
      }, []),
    },
  });
}

function _checkPassword({ encryptedSeed, password } = {}) {
  return toDetachedPromise(() => {
    try {
      decryptPassword({ encryptedSeed, password });
      return true;
    } catch (error) {
      return false;
    }
  });
}

async function _login({
  password,
  wallet,
  encryptedSeed,
  dispatch,
  chain,
  nodeUrl,
  addresses,
  watchMode,
  passphrase,
  account,
} = {}) {
  if (wallet) return;

  dispatch({ type: ACTIONS.LOGIN.REQUESTED });
  // detach the login process and don't wait for it
  return toDetachedPromise(() => {
    try {
      if (!watchMode) {
        const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
        if (mnemonic.error) {
          throw new Error(PASSWORD_ERROR_MSG);
        }
        const wallet = walletFromMnemonic({
          chain,
          mnemonic: mnemonic.value,
          nodeUrl,
          passphrase,
          account,
        });
        dispatch({ type: ACTIONS.LOGIN.SUCCEEDED, payload: { wallet, chain, watchMode } });
      } else {
        const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
        if (mnemonic.error) {
          throw new Error(PASSWORD_ERROR_MSG);
        }
        const wallet = walletFromAddresses({ chain, addresses, nodeUrl });
        dispatch({ type: ACTIONS.LOGIN.SUCCEEDED, payload: { wallet, chain, watchMode } });
      }
    } catch (error) {
      dispatch({ type: ACTIONS.LOGIN.FAILED });
      throw error;
    }
  });
}

async function _createWallet({
  password,
  mnemonic,
  wallet,
  dispatch,
  chain,
  nodeUrl,
  watchMode,
  passphrase,
  account,
  addresses = [],
  name,
} = {}) {
  if (wallet) return;

  dispatch({ type: ACTIONS.CREATE.REQUESTED });
  return toDetachedPromise(async () => {
    try {
      const wallet = watchMode
        ? walletFromAddresses({ chain, addresses, nodeUrl })
        : walletFromMnemonic({ chain, mnemonic, account, passphrase, nodeUrl });
      const encryptedSeed = encryptPassword({
        mnemonic: mnemonic || addresses.join(', '),
        password,
      });
      dispatch({
        type: ACTIONS.CREATE.SUCCEEDED,
        payload: {
          wallet,
          encryptedSeed,
          chain,
          watchMode,
          hasPassphrase: !!passphrase,
          isSubAccount: account > 0,
          label: name,
        },
      });
      return wallet;
    } catch (error) {
      dispatch({ type: ACTIONS.CREATE.FAILED });
      throw error;
    }
  });
}

async function _changePassword({
  dispatch,
  wallet,
  encryptedSeed,
  currentPassword,
  newPassword,
  chain,
}) {
  dispatch({ type: ACTIONS.CHANGE_PASSWORD.REQUESTED });

  return toDetachedPromise(() => {
    try {
      if (!wallet) throw new Error(NO_WALLET_MSG);

      const mnemonic = captureError(() =>
        decryptPassword({ encryptedSeed, password: currentPassword })
      );
      if (mnemonic.error) {
        throw new Error(PASSWORD_ERROR_MSG);
      }

      const newEncryptedSeed = captureError(() =>
        encryptPassword({ mnemonic: mnemonic.value, password: newPassword })
      );
      if (newEncryptedSeed.error) {
        throw new Error(`An error occurred while encrypting: ${newEncryptedSeed.error.message}`);
      }
      dispatch({
        type: ACTIONS.CHANGE_PASSWORD.SUCCEEDED,
        payload: { encryptedSeed: newEncryptedSeed.value, chain },
      });
    } catch (error) {
      dispatch({ type: ACTIONS.CHANGE_PASSWORD.FAILED });
      throw error;
    }
  });
}

async function _fetchBalance({ wallet }) {
  return toDetachedPromise(async () => {
    if (!wallet) throw new Error(NO_WALLET_MSG);
    return await walletActions.getBalance({ wallet }).then((data) => {
      return mapObjIndexed((amount) => assetUtils.fromKalapas(amount), data);
    });
  });
}

/**
 * fetch and returns the transactions, will not change the state
 */
async function _fetchTransactions({ wallet, skip, take } = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  return walletActions
    .getTransactions({ wallet, skip, take })
    .then((data) => data.map((tx) => ({ ...tx, amount: assetUtils.fromKalapas(tx.amount) })));
}

/**
 * fetch and returns the transactions count, will not change the state
 */
async function _fetchTransactionsCount({ wallet } = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  return walletActions.getTransactionsCount({ wallet });
}

async function _getPrivateKeys({
  password,
  wallet,
  encryptedSeed,
  passphrase,
  account = 0,
  dispatch,
}) {
  dispatch({ type: ACTIONS.GET_PRIVATE_KEYS.REQUESTED });

  return toDetachedPromise(() => {
    try {
      if (!wallet) throw new Error(NO_WALLET_MSG);

      const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
      if (mnemonic.error) {
        throw new Error(PASSWORD_ERROR_MSG);
      }

      const privateKey = captureError(() =>
        getPrivateKey({ mnemonic: mnemonic.value, passphrase, account })
      );
      if (privateKey.error) {
        throw new Error('Could not get private key from seed');
      }
      const xPubKey = captureError(() =>
        getXPub({ mnemonic: mnemonic.value, passphrase, account })
      );
      if (xPubKey.error) {
        throw new Error('Could not get xpub key from seed');
      }
      dispatch({ type: ACTIONS.GET_PRIVATE_KEYS.SUCCEEDED });
      return {
        mnemonic: mnemonic.value,
        privateKey: privateKey.value,
        xPubKey: xPubKey.value,
      };
    } catch (error) {
      dispatch({ type: ACTIONS.GET_PRIVATE_KEYS.FAILED });
      throw error;
    }
  });
}

/**
 * Signs a tx (send)
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.address
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {string} params.encryptedSeed
 * @param {string} params.password
 * @returns {Promise}
 */
async function _signSendTx({
  dispatch,
  wallet,
  address,
  spends,
  encryptedSeed,
  password,
  passphrase,
  account,
} = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.SEND.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }
    const privateKey = captureError(() =>
      privateKeys({ mnemonic: mnemonic.value, passphrase, account })
    );
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.send({
      wallet,
      address,
      spends,
      privateKey: privateKey.value,
      publish: false,
    });
    dispatch({ type: ACTIONS.SEND.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.SEND.FINISHED });
    throw error;
  }
}

/**
 * Sends a tx
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.address
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {string} params.encryptedSeed - get the encrypted seed
 * @param {boolean} params.raw - get the transaction in raw form
 * @returns {Promise}
 */
async function _send({
  dispatch,
  wallet,
  address,
  spends,
  encryptedSeed,
  password,
  raw = false,
  passphrase,
  account,
} = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.SEND.STARTED });
    if (password) {
      const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
      if (mnemonic.error) {
        throw new Error(PASSWORD_ERROR_MSG);
      }
      const privateKey = captureError(() =>
        privateKeys({ mnemonic: mnemonic.value, passphrase, account })
      );
      if (privateKey.error) {
        throw new Error('Could not get private key from seed');
      }
      const result = await walletActions.send({
        wallet,
        address,
        spends,
        privateKey: privateKey.value,
        publish: true,
      });
      dispatch({ type: ACTIONS.SEND.FINISHED });
      return result;
    } else {
      const result = await walletActions.send({
        wallet,
        address,
        spends,
        publish: false,
        raw,
      });
      dispatch({ type: ACTIONS.SEND.FINISHED });
      return result;
    }
  } catch (error) {
    dispatch({ type: ACTIONS.SEND.FINISHED });
    throw error;
  }
}

/**
 * Executes a contract
 *
 * @param {Object} params
 * @param {React.Dispatch} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.chain
 * @param {string} params.address - the contract address
 * @param {string} params.command
 * @param {string} params.messageBody
 * @param {boolean} params.includeReturnAddress
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {string} params.path
 * @returns {Promise}
 */
async function _execute({
  dispatch,
  wallet,
  chain,
  address,
  command,
  includeReturnAddress,
  messageBody,
  path,
  spends,
} = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });
    const result = await walletActions.execute({
      address,
      chain,
      command,
      includeReturnAddress,
      messageBody,
      path,
      spends,
      wallet,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

/**
 * Executes a contract
 *
 * @param {Object} params
 * @param {React.Dispatch} params.dispatch
 * @param {Object} params.wallet
 * @param {string} params.chain
 * @param {string} params.address - the contract address
 * @param {string} params.command
 * @param {string} params.messageBody
 * @param {boolean} params.includeReturnAddress
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @param {string} params.path
 * @returns {Promise}
 */
async function _executeAndPublish({
  dispatch,
  wallet,
  chain,
  address,
  command,
  includeReturnAddress,
  messageBody,
  path,
  spends,
  encryptedSeed,
  password,
  passphrase,
  account,
} = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }

    const privateKey = captureError(() =>
      privateKeys({ mnemonic: mnemonic.value, passphrase, account })
    );
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.executeAndPublish({
      address,
      chain,
      command,
      includeReturnAddress,
      messageBody,
      path,
      spends,
      wallet,
      privateKey: privateKey.value,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

/**
 * Sign a contract execution
 *
 * @param {Object} params
 * @param {Object} params.wallet
 * @param {string} params.tx - the tx to sign in hex
 * @param {string} params.sign
 * @param {string} params.encryptedSeed
 * @param {string} params.password
 * @returns {string}
 */
async function _signContractExecution({
  wallet,
  tx,
  sign,
  encryptedSeed,
  password,
  passphrase,
  account,
} = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
  if (mnemonic.error) {
    throw new Error(PASSWORD_ERROR_MSG);
  }

  const privateKey = captureError(() =>
    privateKeys({ mnemonic: mnemonic.value, passphrase, account })
  );
  if (privateKey.error) {
    throw new Error('Could not get private key from seed');
  }

  return walletActions.signContractExecution({
    wallet,
    tx,
    sign,
    privateKey: privateKey.value,
  });
}

/**
 * Sign a general TX
 *
 * @param {Object} params
 * @param {Object} params.wallet
 * @param {string} params.tx - the tx to sign in hex
 * @param {string} params.encryptedSeed
 * @param {string} params.password
 * @returns {string}
 */
async function _signTx({ wallet, tx, encryptedSeed, password, passphrase, account } = {}) {
  if (!wallet) throw new Error(NO_WALLET_MSG);

  const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
  if (mnemonic.error) {
    throw new Error(PASSWORD_ERROR_MSG);
  }

  const privateKey = captureError(() =>
    privateKeys({ mnemonic: mnemonic.value, passphrase, account })
  );
  if (privateKey.error) {
    throw new Error('Could not get private key from seed');
  }

  return walletActions.signTransaction({
    wallet,
    tx,
    privateKey: privateKey.value,
  });
}

async function _submitCgpBallot({
  wallet,
  dispatch,
  ballotData,
  command,
  interval,
  votingContractAddress,
  encryptedSeed,
  password,
  passphrase,
  account,
}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }

    const privateKey = captureError(() =>
      privateKeys({ mnemonic: mnemonic.value, passphrase, account })
    );
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.submitCgpBallot({
      ballotData,
      command,
      interval,
      votingContractAddress,
      wallet,
      privateKey: privateKey.value,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

async function _submitRepoVote({
  wallet,
  dispatch,
  commitId,
  phase,
  interval,
  votingContractAddress,
  encryptedSeed,
  password,
  passphrase,
  account,
}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const mnemonic = captureError(() => decryptPassword({ encryptedSeed, password }));
    if (mnemonic.error) {
      throw new Error(PASSWORD_ERROR_MSG);
    }

    const privateKey = captureError(() =>
      privateKeys({ mnemonic: mnemonic.value, passphrase, account })
    );
    if (privateKey.error) {
      throw new Error('Could not get private key from seed');
    }
    const result = await walletActions.submitRepoVote({
      wallet,
      votingContractAddress,
      phase,
      commitId,
      interval,
      privateKey: privateKey.value,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

async function _activateContract({ wallet, dispatch, code, limit, numberOfBlocks }) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });
    const result = await walletActions.activateContract({
      wallet,
      limit,
      code,
      numberOfBlocks,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

async function _extendContract({ wallet, dispatch, contractId, numberOfBlocks }) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });
    const result = await walletActions.extendContract({
      wallet,
      contractId,
      numberOfBlocks,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

/**
 * Destroy a spend
 *
 * @param {Object} params
 * @param {React.DispatchWithoutAction} params.dispatch
 * @param {Object} params.wallet
 * @param {[{asset: string, amount: string}]} params.spends - amounts should already be in Kalapas
 * @returns {Promise}
 */
async function _destroy({ dispatch, wallet, spend } = {}) {
  try {
    if (!wallet) throw new Error(NO_WALLET_MSG);

    dispatch({ type: ACTIONS.EXECUTE.STARTED });

    const result = await walletActions.destroy({
      wallet,
      spend,
    });
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    return result;
  } catch (error) {
    dispatch({ type: ACTIONS.EXECUTE.FINISHED });
    throw error;
  }
}

/**
 * append to LocalStorage
 *
 * @param {string} ls_string
 * @param {number} viewAccount
 * @returns {string}
 */
function _append(ls_string, viewAccount = 0) {
  return `${ls_string}-${viewAccount}`;
}
