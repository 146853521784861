import { useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { LockIdentifiers, Transaction } from '@zen/zenjs';
import {
  Box,
  Code,
  ConfirmationModalContent,
  CopyableAsset,
  CopyableHash,
  Title,
} from '@zen-common/components-base';
import { assetUtils, ValueDisplay } from '@zen/common-utils';
import * as TD from '../../components/TechnicalData/TechnicalData';
import { SpendsTechnicalData, SpendUtils, useSpendsTechnicalData } from '../../components/Spend';

export default function ConfirmContractExecution({
  contractName,
  contractAddress,
  pkHash,
  tx,
  command,
  includeReturnAddress,
  includeSender,
  messageBody,
  spends,
  onCancel,
  onConfirm,
  cancelText,
}) {
  const txData = useMemo(() => getTxData({ pkHash, tx }), [pkHash, tx]);

  return (
    <ConfirmationModalContent
      title={<Title>{contractName ? contractName : 'Contract'} Execution Summary</Title>}
      confirmText="Continue"
      cancelText={cancelText}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <Box>
        <SpendsSection address={contractAddress} spends={spends} title="Send" />

        <ContractDataSection
          command={command}
          includeReturnAddress={includeReturnAddress}
          includeSender={includeSender}
          messageBody={messageBody}
        />

        <SpendsSection spends={txData.outputs} title="Receive" />

        <TD.Section>
          <TD.Title>TX Info</TD.Title>
          <Box>
            <TD.Label>Tx hash:</TD.Label>
            <TD.Value>
              <CopyableHash hash={txData.txHash} />
            </TD.Value>
          </Box>
          <Fee fee={txData.fee} />
        </TD.Section>
      </Box>
    </ConfirmationModalContent>
  );
}

function SpendsSection({ title, address, spends }) {
  const safeSpends = spends || [];
  const spendsTD = useSpendsTechnicalData({ spends: safeSpends });
  const showMore =
    safeSpends.length > 3 ? (
      <TD.ShowMoreLink isMore={spendsTD.isMore} onClick={spendsTD.toggleMore} />
    ) : null;

  if (!spendsTD.spends || !spendsTD.spends.length) return null;

  return (
    <TD.Section>
      <Box>
        <TD.Title>{title}</TD.Title>
        {showMore}
      </Box>

      {!!address && (
        <Box>
          <TD.Label>Destination address:</TD.Label>
          <TD.Value>
            <CopyableHash hash={address} />
          </TD.Value>
        </Box>
      )}

      <SpendsTechnicalData spends={spendsTD.spends} />
    </TD.Section>
  );
}

function ContractDataSection({ command, messageBody, includeReturnAddress, includeSender }) {
  const [expanded, setExpanded] = useState(false);
  const computedMsgBody = expanded ? messageBody : (messageBody || '').substring(0, 48);
  const threeDots = messageBody === computedMsgBody ? '' : '...';

  const showMore =
    (messageBody || '').length > 48 ? (
      <TD.ShowMoreLink isMore={expanded} onClick={() => setExpanded((e) => !e)} />
    ) : null;

  return (
    <TD.Section>
      <Box>
        <TD.Title>Contract Data</TD.Title>
        {showMore}
      </Box>
      <Box>
        <TD.Label>Command:</TD.Label>
        <TD.Value>
          {command ? (
            command.length > 48 ? (
              <BreakWord>
                <CopyableHash hash={command} />
              </BreakWord>
            ) : (
              command
            )
          ) : (
            'Empty'
          )}
        </TD.Value>
      </Box>
      <Box>
        <TD.Label>Message body:</TD.Label>
        <TD.Value>
          {messageBody ? <Code code={computedMsgBody + threeDots} lang="yaml" /> : 'Empty'}
        </TD.Value>
      </Box>
      <Box>
        <TD.Label>Include return address:</TD.Label>
        <TD.Value>{includeReturnAddress ? 'Yes' : 'No'}</TD.Value>
      </Box>
      <Box>
        <TD.Label>Include authentication:</TD.Label>
        <TD.Value>{includeSender ? 'Yes' : 'No'}</TD.Value>
      </Box>
    </TD.Section>
  );
}

function Fee({ fee }) {
  return (
    <Box>
      <TD.Label>Fee:</TD.Label>
      {fee ? (
        <>
          <TD.Value>{fee.amount.display}</TD.Value>
          <TD.Value>
            <CopyableAsset hash={fee.asset} />
          </TD.Value>
        </>
      ) : (
        <TD.Value>No Fee</TD.Value>
      )}
    </Box>
  );
}

function getTxData({ tx, pkHash }) {
  const txObj = Transaction.fromHex(tx);
  const txHash = txObj.hash().hash;
  const data = txObj.outputs.outputs.reduce(
    (data, output) => {
      const lockType = LockIdentifiers[output.lock.identifier];
      if (lockType === 'Fee') {
        data.fee = SpendUtils.getSpend({
          asset: output.spend.asset.getAsset(),
          assetValid: true,
          amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
          amountValid: true,
        });
      }

      // outputs for the pkHash only
      if (lockType === 'PK' && output.lock.getDataAsString() === pkHash) {
        data.outputs.push(
          SpendUtils.getSpend({
            asset: output.spend.asset.getAsset(),
            assetValid: true,
            amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
            amountValid: true,
          })
        );
      }

      return data;
    },
    { fee: null, outputs: [] }
  );

  return {
    txHash,
    ...data,
  };
}

const BreakWord = styled(Box)`
  word-break: break-all;
`;
