import { produce } from 'immer';

export const ACTIONS = {
  SET_SETTING_REQUESTED: 'SET_SETTING_REQUESTED',
};

const reducer = produce((draft, action) => {
  if (action.type === ACTIONS.SET_SETTING_REQUESTED)
    draft.settings[action.payload.name] = action.payload.value;
  else throw new Error(`Unhandled action type ${action.type}`);
});

export default reducer;
