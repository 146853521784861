import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  Box,
  FaSvg,
  Form,
  FormBody,
  FormGroup,
  InputMessage,
  themeUtils as tu,
} from '@zen-common/components-base';
import { faClock, faLockHashtag, faPencil, faWallet } from '@fortawesome/pro-solid-svg-icons';
import Layout from '../blocks/Layout';
import Header from '../blocks/Header';
import Footer from '../blocks/Footer';
import PaginationButton from '../blocks/PaginationButton';

export default function Confirm({
  onCancel,
  onBack,
  onContinue,
  error,
  type,
  name,
  hasPassphrase,
  logoutMinutes,
}) {
  const finishHandler = () => {
    onContinue();
  };
  const printerType = () => {
    switch (type) {
      case 'watch':
        return 'Successfully created a watch only wallet';
      case 'import':
        return 'Successfully imported a mnemonic wallet';
      case 'create':
        return 'Successfully created a mnemonic wallet';
    }
  };

  return (
    <Layout
      header={
        <Header
          title="Confirm Wallet Creation"
          text="You have successfully completed the onboarding process"
        />
      }
      body={
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            finishHandler();
          }}
        >
          <StyledFormBody>
            <FormGroup>
              <IconAndText icon={faWallet} text={printerType()} isActive />
              <IconAndText
                icon={faClock}
                text={`Auto logout is set for: ${logoutMinutes} ${
                  logoutMinutes > 1 ? 'minutes' : 'minute'
                }`}
                isActive
              />
              <IconAndText
                icon={faPencil}
                text={name ? `Account Name: ${name}` : 'No Account Name Provided'}
                isActive={!!name}
              />
              {type !== 'watch' && (
                <IconAndText
                  icon={faLockHashtag}
                  text={`Passphrase ${hasPassphrase ? 'was added' : 'was not added'} `}
                  isActive={hasPassphrase}
                />
              )}
            </FormGroup>
            <FormGroup>
              <InputMessage>{error}</InputMessage>
            </FormGroup>
          </StyledFormBody>
        </Form>
      }
      footer={
        <Footer
          left={null}
          right={
            <>
              <PaginationButton onClick={onBack} mr="smd" bg="secondary">
                Back
              </PaginationButton>
              <PaginationButton onClick={finishHandler}>Continue</PaginationButton>
            </>
          }
        />
      }
      onClose={onCancel}
    />
  );
}
Confirm.propTypes = {
  onBack: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

const StyledFormBody = styled(FormBody)`
  ${tu.mq({
    width: ['100%', null, '100%'],
  })}
`;

function IconAndText({ icon, text, isActive } = {}) {
  return (
    <IconAndTextContainer>
      <FaSvg icon={icon} mr="4" color={isActive ? 'primary' : 'secondary'} fontSize="1rem" />
      <Box color={isActive ? 'text' : 'label'}>{text}</Box>
    </IconAndTextContainer>
  );
}

const IconAndTextContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: ${tu.space(5)};
  ${tu.mq({
    width: ['100%', null],
  })}
`;
