import { useState } from 'react';

/**
 * @typedef {Object} useInputParams
 * @property {string} initialValue The initial value of the input
 * @property {Function} filter a filter validation function, should return true if value is valid, otherwise false
 */

/**
 * An Input state with optional filter validation
 * @param {useInputParams} params
 */
export default function useInput({ initialValue = '', filter = () => true }) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    const newVal = e.target.value.trim();
    if (filter(newVal)) {
      setValue(newVal);
    }
  };

  return { value, onChange, setValue };
}
