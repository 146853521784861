import storage from '@zen/common-utils/lib/utils/localStorage';
import {
  LS_PASS_PHRASE_MAIN,
  LS_PASS_PHRASE_TEST,
} from '@zen/common-app-parts/lib/stores/WalletStore/modules/useWalletState';

const MIGRATED = 'migrated-old-seed';

/**
 * Copy the seed from the old wallet implementation local storage key
 */
export default function migrate() {
  const migrated = storage.get(MIGRATED);
  const olderTestnetSeed = localStorage.getItem('lsTestnetSeed');
  const olderMainnetSeed = localStorage.getItem('lsMainnetSeed');
  const oldTestnetSeed = storage.get(LS_PASS_PHRASE_TEST);
  const oldMainnetSeed = storage.get(LS_PASS_PHRASE_MAIN);
  if (oldMainnetSeed || oldTestnetSeed) storage.set(MIGRATED, false);
  if (!migrated) {
    const TESTNET_LS = `${LS_PASS_PHRASE_TEST}-0`;
    const MAINNET_LS = `${LS_PASS_PHRASE_MAIN}-0`;
    const newTestnetSeed = storage.get(TESTNET_LS);
    const newMainnetSeed = storage.get(MAINNET_LS);

    if (!newTestnetSeed && (oldTestnetSeed || olderTestnetSeed)) {
      storage.set(TESTNET_LS, oldTestnetSeed || olderTestnetSeed);
      storage.remove(LS_PASS_PHRASE_TEST);
      storage.remove('lsTestnetSeed');
      console.log('migrated testnet seed from old implementation');
    }
    if (!newMainnetSeed && (oldMainnetSeed || olderMainnetSeed)) {
      storage.set(MAINNET_LS, oldMainnetSeed || olderMainnetSeed);
      storage.remove(LS_PASS_PHRASE_MAIN);
      storage.remove('lsMainnetSeed');
      console.log('migrated mainnet seed from old implementation');
    }

    storage.set(MIGRATED, true);
  }
}
