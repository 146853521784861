/* eslint-disable react/display-name */
import { useState } from 'react';
import {
  ConfirmPublishTxModalContent,
  ConfirmSendModalContent,
  SendExecuteStore,
  SignerRequiredModalContent,
} from '@zen/common-app-parts';
import { useUnmountedRef } from '@zen/common-react-hooks';
import usePreventAction from '../../../utils/usePreventAction';

export default function ConfirmSendModalProcess({ onFinish, onCancel }) {
  const unmounted = useUnmountedRef();
  const [status, setStatus] = useState('confirm-send');
  const [signedTx, setSignedTx] = useState();
  const { preventAction } = usePreventAction();
  const { sendStore } = SendExecuteStore.useStore();

  const handleConfirmSendCancel = () => {
    onCancel();
  };
  const handleConfirmSendContinue = () => {
    setStatus('password');
  };

  const handlePasswordBack = () => {
    setStatus('confirm-send');
  };
  const handlePasswordContinue = async (password, passphrase, account) => {
    const signedTx = await sendStore.actions.sign(password, passphrase, account);
    if (!unmounted.current) {
      setSignedTx(signedTx);
      setStatus('confirm-after-sign');
    }
  };

  const handleConfirmAfterSignBack = () => {
    setStatus('password');
  };
  const handleConfirmAfterSignContinue = async () => {
    if (preventAction() || !signedTx) return;
    const success = await sendStore.actions.publish(signedTx);

    onFinish(success);
  };

  if (status === 'confirm-send') {
    return (
      <ConfirmSendModalContent
        title="Send Transaction Summary"
        address={sendStore.state.address}
        spends={sendStore.state.spends}
        onCancel={handleConfirmSendCancel}
        onConfirm={handleConfirmSendContinue}
      />
    );
  }

  if (status === 'password') {
    return (
      <SignerRequiredModalContent
        cancelText="Back"
        onCancel={handlePasswordBack}
        onSuccess={handlePasswordContinue}
      />
    );
  }

  if (status === 'confirm-after-sign') {
    if (!signedTx) return null;

    return (
      <ConfirmPublishTxModalContent
        title="Confirm TX before publishing"
        tx={signedTx}
        onBack={handleConfirmAfterSignBack}
        onConfirm={handleConfirmAfterSignContinue}
        progress={sendStore.state.progress}
      />
    );
  }

  return null;
}
