import Alert from '../../Modal/Alert';
import { useModal } from '../../Modal';

/**
 * Display an offline modal
 */
export default function OfflineModal(props) {
  const { hide } = useModal();
  return (
    <Alert title="There is no internet connection" onDismiss={hide} {...props}>
      Operating is not possible while you&apos;re offline
      <br />
      Please connect to the internet and try again
    </Alert>
  );
}
