import { styled } from 'styled-components';
import { color, typography, space, layout, compose } from 'styled-system';

const StyledParagraph = styled.p`
  ${compose(typography, color, space, layout)}
`;

StyledParagraph.defaultProps = {
  fontWeight: '400',
  lineHeight: 6,
  mt: 0,
  mb: 8,
};

export default StyledParagraph;
