import { ConfirmationModal, Title } from '@zen-common/components-base';

export default function ConfirmWipeModal({ onConfirm, onCancel }) {
  return (
    <ConfirmationModal
      dismissOnClickOutside={true}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={<Title>Confirm Wipe</Title>}
    >
      Are you sure you want to wipe your seed?
    </ConfirmationModal>
  );
}
