import createStore from '../../utils/createStore';
import useWalletState from './modules/useWalletState';

const { Provider, useStore } = createStore({
  useStoreHook: useWalletState,
  contextDisplayName: 'WalletStoreContext',
});

export default {
  /**
   * @type {TWalletStoreProvider}
   */
  Provider,
  /**
   * @type {TUseWalletStore}
   */
  useStore,
};

/**
 * @typedef {({chain: string, storage: Object, nodeUrl: string}) => void} TWalletStoreProvider
 */

/**
 * @typedef {() => TWalletStore} TUseWalletStore
 */

/** @typedef {('Nomination'|'Allocation'|'Payout')} TCgpCommand */

/**
 * @typedef {{
          wallet: {},
          balance: {[asset:string]: string};
          balances: [{asset: string, amount: string}];
          assets: [string];
          keys: {
            address: string;
            pkHash: string;
            pubKey: string;
            addresses: string[]
          },
          hasPassword: boolean,
          encryptedSeed: string,
          isLoggedIn: boolean;
          hasPassword: boolean;
          hasPassphrase: boolean;
          isSubAccount: boolean;
          isWatchMode: string,
        }} TWalletInfo
 */

/**
 * @typedef {{
      initialBalanceFetched: boolean;
      wallets: [{
        test: TWalletInfo[],
        main: TWalletInfo[]
        }],
      loading: {};
      executing: boolean;
      viewAccount: number,
    }} TWalletState
 */
/**
 * @typedef {{
      create: ({ mnemonic: string, password: string, passphrase:string, account: string }) => Promise<Object>;
      createWatchOnly: ({ addresses: string[], pks: string[], password: string }) => Promise<Object>;
      wipeSeed: () => void;
      login: (password: string, passphrase:string, account: string) =>  Promise<void>;
      logout: () => void;
      changePassword: ({ currentPassword: string, newPassword: string }) => Promise<void>;
      getPrivateKeys: (password: string) => Promise<{mnemonic: string, privateKey: string, passphrase:string, account: string }>;
      getXPubKey: (password: string, passphrase:string, account: string) => Promise<{mnemonic: string, privateKey: string }>;
      checkPassword: (password: string) => Promise<boolean>;
      fetchBalance: () => Promise<void>;
      fetchTransactions: ({ skip: number, take: number }) => Promise<[{confirmations: number, asset: string, amount: number, txHash: string}]>;
      fetchTransactionsCount: () => Promise<number>;
      send: ({ address: string, spends: [{asset: string, amount: string}], password: string, passphrase:string, account: string }) => Promise<void>;
      signSendTx: ({ address, spends, password, passphrase:string, account: string }) => Promise<string>;
      signTx: ({tx: string, password: string, passphrase:string, account: string}) => string,
      execute: ({ address: string, command: string, includeReturnAddress: boolean, messageBody: string, path: string, spends: [{asset: string, amount: string}] }) => Promise<string>;
      executeAndPublish: ({ address: string, command: string, includeReturnAddress: boolean, messageBody: string, path: string, spends: [{asset: string, amount: string}], password: string, passphrase:string, account: string }) => Promise;
      signContractExecution: ({tx: string, sign: string, password: string, passphrase:string, account: string}) => string,
      submitCgpBallot: ({ command: TCgpCommand, ballotData: Object, interval: number, votingContractAddress: string, password: string, passphrase:string, account: string }) => Promise<void>;
      submitRepoVote: ({ commitId: string, phase: TGovernancePhaseName, interval: number, votingContractAddress: string, password: string, passphrase:string, account: string }) => Promise<void>;
      activateContract: ({ code: string, numberOfBlocks: number }) => Promise<string>,
      extendContract: ({ contractId: string, numberOfBlocks: number }) => Promise<string>,
      destroy: ({ spend: {asset: string, amount: string} }) => Promise<string>,
      reset: () => void,
      updateAddress: (address: string) => void,
      publish: (signedTx) => Promise<boolean>,
      generateAccount: () => void,
      viewAccount: ({account}) => void,
    }} TWalletActions
 */

/**
 * @typedef {{
 *   state: TWalletState;
 *   actions: TWalletActions;
 * }} TWalletStore
 */

/** @typedef {('Contestant'|'Candidate')} TGovernancePhaseName */
