import { styled } from 'styled-components';
import { color, system } from 'styled-system';
import { Decimal } from 'decimal.js';
import InputRange from '@gollum-ts/react-input-range';
import PropTypes from 'prop-types';
import styles from '@gollum-ts/react-input-range/lib/css/index.css';
import tu from '../../../utils/themeUtils';
import Box from '../../Box';

/**
 * @typedef {Object} CustomInputRangeProps
 * @property {number} min
 * @property {number} max
 * @property {number} step
 * @property {string} label
 * @property {number} value
 * @property {(value: number) => void} onChange
 */

const CustomInputRange = ({
  min,
  max,
  step,
  label,
  value,
  overValue,
  onChange,
  className,
  ...props
}) => {
  // InputRange expects: min <= value <= max
  const safeValue = Decimal.max(min, Decimal.min(value || 0, max)).toNumber();
  const dVal = new Decimal(value || 0);

  // use this to hide the label when not in range
  const outOfRange = dVal.lessThan(min) || dVal.greaterThan(max);
  const dRange = Decimal.sub(max, min);
  // used to align the label
  const percentage = dVal.equals(max)
    ? 100
    : new Decimal(safeValue).minus(min).times(100).dividedBy(dRange).toNumber();
  const positionClassName =
    percentage < 25 ? 'position-left' : percentage < 75 ? 'position-center' : 'position-right';
  return (
    <Box className={tu.cx(className, positionClassName, outOfRange && 'out-of-range')}>
      <InputRange
        minValue={min}
        maxValue={max}
        step={step}
        value={safeValue}
        formatLabel={(value) => `${overValue ? overValue : value}${label ? ' ' + label : ''}`}
        onChange={onChange}
        percentage={percentage}
        {...props}
      />
    </Box>
  );
};

const transition = '0.2s ease-out';

/**
 * @type {React.ComponentType<import('@gollum-ts/react-input-range').InputRangeProps & CustomInputRangeProps>}
 */
const StyledInputRange = styled(CustomInputRange)`
  ${styles}
  .input-range__slider-container {
    transition: left ${transition};
  }

  .input-range__slider {
    transition: transform ${transition}, box-shadow ${transition};
    margin-top: -0.69rem;
    ${system({
      sliderColor: {
        property: 'backgroundColor',
        scale: 'colors',
      },
    })}
    ${system({
      sliderColor: {
        property: 'borderColor',
        scale: 'colors',
      },
    })}
  }

  .input-range__label {
    font-family: inherit;
  }

  .input-range__track {
    height: 5px;
    transition: left 0 ${transition}, width ${transition};
    ${system({
      trackColor: {
        property: 'backgroundColor',
        scale: 'colors',
      },
    })}
  }

  .input-range__track--active {
    ${color}
  }

  .input-range__label--min,
  .input-range__label--max {
    display: none;
  }

  .input-range__label--min {
    .input-range__label-container {
      left: 0;
    }
  }

  .input-range__label--max {
    .input-range__label-container {
      left: 0;
    }
  }

  .input-range__label--value {
    top: auto;
    bottom: -40px;
  }

  &.out-of-range .input-range__label--value {
    display: none;
  }

  .input-range__label--value .input-range__label-container {
    display: inline-block;
    box-sizing: content-box;
    position: relative;
    bottom: 5px;
    font-size: ${tu.fontSize(0)};
    text-align: center;
    border-radius: ${tu.radius(1)};
    padding: ${tu.space(1)} ${tu.space(2)};
    white-space: nowrap;
    will-change: width;
    background-color: ${tu.color('primary')};
    color: ${tu.color('text')};

    &::after {
      content: ' ';
      position: absolute;
      border-width: 5px;
      left: 50%;
      margin-left: -5px;
      border-style: solid;
      bottom: 100%;
      border-color: transparent transparent ${tu.color('primary')} transparent;
    }
  }

  &.position-left .input-range__label--value .input-range__label-container {
    left: -6px;

    &::after {
      left: 7px;
    }
  }

  &.position-right .input-range__label--value .input-range__label-container {
    left: calc(-100% + 7px);

    &::after {
      left: calc(100% - 6px);
    }
  }
`;

StyledInputRange.defaultProps = {
  color: 'text',
  bg: 'primary',
  trackColor: 'bg5',
  sliderColor: 'primary',
};

StyledInputRange.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
};

export default StyledInputRange;
