import PropTypes from 'prop-types';
import { css, styled } from 'styled-components';
import tu from '../../../utils/themeUtils';
import DropDown from '../../DropDown/DropDown';
import Box from '../../Box';

export default function PaginationSize({
  pageSizeOptions,
  pageSize,
  setPageSize,
  showPagination,
  ...props
}) {
  const mappedPageSizeOptions = pageSizeOptions.map((size) => ({
    value: size,
    label: String(size),
  }));

  return showPagination ? (
    <Box {...extractProps(props)}>
      <StyledSpan>View results</StyledSpan>
      <Box width={['100%', 'auto']}>
        <DropDown
          items={mappedPageSizeOptions}
          value={pageSize}
          onChange={({ value }) => {
            setPageSize(value);
          }}
        />
      </Box>
    </Box>
  ) : null;
}

PaginationSize.propTypes = {
  pageSizeOptions: PropTypes.array.isRequired,
  pageSize: PropTypes.any,
  showPagination: PropTypes.bool,
  setPageSize: PropTypes.func,
};

const StyledSpan = styled.span`
  color: ${tu.color('text')};
  font-size: ${tu.fontSize('sm')};
  ${css(
    tu.mq({
      marginBottom: [tu.space('xxs'), '0'],
      marginRight: ['0', tu.space('sm')],
    })
  )}
`;

/**
 * Remove unwanted props from usePagination to prevent them from being applied to the div
 */
function extractProps(props) {
  // eslint-disable-next-line no-unused-vars
  const { manual, pages, page, onPageChange, ...rest } = props;
  return rest;
}
