import { styled } from 'styled-components';
import calcTimeRemaining from '../utils/calcTimeRemaining';

/**
 * Display the estimated time for the amount of blocks provided
 * @param {Object} props
 * @param {number} props.blocksCount - the amount of blocks to calculate by
 */
export default function EstimatedTimeLeft({ blocksCount }) {
  return <NoWrap>~ {calcTimeRemaining(blocksCount)}</NoWrap>;
}

const NoWrap = styled.span`
  white-space: nowrap;
`;
