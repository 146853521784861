import { useMemo } from 'react';
import { css, styled } from 'styled-components';
import { LockIdentifiers, Transaction } from '@zen/zenjs';
import {
  Box,
  ConfirmationModalContent,
  CopiedMessage,
  CopyableAsset,
  CopyableHash,
  Input,
  themeUtils as tu,
  Title,
} from '@zen-common/components-base';
import { useCopy, useShowMore } from '@zen/common-react-hooks';
import { assetUtils, ValueDisplay } from '@zen/common-utils';
import * as TD from '../../TechnicalData/TechnicalData';
import { SpendUtils } from '../../Spend';
import * as ZenJsUtils from '../../../utils/zenJsUtils';
import SettingsStore from '../../../stores/SettingsStore';

export default function ConfirmPublishTxModalContent({
  tx,
  title,
  progress,
  onBack,
  onConfirm,
  confirmText = 'Publish',
}) {
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const txData = useMemo(() => getTxData({ tx, chain }), [chain, tx]);

  return (
    <ConfirmationModalContent
      title={<Title>{title}</Title>}
      confirmText={confirmText}
      cancelText="Back"
      onCancel={onBack}
      onConfirm={onConfirm}
      progress={progress}
    >
      <Box>
        <TxInfoSection tx={tx} {...txData} />
        <PkWitnessesSection witnesses={txData.pkWitnesses} />
        <ContractWitnessesSection witnesses={txData.contractWitnesses} />
      </Box>
    </ConfirmationModalContent>
  );
}

function TxInfoSection({ tx, txHash, fee, activationFee, extensionFee }) {
  const [copied, copyError, onCopy] = useCopy({ value: tx });

  const handleCopy = () => {
    if (tx) {
      onCopy();
    }
  };

  return (
    <TD.Section>
      <TD.Title>TX Info</TD.Title>
      <Box>
        <Box>
          <TD.Label>Tx - Hex format</TD.Label>
        </Box>
        <Box my="sm">
          <Input
            inputType="multiline"
            readOnly
            value={tx}
            onClick={handleCopy}
            variant="block"
            rows="3"
          />
          <CopiedMessage copied={copied} error={copyError} />
        </Box>
      </Box>
      <Box>
        <TD.Label>TX Hash:</TD.Label>
        <TD.Value>
          <CopyableHash hash={txHash} />
        </TD.Value>
      </Box>
      <Fee fee={fee} label="Fee" />
      {activationFee && <Fee fee={activationFee} label="Activation fee" />}
      {extensionFee && <Fee fee={extensionFee} label="Extension fee" />}
    </TD.Section>
  );
}

/**
 * @param {Object} props
 * @param {[string]} props.witnesses
 */
function ContractWitnessesSection({ witnesses }) {
  const { isMore, items, toggleMore } = useShowMore({ items: witnesses, defaultCount: 3 });
  if (!witnesses || !witnesses.length) return null;

  const showMore =
    witnesses.length > 3 ? <TD.ShowMoreLink isMore={isMore} onClick={toggleMore} /> : null;

  return (
    <TD.Section>
      <Box>
        <TD.Title>Contract Witnesses</TD.Title>
        {showMore}
      </Box>
      {items.map((witness) => (
        <Box key={witness}>
          <TD.Label>Contract witness:</TD.Label>
          <TD.Value>
            <CopyableHash hash={witness} />
          </TD.Value>
        </Box>
      ))}
    </TD.Section>
  );
}

/**
 * @param {Object} props
 * @param {[{address: string, signature: string}]} props.witnesses
 */
function PkWitnessesSection({ witnesses }) {
  const { isMore, items, toggleMore } = useShowMore({ items: witnesses, defaultCount: 3 });
  if (!witnesses || !witnesses.length) return null;

  const showMore =
    witnesses.length > 3 ? <TD.ShowMoreLink isMore={isMore} onClick={toggleMore} /> : null;

  return (
    <TD.Section>
      <Box>
        <TD.Title>PK Witnesses</TD.Title>
        {showMore}
      </Box>
      <Table>
        {items.map((witness, index) => (
          <Row key={index}>
            <Col>
              <TD.Label>PK witness (signed by):</TD.Label>
              <TD.Value>
                <CopyableHash hash={witness.address} />
              </TD.Value>
            </Col>
            <Col>
              <TD.Label>Signature:</TD.Label>
              <TD.Value>
                <CopyableHash hash={witness.signature} />
              </TD.Value>
            </Col>
          </Row>
        ))}
      </Table>
    </TD.Section>
  );
}

function Fee({ label, fee }) {
  return (
    <Box>
      <TD.Label>{label}:</TD.Label>
      {fee ? (
        <>
          <TD.Value>{fee.amount.display}</TD.Value>
          <TD.Value>
            <CopyableAsset hash={fee.asset} />
          </TD.Value>
        </>
      ) : (
        <TD.Value>No Fee</TD.Value>
      )}
    </Box>
  );
}

function getTxData({ tx, chain }) {
  const txObj = Transaction.fromHex(tx);
  const txHash = txObj.hash().hash;
  const witnessesData = txObj.witnesses.witnesses.reduce(
    (data, witness) => {
      const witnessJson = witness.toJson();

      // pk witness
      if (witness.identifier === 1) {
        data.pkWitnesses.push({
          address: ZenJsUtils.getPublicKeyAddress(chain, witnessJson.publicKey),
          signature: witnessJson.signature,
        });
      }

      // contract witness
      if (witness.identifier === 2) {
        data.contractWitnesses.push(ZenJsUtils.getContractAddress(chain, witnessJson.contractId));
      }

      return data;
    },
    { contractWitnesses: [], pkWitnesses: [] }
  );

  const outputData = txObj.outputs.outputs.reduce(
    (data, output) => {
      if (LockIdentifiers[output.lock.identifier] === 'Fee') {
        data.fee = getOutputSpend(output);
      } else if (LockIdentifiers[output.lock.identifier] === 'ActivationSacrifice') {
        data.activationFee = getOutputSpend(output);
      } else if (LockIdentifiers[output.lock.identifier] === 'ExtensionSacrifice') {
        data.extensionFee = getOutputSpend(output);
      }

      return data;
    },
    {
      fee: null,
      activationFee: null,
      extensionFee: null,
    }
  );

  return {
    txHash,
    ...outputData,
    ...witnessesData,
  };
}

function getOutputSpend(output) {
  return SpendUtils.getSpend({
    asset: output.spend.asset.getAsset(),
    assetValid: true,
    amount: ValueDisplay.create(assetUtils.fromKalapas(output.spend.amount)),
    amountValid: true,
  });
}

const Table = styled(Box)`
  ${tu.mq({
    display: ['block', null, 'table'],
  })}
`;

const Row = styled(Box)`
  ${css(
    tu.mq({
      display: ['block', null, 'table-row'],
      marginBottom: [tu.space('sm'), null, 0],
    })
  )}
  &:last-child {
    margin-bottom: 0;
  }
`;

const Col = styled(Box)`
  ${tu.mq({
    display: ['block', null, 'table-cell'],
  })}
`;
