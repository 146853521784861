import { ConfigStore, ReceiveForm, TitleContentLayout, WalletStore } from '@zen/common-app-parts';
import { Box } from '@zen-common/components-base';

export default function Receive() {
  const {
    state: {
      currentWalletInfo: {
        keys: { address, addresses },
      },
    },
    actions: { updateAddress },
  } = WalletStore.useStore();
  const config = ConfigStore.useStore();
  return (
    <TitleContentLayout title="Receive">
      <Box maxWidth="700px">
        <ReceiveForm
          address={address}
          addresses={addresses}
          config={config}
          updateAddress={updateAddress}
        />
      </Box>
    </TitleContentLayout>
  );
}
