import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import {
  Box,
  Button,
  Centered,
  cssMixins,
  Form,
  FormBody,
  FormGroup,
  InfoBox,
  InputMessage,
  Label,
  LabelText,
  LinkButton,
  LoadingOr,
  Paragraph,
  PasswordInput,
  themeUtils as tu,
  Title,
  useModal,
} from '@zen-common/components-base';
import WalletStore from '../../stores/WalletStore';
import ConfirmWipeModal from '../modals/ConfirmWipeModal';

/**
 * @param {Object} props
 * @param {Function} props.onWipe - a function to call when wipe is clicked
 * @param {boolean} props.showAccount - a boolean to render the account label
 */
export default function UnlockWallet({ onWipe, showAccount = true }) {
  const {
    actions: { login },
    state: {
      viewAccount,
      currentWalletInfo: { isWatchMode, hasPassphrase, label },
    },
  } = WalletStore.useStore();
  const { show, hide } = useModal();
  const PasswordInputRef = useRef();
  const PassphraseInputRef = useRef();
  const [password, setPassword] = useState('');
  const [passphrase, setPassphrase] = useState('');

  const [error, setError] = useState('');
  const [progress, setProgress] = useState(false);
  const isDisabled = progress || !password;
  const passwordInputValidState = error !== '' ? false : undefined;

  useEffect(() => {
    if (hasPassphrase) {
      PassphraseInputRef.current.focus();
    } else {
      PasswordInputRef.current.focus();
    }
  }, [hasPassphrase]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);
    try {
      await login(password, passphrase, '0');
    } catch (error) {
      setError(error.message);
      setProgress(false);
      PasswordInputRef.current.focus();
    }
  };

  const handleOnChangePassword = (e) => {
    setPassword(e.target.value.trim());
    setError('');
  };

  const handleOnChangePassphrase = (e) => {
    setPassphrase(e.target.value);
    setError('');
  };

  const onForgotPassword = (e) => {
    e.preventDefault();
    show(() => (
      <ConfirmWipeModal
        onCancel={hide}
        onConfirm={() => {
          hide();
          onWipe && onWipe();
        }}
      />
    ));
  };

  return (
    <AbsoluteCentered>
      <FormContainer>
        <Title mb="4">Welcome Back{label ? `, ${label}` : ''}!</Title>
        <Paragraph color="label" mb="lg" fontSize="md">
          Please enter your password to continue
        </Paragraph>
        <Form onSubmit={handleSubmit} disabled={progress}>
          <FormBody>
            {showAccount && (
              <FormGroup mb="smd">
                <Box display="inline-block">
                  <Label htmlFor="UnlockWallet-Account">
                    <StyledLabelText>Account {viewAccount + 1}</StyledLabelText>
                  </Label>
                  <InputMessage data-testid="UnlockWallet-Account-error-message" />
                </Box>
              </FormGroup>
            )}
            {hasPassphrase && !isWatchMode && (
              <FormGroup mb="smd">
                <InfoBox info="A passphrase is the 25th word of the seed that can be any word, phrase, paragraph that generates a deterministic unique account">
                  <Label htmlFor="UnlockWallet-Passphrase">
                    <LabelText>Passphrase</LabelText>
                  </Label>
                </InfoBox>
                <PasswordInput
                  id="UnlockWallet-Passphrase"
                  ref={PassphraseInputRef}
                  value={passphrase}
                  variant="block"
                  onChange={handleOnChangePassphrase}
                  disabled={progress}
                />
                <InputMessage />
              </FormGroup>
            )}
            <FormGroup mb="smd">
              <Label htmlFor="UnlockWallet-Password">
                <LabelText>Password</LabelText>
              </Label>
              <PasswordInput
                id="UnlockWallet-Password"
                ref={PasswordInputRef}
                valid={passwordInputValidState}
                variant="block"
                value={password}
                onChange={handleOnChangePassword}
                autoComplete="current-password"
                disabled={progress}
              />
              <InputMessage data-testid="UnlockWallet-error-message">{error}</InputMessage>
            </FormGroup>
            <FormGroup>
              <Button type="submit" sizeVariant="large" width="100%" disabled={isDisabled}>
                <LoadingOr loading={progress} data-testid="UnlockWallet-loading">
                  Unlock Wallet
                </LoadingOr>
              </Button>
            </FormGroup>
            <FormGroup mb="0">
              <LinkButton fontSize="2" onClick={onForgotPassword} disabled={progress}>
                Forgot password?
              </LinkButton>
              <Paragraph mb="0" color="label" fontSize="md">
                Import your wallet again or create a new one
              </Paragraph>
            </FormGroup>
          </FormBody>
        </Form>
      </FormContainer>
    </AbsoluteCentered>
  );
}
UnlockWallet.propTypes = {
  onAction: PropTypes.func,
  error: PropTypes.string,
  progress: PropTypes.bool,
  logo: PropTypes.element,
};

const AbsoluteCentered = styled(Centered)`
  ${cssMixins.fadeIntoView}
  flex-grow: 1;
  flex-shrink: 0;
`;
const StyledLabelText = styled(LabelText)`
  color: ${tu.color('text')};
  font-size: ${tu.fontSize('md')};
`;
export const UnlockWalletActions = {
  SUBMIT: 'submit',
  FORGOT_PASSWORD: 'forgot',
  PASSWORD_CHANGE: 'password-change',
};

const FormContainer = styled(Box)`
  padding: ${tu.space('mlg')};
  ${cssMixins.containerXPadding}
  ${tu.mq({
    minWidth: ['100%', null, '450px'],
  })}
`;
