import { css, keyframes, styled } from 'styled-components';
import { color, compose, display, flexbox, fontSize, space } from 'styled-system';
import PropTypes from 'prop-types';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import FaSvg from './helpers/FaSvg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const getIconAnimation = (p) => css`
  animation: ${rotate} ${p.duration ? p.duration : '1s'} infinite ${p.timing ? p.timing : 'linear'};
`;

const StyledLoading = styled.span`
  ${compose(color, space, fontSize, display, flexbox)}
  > svg {
    ${getIconAnimation}
  }
`;

const Loading = (props) => (
  <StyledLoading {...props}>
    <FaSvg icon={faSpinnerThird} />
  </StyledLoading>
);

Loading.propTypes = {
  duration: PropTypes.string,
  timing: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Loading.defaultProps = {
  color: 'text',
  display: 'flex',
  justifyContent: 'center',
};

export default Loading;
