import { styled } from 'styled-components';
import { color, layout, space, typography } from 'styled-system';
import PropTypes from 'prop-types';
import { getTransition } from '../../utils/styles/mixins';

export const StyledSvg = styled.svg`
  display: inline-block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: middle;
  ${typography}
  ${space}
  ${color}
  ${layout}
  ${getTransition({ property: 'color', timing: '0.2s', duration: 'ease-in-out' })}
`;

const FaSvg = ({ icon, ...props }) => (
  <StyledSvg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${icon.icon[0]} ${icon.icon[1]}`}
    {...props}
  >
    <path fill="currentColor" d={icon.icon[4]} />
  </StyledSvg>
);
FaSvg.propTypes = {
  icon: PropTypes.object,
};

export default FaSvg;
