import { styled } from 'styled-components';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import FaSvg from '../helpers/FaSvg';

const StyledSpan = styled.span`
  cursor: pointer;
  padding: 0 ${(p) => (p.visible ? '9px' : '10px')};

  &:hover {
    filter: brightness(1.25);
  }
`;

export default function ToggleVisibilityIcon({ visible, onClick, ...p }) {
  const handleKeyDown = (event) => {
    // Check if the key pressed is Enter or Space
    if (event.key === 'Enter' || event.key === 'Space' || event.key === ' ') {
      // Prevent the default action to avoid any unintended behavior (especially for Space key)
      event.preventDefault();
      // Trigger the onClick function
      onClick(event);
    }
  };

  return (
    <StyledSpan
      role="button" // Indicate the role of this element as a button
      tabIndex="0" // Make it focusable
      {...p}
      onClick={onClick} // Mouse click handler
      onKeyDown={handleKeyDown} // Keyboard input handler
      // You might also consider adding aria attributes for improved accessibility
      aria-pressed={visible} // Indicates the state of the toggle
    >
      {visible ? (
        <FaSvg icon={faEyeSlash} fontSize="1rem" width="18px" />
      ) : (
        <FaSvg icon={faEye} fontSize="1rem" width="16px" />
      )}
    </StyledSpan>
  );
}
