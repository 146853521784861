import { UpdateIndicator } from '@zen-common/components-base';
import { WalletStore } from '@zen/common-app-parts';
import {
  CgpPhaseChangedStore,
  GovernancePhaseChangedStore,
} from '../../../stores/ChangeNotificationsStore';

/**
 * Show an update indicator if any of the votes has changed
 */
export default function VotingPhaseChangedUpdateIndicator(props) {
  const {
    state: { changed: cgpChanged },
  } = CgpPhaseChangedStore.useStore();
  const {
    state: { changed: governanceChanged },
  } = GovernancePhaseChangedStore.useStore();
  const {
    state: {
      currentWalletInfo: { isLoggedIn },
    },
  } = WalletStore.useStore();

  return <UpdateIndicator show={isLoggedIn && (cgpChanged || governanceChanged)} {...props} />;
}
