import { styled } from 'styled-components';
import { DateRangeInput } from '@zen/styled-datepicker';
import Box from '../../Box';

function DatePickerRange({ store, isDateBlocked }) {
  const { state, dispatch } = store;
  return (
    <Styled>
      <DateRangeInput
        onDatesChange={(data) => dispatch({ type: 'dateChange', payload: data })}
        onFocusChange={(focusedInput) => dispatch({ type: 'focusChange', payload: focusedInput })}
        startDate={state.startDate} // Date or null
        endDate={state.endDate} // Date or null
        focusedInput={state.focusedInput} // START_DATE, END_DATE or null
        showSelectedDates={false}
        phrases={{
          datepickerStartDatePlaceholder: 'Select',
          datepickerStartDateLabel: 'Start date:',
          datepickerEndDatePlaceholder: 'Select',
          datepickerEndDateLabel: 'End date:',
          resetDates: 'Reset',
          close: '',
          startDatePlaceholder: 'DD/MM/YY',
          endDatePlaceholder: 'DD/MM/YY',
        }}
        isDateBlocked={isDateBlocked}
        displayFormat="dd/MM/yy"
        numberOfMonths={1}
      />
    </Styled>
  );
}

export default DatePickerRange;

const Styled = styled(Box)`
  input {
    -webkit-border-radius: 0;
    border-radius: 0;
  }
`;
