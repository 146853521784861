import { forwardRef } from 'react';
import { FormGroup } from '../Form';
import Box from '../Box';
import {
  AddInputAligner,
  AddInputButton,
  InputAndRemoveAligner,
  RemoveInputButton,
} from './components/InputGroupBlocks';
import InputForm from './components/InputForm';

/**
 * Renders a group of Input with the ability to add or remove inputs
 * @type {React.ForwardRefExoticComponent<TInputGroupProps & React.RefAttributes<any>}
 */
const InputGroup = forwardRef(
  (
    {
      inputs,
      inputsValid,
      validAddress,
      setAddresses,
      removeInput,
      addInput,
      addButtonAlignment,
      readOnly,
      disabled,
    },
    ref
  ) => {
    const addAddressDisabled = !inputsValid() || inputs.length > 1;

    const checkValid = (item) => {
      return validAddress(item) && checkEquals();
    };

    const checkEquals = () => {
      const _inputs = [];
      for (let address of inputs) {
        if (_inputs.map((a) => a.value).indexOf(address.value) < 0) _inputs.push(address);
      }
      return inputs.length === _inputs.length;
    };

    return (
      <Box>
        {inputs.map((item, index) => (
          <FormGroup key={index}>
            <InputAndRemoveAligner
              isFirst={index === 0}
              noMarginTop
              input={
                <InputForm
                  readOnly={readOnly}
                  value={item.value}
                  onChange={setAddresses}
                  variant="block"
                  index={index}
                  inputValid={() => checkValid(item)}
                  inputType="expandable"
                  ref={ref}
                  invalidMessage={
                    !checkEquals() ? 'Cannot add the same address' : 'Invalid address'
                  }
                />
              }
              button={<RemoveInputButton onClick={() => removeInput(index)} />}
              showButton={!readOnly && !disabled && inputs.length > 1}
            />
          </FormGroup>
        ))}
        {!readOnly && !disabled && (
          <FormGroup mb={6}>
            <AddInputAligner align={addButtonAlignment}>
              <AddInputButton disabled={addAddressDisabled} onClick={addInput} />
            </AddInputAligner>
          </FormGroup>
        )}
      </Box>
    );
  }
);
InputGroup.displayName = 'InputGroup';
export default InputGroup;

/**
 * @typedef {Object} TInputGroupProps
 * @property {[import('../types').Input]} inputs
 * @property {boolean} spendsValid
 * @property {[string]} assets
 * @property {{[asset: string]: string}} balance
 * @property {({ value: string, index: number }) => void} setAsset
 * @property {({ value: string, index: number }) => void} setAmount
 * @property {(index: number) => void} removeInput
 * @property {() => void} addInput
 * @property {boolean} noMarginTop - no margin above each input
 * @property {('default'|'inline'|'grid')} alignment - the input alignment
 * @property {('left'|'right')} addButtonAlignment
 * @property {boolean} readOnly - make the inputs readonly
 * @property {boolean} disabled - disable the inputs completely (no events)
 */
