import { UpdateIndicator } from '@zen-common/components-base';
import { WalletStore } from '@zen/common-app-parts';
import { CgpPhaseChangedStore } from '../../../stores/ChangeNotificationsStore';

export default function CgpPhaseChangedUpdateIndicator(props) {
  const {
    state: { changed },
  } = CgpPhaseChangedStore.useStore();
  const {
    state: {
      currentWalletInfo: { isLoggedIn },
    },
  } = WalletStore.useStore();

  return <UpdateIndicator show={isLoggedIn && changed} {...props} />;
}
