import { styled } from 'styled-components';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import Box from '../../Box';
import FaSvg, { StyledSvg } from '../../helpers/FaSvg';
import TransparentButton from './TransparentButton';

const ToggleButton = ({ isOpen, onClick, ...props }) => {
  return (
    <StyledBox px="xxs">
      <TransparentButton onClick={onClick} {...props}>
        <FaSvg icon={!isOpen ? faAngleDown : faAngleUp} size="1.4rem" color="label" />
      </TransparentButton>
    </StyledBox>
  );
};

export default ToggleButton;

const StyledBox = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 34px;

  &:hover {
    ${StyledSvg} {
      filter: brightness(1.25);
    }
  }

  ${StyledSvg} {
    -webkit-appearance: none;
  }
`;
