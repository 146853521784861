import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiService } from '@zen/common-app-parts';
import { calcBalance } from '../../utils';

/**
 * Fetches the CGP snapshot balance
 */
export default function useCgpSnapshotBalance({ voteStatus, nodeUrl, addressCgp, snapshotBlock }) {
  const [fetched, setFetched] = useState(false);
  // load once only - this should stay the same for the whole vote
  const { data: contractBalance } = useQuery(
    ['cgp-fund-snapshot-balance', { nodeUrl, addressCgp, snapshotBlock }],
    fetchCgpSnapshotBalance,
    { enabled: voteStatus !== 'between', refetchOnWindowFocus: false }
  );

  // set not fetched when the interval changes
  useEffect(() => {
    setFetched(false);
  }, [snapshotBlock]);
  // set fetched as soon as balance was fetched
  useEffect(() => {
    if (contractBalance) {
      setFetched(true);
    }
  }, [contractBalance]);

  const cgpSnapshotBalance = useMemo(() => {
    if (!contractBalance) return {};

    return (contractBalance || []).reduce((balance, pointedOutput) => {
      return calcBalance({ balance, pointedOutput });
    }, {});
  }, [contractBalance]);

  return {
    cgpSnapshotBalance: cgpSnapshotBalance,
    cgpSnapshotBalanceFetched: fetched,
  };
}

function fetchCgpSnapshotBalance(_, { nodeUrl, addressCgp, snapshotBlock }) {
  return ApiService.getBalance(nodeUrl, { addresses: [addressCgp], blockNumber: snapshotBlock });
}
