import PropTypes from 'prop-types';
import { useModal } from '@zen-common/components-base';
import SettingLayout from '../containers/SettingLayout';
import SettingButton from '../common/SettingButton';
import ChangePasswordModal from './components/ChangePasswordModal';

const ChangePasswordSetting = ({ useSetting, ...props }) => {
  const { show, hide } = useModal();

  const showChangePasswordModal = () => {
    show(() => <ChangePasswordModal hide={hide} useSetting={useSetting} />);
  };

  return (
    <SettingLayout header="Login Password" paragraph="Change your login password" {...props}>
      <SettingButton onClick={showChangePasswordModal}>Change</SettingButton>
    </SettingLayout>
  );
};

ChangePasswordSetting.propTypes = {
  useSetting: PropTypes.func,
};

export default ChangePasswordSetting;
