import { useEffect } from 'react';
import { assetUtils } from '@zen/common-utils';
import {
  BlockchainInfoStore,
  ConfigStore,
  SettingsStore,
  WalletStore,
} from '@zen/common-app-parts';
import { GovernancePhaseChangedStore } from '../../stores/ChangeNotificationsStore';
import {
  getStatus,
  getVoteStatus,
  useContractInfo,
  useFetchCandidates,
  useFetchContestants,
  useFetchOtherPhase,
  useFetchRelevantPhase,
  useFetchUserSnapshotZpBalance,
  useFetchVote,
} from './store';
import Governance from './Governance';

export default function GovernanceContainer() {
  const {
    state: {
      settings: { chain, nodeUrl },
    },
  } = SettingsStore.useStore();
  const {
    state: {
      currentWalletInfo: { balance },
      executing,
    },
  } = WalletStore.useStore();
  const {
    state: {
      infos: { headers },
    },
  } = BlockchainInfoStore.useStore();
  const config = ConfigStore.useStore();

  const {
    state: { changed },
    actions: { clear: clearPhaseChanged },
  } = GovernancePhaseChangedStore.useStore();

  // clear phase changed when page is mounted
  useEffect(() => {
    clearPhaseChanged();
  }, [clearPhaseChanged, changed]);

  const { contractAddress, contractId } = useContractInfo({ chain, config });

  const {
    data: relevantPhase,
    status: relevantStatus,
    refetch: refetchRelevant,
  } = useFetchRelevantPhase({
    chain,
    config,
  });
  useRefetchRelevant({ relevantPhase, headers, refetch: refetchRelevant });
  const voteStatus = getVoteStatus({ ...relevantPhase, headers });

  const { data: otherPhase } = useFetchOtherPhase({
    chain,
    config,
    relevantPhase,
    voteStatus,
  });

  // calculate currentPhase, by headers and 2 phases, make phase transition smooth
  const currentPhase = !relevantPhase
    ? null
    : headers < relevantPhase.endBlock
    ? relevantPhase
    : otherPhase && headers < otherPhase.endBlock
    ? otherPhase
    : relevantPhase;

  const { data: contestants } = useFetchContestants({
    chain,
    config,
    currentPhase,
    voteStatus,
  });
  const { data: candidates, status: candidatesStatus } = useFetchCandidates({
    chain,
    config,
    currentPhase,
    voteStatus,
  });
  const { initialVotesFetchDone, refetchVote, vote, voted, setVoted, voteFetchStatus } =
    useFetchVote({
      contractId,
      currentPhase,
      nodeUrl,
      voteStatus,
    });
  const contestantVote = voteStatus === 'contestant' ? vote : null;
  const candidateVote = voteStatus === 'candidate' ? vote : null;

  const thresholdZp = currentPhase ? assetUtils.fromKalapas(currentPhase.threshold || 0) : '0';
  const zpBalance = assetUtils.getAssetBalance('00', balance);
  const { snapshotZpBalance, snapshotZpBalanceStatus } = useFetchUserSnapshotZpBalance({
    currentPhase,
    voteStatus,
  });

  const phaseOne = currentPhase
    ? currentPhase.phase === 'Contestant'
      ? currentPhase
      : otherPhase
    : null;
  const phaseTwo = currentPhase
    ? currentPhase.phase === 'Candidate'
      ? currentPhase
      : otherPhase
    : null;

  const status = getStatus({
    voteStatus,
    relevantStatus,
    snapshotZpBalanceStatus,
    candidatesStatus,
    initialVotesFetchDone,
    voteFetchStatus,
  });

  return (
    <Governance
      contractAddress={contractAddress}
      headers={headers}
      chain={chain}
      zpBalance={zpBalance}
      phaseOne={phaseOne}
      phaseTwo={phaseTwo}
      currentPhase={currentPhase}
      thresholdZp={thresholdZp}
      snapshotZpBalance={snapshotZpBalance}
      contestants={contestants}
      candidates={candidates}
      contestantVote={contestantVote}
      candidateVote={candidateVote}
      status={status}
      refetchVote={refetchVote}
      executing={executing}
      voted={voted}
      setVoted={setVoted}
    />
  );
}

/**
 * Refetch the relevant interval at the right time
 * @param {Object} params
 * @param {number} params.headers
 * @param {import('./types').Phase} params.relevantPhase
 * @param {Function} params.refetch
 */
function useRefetchRelevant({ headers, relevantPhase, refetch }) {
  const { endBlock } = relevantPhase || {};

  useEffect(() => {
    if (!endBlock) return;

    if (headers >= endBlock) {
      refetch();
    }
  }, [endBlock, headers, refetch]);
}
