import { useState } from 'react';
import PasswordRequiredModalContentBase from '../../modals/PasswordRequiredModal/PasswordRequiredModalContentBase';
import BackupWalletModalContent from '../common/BackupWalletModalContent';
import SecurityWarningModalContent from '../common/SecurityWarningModalContent';
import WalletStore from '../../../stores/WalletStore';
import SettingsStore from '../../../stores/SettingsStore/SettingsStore';
import { PasswordRequiredModalContent } from '../../modals/PasswordRequiredModal';
import OptionsModal from './components/OptionsModalContent';
import { actions } from './actions';

export default function DeleteWalletSettingProcess({ onDone, useSetting }) {
  const {
    state: {
      wallets,
      currentWalletInfo: { isWatchMode: isWatch },
    },
  } = WalletStore.useStore();
  const {
    state: {
      settings: { chain },
    },
  } = SettingsStore.useStore();
  const [status, setStatus] = useState('options');
  const [data, setData] = useState();

  const { error, loading, onAction } = useSetting();

  function gotoBackup() {
    setStatus('security-warning');
  }

  function gotoOptions() {
    setStatus('options');
  }

  function gotoWipeAll() {
    setStatus('password-wipe-all');
  }

  function gotoWipeSingle() {
    setStatus('password-wipe');
  }

  async function handleSubmitBackup(password) {
    const data = await onAction({
      type: actions.backup,
      value: { password },
    });
    if (data) {
      setData(data);
      setStatus('show-backup');
    }
  }

  async function handleSubmitWipe(password) {
    await onAction({
      type: actions.delete,
      value: { password: password },
    });
    onDone();
  }

  async function handleSubmitWipeAll(password) {
    await onAction({
      type: actions.deleteAll,
      value: { password: password },
    });
    onDone();
  }

  if (status === 'options') {
    return (
      <OptionsModal
        onBackupWallet={gotoBackup}
        onWipe={gotoWipeSingle}
        onWipeAll={gotoWipeAll}
        isWatch={isWatch}
        hasMultiple={wallets[chain].length !== 1}
      />
    );
  }

  if (status === 'security-warning') {
    return (
      <SecurityWarningModalContent
        cancelText="Back"
        onCancel={() => setStatus('options')}
        onConfirm={() => setStatus('password-backup')}
      />
    );
  }

  if (status === 'password-backup') {
    return (
      <PasswordRequiredModalContentBase
        error={error}
        progress={loading}
        onSubmit={handleSubmitBackup}
        cancelText="Back"
        onCancel={() => setStatus('security-warning')}
      />
    );
  }

  if (status === 'password-wipe') {
    return (
      <PasswordRequiredModalContent
        onSuccess={handleSubmitWipe}
        cancelText="Back"
        onCancel={gotoOptions}
      />
    );
  }
  if (status === 'password-wipe-all') {
    return (
      <PasswordRequiredModalContent
        onSuccess={handleSubmitWipeAll}
        cancelText="Back"
        onCancel={gotoOptions}
      />
    );
  }

  if (status === 'show-backup') {
    return (
      <BackupWalletModalContent
        {...data}
        onCancel={onDone}
        cancelText="Cancel"
        onConfirm={gotoOptions}
        confirmText="Wipe Seed"
      />
    );
  }

  return null;
}
