/* eslint-disable react/display-name */
import { useMemo } from 'react';
import { Decimal } from 'decimal.js';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, CopyableHash, FaSvg } from '@zen-common/components-base';
import { numberUtils } from '@zen/common-utils';
import VoteResultsTable from '../../../components/voting/VoteResultsTable';
import VoteTableButton from '../../../components/voting/VoteTableButton';

export default function BallotsTable({
  onRowClick,
  canVote,
  progress,
  thresholdZp,
  data,
  ...props
}) {
  const columns = useMemo(
    () => getColumns({ canVote, progress, thresholdZp }),
    [canVote, progress, thresholdZp]
  );

  return <VoteResultsTable columns={columns} data={data} onRowClick={onRowClick} {...props} />;
}

function getColumns({ canVote, progress, thresholdZp } = {}) {
  return [
    {
      accessor: 'ballot',
      Header: 'Payout Ballot ID',
      minWidth: 150,
      Cell: ({ value }) => <CopyableHash hash={value} copy={true} />,
    },
    {
      accessor: 'zpAmount',
      Header: 'ZP Voted',
      Cell: ({ value }) => numberUtils.toDisplay(value, 2),
    },
    {
      // threshold indication
      accessor: 'zpAmount',
      Header: '',
      minWidth: 20,
      Cell: ({ value }) =>
        new Decimal(value || 0).gte(thresholdZp || Number.MAX_SAFE_INTEGER) ? (
          <Box title="passed threshold">
            <FaSvg icon={faCheckCircle} />
          </Box>
        ) : null,
    },
    {
      className: 'force-hidden-mobile align-right',
      headerClassName: 'force-hidden-mobile align-right',
      Cell: () => {
        if (canVote) {
          return <VoteTableButton disabled={progress} />;
        }
        return null;
      },
    },
  ];
}
