import PropTypes from 'prop-types';
import { styled } from 'styled-components';
import { Box, Button, cssMixins, Header } from '@zen-common/components-base';

const { fadeIntoView } = cssMixins;

const StyledStartOnBoarding = styled(StartOnBoarding)`
  ${fadeIntoView}
`;

function StartOnBoarding({ onCreate, onImport, onWatch, appName, ...props }) {
  return (
    <StyledCenterContainer {...props}>
      <Column>
        <AlignCenterCol>
          <Header fontWeight="400" mb="xl">
            {appName}
          </Header>
        </AlignCenterCol>
        <AlignCenterCol>
          <StyledButton onClick={onCreate} mb="smd">
            Create a Wallet
          </StyledButton>
          <StyledButton onClick={onImport} mb="smd" bg="secondary">
            Import a Wallet
          </StyledButton>
          {appName.includes('Wallet') && (
            <StyledButton onClick={onWatch} bg="secondary">
              Watch only mode
            </StyledButton>
          )}
        </AlignCenterCol>
      </Column>
    </StyledCenterContainer>
  );
}

StyledStartOnBoarding.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  appName: PropTypes.string,
};

export default StyledStartOnBoarding;

const StyledCenterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const AlignCenterCol = styled(Column)`
  align-items: center;
`;

const StyledButton = styled(Button).attrs({ sizeVariant: 'large' })`
  width: 100%;
  max-width: 208px;
`;
