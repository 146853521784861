import { useCallback } from 'react';
import { useNavigation } from 'react-navi';
import { UnlockWallet } from '@zen/common-app-parts';
import PATHS from '../paths';

export default function UnlockWalletPage() {
  const { navigate } = useNavigation();

  const onWipe = useCallback(() => {
    navigate(PATHS.WIPE_SEED);
  }, [navigate]);

  return <UnlockWallet onWipe={onWipe} />;
}
